import { useState, useRef, useCallback, useEffect } from 'react'
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useDebounceCallback } from '@react-hook/debounce'

import './styles.scss';

import useClickOutside from "../../hooks/useClickOutside";

export default function ColorPicker({ name, initColor, alignRight, onChange, enableTransparent }) {
  initColor = initColor || '#fff'
  const [color, setColor] = useState(initColor);
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  useEffect(() => {
    setColor(initColor)
  }, [initColor])

  const debounced = useDebounceCallback(
    (value) => {
      onChange({ target: { name: name, value: value } })
    },
    400
  );

  let leftAlign = alignRight ? '-170px' : '0';

  const handleChange = value => {
    setColor(value)
    debounced(value)
  };

  return (
    <div className="picker">
      <div
        className="swatch h-10 w-10"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div style={{ left: leftAlign }} className="z-50 popover rounded-md border border-gray-300 text-gray-500 p-3 bg-white" ref={popover}>
          <HexColorPicker color={color} onChange={handleChange} />
          <div>
            <div className="mt-2 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">#</span>
              </div>
              <HexColorInput className='block w-full pl-7 rounded-md border border-gray-300 text-gray-500' color={color} onChange={handleChange} />
            </div>
            <div className='flex justify-between'>
              <button
                type="button"
                onClick={close}
                className="inline-flex mt-3 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Close
              </button>
              {enableTransparent && <button
                type="button"
                onClick={() => handleChange("transparent")}
                className={`inline-flex mt-3 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 ${color === 'transparent' ? "outline-none ring-2 ring-offset-2 ring-indigo-500" : ""}`}
              >
                Transparent
              </button>}
            </div>
          </div>

        </div>
      )}
    </div>
  );
}