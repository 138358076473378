import { useEffect, useRef, useState } from "react";
import { getStep } from "../../api/public/dialogValues";

export const useAutomaticSignUrlCheck = (onCheck, responsePropKey, onComplete) => {
    const [isChecking, setIsChecking] = useState(false);
    const intervalRef = useRef(null);
    const prevResponseRef = useRef(null);
    const checkCountRef = useRef(0);
    const [checkResponse, setCheckResponse] = useState();


    const pollingChecksPerSecond = 1
    const secondsToPollFor = 30;

    useEffect(() => {
        // load()

        return () => stopChecking()
    }, []);

    useEffect(() => {
        if(checkResponse){
            validateResponseResult();
        }
    }, [checkResponse])

    const validateResponseResult = () => {
        let shouldTerminate = false;

        checkCountRef.current = checkCountRef.current + 1;
        const checkDurationSeconds = checkCountRef.current / pollingChecksPerSecond;

        if (checkDurationSeconds > secondsToPollFor) {
            shouldTerminate = true;
        }

        if (checkResponse && checkResponse[responsePropKey]) {
            shouldTerminate = true;
            onComplete(checkResponse[responsePropKey]);
        }
        prevResponseRef.current = checkResponse;
        if (shouldTerminate) {
            stopChecking();
        }
    }

    const startChecking = () => {
        setIsChecking(true);
        setIsChecking(true)
        intervalRef.current = setInterval(() => {
            check();
        }, 1000 / pollingChecksPerSecond)
    }

    const stopChecking = () => {
        try {
            setIsChecking(false)
            checkCountRef.current = 0;
            clearInterval(intervalRef.current)
        }
        catch {
            // swallow error
        }
    }

    const check = async () => {
        try {
            const res = await onCheck();
            setCheckResponse(res);
        } catch {

        }
    }

    return {
        startChecking,
        isChecking
    }
}