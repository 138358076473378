import { Fragment, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import Button from '../../../components/Button';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getPublicVideos } from '../../../api/videos';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Link } from 'react-router-dom';
import { useTableActions } from '@metaforcelabs/metaforce-core';
const dummyLoadData = [{}, {}, {}, {}, {}];

const videoState = {
  Draft: 0,
  Published: 1
};

const formInitialValues = {
  searchQuery: ''
};

const VideoScreen = () => {
  const [formValues, ] = useState(formInitialValues);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);

  const videosAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 10);

  const fetchVideos = (values) => {
    videosAction.execute(async () => {
      const getVideosResult = await getPublicVideos();
      const filter = values ? values.searchQuery.toLowerCase() : false;
      setRecordsToShow(getVideosResult
        .filter(x => !filter ||
            (x.title.toLowerCase().includes(filter) || x.content.toLowerCase().includes(filter)))
        .sort((a, b) => b.priority - a.priority));
    }, 'Failed to load videos');
  };

  const handleOnResetFilter = (resetAction) => {
    resetAction();
    fetchVideos();
  };

  const handleOnSubmit = async (values) => {
    fetchVideos(values);
  };

  useEffect(() => {
    fetchVideos(formValues);
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">Videos</h1>
        </div>

        <div>
          <Formik initialValues={formValues} enableReinitialize={true} onSubmit={handleOnSubmit}>
            {(props) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <FormikInput label="Search" name="searchQuery" formikProps={props} />
                  </div>

                  <div className="flex items-end ml-auto col-span-3 sm:col-span-2">
                    <Button
                      variant={Button.variants.secondary}
                      onClick={() => handleOnResetFilter(props.resetForm)}
                    >
                      Reset
                    </Button>

                    <FormikSubmitButton
                      id="filter"
                      text="Filter"
                      className="ml-2 i"
                      formikProps={props}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="relative mt-8">
          <Table>
            <TableHead>
              <TableHeader>Title</TableHeader>
              <TableHeader>Content</TableHeader>
              <TableHeader>Created</TableHeader>
            </TableHead>
            <TableBody>
              {tableActions.pageData?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'} data-id={row.id}>
                    <TableColumn>
                      <Link
                        to={`${row.url}`}
                        className="font-medium text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.title}
                      </Link>
                    </TableColumn>
                    <TableColumn className="px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">{row.content}</TableColumn>
                    <TableColumn>{stringToLocaleDateTimeString(row.createdDate)}</TableColumn>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <TableCore.Pagination tableActions={tableActions} />
        </div>
      </div>
    </>
  );
};

export default VideoScreen;
