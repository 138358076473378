import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import './styles.scss';
import { config } from '../../utils/config';
import { OidcRoutesContext, TopbarContext } from '../../contexts';
import { featureFlags } from '../../utils/features';
import { useFeature } from '../../hooks/useFeature';
import * as Constants from '../../utils/constants';
import { useSelector } from 'react-redux';
import { Badge } from '../../components/Badge';
import { MenuBar } from '@metaforcelabs/metaforce-core';

export default function AdminLayout({ nav, subMenuNav, children }) {
  const feature = useFeature();
  let { dialogKey } = useParams();
  let { pathname } = useLocation();
  const closeButtonRef = useRef();
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const currentDialogDefinition = useSelector(state => state.dialogDefinitions.current)
  const [displayDialogNav, setDisplayDialogNav] = useState(false);
  const [otherUserIsActive, setOtherUserIsActive] = useState(false);
  const [openHelperSidebar, setOpenHelperSidebar] = useState(false);
  const [showHelperSidebar, setShowHelperSidebar] = useState(false);

  const NavigationMenuReactLinkElement = (
    name,
    route,
    currentCheckValue,
    featureEnabledCheckResult = true,
    children
  ) => {
    return {
      linkElement: <Link to={route}>{name}</Link>,
      name: name,
      route: route,
      current: currentCheckValue,
      featureEnabled: featureEnabledCheckResult,
      children
    };
  };

  const sideBadge =
  <>
    {
      otherUserIsActive &&
      <Badge type="warn" text="Other active user" className='mr-2' />
    }

    {
      currentDialogDefinition?.editState === Constants.editStates.draft &&
      <Badge type="default" text="Draft" />
    }

    {
      currentDialogDefinition && currentDialogDefinition?.editState !== Constants.editStates.draft &&
      <Badge type="success" text="Published" />
    }
  </>

  const checkIfSmartFormsSubMenuEnabled = feature.hasFeature(Constants.designFeatureCode) && !!dialogKey;

  const smartFormSubMenu = [
    NavigationMenuReactLinkElement('Design', '/admin/dialog/' + dialogKey + '/design', nav === "dialog_design", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Configuration', '/admin/dialog/' + dialogKey + '/configurations', nav === "dialog_configurations", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Integration', '/admin/dialog/' + dialogKey + '/integration', nav === "dialog_integration", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Process', '/admin/dialog/' + dialogKey + '/process', nav === "dialog_process", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Records', '/admin/dialog/' + dialogKey + '/records', nav === "dialog_records", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Translations', '/admin/dialog/' + dialogKey + '/language', nav === "dialog_language", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Versions', '/admin/dialog/' + dialogKey + '/versions', nav === "dialog_versions", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Notification', '/admin/dialog/' + dialogKey + '/notification', nav === "dialog_notification", checkIfSmartFormsSubMenuEnabled),
    NavigationMenuReactLinkElement('Security', '/admin/dialog/' + dialogKey + '/security', nav === "dialog_security", checkIfSmartFormsSubMenuEnabled)
  ];
  const dataloaderSubMenu = [
    NavigationMenuReactLinkElement('Load', '/admin/dataload/', nav === "data_load"),
    NavigationMenuReactLinkElement('Export', '/admin/dataexport/', nav === "data_export")
  ];
  const templatesSubMenu  = [
    NavigationMenuReactLinkElement('Smart Object', '/admin/templates/smartobject', nav === "templates_smartobject"),
    NavigationMenuReactLinkElement('Smartforms', '/admin/templates/smartforms', nav === "templates_smartform"),
    NavigationMenuReactLinkElement('Object', '/admin/templates/objects', nav === "import_object"),
    NavigationMenuReactLinkElement('Email', '/admin/templates/email', nav === "templates_email"),
    NavigationMenuReactLinkElement('SMS', '/admin/templates/sms', nav === "templates_sms"),
    NavigationMenuReactLinkElement('Authentication', '/admin/templates/auth', nav === "templates_auth"),
    NavigationMenuReactLinkElement('Theme Style', '/admin/templates/themestyle', nav === "templates_themestyle"),
    NavigationMenuReactLinkElement('Contact Lists', '/admin/templates/contact-lists', nav === "templates_contactlists"),
    NavigationMenuReactLinkElement('Resources', '/admin/templates/resources', nav === "templates_resources")
  ];
  const helperMenu = [
    {
        name: 'Documentation',
        onClick: () => window.location.href = config.docsUrl,
        isExternal: true
    },
    {
        name: 'Education videos',
        onClick: () => window.location.replace("/admin/videos")
    }
  ];

  const navigationMenu = [
    NavigationMenuReactLinkElement('Home', '/', nav === 'home'),
    NavigationMenuReactLinkElement('Smart forms', '/admin/smartforms', nav?.includes('dialog_'), 
     true, smartFormSubMenu
    ),
    NavigationMenuReactLinkElement('Dataloader', '/admin/dataload', nav?.includes('data_'), 
      feature.hasFeature(Constants.dataFeatureCode), dataloaderSubMenu
    ),
    NavigationMenuReactLinkElement('Templates', '/admin/templates/smartobject', nav?.includes('templates_') || nav?.includes('import_object'), 
      featureFlags.template && feature.hasFeature(Constants.templateFeatureCode), templatesSubMenu
    ),
    NavigationMenuReactLinkElement('Dashboard', '/admin/dashboard', nav?.includes('multi_step_forms'), feature.hasFeature(Constants.dataFeatureCode)
    ),
    NavigationMenuReactLinkElement('Api', '/admin/api', nav?.includes('api'), feature.hasFeature(Constants.integrationCode)
    )
  ];

  useEffect(() => {
    closeButtonRef?.current?.click();
    if (pathname.includes('dialog') && dialogKey && dialogKey.length)
    {
      setDisplayDialogNav(true);
    }
  }, [pathname])

  const isDesignMode = pathname.includes("design");
  const styleForDesignMode = {
    backgroundColor: "#faf5e2"
  }

  return (
    <TopbarContext.Provider
      value={{
        setOtherUserIsActive: setOtherUserIsActive,
        openHelperSidebar: openHelperSidebar,
        setOpenHelperSidebar: setOpenHelperSidebar,
        setShowHelperSidebar: setShowHelperSidebar,
      }}
    >
      <div className="relative flex flex-col bg-white h-screen">
        <MenuBar
            navigation={navigationMenu}
            customerName={oidcRoutesContext?.customerInfo?.name}
            customerOrgNumber={oidcRoutesContext?.customerInfo?.orgNumber}
            homeButtonLink={config?.centerpointUiBaseUrl}
            userProfileImage={oidcRoutesContext.userProfile.userProfileImage}
            helpLink={helperMenu}
            userName={oidcRoutesContext?.userProfile?.fullName}
            sideSubMenuElement={sideBadge}
            bulbIconAction={showHelperSidebar ? () => setOpenHelperSidebar(true) : undefined}
        />
        <main className={`main-container bg-brand-background z-0 focus:outline-none h-full overflow-auto`} style={isDesignMode ? styleForDesignMode : {} }>
          <div className={`max-w-screen-3xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8`}>
            <div className="flex flex-col pb-10 h-full">
              <div className={`max-w-screen-3xl h-full`}>
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </TopbarContext.Provider>
  )
}