import React, { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { useQuill } from 'react-quilljs';
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import './styles.scss'

Quill.register('modules/magicUrl', MagicUrl);

export default function RichTextWrapper({ id, value, handleOnChangeContent, elementsClasses, showHeaderAlways, onInputClicked, disabled, key, isPdfMode, height, width, ...props }) {
  const modules = {
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ color: [] }, { background: [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ],
  };
  const formats = ["align", "list", "bullet", "background", "color", "size", "font", "underline", "strike", "italic", "bold", "link", "indent"]

  const { quill, quillRef } = useQuill({ modules, formats, readOnly: disabled });
  const componentRef = useRef()
  const [firstRender, setFirstRender] = useState(true);

  const style = {
    
    height,
    minHeight: '100px',
    maxHeight: 'max-content',
    backgroundColor: 'white',
    wordBreak: "break-word",
    minWidth: "20rem",
    maxWidth: "43rem",
    width
  }

  if(disabled) {
    style.backgroundColor = "rgba(243, 244, 246)"
    style.padding = "10px"
    style.border = "solid 1px"
    style.borderRadius = "6px"
    style.borderColor = "rgba(209, 213, 219)"
  }

  const showToolbar = (show) => {
    if (!showHeaderAlways && componentRef.current) {
      const qlSnowSelectElement = componentRef.current.getElementsByClassName("ql-toolbar")
      qlSnowSelectElement && (qlSnowSelectElement[0].hidden = !show)
      quillRef.current.style.border = !show ? "none" : ""
      componentRef.current.style.padding = disabled ? "10px" : !show ? "0px" : "0px 0px 43px 0px"

      let editor = quillRef.current.getElementsByClassName('ql-editor')[0]

      if (editor) {
        editor.style.padding = !show ? "0" : "12px 15px"
      }
    }
  }

  useEffect(() => {
    if (quill) {
      value && quill.clipboard.dangerouslyPasteHTML(value);
      firstRender && showToolbar(!disabled && !isPdfMode)
      quill.on('text-change', (delta, oldDelta, source) => {
        const valueInHtml = quillRef?.current
        handleOnChangeContent(valueInHtml?.firstChild?.innerHTML);
      });
      setFirstRender(false)
    }
  }, [quill, disabled]);

  return (
    <div
      ref={componentRef}
      className={`mb-1 ${elementsClasses}`}
      style={style}
      onClick={onInputClicked}
    >
      <div ref={quillRef} key={key} />
    </div>
  )
}