import { DialogModes, ElementContext } from "../../contexts";
import { setProperty } from "../../utils/property";
import { useElementDesign } from "../../elements/useElementDesign";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { OpenElementProperties } from "../../utils/elements";
import { useAutoSize } from "../../hooks/useAutoSize";
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'

/**
 * Returns a textarea for InlineEditing or his own children
 * @param {Object} initData 
 * @param {Object} style  - Inline styles for textarea
 * @param {String} classes - className for textarea
 * @param {String} name - name for textarea
 * @param {String} id - id for textarea - optional
 * @param {String} value - value for textarea
 * @param {Boolean} autoSize - enable auto size for textarea
 */
export default function InlineEditing({
    initData,
    style,
    classes = "",
    name,
    children,
    id = null,
    value = null,
    autoSize = false,
    useHighlightWithinTextarea = false,
    disabledFullWidth = false,
}) {
    const elementContext = useContext(ElementContext) || {};
    const { isEditMode } = useContext(DialogModes);
    const [clonedData, setClonedData] = useState(_.cloneDeep(initData));
    const [inputValue, setInputValue] = useState(value ?? initData[name] ?? '');
    const editorWrapper = useRef();
    const inputRef = useRef();

    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
    const elementDesign = useElementDesign(initData.id, initData);
    const isLocked =  elementDesign.isLocked();
    useAutoSize(inputRef.current, inputValue, autoSize, false, style)

    function parseInlineStyle(style) {
        const template = document.createElement('template');
        template.setAttribute('style', style)
        return Object.entries(template.style)
            .filter(([ key ]) => !/^[0-9]+$/.test(key))
            .filter(([ , value ]) => Boolean(value))
            .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), {});
    }

    const onChange =  useCallback((value) => {
        const type = "text"
        const verifiedValue = useHighlightWithinTextarea ? value : value.target.value
        const customEventObject = {
            target: {
                name,
                value: verifiedValue
            }
        };

        elementContext?.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)
        setProperty(clonedData, name, verifiedValue, type);
        setClonedData((data) => ({
            ...data,
        }));
        setInputValue(verifiedValue)
    }, [clonedData, dialogDefinition, elementContext?.actions]);

    useEffect(() => {
        setClonedData(_.cloneDeep(initData));
        setInputValue(initData[name])

        if(useHighlightWithinTextarea && editorWrapper.current) {
            const wrapper = editorWrapper.current;
            let editor = wrapper.getElementsByClassName("DraftEditor-root")[0];

            editor = editor || wrapper.getElementsByClassName(`inline-editing-${clonedData.id}`)[0];

            if(editor) {
                // string to array of strings separated by space
                let classesToAdd =`${classes} bg-transparent resize-none border-0 px-0 py-0 w-full inline-editing-${clonedData.id}`.split(' ');
                classesToAdd = classesToAdd.filter((item) => item !== '');

                editor.classList.remove(...editor.classList);
                editor.classList.add(...classesToAdd)

                for(var styleCamelCase in style) {
                    const styleSnakeCase = styleCamelCase.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
                    editor.style[styleSnakeCase] = style[styleCamelCase];
                }
            }
        }

        // console.log('inputRef.current', inputRef.current)
    }, [initData, name]);

    return (
        isEditMode && !isLocked ?
            (
                <div ref={editorWrapper} className={`${disabledFullWidth ? "w-10/12" : "w-full"}`}>
                {
                useHighlightWithinTextarea ? 
                <div 
                    onClick={(e) => OpenElementProperties(e, elementDesign.onClick)}
                >
                    <HighlightWithinTextarea
                        id={id ?? `inline-editing-${clonedData.id}`}
                        rows={1}
                        ref={editorWrapper}
                        style={style}
                        name={name}
                        value={inputValue}
                        onChange={(value) => onChange(value)}
                        highlight={[
                            { highlight: /\{{ (.*?)\ }}/g, className: 'bg-yellow-200' },
                            { highlight: /\[\[ (.*?)\ ]\]/g, className: 'bg-blue-200' },
                            { highlight: /\<< (.*?)\ >>/g, className: 'bg-green-200' },
                        ]}
                    />
                </div>
                :
                <textarea
                    id={id ?? `inline-editing-${clonedData.id}`}
                    ref={inputRef}
                    rows={1}
                    style={style}
                    className={`${classes} bg-transparent resize-none border-0 px-0 py-0 w-full`}
                    name={name}
                    value={inputValue}
                    onChange={onChange}
                    onClick={(e) => OpenElementProperties(e, elementDesign.onClick)}
                />
                }
            </div>
            )
            
        : 
            children
    );
}