import { RadioGroup } from "@headlessui/react";

export const ToggleButton = ({ elements, toggle, setToggle, disabled = false }) => {
    return (
        <RadioGroup value={toggle} onChange={setToggle} disabled={disabled}>
            <div className="group p-0.5 rounded-lg flex bg-gray-100 hover:bg-gray-200">

                {elements?.map((item) => {
                    return (
                    <RadioGroup.Option value={item.name}>
                        {({ checked, active }) => (
                            <button
                                className={`h-8 w-32 flex justify-center items-center focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2 rounded-md focus:outline-none focus-visible:ring-offset-gray-100 ${checked ? "bg-white shadow-sm ring-1 ring-black ring-opacity-5" : ""}
                            ${active && !disabled ? "bg-white shadow-sm ring-1 ring-black ring-opacity-5" : ""}`}
                            >
                                { item.icon && 
                                    <span className={`lg:mr-2 ${checked && !disabled ? "text-green-500" :"text-gray-500 group-hover:text-gray-900"}`}>
                                        <item.icon height={20} width={20} aria-hidden="true"/>
                                    </span>}
                                <span className={`${checked && !disabled ? "text-gray-900" : "text-gray-600 group-hover:text-gray-900"}`}>{item.name}</span>
                            </button>
                        )}
                    </RadioGroup.Option>
                    );
                })}
            </div>

        </RadioGroup>
    );
};
