import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { createNewDataLoad, validateImportFile } from '../../../api/dataLoad';
import { getDialogDefinition } from '../../../api/dialogDefinition';
import Loader from '../../../components/Loader';
import { useClassNames } from '../../../hooks/useClassNames';
import { useToastAction } from '../../../hooks/useToastAction';
import { useDebounce } from '@react-hook/debounce'
import ImportStatusBar from './importStatusBar';

export default function ImportDataLoad() {
    const { dialogKey } = useParams();
    const [dialog, setDialog] = useState(null);
    const [name, setName] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileContent, setFileContent] = useState(null);
    const [importValidationResult, setImportValidationResult] = useState(null);
    const [importStatus, setImportStatus] = useState({ statusText: "Waiting for file", status: "info" });
    const [draggingFile, setDraggingFile] = useState(false);
    const [toggleDraggingDebounce, setToggleDraggingDebounce] = useDebounce(null, 100);

    const navigate = useNavigate();
    const loadFileAction = useToastAction();
    const importAction = useToastAction();
    const loadAction = useToastAction();
    const { classNames } = useClassNames();

    useEffect(() => {
        loadAction.execute(async () => {
            const dialog = await getDialogDefinition(dialogKey);
            setDialog(dialog);
        }, "Failed to load dialog");
    }, []);

    useEffect(() => {
        updateImportStatus();
    }, [importValidationResult]);

    useEffect(() => {
        if (toggleDraggingDebounce) {
            setDraggingFile(false);
        }
    }, [toggleDraggingDebounce]);

    useEffect(() => {
        updateImportStatus();
    }, [name]);

    const updateImportStatus = () => {
        if (!importValidationResult) {
            setImportStatus(prev => { return { statusText: "Waiting for file", status: "info" } });
            return;
        }
        if (!importValidationResult.isValid) {
            setImportStatus(prev => { return { statusText: "Invalid import file", status: "error" } });
            return;
        }
        if (!name) {
            setImportStatus(prev => { return { statusText: "Name missing", status: "info" } });
            return;
        }
        setImportStatus(prev => { return { statusText: "Ready", status: "info" } })
    }

    const getFileContent = file => {
        return new Promise(resolve => {
            let content = "";
            let reader = new FileReader();

            reader.onload = () => {
                content = reader.result;
                resolve(content);
            };

            reader.readAsText(file);
        });
    };

    const handleFileDrop = (e) => {
        let file = e.dataTransfer.files[0]
        readInputFile(file);
        e.preventDefault();
    }

    const handleFileInputChange = async (e) => {
        let file = e.target.files[0];
        readInputFile(file);
    };

    const rebuildResult = async (result) => {
        const buildResult = result.split(/[,;|]|\\t|\\r/g)
        return buildResult.join(",")
    }

    const readInputFile = (file) => {

        loadFileAction.execute(async () => {
            setFileName(null);
            setFileContent(null);
            setImportValidationResult(null);
            const result = await getFileContent(file);

            const buildResult = await rebuildResult(result)

            file["base64"] = buildResult;
            setFileName(file.name)
            setFileContent(buildResult);
            const validationResult = await validateImportFile(dialog.id, buildResult, fileName, name);
            setImportValidationResult(validationResult);
        }, "Failed to load file");
    }

    const handleImport = async () => {
        importAction.execute(async () => {
            const created = await createNewDataLoad(dialog.id, fileContent, fileName, name);
            navigate(`/admin/dataload/${dialogKey}/import/${created.id}`)
        }, "Import failed")
    }

    return (
        <div className="max-w-4xl">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Import data to: {dialog?.name}</h3>
            </div>
            <div className="mt-4 pb-4 border-b border-gray-200">
                <ImportStatusBar status={importStatus} />
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Data Load Name
                    </label>
                    <input
                        type={"text"}
                        name="name"
                        value={name}
                        className={`focus:ring-gray-400 focus:border-gray-400  block w-full sm:text-sm border-gray-300 rounded-md ${[].find(e => e === "name") ? 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500' : ''}`}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                { fileContent === null && 
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Import File
                    </label>
                    <div onDrop={handleFileDrop}
                        onDragOver={e => {
                            setDraggingFile(true);
                            setToggleDraggingDebounce(new Date().getMilliseconds());
                            e.preventDefault();
                        }}
                        onDragEnter={e => {
                            e.preventDefault();
                        }}
                        className={classNames("w-full flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md", draggingFile ? "border-indigo-600" : "border-gray-300")}>
                        <div className="space-y-1 text-center">
                            {
                                !loadFileAction.isExecuting &&
                                (<svg
                                    className={classNames("mx-auto h-12 w-12", draggingFile ? "text-indigo-600" : "text-gray-400")}
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>)
                            }
                            {
                                loadFileAction.isExecuting && (
                                    <Loader />
                                )
                            }
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-300"
                                >
                                    <span>Upload a file</span>
                                    <input id="file-upload" disabled={loadFileAction.isExecuting} accept=".csv" name="file-upload" type="file" className="sr-only" onChange={handleFileInputChange} />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">.CSV</p>
                        </div>
                    </div>
                </div>
                }
                <div className="mt-4">

                    {
                        importValidationResult &&
                        (<>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Import details
                            </label>
                            <div className="text-sm text-gray-500 grid grid-cols-3 gap-y-1 gap-x-1">
                                <div className="font-medium">File name:</div> 
                                    <div className="col-span-2">
                                        <label className='mr-2'>{fileName}</label> 
                                        <label htmlFor="file-upload" className="col-span-1 relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-300"> 
                                            <span aria-hidden="true">&rarr; </span> 
                                            Change file 
                                            <input id="file-upload" accept=".csv" name="file-upload" type="file" className="sr-only w-full" onChange={handleFileInputChange}/>
                                        </label>
                                    </div>
                                <div className="font-medium">Dialogs to create:</div><div className="col-span-2">{importValidationResult.noOfRecipients}</div>
                                <div className="font-medium">Is Valid:</div><div className="col-span-2">{importValidationResult.isValid ? 'Yes' : 'No'}</div>
                                {
                                    !!importValidationResult.errors?.length && (
                                        <><div className="font-medium">Errors:</div><div className="col-span-2 text-red-400">{importValidationResult.errors.map(e => <div>{e}</div>)}</div></>
                                    )
                                }
                            </div>
                        </>)
                    }
                </div>

            </div>
            <div className="mt-4 flex">
                <Link
                    to={`/admin/dataload/${dialogKey}`}
                    className="w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"

                >

                    Cancel
                </Link>
                <button
                    type="button"
                    className="ml-5 justify-center flex items-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                    disabled={!importValidationResult?.isValid || !name || importAction.isExecuting}
                    onClick={() => handleImport()}
                >
                    {
                        importAction.isExecuting && (
                            <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        )
                    }
                    Import
                </button>
            </div>

        </div>
    )
}
