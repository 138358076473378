import { useState } from "react"
import { ArrowSmUpIcon, ArrowSmDownIcon } from "@heroicons/react/outline"
import { BaseUrl, get } from "../api/api"
import { useEffectAfterMount } from "./useEffectAfterMount";
import { useToastAction } from "./useToastAction";

/**
 * @typedef {Object} ServerSideTableActions
 * @prop {boolean} isLoaded If true, initial load has happened 
 * @prop {boolean} isLoading If a search is currently happening
 * @prop {number} page Current page number. 0 based
 * @prop {React.Dispatch<React.SetStateAction<number>>} setPage Set current page
 * @prop {number} currentPageStart Starting record index on the current page
 * @prop {number} currentPageEnd Ending record index on the current page
 * @prop {number} totalRecordsCount Total number of records that match the provided filters
 * @prop {number?} nextPage Index of the next page. If not set, current page is the last page
 * @prop {number?} previousPage Index of the previous page. If not set, current page is the first page
 * @prop {object} queryStringParams Filter object that will get sent to the server as query string parameters
 * @prop {React.Dispatch<React.SetStateAction<{}>>} setQueryStringParams Set filters. Resets to the first page and triggers a search.
 * @prop {number} pageSize
 * @prop {React.Dispatch<React.SetStateAction<number>>} setPageSize Updates the page size. Triggers a search.
 * @prop {string} sortBy Property to sort by
 * @prop {React.Dispatch<React.SetStateAction<number>>} setSortBy Updates which property to sorty by. Triggers a search.
 * @prop {boolean} descending If search is descending
 * @prop {React.Dispatch<React.SetStateAction<boolean>>} setDescending Updates if search is descending/ascending. Triggers a search.
 * @prop {any[]} pagedData Records for the current page
 * @prop {() => Promise<void>} search Perform a search based on current filters & page settings
 * @prop {(propertyName: string) => void} orderBy Set the name of the property to sort by. Triggers a search.
 * @prop {(propertyName: string) => JSX.Element | null} getSortIcon Gets the current sort icon (up or down arrow). Returns null if property isn't currently being sorted on.
 */

/**
 * @returns {ServerSideTableActions}
 */
export const useServerSideTableActions = (resourcePath, {
  queryStringParams: queryStringParamsProp = {},
  pageSize: pageSizeProp = 10,
  sortBy: sortByProp = '',
  descending: descendingProp = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const searchAction = useToastAction()

  const [queryStringParams, setQueryStringParams] = useState(queryStringParamsProp)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(pageSizeProp)
  const [sortBy, setSortBy] = useState(sortByProp)
  const [descending, setDescending] = useState(descendingProp)

  const [records, setRecords] = useState([])
  const [totalRecordsCount, setTotalRecordsCount] = useState()
  const [currentPageStart, setCurrentPageStart] = useState()
  const [currentPageEnd, setCurrentPageEnd] = useState()
  const [nextPage, setNextPage] = useState()
  const [previousPage, setPreviousPage] = useState()

  useEffectAfterMount(() => {
    search()
  }, [page, pageSize, sortBy, descending])

  useEffectAfterMount(() => {
    if (page !== 0) {
      // reset the page to 0 if the filter parameters change. This will trigger
      // the search when page is updated
      setPage(0)
    }
    else {
      // We are already on page 0. Filter parameters changed
      // so we need to trigger search.
      search()
    }
  }, [queryStringParams])

  const search = async () => {
    await searchAction.executeAsync(async () => {
      const result = await get(resourcePath, BaseUrl.Api, {
        axiosParams: {
          params: {
            ...queryStringParams,
            ...{
              page,
              pageSize,
              sortBy,
              descending,
            }
          }
        }
      })
      const { records, ...pageDetails } = result
      setRecords(records)

      setPage(pageDetails.currentPage)
      setTotalRecordsCount(pageDetails.totalRecordsCount);
      setCurrentPageStart(pageDetails.currentPageStart);
      setCurrentPageEnd(pageDetails.currentPageEnd);
      setNextPage(pageDetails.nextPage)
      setPreviousPage(pageDetails.previousPage)
    }, "Failed to load records")
    setIsLoaded(true)
  }

  return {
    isLoaded,
    isLoading: searchAction.isExecuting,
    page,
    setPage,
    currentPageStart,
    currentPageEnd,
    totalRecordsCount,
    nextPage,
    previousPage,
    queryStringParams,
    setQueryStringParams,
    pageSize,
    setPageSize,
    sortBy,
    setSortBy,
    descending,
    setDescending,
    pagedData: records,
    search,
    orderBy: (propertyName) => {
      if (propertyName === sortBy) {
        setDescending(!descending)
      }
      else {
        setSortBy(propertyName)
        setDescending(false)
      }
    },
    getSortIcon: (propertyName) => {
      if (propertyName !== sortBy) {
        return null;
      }
      return !descending
        ? <ArrowSmUpIcon className="h-5 w-5 inline" />
        : <ArrowSmDownIcon className="h-5 w-5 inline" />
    }
  }
}