import { Formik, Form } from "formik"
import * as yup from "yup";
import { Button } from "../../../components/Form/Button";
import Modal from "../../../components/Modal"
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { FormikPhoneInput } from "../../../components/Form/Formik/FormikPhoneInput";
import { FormikDate } from "../../../components/Form/Formik/FormikDate";
import { securityType } from "../../../utils/constants";
import { useToastAction } from "../../../hooks/useToastAction";
import { updateDialogValuesParticipatingParties } from "../../../api/dialogValues";

export const UpdateParticipatingParties = ({
  dialogValuesProcessDetails,
  onClose,
  onSaved,
}) => {

  const saveAction = useToastAction()
  const hasBankIdSecurityOption = (securityOptions) => {
    return securityOptions.find(so => so === securityType.bankId);
  };

  return (
    <Modal
      isOpen={!!dialogValuesProcessDetails}
      onClose={onClose}
      size="large"
      title="Update participating parties"
    >
      <div>
        <Formik
          initialValues={{
            participatingParties: dialogValuesProcessDetails?.participatingParties,
          }}
          validationSchema={() => {
            return yup.object().shape({
              participatingParties: yup.array().of(
                yup.object().shape({
                  name: yup.string().required("Required"),
                  email: yup.string().email("Must be an email").required("Required"),
                })
              )
            })
          }}
          onSubmit={(values, actions) => {
            saveAction.execute(async () => {
              try {
                const updateValues = values.participatingParties.map(p => {
                  return {
                    participatingPartyId: p.participatingPartyId,
                    name: p.name,
                    email: p.email,
                  }
                })
                await updateDialogValuesParticipatingParties(dialogValuesProcessDetails.id, updateValues)
                onSaved()
              }
              finally {
                actions.setSubmitting(false)
              }
            }, "Failed to save", "Updated participating parties")
          }}
        >
          {
            props => (
              <Form>
                {
                  dialogValuesProcessDetails?.participatingParties?.map((p, i) => {
                    return (
                      <div className={i > 0 ? 'mt-8' : ''}>
                        <div className="border-b border-gray-300 mb-4">
                          <h3 className="text-gray-900 text-md">
                            {p.participatingPartyName}
                            {
                              p.participatingPartyId === dialogValuesProcessDetails.currentParticipatingPartyId && " (current)"
                            }
                          </h3>
                        </div>
                        <div className={i > 0 ? 'mt-4 grid grid-cols-2 gap-2' : 'grid grid-cols-2 gap-2'}>
                          <FormikTextInput
                            label={`Name`}
                            name={`participatingParties[${i}].name`}
                            required={true}
                            formikProps={props}
                          />
                          <FormikTextInput
                            label={`Email`}
                            name={`participatingParties[${i}].email`}
                            required={true}
                            formikProps={props}
                          />
                        </div>
                        <div className="mt-4 grid grid-cols-2 gap-2">
                          {/* <FormikPhoneInput
                            label={`Phone`}
                            name={`participatingParties[${i}].phone`}
                            required={dialogValuesProcessDetails.sendSms === true}
                            formikProps={props}
                          /> */}
                          {
                            hasBankIdSecurityOption(p.securityOptions) &&
                            <FormikDate
                              label={`Birth date`}
                              name={`parties[${i}].birthDate`}
                              formikProps={props}
                            />
                          }
                        </div>
                      </div>
                    )
                  })
                }
                <div className="mt-10 flex justify-end gap-2">
                  <Button
                    onClick={onClose}
                    text="Close"
                    theme="white"
                  />
                  <FormikSubmitButton
                    formikProps={props}
                    text={"Save"}
                  />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Modal>
  )

}