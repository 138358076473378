import * as Property from "../../components/Properties";

export default function DynamicOptions({
    data,
    workflowResourceOptions,
    dynamicState, 
    setDynamicState
}) {

    const handleChange = (e) => {
        setDynamicState(e.target.value)
    } 

    return (
        <div className="">
        <Property.Select
          onChange={handleChange}
          label={"Workflow Retrieve"}
          name={"workflowRetrieveId"}
          value={dynamicState}
          options={workflowResourceOptions}
        />
        </div>
    );
}
