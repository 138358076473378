import { useEffect, useState } from "react"
import { useToastAction } from "../../hooks/useToastAction"
import { getDialogDefinitionProcess } from "../../api/public/dialogDefinition"
import { useParams, useNavigate } from "react-router"
import { processTypes } from "../../utils/constants"
import { useTokenAuthentication } from "./useTokenAuthentication"
import { getAuthenticationTemplateForDialog } from "../../api/public/authenticationTemplates"
import { useEnterKeyPress } from "../../hooks/useEnterKeyPress"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import 'react-phone-number-input/style.css'
import { Button } from "../../components/Form/Button"
import { getDialogValuesFromPhoneNumber } from "../../api/public/dialogDefinitionProcess"
import { PhoneInputConfig } from "../../utils/config"

export const AuthenticateSmsForm = () => {
  const { dialogKey, valuesKey } = useParams()
  const [authenticationTemplate, setAuthenticationTemplate] = useState([])
  const [definitionProcessId, setDefinitionProcessId] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [validPhoneNumberFormat, setValidPhoneNumberFormat] = useState(false)
  const navigate = useNavigate()
  const loadAction = useToastAction()
  const lookupPhoneNumberAction = useToastAction()
  const tokenAuthentication = useTokenAuthentication()

  useEffect(() => {
    loadAction.execute(async () => {
      const [process, authTemplate] = await Promise.all([
        getDialogDefinitionProcess(dialogKey),
        getAuthenticationTemplateForDialog(dialogKey),
      ])

      if (process.process !== processTypes.normal) {
        navigate("/not-found", { replace: true })
      }

      setDefinitionProcessId(process.definitionProcessId);
      setAuthenticationTemplate(authTemplate);
    })
  }, [])

  const lookupPhoneNumber = async () => {
    if (!validPhoneNumberFormat) {
      return
    }

    lookupPhoneNumberAction.execute(async () => {
      const result = await getDialogValuesFromPhoneNumber(definitionProcessId, valuesKey, phoneNumber)

      if (result) {
        tokenAuthentication.completeAuthenticationPhoneNumberLookup(result.dialogValuesId, result.jwt)
        navigate(`/dialog/${dialogKey}/form/${result.dialogValuesId}/authenticate`, { replace: true });
      }
      else {
        lookupPhoneNumberAction.toastError("You tried to retrieve a SMS code but your phone number is not in the access list")
      }
    })
  }

  useEffect(() => {
    setValidPhoneNumberFormat(phoneNumber && isPossiblePhoneNumber(phoneNumber))
  }, [phoneNumber])

  const enterKeyPress = useEnterKeyPress(lookupPhoneNumber)

  return (
    <div>
      <div
        className="flex flex-col relative h-screen"
        style={{ backgroundColor: authenticationTemplate?.backgroundColor, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url("data:image/png;base64,${authenticationTemplate?.backgroundImage?.content}") ` }}
      >
        {
          authenticationTemplate?.logo?.content &&
          <div className="absolute m-4 w-96">
            <img src={`data:image/png;base64,${authenticationTemplate.logo.content}`} alt="logo" className="w-full md:w-1/2 mx-auto md:mx-0" />
          </div>
        }

        <div className="my-auto md:m-auto">
          <div>
            {
              authenticationTemplate?.about &&
              <p className="max-w-md mb-4" style={{ color: authenticationTemplate?.fontColor }}>
                {authenticationTemplate.about}
              </p>
            }
          </div>
          <div className="w-96">
            <div className="w-full">
              <h3>
                Enter your phone number below to receive a security code
              </h3>
              <div className="mt-4 p1 pl-2 mt-1 border w-full shadow-sm  sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400">
                <PhoneInput
                  defaultCountry={PhoneInputConfig.defaulPhoneInputCountry}
                  international
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  countryCallingCodeEditable={false}
                  onKeyPress={enterKeyPress.onKeyPressed}
                />
              </div>
              <div className="mt-4">
                <Button
                  onClick={lookupPhoneNumber}
                  text={"Send code"}
                  theme="white"
                  fontSize="base"
                  disabled={!validPhoneNumberFormat || lookupPhoneNumberAction.isExecuting}
                  className="px-6 py-3 focus:ring-offset-2 focus:ring-indigo-500"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}