import { useState, useEffect, useRef } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { allFileTypesOptions } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  const requiredFieldRef = useRef()
  const readOnlyRef = useRef()
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="File upload"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Text'}
          name={'label'}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Max size in mb"}
          name={"maxFileSizeInMb"}
          type={'number'}
          maxNumber={10}
          value={data?.maxFileSizeInMb || 10}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data?.text.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          maxNumber={200}
          value={data?.text?.size}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Description'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.RequiredWithCustomMessage
          onChange={handleOnChange}
          data={data}
          ref={requiredFieldRef}
          dependency={readOnlyRef}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
          ref={readOnlyRef}
          dependency={requiredFieldRef}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Prevent data insert"}
          name={"preventDataInsert"}
          value={data?.preventDataInsert}
        />

        <Property.MultiSelect
          onChange={handleOnChange}
          label="Restricted file types"
          name="restrictedFileTypes"
          value={data?.restrictedFileTypes}
          options={allFileTypesOptions}
        />

      </Property.Group>

      <Property.Group title="Element Style">

        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

      </Property.Group>

    </div>
  )
}
