import { useEffect, useState } from "react";
import { TextInput } from "../../../components/Form/TextInput";
import Modal from "../../../components/Modal";
import { onKeyPressEnter } from "../../../utils/onKeyPress";

export default function OnRenameModal({ modalHelper, selectedDialogDefinition, onRenameSmartForm, }) {
  const [name, setName] = useState("");
  const onCancel = () => {
    setName("")
    modalHelper.close();
  };

  const onKeyPress = (event) => {
    onKeyPressEnter(event, "submitButton");
  };

  useEffect(() => {
    // When modal opens, initialize input text with the current smart form's name
    if (modalHelper.isOpen) {
      setName(selectedDialogDefinition.name)
    }
  }, [modalHelper.isOpen])

  const handleRename = (id, name) => {
    onRenameSmartForm(id, name)
    modalHelper.close();
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title={`Rename ${selectedDialogDefinition?.name} SmartForm`}
    >
      <TextInput
        name={`rename-smarform-${selectedDialogDefinition?.id}`}
        value={name}
        onChange={setName}
        onKeyPress={onKeyPress}
        required
      />
      <div className="mt-10 flex gap-2 justify-end">
        <button
          onClick={() => handleRename(selectedDialogDefinition?.id, name)}
          disabled={name.length === 0}
          type="button"
          id="submitButton"
          className="mt-3 ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
        >
          Rename
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}