import { get, post } from './api'

export const getAllMigrations = async () => {
  return await get('migrations');
}

export const getMigrationByMigrationName = async (migrationName) => {
  return await get(`migrations/${migrationName}`)
}

export const startMigration = async (migrationName) => {
  return await post(`migrations/${migrationName}`)
}
