import { Field, ErrorMessage } from 'formik';
import { Label } from '../Label';
import { useClassNames } from '../../../hooks/useClassNames';

export const FormikSelectInput = ({
  name,
  label,
  formikProps,
  options,
  onBlur,
  onChange,
  disabled = false,
  type = null,
  placeholder = null,
  required = false,
}) => {

  const { classNames } = useClassNames();

  const handleBlur = (evt) => {
    if (onBlur) {
      onBlur(evt)
    }
    if (formikProps?.handleBlur) {
      formikProps.handleBlur(evt)
    }
  }

  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  return (
    <div>
      {
        label && <Label name={name} label={label} required={required} />
      }
      <div className="mt-1">
        <Field
          as={type || "select"}
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''}`}
          placeholder={placeholder}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={(e) => {
            formikProps.setFieldValue(name, e.target.value);
            onChange && onChange()
          }}
        >
          {
            options?.map(option => {
              return (
                <option disabled={!!option.disabled} key={option.key || option.value} value={option.value} style={option?.style}>{option.name}</option>
              );
            })
          }
        </Field>
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
    </div>
  )
}