import { useState } from "react";
import { ButtonWithLabel } from "./buttonWithLabel";
import { elementTypes } from "../../utils/constants";
import { buildPropertyForConnectedObject } from "../../utils/elements";
import { InputTextLine } from "../../data";
import { TrashIcon } from "@heroicons/react/solid";

/**
 * @param {*} Border 
 */
export const ConnectedElements = ({ data, handleOnChange }) => {
  const handleAddConnectedElement = (elementType) => {
    let newElement;

    switch (elementType) {
      case elementTypes.inputTextLine:
      default:
        newElement = InputTextLine();
        
        break;
    }

    newElement.label = '';
    newElement.connectedParentElementId = data?.id;

    buildPropertyForConnectedObject(newElement, data?.connectedElements?.length);

    let options = data?.connectedElements || [];
    options.push(newElement);

    handleOnChange({
      target: {
        name: 'connectedElements',
        value: options
      }
    })
  }

  const handleRemoveConnectedElement = (id) => {
    let options = data?.connectedElements || [];
    options = options.filter((option) => option.id !== id);

    handleOnChange({
      target: {
        name: 'connectedElements',
        value: options
      }
    })
  }

  return (
    <>
      <ButtonWithLabel
          label="Elements"
          onClick={() => handleAddConnectedElement(elementTypes.inputTextLine)}
          text='Add elements'
        />

        <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">
          <div className='flex flex-grow mt-3 flex-col gap-1'>
          {
            data?.connectedElements?.map((element, index) => {
              return (
                <div className='flex flex-grow justify-between'>
                  <div>
                    {element?.property}
                  </div>
                  <div>
                  <TrashIcon className="w-5 cursor-pointer" onClick={() => handleRemoveConnectedElement(element.id)} />
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
    </>
  );
}