import { Disclosure } from "@headlessui/react";
import * as Icons from "@heroicons/react/solid"
import { Children } from "react";

/**
 * @param {*} tabs 
 */
export const ShowHideGroup = ({ label, children, defaultOpen = false, useGrid = false, id = null }) => {
  let selectedTab = null;

  return (
    <Disclosure as="div" id={id} defaultOpen={defaultOpen} className={`py-1 mb-4`} >
      {({ open }) => (
        <>
          <Disclosure.Button className={"bgroup flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md"}>
            <span className="flex-1">{label}</span>
            <Icons.ChevronRightIcon
              className={`${open ? "transform rotate-90" : ""} ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
            />
          </Disclosure.Button>

          <Disclosure.Panel
            className={`pt-3 ${useGrid ? 'grid grid-cols-2 gap-2' : ''}`}
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}