import { isContainerLocked } from "../../../utils/dialogDefinitions";

export const useDefinitionManager = () => {
  const addItemAbove = (items, itemId, newItem) => {
    if (newItem) {
      let itemIndex = items?.findIndex((item) => item.id === itemId);
      items.splice(itemIndex, 0, newItem);
    }
  }

  const addItemBelow = (items, itemId, newItem) => {
    if (newItem) {
      let itemIndex = items?.findIndex((item) => item.id === itemId);
      items.splice(itemIndex + 1, 0, newItem);
    }
  }

  const addItemTop = (items, newItem) => {
    items.unshift(newItem);
  }

  const addItemBottom = (items, newItem) => {
    items.push(newItem);
  }

  const addItem = (items, newItem) => {
    addItemBottom(items, newItem)
  }

  const addItemByIndex = (items, newItem, index) => {
    items?.splice(index, 0, newItem);
  }

  const removeItem = (items, itemId) => {
    let itemIndex = items?.findIndex((item) => item.id === itemId);
    items.splice(itemIndex, 1);
  }

  const swapItems = (itemIndexFrom, itemIndexTo, items) => {
    if (items.length > 1 && itemIndexTo >= 0 && itemIndexFrom >= 0 && itemIndexTo < items.length) {
      [items[itemIndexFrom], items[itemIndexTo]] = [items[itemIndexTo], items[itemIndexFrom]];
    }
  }

  const moveItemTop = (moveFrom, moveTo, item) => {
    removeItem(moveFrom, item.id)
    addItemTop(moveTo, item)
  }

  const moveItemBottom = (moveFrom, moveTo, item) => {
    removeItem(moveFrom, item.id)
    addItemBottom(moveTo, item)
  }

  const findContainerFromRow = (dialogDefinition, rowId) => {
    let containerForRow;

    dialogDefinition.containers?.forEach(container => {
      let row = container?.rows?.find(e => e.id === rowId);

      if (row)
        containerForRow = container;
    });

    return containerForRow;
  }

  const findContainerIndexFromRow = (dialogDefinition, rowId) => {
    let containerIndexForRow = -1;
    let indexCounter = -1;

    dialogDefinition.containers?.forEach(container => {
      indexCounter++;
      let row = container?.rows?.find(e => e.id === rowId);

      if (row)
        containerIndexForRow = indexCounter;
    });

    return containerIndexForRow;
  }

  const findContainer = (dialogDefinition, containerId) => {
    return dialogDefinition.containers?.find(c => c.id === containerId)
  }

  const findRowFromColumn = (dialogDefinition, columnId) => {
    let rowForColumn;

    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        let column = row?.columns?.find(e => e.id === columnId);

        if (column)
          rowForColumn = row;
      })
    });

    return rowForColumn;
  }

  const findRowFromElementId = (dialogDefinition, elementId) => {
    let rowForElement;

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.some(column => {
          let element = column?.elements?.find(e => e.id === elementId);
          if (element)
            rowForElement = row;
        })
      })
    });

    return rowForElement;
  }

  const findBeforeRowOfRowId = (dialogDefinition, rowId) => {
    let beforeRow;

    dialogDefinition.containers?.forEach(container => {
      const rowIndex = container?.rows.findIndex(row => row.id === rowId)
      beforeRow = container?.rows[rowIndex - 1]
    });

    return beforeRow;
  }

  const findNextRowOfRowId = (dialogDefinition, rowId) => {
    let nextRow;

    dialogDefinition.containers?.forEach(container => {
      const rowIndex = container?.rows.findIndex(row => row.id === rowId)
      nextRow = container?.rows[rowIndex + 1]
    });

    return nextRow;
  }

  const findContainerFromColumn = (dialogDefinition, columnId) => {
    let containerForColumn;

    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        let column = row?.columns?.find(e => e.id === columnId);

        if (column)
          containerForColumn = container;
      })
    });

    return containerForColumn;
  }

  const findContainerFromElement = (dialogDefinition, elementId) => {
    let containerForElement;

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.id === elementId);
          if (element)
            containerForElement = container;
        })
      })
    });

    return containerForElement;
  }

  const findRow = (dialogDefinition, rowId) => {
    let match
    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        if (row.id === rowId) {
          match = row
        }
      })
    });

    return match
  }

  const findColumn = (dialogDefinition, columnId) => {
    let match
    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        let column = row?.columns?.find(e => e.id === columnId);
        if (column)
          match = column
      })
    });

    return match
  }

  const findColumnFromElement = (dialogDefinition, elementId) => {
    let columnForElement;

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.id === elementId);
          if (element)
            columnForElement = column;
        })
      })
    });

    return columnForElement;
  }

  const findElement = (dialogDefinition, elementId) => {
    let match;

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.id === elementId);
          if (element)
            match = element;

          column?.elements?.forEach(e => {
            e?.connectedElements?.forEach(connectedElement => {
              if (connectedElement.id === elementId)
                match = connectedElement;
            })
          })
        })
      })
    });

    return match;
  }

  const findElementByProperty = (dialogDefinition, property) => {
    let match;

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.property === property);
          if (element)
            match = element;
        })
      })
    });

    return match;
  }

  const findLastContainer = (dialogDefinition) => {
    let totalItems = dialogDefinition?.containers?.length
    return totalItems > 0 ? dialogDefinition.containers[totalItems - 1] : null
  }

  const findLastUnlockedConatiner = (dialogDefinition) => {
    if (!dialogDefinition?.containers?.length > 0) {
      return null
    }

    // Loop from end of containers. Return the first one that is not locked to dialog object
    for (let i = dialogDefinition.containers.length - 1; i >= 0; i--) {
      const container = dialogDefinition.containers[i]
      if (!isContainerLocked(container)) {
        return container
      }
    }
    return null
  }

  const findLastRow = (container) => {
    let totalItems = container.rows.length

    return totalItems > 0 ? container.rows[totalItems - 1] : null
  }

  const findLastColumn = (row) => {
    let totalItems = row.columns.length

    return totalItems > 0 ? row.columns[totalItems - 1] : null
  }

  const findLastColumnForDialogDefinition = (dialogDefinition) => {
    let totalItems = dialogDefinition?.containers?.length
    const lastContainer = findLastUnlockedConatiner(dialogDefinition);
    const lastRow = findLastRow(lastContainer);
    return findLastColumn(lastRow);
  }

  const findLastElementFromContainer = (container) => {
    let lastElement;

    container?.rows.forEach(row => {
      row?.columns.forEach(column => {
        let totalItems = column?.elements?.length
        lastElement = column?.elements[totalItems - 1];
      })
    })

    return lastElement
  }

  const findFirstColumnOfRow = (row) => {
    let firstColumn = row.columns[0];

    return firstColumn
  }

  const openElementActionBar = (element) => {
    setTimeout(() => {
      const elementActionBar = document.querySelector(`.open-element-actionbar-${element?.id}`)
      elementActionBar?.click()
    }, 200);
  }

  const openFirstElementOfRowActionBar = (row) => {
    let firstElement = row.columns[0].elements[0];
    openElementActionBar(firstElement)
  }

  const openFirstElementOfColumnActionBar = (column) => {
    let firstElement = column.elements[0];
    openElementActionBar(firstElement)
  }

  const openRowActionBar = (row) => {
    setTimeout(() => {
      const rowActionBar = document.querySelector(`.open-row-actionbar-${row?.id}`)
      rowActionBar?.click()
    }, 200);
  }

  const openRowOfElementActionBar = (dialogDefinition, elementId) => {
    let row = findRowFromElementId(dialogDefinition, elementId)
    openRowActionBar(row)
  }

  const openRowOfColumnActionBar = (dialogDefinition, columnId) => {
    let row = findRowFromColumn(dialogDefinition, columnId)
    openRowActionBar(row)
  }

  const openColumnActionBar = (column) => {
    setTimeout(() => {
      const columnActionBar = document.querySelector(`.open-column-actionbar-${column?.id}`)
      columnActionBar?.click()
    }, 200);
  }

  const openColumnOfElementActionBar = (dialogDefinition, elementId) => {
    let column = findColumnFromElement(dialogDefinition, elementId)
    openColumnActionBar(column)
  }

  const openFirstColumnOfRowActionBar = (row) => {
    let column = findFirstColumnOfRow(row)
    openColumnActionBar(column)
  }

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
  };

  const reorderBetweenTwo = (sourceList, destinationList, startIndex, endIndex) => {
    const [removed] = sourceList?.splice(startIndex, 1)
    destinationList.splice(endIndex, 0, removed);
  }

  const elementsOfTypeFromDialogDefinition = (dialogDefinition, elementType) => {
    let elementsOfType = [];

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let elements = column?.elements?.filter(e => e.type === elementType);
          elementsOfType = [...elementsOfType, ...elements]
        })
      })
    })

    return elementsOfType;
  }

  const findElementOfContainer = (container, elementId) => {
    let match
    container?.rows.forEach(row => {
      row?.columns.forEach(column => {
        let element = column?.elements?.find(e => e.id === elementId);
        if (element)
          match = element
      })
    })

    return match
  }

  const elementsOfTypeFromContainer = (container, elementType) => {
    let elementsOfType = [];

    container?.rows.forEach(row => {
      row?.columns.forEach(column => {
        let elements = column?.elements?.filter(e => elementType.includes(e.type));
        elementsOfType = [...elementsOfType, ...elements]
      })
    })

    return elementsOfType;
  }

  const elementsOfTypeFromRow = (row, elementType) => {
    let elementsOfType = [];

      row?.columns?.forEach(column => {
        let elements = column?.elements?.filter(e => elementType.includes(e.type));
        elementsOfType = [...elementsOfType, ...elements]
      })

    return elementsOfType;
  }

  const elementsOfTypeExceptContainer = (dialogDefinition, currentContainerId, elementType) => {
    let elementsOfType = [];

    dialogDefinition?.containers?.filter(m => m.id !== currentContainerId).forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let elements = column?.elements?.filter(e => elementType.includes(e.type));
          elementsOfType = [...elementsOfType, ...elements]
        })
      })
    })

    return elementsOfType;
  }

  const elementsOfTypeExceptGroupedByContainer = (dialogDefinition, currentContainerId, elementType) => {
    let elementsOfType = [];

    dialogDefinition?.containers?.filter(m => m.id !== currentContainerId).forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let elements = column?.elements?.filter(e => elementType.includes(e.type));

          if (elements?.length) {
            const existContainerIdIndex = elementsOfType.findIndex(x => x.containerId === container.id);

            //if exist group of container, group element on this container
            if (existContainerIdIndex !== -1) {

              const buildElementsInExistingGroupContainer = { ...elementsOfType[existContainerIdIndex], elements: [...elementsOfType[existContainerIdIndex].elements, ...elements] };
              elementsOfType[existContainerIdIndex] = buildElementsInExistingGroupContainer;

            } else {
              const buildNewElementsOfType = { containerId: container.id, containerName: container.name || "no-name", elements };
              elementsOfType = [...elementsOfType, buildNewElementsOfType]
            }
          }
        })
      })
    })

    return elementsOfType;
  }

  const elementsOfTypeExceptGroupedByRow =  (dialogDefinition, currentContainerId, elementType) => {
    let elementsOfType = [];

    dialogDefinition?.containers?.filter(m => m.id !== currentContainerId).forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let elements = column?.elements?.filter(e => elementType.includes(e.type));

          if (elements?.length) {
            const existRowIdIndex = elementsOfType.findIndex(x => x.rowId === row.id);

            //if exist group of container, group element on this container
            if (existRowIdIndex !== -1) {

              const buildElementsInExistingGroupRow = { ...elementsOfType[existRowIdIndex], elements: [...elementsOfType[existRowIdIndex].elements, ...elements] };
              elementsOfType[existRowIdIndex] = buildElementsInExistingGroupRow;
            } else {

              const buildNewElementsOfType = { rowId: row.id, rowName: row.id, containerId: container.id, containerName: container.name || "no-name", elements };
              elementsOfType = [...elementsOfType, buildNewElementsOfType]
            }
          }
        })
      })
    })

    return elementsOfType;
  }

  const logicControlsByProperty = (dialogDefinition, property) => {
    let logicControls = [];

    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.property === property);
          if (element && container?.showLogicControl) {
            logicControls = [...logicControls, ...container?.showLogicControl]
          }

        })
      })
    })

    return logicControls;
  }

  const logicControlsByPropertyWithDepends = (dialogDefinition, property) => {
    let logicControls = [];
    let abstractLogicControlOfElement = logicControlsByProperty(dialogDefinition, property);
    let dependsExist = abstractLogicControlOfElement.length > 0;

    //This loop starts from the first elementId present looking for it in other showLogicControls, successively entering the next one until it finds none.
    while (dependsExist) {
      // eslint-disable-next-line no-loop-func
      abstractLogicControlOfElement.forEach(logicControl => {
        logicControls.push(logicControl);
        let reusableLogicControl = logicControlsByProperty(dialogDefinition, logicControl.condition.property);
        if (reusableLogicControl.length) {
          reusableLogicControl.forEach(newLogicControls => logicControls.push(newLogicControls));
          abstractLogicControlOfElement = reusableLogicControl;
        }
        else dependsExist = false
      });
    };

    return logicControls;
  }

  const hiddenOrShowColumn = (dialogDefinition, columnId, hidden) => {
    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        let column = row?.columns?.find(e => e.id === columnId);
        if (column) hidden ? (column.hidden = hidden) : (delete column.hidden);

      })
    });

    return dialogDefinition
  }

  const hiddenOrShowElement = (dialogDefinition, elementId, hidden) => {
    dialogDefinition?.containers?.forEach(container => {
      container?.rows.forEach(row => {
        row?.columns.forEach(column => {
          let element = column?.elements?.find(e => e.id === elementId);
          if (element) hidden ? (element.hidden = hidden) : (delete element.hidden);
        })
      })
    });

    return dialogDefinition
  }

  const hiddenOrShowRow = (dialogDefinition, rowId, hidden) => {
    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        if (row.id === rowId) hidden ? (row.hidden = hidden) : (delete row.hidden);
      })
    });

    return dialogDefinition
  }

  const hiddenOrShowContainer = (dialogDefinition, containerId, hidden) => {
    dialogDefinition.containers?.forEach(container => {
      if (container.id === containerId) hidden ? (container.hidden = hidden) : (delete container.hidden);
    });
    return dialogDefinition
  }

  const updateLogicControls = (dialogDefinition) => {
    dialogDefinition.containers.forEach(container => {
      if (container.showLogicControl) {
        container.showLogicControl = container.showLogicControl?.filter(logicControl => {
          // Search for the corresponding element
          const elementExists = dialogDefinition.containers
            .flatMap(container => container.rows)
            .flatMap(row => row.columns)
            .flatMap(column => column.elements)
            .some(element => element.property === logicControl.condition.property);

          // Maintain only logicControls whose element exists
          return elementExists;
        });
      }
    });
  }

  return {
    addItemAbove,
    addItemBelow,
    addItem,
    addItemByIndex,
    removeItem,
    swapItems,
    findContainerFromRow,
    findRowFromColumn,
    findRow,
    findColumnFromElement,
    findContainerIndexFromRow,
    findContainer,
    findColumn,
    findLastContainer,
    findLastUnlockedConatiner,
    findLastRow,
    findLastColumn,
    moveItemBottom,
    moveItemTop,
    findContainerFromColumn,
    findLastColumnForDialogDefinition,
    findRowFromElementId,
    findLastElementFromContainer,
    openElementActionBar,
    openFirstElementOfRowActionBar,
    openFirstElementOfColumnActionBar,
    openRowOfElementActionBar,
    openRowOfColumnActionBar,
    openColumnOfElementActionBar,
    openFirstColumnOfRowActionBar,
    reorder,
    reorderBetweenTwo,
    elementsOfTypeFromDialogDefinition,
    openRowActionBar,
    findElement,
    findElementByProperty,
    elementsOfTypeFromContainer,
    elementsOfTypeExceptContainer,
    findElementOfContainer,
    findBeforeRowOfRowId,
    findNextRowOfRowId,
    logicControlsByProperty,
    logicControlsByPropertyWithDepends,
    elementsOfTypeExceptGroupedByContainer,
    findContainerFromElement,
    hiddenOrShowColumn,
    hiddenOrShowContainer,
    hiddenOrShowRow,
    hiddenOrShowElement,
    updateLogicControls,
    elementsOfTypeFromRow,
    elementsOfTypeExceptGroupedByRow,

  }
}