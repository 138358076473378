import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { deleteDialogValues, generatePdf, getSummariesByDialogDefinitionId, generateSignedFile } from "../../../api/dialogValues";
import { StringListColumn, Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../components/Table";
import { getWebhooksByDialogDefinitionId } from "../../../api/webhook";
import { Button } from "../../../components/Form/Button";
import { WebhookPayload } from "./WebhookPayload";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { useToastAction } from "../../../hooks/useToastAction";
import { useTableActions } from "@metaforcelabs/metaforce-core";
import { processTypes } from "../../../utils/constants";
import { DialogValuesStatusBadge } from "../../../components/DialogValuesStatusBadge";
import MenuContextList from "../../../components/MenuContextList";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

export default function DialogRecords() {
  const { dialogKey } = useParams();
  const [dialogValuesList, setDialogValuesList] = useState();
  const [type, setType] = useState("json");
  const [webhook, setWebhook] = useState({});
  const [currentDialogValuesId, setCurrentDialogValuesId] = useState(null);
  const loadAction = useToastAction();
  const deleteRecordAction = useToastAction();

  const tableActions = useTableActions(dialogValuesList || [], 5, "updatedDate", "desc")
  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    loadAction.execute(async () => {
      const [dialogValuesResult, webhooksResult] = await Promise.all([
        getSummariesByDialogDefinitionId(dialogKey),
        getWebhooksByDialogDefinitionId(dialogKey)
      ]);

      if (webhooksResult?.length > 0) {
        setWebhook(webhooksResult[0]);
      }
      setDialogValuesList(dialogValuesResult);
    }, "Failed to load")
  }

  const handleDowloadPdf = async (valuesKey, filename) => {
    await generatePdf(dialogKey, valuesKey, filename ?? 'smartform.pdf');
  }

  const handleDowloadSignedFile = async (id) => {
    await generateSignedFile(id);
  }

  const pdfFilename = (dialogValue) => {
    if (dialogValue?.dialogValuesParticipatingParties.length > 0) {
      let allNamesParticipatingParties = ''
      dialogValue.dialogValuesParticipatingParties.map(parties => {
        return allNamesParticipatingParties += `${parties.name} `
      })
      return allNamesParticipatingParties.trim();
    } else if (dialogValue?.receiverName) {
      return dialogValue.receiverName
    } else {
      return dialogValue?.privateSecret
    }
  }

  const recordsForParticipatingParties = (dialogValues) => {
    let records = [];

    switch (dialogValues.process) {
      case processTypes.multiStep:
        records = dialogValues.dialogValuesParticipatingParties?.map(p => `${p.email} (${p.name})`)
        break;
      case processTypes.normal:
        records = [`${dialogValues.receiverEmail} (${dialogValues.receiverName})`]
        break;
      default:
    }

    return records
  }

  const handleWebHookPayload = (dialogValuesId, type) => {
    setCurrentDialogValuesId(dialogValuesId);
    setType(type);
  }

  const onDelete = async (recordId) => {
    deleteRecordAction.execute(async () => {
      await deleteDialogValues(recordId);
      setDialogValuesList(dialogValuesList.filter(d => d.id !== recordId))
    }, 'Failed to delete', 'Deleted')
  }

  const onDeletedRecordClick = () => {
    const toastId = uuidv4();
    toast.error(`This form is removed by retention policy and that you have to get it from the archive.`, {
      id: toastId
    })
  }

  if (loadAction.isExecuting) {
    return null;
  }

  return (
    <>
      {
        dialogValuesList &&
        <section className="mt-10">
          <Table tableActions={tableActions}>
            <TableHead>
              <TableHeader>Id</TableHeader>
              <TableHeader>
                Created by
              </TableHeader>
              <TableHeader>
                Participating Parties
              </TableHeader>
              <TableHeader sortBy="isComplete" tableSort={tableActions}>
                Status
              </TableHeader>
              <TableHeader sortBy="updatedDate" tableSort={tableActions}>
                Last updated
              </TableHeader>
              <TableHeader>Webhook payload</TableHeader>
              <TableHeader>ViewPoint</TableHeader>
              <TableHeader></TableHeader>
            </TableHead>
            <TableBody>
              {
                tableActions?.pageData?.map(d => {
                  return (
                    <TableRow key={d.id}>
                      <TableColumn>
                        {d.isDeletedFromRetencionPolicy ?
                          <div 
                            className="text-black underline hover:text-gray-700 cursor-pointer" 
                            onClick={() => onDeletedRecordClick()}>{d.id}
                          </div>
                          : <Link
                            className="text-black underline hover:text-gray-700"
                            to={`/admin/dialog/${d.dialogDefinitionId}/form/${d.id}`}>
                            {d.id}
                          </Link>}
                      </TableColumn>
                      <TableColumn>
                        {d.createdByEmail ? d.createdByEmail : d.createdBy}
                      </TableColumn>
                      <TableColumn>
                        <StringListColumn
                          records={recordsForParticipatingParties(d)}
                          numberOfCharactersToShow={75}
                          joinCharacter={", "}
                        />
                      </TableColumn>
                      <TableColumn>
                        <DialogValuesStatusBadge record={d} />
                      </TableColumn>
                      <TableColumn>{stringToLocaleDateTimeString(d.updatedDate)}</TableColumn>
                      <TableColumn>
                        <select
                          onChange={(e) => handleWebHookPayload(d.id, e.target.value)}
                          className="mt-2 block w-36 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          disabled={d.isDeletedFromRetencionPolicy}
                        >
                          <option value="">Select format</option>
                          <option value="json">Json</option>
                          <option value="interact">Interact</option>
                        </select>
                        {/* </TableColumn>
                        <Button
                          onClick={() => setCurrentDialogValuesId(d.id)}
                          text="View payload"
                        /> */}
                      </TableColumn>
                      <TableColumn>
                        <Button
                          onClick={() => handleDowloadPdf(d.id, pdfFilename(d))}
                          text="Download pdf"
                          disabled={d.isDeletedFromRetencionPolicy}
                        />
                      </TableColumn>
                      <TableColumn>
                        <MenuContextList
                          element={d}
                          deleteType={"template"}
                          actions={[
                            {
                              name: "Delete",
                              onClick: () => onDelete(d.id),
                              disabled: d.isDeletedFromRetencionPolicy
                            },
                            {
                              name: "Download signed files",
                              onClick: () => handleDowloadSignedFile(d.id),
                              hidden: d.signingNeeded && !d.isComplete
                            }
                          ]} 
                        />
                      </TableColumn>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          {
            type &&
            <WebhookPayload
              type={type}
              dialogValuesId={currentDialogValuesId}
              webhook={webhook}
              onClose={() => setCurrentDialogValuesId(null)}
            />
          }
        </section>
      }
    </>
  );
}