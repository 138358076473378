import { post, get, destroy } from './api'

export const createTag = async (dialogDefinitionId, text) => {
  return await post('tags', { dialogDefinitionId, text });
}

export const getTagsForDialogDefinition = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/tags`);
}

export const deleteTag = async (tagId) => {
  return await destroy(`tags/${tagId}`)
}