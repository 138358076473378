import { elementTypes } from "./constants"
import { getLanguageTermKey } from "./features"

export const filterByArrayName = (language, elementIdentifier, languageTerm, arrayName = 'languageTerms') => {

    let currentLanguage = {
        ...language
    }

    if (arrayName === 'digitalSigningTerm' || arrayName === 'processTerm') {
        currentLanguage[arrayName] = { ...languageTerm }
    } else {
        const term = filterOtherTerms(language, arrayName, elementIdentifier)
        currentLanguage[arrayName] = [...term, languageTerm]
    }

    return currentLanguage;
}

export const filterOtherTerms = (language, arrayName, elementIdentifier) => {
    let result

    if (arrayName === 'languageTerms') {
        result = language[arrayName].filter(l => getLanguageTermKey(l) !== elementIdentifier)
    } else if (arrayName === 'containerLanguageTerms') {
        result = language[arrayName].filter(l => l.containerId !== elementIdentifier);
    }

    return result;
}

export const findActualTerm = (language, arrayName, elementIdentifier) => {
    let result

    if (arrayName === 'languageTerms') {
        result = language[arrayName].find(l => getLanguageTermKey(l) === elementIdentifier)
    } else if (arrayName === 'containerLanguageTerms') {
        result = language[arrayName].find(l => l.containerId === elementIdentifier);
    } else if (arrayName === 'digitalSigningTerm' || arrayName === 'processTerm') {
        result = language[arrayName]
    }

    return result;
}

export const hasDigitalSigning = (element) => {
    return element.isDigitalSigning;
}

export const hasProcess = (element) => {
    return element.isProcess;
}

export const hasDynamicListValues = (element) => {
    return element.type === elementTypes.dynamicList
}

export const hasRichText = (element) => {
    return element.type === elementTypes.richText
}

export const hasContainer = (element) => {
    return element.isContainer;
}

export const hasPlaceholder = (element) => {
    const matchingTypes = [elementTypes.dropDown, elementTypes.inputTextLine]
    return matchingTypes.includes(element.type)
}

export const hasValueLabel = (element) => {
    const matchingTypes = [elementTypes.checkbox]
    return matchingTypes.includes(element.type)
}

export const hasLeadingText = (element) => {
    const matchingTypes = [elementTypes.radioButton, elementTypes.inputTextLine, elementTypes.dropDown]
    return matchingTypes.includes(element.type);
}

export const hasTrailingText = (element) => {
    const matchingTypes = [elementTypes.radioButton, elementTypes.inputTextLine, elementTypes.datePicker, elementTypes.dropDown]
    return matchingTypes.includes(element.type);
}

export const hasOptionsWithValues = (element) => {
    return element.optionWithValues
}

export const hasMainLabel = (element) => {
    const hasRestrictedElements = [elementTypes.checkbox, elementTypes.orderTable, elementTypes.matrix].includes(element.type)
    return !hasRestrictedElements && !element.isContainer;
}

export const hasDescription = (element) => {
    const matchingTypes = [elementTypes.fileUpload, elementTypes.button, elementTypes.checkbox, elementTypes.attachment, elementTypes.smartTable, elementTypes.signature]
    return matchingTypes.includes(element.type)
}

export const hasRequiredErrorCustomMessage = (element) => {
    const matchingTypes = [elementTypes.datePicker, elementTypes.dropDown, elementTypes.fileUpload, elementTypes.inputTextLine, elementTypes.radioList, elementTypes.signature, elementTypes.textArea, elementTypes.timePicker, elementTypes.matrix]
    return matchingTypes.includes(element.type)
}

export const hasValidationErrorCustomMessage = (element) => {
    const matchingTypes = [elementTypes.inputTextLine]
    return matchingTypes.includes(element.type)
}

export const hasDisplayLabel = (element) => {
    const matchingTypes = [elementTypes.smartTable]
    return matchingTypes.includes(element.type);
}

export const hasRows = (element) => {
    const matchingTypes = [elementTypes.orderTable]
    return matchingTypes.includes(element.type);
}

export const hasSmartObject = (element) => {
    const matchingTypes = [elementTypes.smartTable]
    return matchingTypes.includes(element.type);
}

export const isMatrix = (element) => {
    return element.type === elementTypes.matrix
}