import { Button } from "../Form/Button"
import Modal from "../Modal"

const Confirm = ({
  modalHelper,
  onConfirm,
  onCancel = null,
  title = "Are you sure?",
  yesText = "Yes",
  noText = "No",
}) => {

  const handleYes = async () => {
    await onConfirm();
    modalHelper.close();
  }

  const handleNo = async () => {
    if (onCancel) {
      await onCancel();
    }
    modalHelper.close();
  }
  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="small"
      isTitleCentered={true}
      title={title}
    >
      <div className="mt-5 flex justify-between">
        <Button
          onClick={handleNo}
          text={noText}
          theme="white"
        />
        <Button
          onClick={handleYes}
          text={yesText}
          theme="primary"
        />
      </div>
    </Modal>
  )
}

export default Confirm