
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoaderSpinner from "react-loader-spinner";
import React from "react";
import { default as ReactLoader } from "react-loader-spinner";

export default function Loader({height = 25, width = 25}) {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
    >
      <LoaderSpinner
        type="Oval"
        color="#2b59c0"
        height={height}
        width={width}
      />
    </div>
  );
}

export const LoaderThreeDots = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
    >
      <ReactLoader
        height="80"
        width="80"
        radius="9"
        type="ThreeDots"
        color="#2b59c0"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  )
}