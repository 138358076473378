import { useCallback, useRef } from 'react';

export const useDoubleClick = ({tripleClick, doubleClick, click, timeout = 150}) => {
    // we're using useRef here for the useCallback to rememeber the timeout
    const clickTimeout = useRef();
    const doubleClickTimeout = useRef();
    const tripleClickTimeout = useRef();

    const clearClickTimeout = () => {
        if (clickTimeout) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = undefined;
        } 
        if(doubleClickTimeout) {
            clearTimeout(doubleClickTimeout.current);
            doubleClickTimeout.current = undefined;
        }
        if(tripleClickTimeout) {
            clearTimeout(tripleClickTimeout.current);
            tripleClickTimeout.current = undefined;
        }
    };

    // return a memoized version of the callback that only changes if one of the dependencies has changed
    return useCallback((event) => {
        clearClickTimeout();
        if (click && event.detail === 1) {
            clickTimeout.current = setTimeout(() => {
                click(event);
            }, timeout);
        }
        if (doubleClick && event.detail === 2) {
            doubleClickTimeout.current = setTimeout(() => {
                doubleClick(event);
            }, timeout);
        }
        if (tripleClick && event.detail === 3) {
            tripleClickTimeout.current = setTimeout(() => {
                tripleClick(event);
            }, timeout);
        }
        return () => clearClickTimeout()
        
    }, [click, doubleClick, tripleClick]);
};