import { v4 as uuidv4 } from "uuid";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { useClassNames } from "../../hooks/useClassNames";
import _ from "lodash";

export default function StaticOptions({
    addDescription,
    options,
    setOptions
}) {

    const classNames = useClassNames();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const optionType = e.target.getAttribute("data-option-type");
        const newOptions = options.map((option) => {
            if (option.id === name) {
                if (optionType === "display") {
                    return { ...option, display: value };
                } else if (optionType === "description") {
                    return { ...option, description: value };
                } else {
                    return { ...option, value: value };
                }
            }
            return option;
        });

        setOptions(newOptions);
    };

    const createNewRow = () => {
        return {
            id: uuidv4(),
            display: "",
            description: "",
            value: "",
        };
    };

    const handleAdd = (index = -1) => {
        let newOptions = [...options];

        newOptions.splice(index + 1, 0, createNewRow());

        setOptions(newOptions);
    };

    const handleRemove = (id) => {
        setOptions(options.filter((m) => m.id !== id));
    };

    return (
        <div className="">            
            {options?.length > 0 && (
                <div className="grid grid-cols-11 gap-3 mb-1">
                    <div className="col-span-5">Display</div>
                    <div className="col-span-5">Value</div>
                    <div className="col-span-1"></div>
                </div>
            )}
            {options?.map((option, index) => (
                <div key={option.id}>
                    <div
                        className={classNames.classNames(
                            addDescription ? "mb-2 " : "mb-3",
                            "grid grid-cols-11 gap-3"
                        )}
                    >
                        <input
                            name={option.id}
                            type={"text"}
                            data-option-type={"display"}
                            value={option.display}
                            placeholder="Display..."
                            className={`col-span-5 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                            onChange={(e) => handleOnChange(e)}
                        />
                        <input
                            name={option.id}
                            type={"text"}
                            data-option-type={"value"}
                            value={option.value}
                            placeholder="Value..."
                            className={`col-span-5 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                            onChange={(e) => handleOnChange(e)}
                        />
                        <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                            <PlusCircleIcon
                                className="w-5"
                                onClick={() => handleAdd(index)}
                            />
                            <TrashIcon
                                className="w-5"
                                onClick={() => handleRemove(option.id)}
                            />
                        </div>
                    </div>
                    {addDescription && (
                        <input
                            name={option.id}
                            type={"text"}
                            data-option-type={"description"}
                            value={option.description}
                            placeholder="Description..."
                            className={`focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md mb-5`}
                            onChange={(e) => handleOnChange(e)}
                        />
                    )}
                </div>
            ))}

            {options?.length < 1 && (
                <div className="">
                    No options are created.
                    <button
                        type="button"
                        onClick={handleAdd}
                        className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                    >
                        Add option
                    </button>
                </div>
            )}
        </div>
    );
}
