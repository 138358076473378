import { useEffect, useRef, useState } from "react"
import { createDialogDefinitionReminder, getDialogDefinitionRemindersByDialogDefinitionId, updateDialogDefinitionReminder } from "../../../api/dialogDefinitionReminders";
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { Formik, Form } from 'formik';
import { useToastAction } from "../../../hooks/useToastAction";
import { dialogDefinitionReminderMethods } from "../../../utils/constants";
import { Label } from "../../../components/Form/Label";
import { isTrue } from "../../../utils/select";

export const Reminders = ({ dialogKey }) => {
  const loadAction = useToastAction();
  const saveAction = useToastAction()
  const [reminders, setReminders] = useState([])
  const [isSecondReminderDisabled, setSecondReminderDisabled] = useState();

  useEffect(() => {
    load();
  }, [])

  const load = () => {
    loadAction.execute(async () => {
      const results = await getDialogDefinitionRemindersByDialogDefinitionId(dialogKey)
      setReminders(results);
      const isFirstReminder = results?.find(r => r.order === 0)
      if(results.length > 0 && isFirstReminder){
        setSecondReminderDisabled(!isFirstReminder.enabled)
      } else {
        setSecondReminderDisabled(true)
      }
    }, "Failed to load reminders")
  }

  const reminderByOrder = (order) => {
    return reminders.find(r => r.order === order) || { order }
  }

  const onSave = (dialogDefinitionReminder) => {
    saveAction.execute(async () => {
      if (dialogDefinitionReminder.id) {
        const updateResult = await updateDialogDefinitionReminder(dialogDefinitionReminder.id, dialogKey, dialogDefinitionReminder)
        const updatedReminders = [...reminders.filter(r => r.id !== updateResult.id), updateResult]
        setReminders([...updatedReminders]);
      }
      else {
        const createResult = await createDialogDefinitionReminder(dialogKey, dialogDefinitionReminder)
        setReminders([...reminders, createResult])
      }

      if (dialogDefinitionReminder.order === 0) {
        const isFirstReminderEnabled = dialogDefinitionReminder.enabled;
        setSecondReminderDisabled(!isFirstReminderEnabled);
      }  
    }, "Failed to save reminder")
  }

  if (loadAction.isExecuting || !reminders) {
    return null
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-10">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Reminders</h3>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-6">
            <div className="col-span-6 sm:col-span-1">
              <Label label="First" />
              <div className="mt-1">
                <ReminderInput
                  onSubmit={onSave}
                  dialogDefinitionReminder={reminderByOrder(0)}
                />
              </div>
            </div>
            <div className="col-span-6 sm:col-span-1">
              <Label label="Second" />
              <div className="mt-1">
                <ReminderInput
                  onSubmit={onSave}
                  dialogDefinitionReminder={reminderByOrder(1)}
                  isDisabled={isSecondReminderDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ReminderInput = ({
  dialogDefinitionReminder,
  onSubmit,
  isDisabled = false
}) => {
  const resetButtonRef = useRef();

  useEffect(() => {
    if (isDisabled) {
      resetButtonRef?.current.click()
    }
  }, [isDisabled]);

  const yesNoOptions = [{
    value: false,
    name: "No"
  },
  {
    value: true,
    name: "Yes"
  }]

  const reminderMethodOptions = [
    {
      value: dialogDefinitionReminderMethods.email,
      name: "Email"
    },
    {
      value: dialogDefinitionReminderMethods.sms,
      name: "SMS"
    },
    {
      value: "all",
      name: "Email and SMS"
    },
  ]

  const numberOfDaysOptions = []
  for (let i = 1; i <= 30; i++) {
    numberOfDaysOptions.push({
      value: i,
      name: i === 1 ? `${i} day` : `${i} days`
    })
  }

  const nullChoice = [{ value: null, name: '--' }]

  const reminderMethods = dialogDefinitionReminder?.dialogDefinitionReminderMethods || []
  let initialReminderMethod = reminderMethods.includes(dialogDefinitionReminderMethods.email) && reminderMethods.includes(dialogDefinitionReminderMethods.sms)
    ? "all"
    : (reminderMethods.length === 1 && reminderMethods[0]) || dialogDefinitionReminderMethods.email

  const resetFields = (props) => {
    props.setValues({
      id: dialogDefinitionReminder?.id,
      order: dialogDefinitionReminder?.order,
      enabled: false,
      dialogDefinitionReminderMethods: initialReminderMethod,
      daysUntilReminder: 1,
    });
  };

  return (
    <Formik
      initialValues={{
        id: dialogDefinitionReminder?.id,
        order: dialogDefinitionReminder?.order,
        enabled: dialogDefinitionReminder?.enabled || false,
        dialogDefinitionReminderMethods: initialReminderMethod,
        daysUntilReminder: dialogDefinitionReminder?.daysUntilReminder || 1,
      }}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        await onSubmit({
          ...values,
          // enabled: values.enabled || values.enabled === "true" ? true : false,
          enabled: isTrue(values.enabled) ? true : false,
          daysUntilReminder: parseInt(values.daysUntilReminder),
          dialogDefinitionReminderMethods: values.dialogDefinitionReminderMethods === "all"
            ? [dialogDefinitionReminderMethods.email, dialogDefinitionReminderMethods.sms]
            : [parseInt(values.dialogDefinitionReminderMethods)]
        })
        actions.setSubmitting(false);
      }}
    >
      {
        props => (
          <Form onChange={() => props.submitForm()}>
            <div className="flex">
              <div>
                <FormikSelectInput
                  name={"enabled"}
                  options={yesNoOptions}
                  formikProps={props}
                  disabled={isDisabled}
                />
              </div>
              <div className={`ml-4 ${props.values.enabled ? "" : "hidden"}`}>
                <FormikSelectInput
                  name={"dialogDefinitionReminderMethods"}
                  options={isTrue(props.values.enabled) && !isDisabled ? reminderMethodOptions : nullChoice}
                  formikProps={props}
                  disabled={isDisabled}
                />
              </div>
              <div className={`ml-4 ${props.values.enabled ? "" : "hidden"}`}>
                <FormikSelectInput
                  name={"daysUntilReminder"}
                  options={isTrue(props.values.enabled) && !isDisabled ? numberOfDaysOptions : nullChoice}
                  formikProps={props}
                  disabled={isDisabled}
                />
              </div>
              <div className="hidden">
                <button ref={resetButtonRef} onClick={()=> resetFields(props)}>Reset</button>
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  )
}