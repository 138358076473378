/**
 * @param {*} Group 
 */
export const Group = ({ title, children, divider = false, subtitle, icon }) => {

  return (
    <>
      <div className={`mb-8 border-b border-gray-200 pb-7`}>
        <div className="flex start">
          {icon}
          <div>
            {
              title && <h3 className={`${icon ? "px-2 sm:px-4" : "px-4 sm:px-6"}  font-medium text-gray-900`}>{title}</h3>
            }
            {
              subtitle && <p className="pl-4 sm:pl-6 text-xs font-medium opacity-40">{subtitle}</p>
            }
          </div>
        </div>
        <div className={`${divider && "divide-y divide-gray-200 space-y-6"}`}>
          {children}
        </div>
      </div>
    </>
  );
}