
export const runYupValidationSchemaSync = (schema, values) => {
    let errors = {};
    try {

        schema.validateSync(values, { abortEarly: false });
    } catch ({ name, ...rest }) {
        if (name === 'ValidationError') {
            errors = yupToErrors({ name, ...rest })
        }
    }
    return errors;
}

export const yupToErrors = (yupError) => {
    let errors = {};
    if (yupError.inner) {
        if (yupError.inner.length === 0) {
            errors[yupError.path] = yupError.message;
            // return setIn(errors, yupError.path, yupError.message);
        }
        for (let err of yupError.inner) {
            if (!errors[err.path]) {
                errors[err.path] = err.message;
            }
        }
    }
    return errors;
}