import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import AddExtraElementsPopup from '../addExtraElementsPopup';

export default function Properties({ initData, actions }) {
  const [openPopup, setOpenPopup] = useState(false)
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges, 'value')
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  

  return (
    <div className="popover-container-items">
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Checkbox"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Label"}
          name={"valueLabel"}
          value={data?.valueLabel}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Description'}
          name={'label'}
          value={data?.label}
        />


        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data?.backgroundColor}
        />

        <Property.Toggle
          onChange={handleOnChange}
          label={'Default state'}
          name="defaultCheckedState"
          onText="On"
          offText="Off"
          enabled={data?.defaultCheckedState}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide description"}
          name={"hideLabel"}
          value={data?.hideLabel}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Prevent data insert"}
          name={"preventDataInsert"}
          value={data?.preventDataInsert}
        />

      </Property.Group>

      <Property.Group
        title='Connected elements'>

        <Property.ConnectedElements data={data} handleOnChange={handleOnChange} />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Subgroup
         title="Label">

          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'text.color'}
            initColor={data?.text?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'text.size'}
            type={'number'}
            value={data?.text?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="text"
            property={data?.text}
          />

        </Property.Subgroup>
        
        <Property.Subgroup
          title="Description">
          
          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="labelStyle"
            property={data?.labelStyle}
          />
          
        </Property.Subgroup>
      </Property.Group>

      <AddExtraElementsPopup data={data} onChange={handleOnChange} open={openPopup} setOpen={setOpenPopup} />
    </div>
  )
}
