import { useContext, useEffect, useState } from "react"
import { createActivityRecord } from "../../../api/activityRecord"
import { TopbarContext } from "../../../contexts";

export const useActivityManager = (key) => {
  const [currentActivity, setCurrentActivity] = useState([])
  const topbarContext = useContext(TopbarContext);

  const createActivity = async () => {
    try {
      const results = await createActivityRecord(key)
      setCurrentActivity(results);
    }
    catch (error) {
      // Swallow error. Could been because hook is no longer mounted.
    }
  }

  useEffect(() => {
    topbarContext?.setOtherUserIsActive(currentActivity?.length > 0)
  }, [currentActivity])

  useEffect(() => {
    createActivity()
    const interval = setInterval(() => {
      createActivity();
    }, 10000);

    return () => clearInterval(interval);
  }, [])
}