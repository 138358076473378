import _, { } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToastAction } from '../../../../hooks/useToastAction';
import { addNewProperty, deleteProperty, getSmartObject, updateProperty } from '../../../../api/smartObject';
import { smartObjectPropertyTypes } from '../../../../utils/constants';
import Modal from '../../../../components/Modal';
import { TextInput } from '../../../../components/Form/TextInput';
import { Select } from '../../../../components/Form/Select';
import MenuContextList from '../../../../components/MenuContextList';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import * as yup from "yup";
import { onKeyPressAvoidSymbols } from '../../../../utils/onKeyPress';


export default function SmartObjectForm() {
  const defaultProperty = { name: "", label: "", type: smartObjectPropertyTypes.string, dropdownOptions: [] };
  let propertySchema = yup.object({
    name: yup.string().required("Required"),
    label: yup.string().required("Required"),
    type: yup.number().required("Required"),
  });

  const [smartObject, setSmartObject] = useState({});
  const [showNewModal, setShowNewModal] = useState(false);
  const [newProperty, setNewProperty] = useState(defaultProperty);
  const [formStateValid, setFormStateValid] = useState(false);

  const { smartObjectId } = useParams();

  const loadAction = useToastAction();
  const saveAction = useToastAction(true);

  const loadData = async () => {
    loadAction.execute(async () => {
      const smartObject = await getSmartObject(smartObjectId);
      setSmartObject(smartObject);
    }, "Failed to load template")
  }

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    try {
      propertySchema.validateSync(newProperty);
      setFormStateValid(true)
    }
    catch (error) {
      setFormStateValid(false)
    }
  }, [newProperty])

  const handleSaveProperty = () => {
    saveAction.execute(async () => {
      if (newProperty.id) {
        await updateProperty(smartObjectId, newProperty.id, newProperty);
      }
      else {
        await addNewProperty(smartObjectId, newProperty);
      }
      setNewProperty(defaultProperty);
      setShowNewModal(false)
      await loadData();
    }, "Failed to save", "Property saved")

  }

  const handleDeletedRow = async (id) => {
    saveAction.execute(async () => {
      await deleteProperty(smartObjectId, id);
      await loadData();
    }, "Failed to delete", "Deleted property")
  }

  const typeOptions = [
    {
      value: smartObjectPropertyTypes.string,
      name: 'Text',
    },
    // {
    //   value: smartObjectPropertyTypes.number,
    //   name: 'Number',
    // },
    {
      value: smartObjectPropertyTypes.dropdown,
      name: 'Dropdown',
    },
    {
      value: smartObjectPropertyTypes.checkbox,
      name: 'Checkbox',
    },
    {
      value: smartObjectPropertyTypes.date,
      name: 'Date',
    },
  ]

  const handleTypeDisplayName = (type) => {
    switch (type) {
      case smartObjectPropertyTypes.string:
        return "String"
      case smartObjectPropertyTypes.number:
        return "Number"
      case smartObjectPropertyTypes.dropdown:
        return "Dropdown"
      case smartObjectPropertyTypes.checkbox:
        return "Checkbox"
      case smartObjectPropertyTypes.date:
        return "Date"
      default:
        return "Unknown"
    }
  }

  const handleUpdatePropertyModal = (property) => {
    setNewProperty(property);
    setShowNewModal(true);
  }

  return (
    <>
      <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Edit </h1>
        <button
          type="button"
          className="mt-3 ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
          onClick={() => setShowNewModal(true)}
        >
          New Property
        </button>
      </div>
      <div className='my-2 sm:my-4 md:my-6'>
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
        <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  Property
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  Label
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                smartObject?.smartObjectProperties?.map((item, index) =>
                  <tr key={index}>
                    <td className="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden"
                      style={{ maxWidth: "300px" }}
                    >
                      {item.name}
                    </td>
                    <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                      {item.label}
                    </td>
                    <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                      {handleTypeDisplayName(item.type)}
                    </td>
                    <td className="pt-6 pb-10 whitespace-nowrap text-right text-sm font-medium absolute">
                      {
                        <MenuContextList
                          element={item}
                          deleteType={"template"}
                          actions={[
                            {
                              name: "Edit",
                              onClick: () => handleUpdatePropertyModal(item),
                              icon: PencilAltIcon,
                            },
                            {
                              name: "Delete",
                              onClick: () => handleDeletedRow(item.id),
                              icon: TrashIcon,
                            },
                          ]}
                        />
                      }
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        </div>
      </div>

      <Modal
        isOpen={showNewModal}
        onClose={() => {
          setShowNewModal(false)
        }}
        size="medium"
      >
        <div className="mt-4">
          <TextInput
            name={`name`}
            label={"Property"}
            value={newProperty.name}
            onKeyPress={onKeyPressAvoidSymbols}
            onChange={value => {
              setNewProperty({ ...newProperty, name: value })
            }}
            required
          />
        </div>
        <div className="mt-4">
          <TextInput
            name={`label`}
            label={"Label"}
            value={newProperty.label}
            onChange={value => {
              setNewProperty({ ...newProperty, label: value })
            }}
            required
          />
        </div>
        <div className="mt-4">
          <Select
            id="type"
            label={"Type"}
            required={true}
            horizontal={false}
            selectedValue={newProperty.type}
            options={typeOptions}
            onChange={value => {
              setNewProperty({ ...newProperty, type: Number(value) })
            }}
          />
        </div>
        {
          newProperty.type === smartObjectPropertyTypes.dropdown && (
            <div className="mt-8 pb-4">
              <div className='flex justify-between pb-2'>
                <label className="block text-sm font-medium text-gray-700 mb-2">Dropdown Options</label>

                <button
                  type="button"
                  className="w-auto inline-flex justify-center rounded-md shadow-sm px-2 py-1 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                  onClick={() => {
                    const options = [...newProperty.dropdownOptions];
                    options.push("");
                    setNewProperty({ ...newProperty, dropdownOptions: options })
                  }}
                >
                  Add
                </button>
              </div>

              {
                newProperty?.dropdownOptions?.map((item, index) => (
                  <div key={index} className="flex items-center mt-2">
                    <TextInput
                      name={`dropdownOption-${index}`}
                      value={item}
                      onChange={value => {
                        const options = [...newProperty.dropdownOptions];
                        options[index] = value;
                        setNewProperty({ ...newProperty, dropdownOptions: options })
                      }}
                    />
                    <button
                      type="button"
                      className="ml-2 w-auto inline-flex justify-center rounded-md shadow-sm px-2 py-1 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        const options = [...newProperty.dropdownOptions];
                        options.splice(index, 1);
                        setNewProperty({ ...newProperty, dropdownOptions: options })
                      }
                      }
                    >
                      Delete
                    </button>
                  </div>
                ))
              }
            </div>
          )
        }
        <div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            id="submitButton"
            type="button"
            className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-base font-medium text-white sm:text-sm"
            disabled={!formStateValid || saveAction.isExecuting}
            onClick={handleSaveProperty}
          >
            Save
          </button>
          <button
            type="button"
            className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={(event) => {
              event.preventDefault();
              setShowNewModal(false)
              setNewProperty(defaultProperty);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>

    </>
  )
}
