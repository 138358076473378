import React, { forwardRef, useContext } from 'react'
import { DesignActionbarContext } from "../../contexts"
import { PlusButton } from '../../components/PlusButton';
import { Popover } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns3, faSquare, faTableColumns } from '@fortawesome/pro-light-svg-icons';
import * as Data from "../../data";
export const AddActions = ({ containerId, ...props }) => {
  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    handleAddContainer,
  } = designActionbarContext || {};

  const onContainerClick = (containerTemplate) => {
    handleAddContainer(containerId, containerTemplate)
  }

  const containerOptions = [
    {
      key: 1,
      name: 'One Column',
      containerTemplate: Data.ContainerNew,
      icon: <FontAwesomeIcon size={'2x'} icon={faSquare} />
    },
    {
      key: 1,
      name: 'Two Columns',
      containerTemplate: Data.ContainerTwoColumns,
      icon: <svg className='h-8 stroke-3' viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.5 2V57M7 57H52C54.7614 57 57 54.7614 57 52V7C57 4.23858 54.7614 2 52 2H7C4.23858 2 2 4.23857 2 7V52C2 54.7614 4.23857 57 7 57Z" stroke="currentColor" />
      </svg>


    },
    {
      key: 1,
      name: 'Three Columns',
      containerTemplate: Data.ContainerThreeColumns,
      icon: <FontAwesomeIcon size={'2x'} icon={faColumns3} />
    },
    {
      key: 1,
      name: 'One-third Left',
      containerTemplate: Data.ContainerOneThirdLeft,
      icon: <svg className='h-8 stroke-3' viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 57V1M7 57H52C54.7614 57 57 54.7614 57 52V7C57 4.23858 54.7614 2 52 2H7C4.23858 2 2 4.23857 2 7V52C2 54.7614 4.23857 57 7 57Z" stroke="currentColor" />
      </svg>

    },
    {
      key: 1,
      name: 'One-third Right',
      containerTemplate: Data.ContainerOneThirdRight,
      icon: <svg className='h-8 stroke-3' viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.5 2V58M52 2H7C4.2386 2 2 4.2386 2 7V52C2 54.7614 4.2386 57 7 57H52C54.7614 57 57 54.7614 57 52V7C57 4.2386 54.7614 2 52 2Z" stroke="currentColor" />
      </svg>

    },
    {
      key: 1,
      name: 'Row-Columns',
      containerTemplate: Data.ContainerRowAndTwoCOls,
      icon: <FontAwesomeIcon size={'2x'} icon={faTableColumns} />
    }
  ]

  return (
    <>
      {
        handleAddContainer ?
          <div className='flex items-center my-2 mr-6'>
            <div className='flex-grow bg-black h-0.5 mr-2' />
            <div className='flex-grow-0 text-center'>
              
              <Popover className="relative">
                <Popover.Button as={NewSectionButton}></Popover.Button>

                <Popover.Panel className="mt-2 absolute z-10 w-96 left-1/2 transform -translate-x-1/2 focus:ring-0">
                  <div className="grid grid-cols-3 bg-gray-50 border border-gray-200 rounded-md shadow-md">
                    {
                      containerOptions.map((option) => (
                        <div key={option.key} className="flex flex-col items-center text-gray-700 hover:text-brand-blue justify-center p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => { onContainerClick(option.containerTemplate)}}>
                          {option.icon}
                          <span className="ml-2 text-sm text-gray-800 font-medium">{option.name}</span>
                        </div>
                      ))
                    }
                  </div>
                </Popover.Panel>
              </Popover>
            </div>
            <div className='flex-grow bg-black h-0.5 ml-2' />
          </div >
          :
          <></>
      }
    </>
  )
}



let NewSectionButton = forwardRef(function (props, ref) {
  return <button className="bg-brand-blue hover:bg-brand-blue-light ring-0 outline-none rounded-full px-2 py-1 text-white text-sm font-medium" ref={ref} {...props} >
    + Section
  </button>
})