import { useEffect, useState } from "react";
import { useFeature } from "../../../../hooks/useFeature";
import { useNavigate } from "react-router";
import { stringToLocaleDateTimeString, useTableSearch, useTableSort } from "@metaforcelabs/metaforce-core";
import { useToastAction } from "../../../../hooks/useToastAction";
import React from "react";
import { EyeIcon, PencilAltIcon, SearchIcon, TrashIcon } from "@heroicons/react/outline";
import * as Constants from '../../../../utils/constants';
import MenuContextList from "../../../../components/MenuContextList";
import Modal from "../../../../components/Modal";
import { TextInput } from "../../../../components/Form/TextInput";
import { Link, NavLink } from "react-router-dom";
import { createResource, destroyResource, getResources } from "../../../../api/resources";
import { Form, Formik, useFormikContext } from "formik";
import { FormikFileInput } from "../../../../components/Form/Formik/FormikFileInput";
import { FormikTextInput } from "../../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { getReadableFileSizeString } from "../../../../utils/file";
import { ResourceModal } from "./resourceModal";
import { useResourcesHelper } from "../../../../hooks/useResourcesHelpers";

export default function Resources() {
    const { hasFeature } = useFeature();
    const [allResources, setAllResources] = useState([]);
    const [resources, setResources] = useState([]);
    const navigate = useNavigate();
    const tableSort = useTableSort(resources);
    const tableSearch = useTableSearch(allResources);
    const loadAction = useToastAction(null, "");
    const newAction = useToastAction();
    const updateAction = useToastAction();
    const [showNewModal, setShowNewModal] = useState(false);
    const resourcesHelper = useResourcesHelper();


    const handleSearch = ({ target }) => {
        tableSearch.search(target.value, setResources, "name")
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            const resourcesData = await getResources();
            setResources(resourcesData);
            setAllResources(resourcesData)
        }, "Failed to load")
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleCreateTemplate = () => {
        setShowNewModal(true);
    }

    const onKeyPress = (event) => {
        //   onKeyPressEnter(event, "submitButton")
    }

    const handleSaveNew = async (values, onComplete) => {

        await newAction.executeAsync(async () => {
            const request = await resourcesHelper.createAddResourceRequestAsync(values);
            const addedResource = await createResource(request);
            setAllResources(prev => [...prev, addedResource])
            setResources(prev => [...allResources, addedResource]);
            setShowNewModal(false);
        })
        onComplete();
    }

    const handleDeletedRow = (id) => {
        updateAction.execute(async () => {
            await destroyResource(id);
            const removedList = allResources.filter(x => x.id !== id);
            setAllResources(removedList);
            setResources([...resources.filter(x => x.id !== id)])
        }, "Smart object is in use", "SmartObject deleted")
    }

    return (<>
        <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
            <h1 className="text-3xl font-bold">Resources</h1>
            <div className='flex justify-end mt-5 pb-5'>
                <div className="w-72">
                    <label htmlFor="desktop-search-candidate" className="sr-only">
                        Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <SearchIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                placeholder="Search..."
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                    onClick={() => handleCreateTemplate()}
                >
                    New Resource
                </button>
            </div>

        </div>

        <div className="my-2 sm:my-4 md:my-6 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
                <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("name")
                                    }
                                >
                                    Name {tableSort.getSortIcon("name")}
                                </th>
                                {/* <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("name")
                                    }
                                >
                                    Status {tableSort.getSortIcon("status")}
                                </th> */}
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("category")
                                    }
                                >
                                    Category {tableSort.getSortIcon("category")}
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("size")
                                    }
                                >
                                    Size {tableSort.getSortIcon("size")}
                                </th>

                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("createdDate")
                                    }
                                >
                                    Created {tableSort.getSortIcon("createdDate")}
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    onClick={() =>
                                        tableSort.orderBy("updatedDate")
                                    }
                                >
                                    Updated {tableSort.getSortIcon("updatedDate")}
                                </th>
                                {
                                    hasFeature(Constants.designFeatureCode) && (
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                tableSort.tableData.map(item =>
                                    <tr key={item?.id}>
                                        <td className="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden"
                                            style={{ maxWidth: "300px" }}
                                        >
                                            {
                                                <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                    <Link target="_blank" to={item?.assetUrl}
                                                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </span>
                                            }
                                        </td>
                                        {/* <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                {item.status}
                                            </span>
                                        </td> */}
                                        <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                {Constants.resourceCategoryNames[item.category]}
                                            </span>
                                        </td>
                                        <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                {getReadableFileSizeString(item.size)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                {stringToLocaleDateTimeString(item?.createdDate)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                                {stringToLocaleDateTimeString(item?.updatedDate)}
                                            </span>
                                        </td>
                                        {
                                            hasFeature(Constants.designFeatureCode) && (
                                                <td className="pt-6 pb-10 whitespace-nowrap text-right text-sm font-medium absolute">
                                                    {
                                                        item?.id &&
                                                        <MenuContextList
                                                            element={item}
                                                            deleteType={"resource"}
                                                            actions={[
                                                                {
                                                                    name: "View",
                                                                    href: item?.assetUrl,
                                                                    external: true,
                                                                    icon: EyeIcon,
                                                                },
                                                                {
                                                                    name: "Delete",
                                                                    disabled: item?.isDefaultForType,
                                                                    onClick: () => handleDeletedRow(item.id),
                                                                    icon: TrashIcon,
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ResourceModal isOpen={showNewModal} setOpen={setShowNewModal} onSubmit={handleSaveNew}/>
    </>
    )
}