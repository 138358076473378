import React from "react";
import { validateDataDefaultMessage } from '../utils/constants'
import { getErrorKey } from "../utils/features";

export const useErrorDetails = () => {

  const generateMessage = (error, translatedCustomErrorMessage) => {
    let message = '';
    // TODO this thing shouldn't blindly render the is required error message if the input actually has a value.
    // To get around this and to prevent the wrong error message from being rendered, the caller has to explicitely
    // pass in required=false so that the proper validation message is rendered.

    if (error?.required) {
      message = translatedCustomErrorMessage?.byRequired ? translatedCustomErrorMessage?.byRequired : error.customErrorMessage?.byRequired 
    }
    else {
      const fallBackValidationMessage = error.customErrorMessage?.byValidation || translatedCustomErrorMessage?.byValidation || validateDataDefaultMessage[error.validateData] || ''
      let validatedMessage =  fallBackValidationMessage
      validatedMessage = validatedMessage.charAt(0).toUpperCase() + validatedMessage.slice(1);
      message += validatedMessage;
    }

    return message;
  }

  const createErrorObject = (error, translatedCustomErrorMessage) => {
    var message = generateMessage(error, translatedCustomErrorMessage);

    return {
      messageWithSpan: <span className="text-red-500 pb-2">{message}</span>,
      message: message
    }
  }

  return {
    generateError: (errors, errorIdentifier, translatedCustomErrorMessage) => {
      var error = errors?.find(x => getErrorKey(x) === errorIdentifier);
      if (!error) {
        return null;
      }

      return createErrorObject(error, translatedCustomErrorMessage);
    }
  }
}