import { useDefinitionManager } from "./useDefinitionManager"
import { useNewIdGenerator } from "./useNewIdGenerator";
import * as Data from "../../../data";
import _ from 'lodash';
import { setProperty } from '../../../utils/property'
import { useCallback } from "react";

export const useContainerActions = (onSuccess, setContainerContext, themeStyle) => {
  const definitionManager = useDefinitionManager();
  const idGenerator = useNewIdGenerator();

  const buildPropertyWithValidationForContainer = useCallback((payload, container) => {
    const containersOfDialogDefinition = payload.containers;
    const containerIndexOfName = containersOfDialogDefinition.findIndex(e => !container.name ? !e.name : e.name === container.name);
    let containerLengthWithValidation = container.id.substring(0, 4);

    if (containerIndexOfName !== -1) {
      containerLengthWithValidation = containerIndexOfName + 1;
    } else if (!container.name) {
      containerLengthWithValidation = containersOfDialogDefinition.length + 1;
    }

    container.name = `Section-${containerLengthWithValidation}`
  }, [])

  return {
    addToEmpty: (dialogDefinition) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(Data.ContainerNew(themeStyle))
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)

      payload.containers = [newContainer]

      onSuccess(payload);
      setContainerContext(newContainer)

      const element = definitionManager.findLastElementFromContainer(newContainer);
      definitionManager.openElementActionBar(element);
    },
    addTemplateToEmpty: (dialogDefinition, containerTemplate) => {
      const payload = _.cloneDeep(dialogDefinition);
      let newContainer = _.cloneDeep(containerTemplate(themeStyle));
      idGenerator.newIdsOnContainer(newContainer);
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)

      payload.containers = [newContainer]

      onSuccess(payload);
      setContainerContext(newContainer)

      const element = definitionManager.findLastElementFromContainer(newContainer);
      definitionManager.openElementActionBar(element);
    },
    addEmptyWithElement: (dialogDefinition, element, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(Data.ContainerEmpty(element, themeStyle))
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)

      payload.containers = [newContainer]

      if (!skipSave) {
        onSuccess(payload);
      }
      return payload;
    },
    addAbove: (dialogDefinition, containerId, customElement) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(customElement ? Data.ContainerEmpty(customElement, themeStyle) : Data.ContainerNew(themeStyle))
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)

      definitionManager.addItemAbove(payload.containers, containerId, newContainer)

      onSuccess(payload);
      setContainerContext(newContainer)
    },
    addBelow: (dialogDefinition, containerId, customElement, skipSave = false) => {
      let element = customElement;
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(element ? Data.ContainerEmpty(element, themeStyle) : Data.ContainerNew(themeStyle))
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)

      definitionManager.addItemBelow(payload.containers, containerId, newContainer)
      if (!skipSave) {
        onSuccess(payload);
      }
      setContainerContext(newContainer)

      if (!customElement) {
        element = definitionManager.findLastElementFromContainer(newContainer);
      }

      definitionManager.openElementActionBar(element);
      return payload
    },
    addTemplateBelow: (dialogDefinition, containerId, containerTemplate, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition);
      let newContainer = _.cloneDeep(containerTemplate(themeStyle));
      idGenerator.newIdsOnContainer(newContainer);
      definitionManager.addItemBelow(payload.containers, containerId, newContainer)
      if (!skipSave) {
        onSuccess(payload);
      }
      setContainerContext(newContainer);
      return payload
    },
    addFromSidebar: (dialogDefinition, containerIndexTo, id, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(Data.ContainerNew(themeStyle))
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)
      newContainer.id = id

      definitionManager.addItemByIndex(payload.containers, newContainer, containerIndexTo)

      setContainerContext(newContainer)
      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    addObjectFromSidebar: (dialogDefinition, containerIndexTo, newContainer, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      definitionManager.addItemByIndex(payload.containers, newContainer, containerIndexTo)
      setContainerContext(newContainer)
      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    remove: (dialogDefinition, containerId) => {
      const payload = _.cloneDeep(dialogDefinition)

      //#region remove logic controls dependencies
      const elementsToRemove = payload.containers.find(container => container.id === containerId)?.rows
        .flatMap(row => row.columns.flatMap(column => column.elements.map(element => element.property)));

      //Delete container.
      definitionManager.removeItem(payload.containers, containerId)

      // Iterate through the other containers to remove the showLogicControl elements.
      payload.containers.forEach(container => {
        if (container.showLogicControl) {
          const originalLength = container.showLogicControl.length;
          container.showLogicControl = container.showLogicControl.filter(logic => {
            const property = logic.condition.property;
            return !elementsToRemove.includes(property);
          });

          // Check if any showLogicControl was deleted and left an orphaned connector.
          if (container.showLogicControl.length < originalLength) {
            const orphanedLogic = container.showLogicControl[container.showLogicControl.length - 1];
            if (orphanedLogic) {
              orphanedLogic.conector = 0;
            }
          }
        }
      });
      //#endregion

      onSuccess(payload, { dismissActions: true });
    },
    hiddenOrShow: (dialogDefinition, containerId, hidden, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      definitionManager.hiddenOrShowContainer(payload, containerId, hidden);

      if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    duplicate: (dialogDefinition, containerId, container) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newContainer = _.cloneDeep(container);
      idGenerator.newIdsOnContainer(newContainer);
      buildPropertyWithValidationForContainer(payload, newContainer)
      definitionManager.addItemBelow(payload.containers, containerId, newContainer)

      onSuccess(payload);
    },
    addRow: (dialogDefinition, container) => {
      let newRow = _.cloneDeep(Data.RowNew(themeStyle))
      idGenerator.newIdsOnRow(newRow);

      let lastRow = container.rows[container.rows.length - 1];
      definitionManager.addItemBelow(container.rows, lastRow.id, newRow)

      onSuccess(dialogDefinition);
    },
    addRowAndNewContainer: (dialogDefinition) => {
      let newRow = _.cloneDeep(Data.RowNew(themeStyle))
      idGenerator.newIdsOnRow(newRow);

      let container = Data.ContainerNew(themeStyle);
      let lastRow = container.rows[container.rows.length - 1];
      definitionManager.addItemBelow(container.rows, lastRow.id, newRow)

      onSuccess(dialogDefinition);
    },
    moveUp: (dialogDefinition, containerId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let itemIndex = payload.containers?.findIndex((item) => item.id === containerId);

      definitionManager.swapItems(itemIndex, itemIndex - 1, payload.containers);

      onSuccess(payload);
    },
    dragAndDrop: (dialogDefinition, containerIndexFrom, containerIndexTo, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      if (containerIndexTo !== -1) definitionManager.reorder(payload.containers, containerIndexFrom, containerIndexTo)

      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    moveDown: (dialogDefinition, containerId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let itemIndex = payload.containers?.findIndex((item) => item.id === containerId);
      const itemIndexFrom = itemIndex + 1;
      const items = payload.containers;

      if (itemIndexFrom >= items.length) return;

      definitionManager.swapItems(itemIndexFrom, itemIndex, items);

      onSuccess(payload);
    },
    handleChange: (dialogDefinition, e, containerId, type, replaceContainer) => {
      const payload = _.cloneDeep(dialogDefinition)
      const { name, value } = e.target;

      let container = payload?.containers?.find(e => e.id === containerId);

      if (container) {
        if (replaceContainer === true) {
          _.set(payload, name, value)
        } else {
          setProperty(container, name, value, type)
        }

        onSuccess(payload);
      }
    },
    handleChanges: (dialogDefinition, events, containerId, type, replaceContainer) => {
      const payload = _.cloneDeep(dialogDefinition);
      let container = payload?.containers?.find(e => e.id === containerId);
      if (container) {
        events.forEach(e => {
          const { name, value } = e.target;

          if (replaceContainer === true) {
            _.set(payload, name, value)
          } else {
            setProperty(container, name, value, type)
          }
        });
        onSuccess(payload);
      }

    }
  }
}