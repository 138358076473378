import { get, put, post, destroy } from './api'

const controllerName = "QuickDesign"

export const createQuickDesignForm = async (dialogDefinitionId) => {
    return await post(controllerName, { dialogDefinitionId })
}

export const convertQuickDesignToStandard = async (dialogDefinition) => {
    return await post(`${controllerName}/converttostandard`, dialogDefinition)
}

export const updateQuickDesignForm = async (quickDesignDefinition) => {
    return await put(controllerName, quickDesignDefinition)
}

export const getQuickDesignDefinition = async (quickDesignid) => {
    return await get(`${controllerName}/${quickDesignid}`);
}