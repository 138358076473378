import { v4 as uuidv4 } from 'uuid';
import { elementTypes } from '../../../utils/constants';

const findElementTypeKeyByValue = (value) => {
  for (const key in elementTypes) {
    if (elementTypes[key] === value) {
      return key;
    }
  }
  return undefined;
}

export const useNewIdGenerator = () => {
  const newIdOnElement = (element) => {
    element.id = uuidv4();

    if(element?.connectedElements) {
      element.connectedElements.forEach(item => {
        item.id = uuidv4();
        const elementTypeName = findElementTypeKeyByValue(item.type);
        let newElementTag = item.id.substring(0, 4);

        item.property = `${elementTypeName}-${newElementTag}`
      })
    }

    console.log('element', element)
  }

  const newIdsOnColumn = (column) => {
    column.id = uuidv4();
    column.elements.forEach(item => {
      newIdOnElement(item)
    })
  }

  const newIdsOnColumnElements = (column) => {
    column.elements.forEach(item => {
      newIdOnElement(item)
    })
  }

  const newIdsOnRow = (row) => {
    row.id = uuidv4();
    row.columns.forEach(column => {
      newIdsOnColumn(column)
    })
  }

  const newIdsOnContainer = (containers) => {
    if (containers.length) {
      containers.forEach(container => {
        container.id = uuidv4();

        container?.rows.forEach(row => {
          newIdsOnRow(row)
        })
      })
    } else {
      containers.id = uuidv4();

      containers?.rows.forEach(row => {
        newIdsOnRow(row)
      })
    }
  }

  const newIdsOnDialog = (dialog) => {
    dialog.containers.forEach(container => {
      newIdsOnContainer(container)
    })
  }

  return {
    newIdOnElement,
    newIdsOnColumn,
    newIdsOnColumnElements,
    newIdsOnRow,
    newIdsOnContainer,
    newIdsOnDialog
  }
}