import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import * as Dialogs from "../../../templates/dialogs";
import toast from "react-hot-toast";
import * as Structures from "../../../structures/"

export default function TemplateGallery({ open, setOpen, dialogId, customTemplates }) {
    const [dialogDefinition, setDialogDefinition] = useState({});

    const showTemplate = (createType) => {
        switch (createType) {
            case 1:
                return Dialogs.Blank;
            case 2:
                return Dialogs.Basic;
            case 3:
                return Dialogs.NonDisclosureAgreement;
            case 4:
                return Dialogs.ResignationLetter;
            case 5:
                return Dialogs.RehirePolicy;
            case 6:
                return Dialogs.EmploymentContract;  
            case 7:
                return Dialogs.CancellationOfLeaseAgreement;
            case 8:
                return Dialogs.RentContract;
            case 9:
                return Dialogs.BoatLeaseAgreement;
            case 10:
                return Dialogs.StorageRentalAgreement;
            case 11:
                return Dialogs.AbsenceRequestForm;
            case 12:
                return Dialogs.AbsenceRequestFormFromManager;
            case 20:
                return Dialogs.QuickForm;
            default:
                const customTemplate = customTemplates.find((template) => template.id === createType);
                return customTemplate ? customTemplate : toast.error("Template not found");
        }

    };
    useEffect(() => {
        if (open) {
            const template = showTemplate(dialogId);
            setDialogDefinition(template);
        }
    }, [open, dialogId]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Transition.Root id="defaultModal" show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto overflow-x-hidden"
                open={open}
                onClose={handleClose}
            >
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left shadow-xlsm:align-middle w-full min-h-screen h-full">
                            <div className="fixed bg-white top-0 right-0 left-0 h-10">
                                <div className="absolute top-0 right-0">
                                    <button type="button"
                                        onClick={() => handleClose()}
                                        className="inline-flex p-3 text-gray-400"
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <Structures.Dialog data={dialogDefinition} isPreviewTemplateMode={true} />
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
