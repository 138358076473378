import { AtSymbolIcon, DocumentTextIcon, KeyIcon, LinkIcon, OfficeBuildingIcon, PencilAltIcon, PencilIcon, PhoneIcon, DownloadIcon } from "@heroicons/react/outline"

export const useReactIconFactory = () => {

    const icons = {
        "pencil": PencilIcon,
        "pencil-alt": PencilAltIcon,
        "key": KeyIcon,
        "url": LinkIcon,
        "email": AtSymbolIcon,
        "phone": PhoneIcon,
        "office-building": OfficeBuildingIcon,
        "document-text": DocumentTextIcon,
        "download": DownloadIcon,
        "default": AtSymbolIcon
    };

    return {
        createReactIcon: (iconKey) => {
            return icons[iconKey] || icons.default;
        }
    }
}