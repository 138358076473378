import { useState, useEffect } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Spacer"
        />
      </Property.Group>

      <Property.Group title="Element Style">
        <Property.Text
          type={"number"}
          onChange={(e) => handleOnChange(e, "number")}
          label={'Height'}
          name={'height'}
          value={data?.height}
        />

        <Property.Text
          type={"number"}
          onChange={(e) => handleOnChange(e, "number")}
          label={'Width'}
          name={'width'}
          value={data?.width}
        />

      </Property.Group>

    </div>
  )
}
