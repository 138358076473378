import { destroy, download, get, post, put } from './api'


export const getDialogValues = async (id) => {
  return await get(`dialogValues/${id}`);
}

export const deleteDialogValues = async (id) => {
  return await destroy(`dialogValues/${id}`);
}

export const rollbackDialogValues = async (id) => {
  return await post(`dialogValues/rollback/${id}`);
}

export const getDialogValuesWebhookPayload = async (id, type) => {
  return await get(`dialogValues/${id}/webhookPayload/${type}`);
}

export const getByDialogDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogValues`);
}

export const getSummariesByDialogDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogValues/summary`);
}

export const getDialogValuesWithStepsByDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogValuesWithSteps`);
}

export const getAllInMultiStepInProgress = async (id) => {
  return await get(`dialogValues/multistep/in-progress`);
}

export const getInProgressReport = async (dialogDefinitionStatus) => {
  return await get(`dialogValues/in-progress?status=${dialogDefinitionStatus}`);
}

export const getInProgressReportDialog = async (dialogDefinitionId) => {
  return await get(`dialogValues/${dialogDefinitionId}/in-progress`);
}

export const getInProgressReportForDialogs = async (dialogDefinitionIds) => {
  return await post(`dialogValues/in-progress`, dialogDefinitionIds);
}

export const getDialogDefinitionWithValues = async (id, dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogValues/${id}`);
}

export const updateDialogValues = async (id, values, isComplete = false) => {
  return await put(`dialogValues/${id}`, { values, isComplete });
}

export const createDialogValues = async (dialogDefinitionId, environment) => {
  return await post('dialogValues', { dialogDefinitionId, environment });
}

export const sendDialogValues = async (payload) => {
  return await post('dialogValues/send', payload);
}

export const resendDialogValues = async (dialogValuesId) => {
  return await post(`dialogValues/${dialogValuesId}/resend`, {
    // empty
  });
}

export const resendDialogValuesWithNewRecipientDetails = async (dialogValuesId, recipient) => {
  return await post(`dialogValues/${dialogValuesId}/resend`, {
    phone: recipient.phone,
    email: recipient.email,
  });
}

export const uploadDialogValuesFile = async (id, elementIdentifier, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return await post(`dialogValues/${id}/values/${elementIdentifier}/files`, formData);
}

export const deleteDialogValuesFile = async (id, elementIdentifier, fileId) => {
  return await destroy(`dialogValues/${id}/values/${elementIdentifier}/files/${fileId}`);
}

export const saveDialogValuesTable = async (id, elementIdentifier, values) => {
  return await post(`dialogValues/${id}/values/${elementIdentifier}/table`, values);
}

export const deleteDialogValuesTableValue = async (id, elementIdentifier, tableValueId) => {
  return await destroy(`dialogValues/${id}/values/${elementIdentifier}/table/${tableValueId}`);
}

export const generatePdf = async (dialogDefinitionId, dialogValuesId, filename) => {
  return await download(`dialogs/generate/${dialogDefinitionId}/${dialogValuesId}`, filename);
}

export const generatePdfPreview = async (dialogDefinitionId, filename) => {
  return await download(`dialogs/generate/${dialogDefinitionId}`, filename);
}

export const downloadFromViewPoint = async (dialogDefinitionId, dialogValuesId) => {
  return await download(`dialogs/export/viewpoint/${dialogDefinitionId}/${dialogValuesId}`);
}

export const exportData = async (dialogDefinitionId) => {
  return await download(`dialogValues/${dialogDefinitionId}/values/export`, `export-${dialogDefinitionId}.csv`);
}

export const getWithMissingSignatures = async () => {
  return await get(`dialogValues/missingSignatures`)
}

export const abortDialogValues = async (dialogValuesId) => {
  return await post(`dialogValues/${dialogValuesId}/abort`)
}

/**
 * @typedef {Object} DialogValuesParticipatingParty
 * @prop {string} particpatingPartyId
 * @prop {string} name
 * @prop {string} email 
 */
/**
 * 
 * @param {string} dialogValuesId  
 * @param {DialogValuesParticipatingParty[]} values 
 * @returns 
 */
export const updateDialogValuesParticipatingParties = async (dialogValuesId, values) => {
  return await put(`dialogValues/${dialogValuesId}/participatingParties`, values)
}

export const transferDialogValues = async (dialogValuesId, userInfo) => {
  return await put(`dialogValues/${dialogValuesId}/transfer`, { ...userInfo })
}

export const generateSignedFile = async (id) => {
  return await download(`dialogValues/${id}/signedFile`)
}