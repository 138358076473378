export default function TranslationProgressBar({ automaticTranslationProgress }) {
  return (
    <>
      {(automaticTranslationProgress.inProgress || automaticTranslationProgress.isChecking) && (
        <div className="mt-10">
          <div className="">
            <div className="pb-5 border-b border-gray-200 mt-10 mb-6">
              <h2 className="text-2xl leading-6 font-medium text-gray-900">Translated items</h2>
            </div>
          </div>
          {automaticTranslationProgress.inProgress ? (
            <div className="flex flex-col flex-1 align-center justify-center items-center w-full border border-gray-300 p-20 gap-3">
              <div className="flex items-center">
                <label className="text-gray-500 mr-2">
                  Currently running automatic translation
                </label>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
              <div className="relative border-solid border-2 rounded-lg w-80 text-gray-600 bg-transparent">
                <div
                  className={"absolute bg-brandBlue bg-opacity-70 h-full rounded-md"}
                  style={{ width: `${automaticTranslationProgress.progress}%`, transition: "all 200ms" }}
                />
                <span className="ml-2">
                  {automaticTranslationProgress.progress} %
                </span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col flex-1 align-center justify-center items-center w-full border border-gray-300 p-20 gap-3">
              <div className="flex items-center">
                <label className="text-gray-500 mr-2">
                  Automatic translation starting shortly
                </label>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}