import { useState } from "react"
import { createDialogTemplate, updateDialogTemplateName } from "../../../../api/dialogTemplates";
import { Button } from "../../../../components/Form/Button";
import Modal from '../../../../components/Modal';
import { useToastAction } from "../../../../hooks/useToastAction";
import { Blank } from "../../../../templates/dialogs";

export const SmartformTemplateForm = ({
    isOpen,
    onClose,
    onSuccess,
    smartformToRename,
    onRenameSuccess,
}) => {
    const [name, setName] = useState();
    const createDialogObjectAction = useToastAction()
    const updateNameAction = useToastAction();
    const isNew = !smartformToRename;

    const onCreate = () => {
        createDialogObjectAction.execute(async () => {
            const resp = await createDialogTemplate(Blank, name)
            setName()
            onClose()
            onSuccess(resp)
        }, "Failed to create", "Smartform template created")
    }

    const onUpdate = async () => {
        updateNameAction.execute(async () => {
            await updateDialogTemplateName(smartformToRename.id, name)
            setName()
            onClose()
            onSuccess()
        }, "Failed to rename smartform template", "Renamed smartform template")
    }

    const onSaveClick = () => {
        isNew ? onCreate() : onUpdate()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'New Smartform template' : 'Rename Smartform template'}
            size="small"
        >
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                <input defaultValue={smartformToRename.name || ""} type="text" className='flex-1 block w-full sm:text-sm pr-10 py-2 text-base border-gray-300 hover:border-gray-300 focus:border-gray-300 rounded-md shadow-sm' name="name"
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    text={isNew ? "Create" : "Save"}
                    className="mt-2"
                    disabled={!name?.length}
                    onClick={onSaveClick}
                />
            </div>
        </Modal>
    );
}