
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDialogDefinitionEditMode, updateDialogDefinition } from '../../../api/dialogDefinition';
import { useToastAction } from "../../../hooks/useToastAction";
import { DialogDesign } from './DialogDesign';
import QuickDesign from "./QuickDesign";
import { useActivityManager } from "./useActivityManager";
import Loader from "../../../components/Loader";

export default function Design() {  
  const { dialogKey } = useParams();
  const [dialogDefinition, setDialogDefinition] = useState(null)
  const loadAction = useToastAction();
  
  // Start the activity manager.
  useActivityManager(dialogKey)

  const onLoad = async () => {
    return await getDialogDefinitionEditMode(dialogKey);
  }

  const onSave = async (dialogDefinition, lastUpdatedAt) => {
    const payload = lastUpdatedAt
      ? { ...dialogDefinition, updatedDate: lastUpdatedAt }
      : dialogDefinition
    const updateResult = await updateDialogDefinition(payload);
    return updateResult
  }
  const loadData = () => {
    loadAction.execute(async () => {
      const dialog = await getDialogDefinitionEditMode(dialogKey);
      setDialogDefinition(dialog);
    })
  }

  // TODO if we're already loading the dialogDefinition here, why not just pass it into child components
  // instead of two calls to the api?
  useEffect(() => {
    loadData();
  }, [dialogKey])

  if (loadAction.isExecuting || !dialogDefinition) {
    return (
    <div className='w-full h-full flex flex-col justify-center items-center'>
      <Loader height={60} width={60}/>
    </div>);
  }

  return dialogDefinition?.isQuickForm ? (
    <QuickDesign dialogDefinition={dialogDefinition} onLoad={loadData} />
  ) : (
    <DialogDesign
      onLoad={onLoad}
      onSave={onSave}
    />
  )
}
