import { v4 as uuidv4 } from "uuid";

export const AbsenceRequestFormFromManager = {
    folderIds: [],
    process: "Normal",
    companyId: "62fcd7271276f96365d151ad",
    createdBy: "df9f3ed3-2b23-4de5-b649-16e02f23bb05",
    updatedBy: "df9f3ed3-2b23-4de5-b649-16e02f23bb05",
    externalRedirectUrl: null,
    authenticationTemplate: null,
    borderWidth: null,
    space: 0,
    size: 0,
    generatePdfOnSubmit: false,
    sendPdfToViewpoint: false,
    backgroundColor: "#fff",
    toolbarColor: null,
    type: 1,
    position: 0,
    name: "Absence Request Form From Manager",
    isQuickForm: false,
    quickFormId: null,
    containers: [
        {
            id: uuidv4(),
            dialogObjectId: null,
            lockToDialogObject: null,
            space: null,
            backgroundColor: null,
            margin: "20px 0",
            padding: null,
            borderWidth: null,
            borderColor: null,
            roundedCorners: false,
            static: false,
            name: "Absence Request Form Manager Approval",
            newPage: false,
            backgroundImage: null,
            backgroundSize: 0,
            backgroundRepeat: false,
            avoidBreakInside: false,
            rows: [
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 18,
                                        bold: true,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Managerapproval.Headline",
                                    value: "Manager Approval",
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: null,
                                    labelSetManually: false,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: null,
                            margin: null,
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#f7efc0",
                    margin: "20px 0 0 0",
                    padding: "5px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: false,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Radiobutton.Approved",
                                    value: "Radio button 486",
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: "Approved ",
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 15,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Radiobutton.rejected",
                                    value: "Radio button 486",
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: "Rejected",
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 5,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#fdfbef",
                    margin: "0px 0 0 0",
                    padding: "10px 10px 0 10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: false,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 2,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelStyle: {
                                        level: null,
                                        alignment: null,
                                        fontFamily: null,
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: null,
                                    },
                                    dependencies: { value: "none", type: 0 },
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Input.Comments",
                                    value: "New input text line",
                                    placeholder: "Comments ",
                                    borderWidth: "1px",
                                    borderColor: "#000",
                                    width: null,
                                    height: null,
                                    label: "Comments ",
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: true,
                                    backgroundColor: "#fff",
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#fdfbef",
                    margin: "0px 0 0 0",
                    padding: "0 10px 0 10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: false,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 2,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelStyle: {
                                        level: null,
                                        alignment: null,
                                        fontFamily: null,
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: null,
                                    },
                                    dependencies: { value: "none", type: 0 },
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Input.Supervisorname",
                                    value: "New input text line",
                                    placeholder: "Supervisor's Name",
                                    borderWidth: "1px",
                                    borderColor: "#000",
                                    width: null,
                                    height: null,
                                    label: "Supervisor's Name",
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: true,
                                    backgroundColor: "#fff",
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 5,
                                    inputType: 1,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "center",
                                        fontFamily: "arial",
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "black",
                                    },
                                    labelStyle: {
                                        level: null,
                                        alignment: "center",
                                        fontFamily: "arial",
                                        size: 14,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "black",
                                    },
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Datepicker.dateapproved",
                                    value: null,
                                    placeholder: "Date",
                                    borderWidth: "1px",
                                    borderColor: "#000",
                                    width: null,
                                    height: null,
                                    label: "Date Approved:",
                                    labelSetManually: true,
                                    helpText: "14",
                                    leadingText: "",
                                    trailingText: "",
                                    labelTop: true,
                                    backgroundColor: "#fff",
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#fdfbef",
                    margin: "0px 0 0 0",
                    padding: "0 10px 0 10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: true,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 19,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 0, right: null },
                                    text: {
                                        level: null,
                                        alignment: null,
                                        fontFamily: null,
                                        size: 0,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: null,
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property:
                                        "Absencerequestform.Manager.Signature",
                                    value: "Signature",
                                    placeholder: "",
                                    borderWidth: "1px",
                                    borderColor: "#000",
                                    width: null,
                                    height: 152,
                                    label: "Label",
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: "#fff",
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#fdfbef",
                    margin: "0px 0 0 0",
                    padding: "0 10px 0 10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: true,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 6,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableInputType: 0,
                                    dynamicTableValues: null,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: "1",
                                        alignment: "right",
                                        fontFamily: "arial",
                                        size: 18,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#fff",
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property: "Submitbutton",
                                    value: "Submit",
                                    placeholder: null,
                                    borderWidth: "1px",
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: null,
                                    labelSetManually: true,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: "#000",
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                },
                            ],
                            contentAlign: "right",
                            backgroundColor: null,
                            space: 3,
                            span: 2,
                            margin: "5px",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#fdfbef",
                    margin: "0px 0 0 0",
                    padding: "0 10px 0 10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: true,
                },
            ],
            readOnly: null,
        },
    ],
    margin: null,
    padding: null,
    isDeleted: false,
    hasDigitalSigning: false,
    accessGroups: [],
    folderId: null,
};
