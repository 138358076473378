import { useEffect, useState } from "react";

export const useHeadings = (elements = []) => {
    const [headings, setHeadings] = useState([]);
    useEffect(() => {        
      elements.length && elements.map((element) => ({
        id: element.id,
        text: element.textContent ?? "",
        level: Number(element.tagName.substring(1))
      }));

      setHeadings(elements);
    }, [headings, elements]);
    return headings;
  }