import { useClassNames } from "../../hooks/useClassNames";

export default function IconButton({ icon, label, disabled, onClick, spaceLeft, enabled, processing, size }) {
  const Icon = icon ? icon : null;
  const classNames = useClassNames();

  const sizeStyle = () => {
    switch (size) {
      case 'sm':
        return 'h-9 w-9'
      default:
        return 'h-10 w-10'
    }
  }
 

  return (
    <div 
    className={classNames.classNames(
        spaceLeft
              ? 'ml-4'
              : '',
        `grid justify-items-center ${size === 'sm' ? "h-10" : "h-14 mb-4"}`,
      )}
      onClick={disabled ? null : onClick}
      >
        { processing
        
        ? <div className="flex h-10 w-10 bg-gray-200 text-gray-400 px-2 py-1 border-gray-300 shadow-sm leading-4 font-medium rounded-md text-sm border">
            <svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="gray" stroke-width="4"></circle>
              <path class="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          
        : <Icon 
            className={classNames.classNames(
              disabled
                ? 'bg-gray-200 text-gray-400'
                : 'text-gray-500 bg-white cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2',
              ` ${sizeStyle()} items-center tems-center px-2 py-1 border-gray-300 shadow-sm leading-4 font-medium rounded-md text-sm`,
              enabled
                ? 'ring-2 ring-gray-400'
                : 'border'
            )}
            aria-hidden="true" 
          />
        
        }
        <span className="mt-1 text-xs text-center text-gray-600">{label}</span> 
    </div>
  );
}