import { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { FormContext } from "../../contexts";
import { documentSigningModes } from "../Admin/DialogProcess/normalProcessDigitalSigning";
import { processTypes } from "../../utils/constants";
import React from "react";
import { Form, Formik } from "formik";
import PartiesToSignForm from "./partiesToSignForm";
import * as yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import useDigitalSigningHelper from "./useDigitalSigningHelper";

export const PartiesToSign = ({ modalHelper, handleSendToSigning, dialogDefinition, canSignNow, canDefinePartiesToSign, availableSignAuthMethods }) => {
    const { digitalSigningValues, dialogValues, digitalSigningProviderDetails, step, ...formContext } = useContext(FormContext);
    const distributionLists = dialogValues?.distributionLists;
    const digitalSigningTranslations = formContext?.translateDigitalSigning(digitalSigningValues);
    // const [isInitialized, setIsInitialized] = useState(false);
    const [isSigningNow, setIsSigningNow] = useState(false);
    const [signDirectParties, setSignDirectParties] = useState([]);
    const { isSingleMode, digitalSigningAllowDirectSigning } = useDigitalSigningHelper(dialogDefinition, dialogValues, step);


    // const isSingleMode = (dialogDefinition.digitalSigningDocumentSigningMode === documentSigningModes.single && dialogDefinition.process === processTypes.normal) ||
    //     (dialogDefinition.process === processTypes.multiStep && canSignNow && !canDefinePartiesToSign);

    // const digitalSigningAllowDirectSigning = dialogDefinition.process === processTypes.normal ? digitalSigningValues?.digitalSigningAllowDirectSigning : dialogDefinition.lastPartyToSignFromSmartform;

    const handleClose = () => {
        modalHelper.close();
        setIsSigningNow(false);
        setSignDirectParties([]);

    }

    const handleSubmitPartiesToSign = (partiesToSignForm, signNow = false) => {
        if (signNow) {
            setIsSigningNow(true);
            setSignDirectParties(partiesToSignForm);
        } else {
            handleSendToSigning(partiesToSignForm, signNow);
        }
        // e.preventDefault();
        // e.stopPropagation();

    }

    const checkForDuplicateEmail = (value, index, partiesToSignForm) => {
        return index === partiesToSignForm.findIndex(x => x.email === value);
    }

    const handleSignDirect = () => {
        setIsSigningNow(true);
    }

    const handleSignNow = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSendToSigning(signDirectParties, true);
        // onSignNow(e);
    }

    const determineContactCountry = (email) => {
        const domain = email.split('@')[1];
        if (!domain) return null;

        const domainParts = domain.split('.');
        const topLevelDomain = domainParts[domainParts.length - 1];
        if (!topLevelDomain) return null;

        switch (topLevelDomain) {
            case 'no':
            case 'se':
                return topLevelDomain;
            default:
                return null;
        }

    }

    const initPartiesToSign = () => {

        if (dialogDefinition.process === processTypes.normal || !distributionLists || distributionLists.length === 0) {

            return [{ key: uuidv4(), name: '', email: '', signatureAuthorizationChoosenMethod: availableSignAuthMethods[0]?.value }]
        }
        return distributionLists.map(contact => {
            let defaultSignMethodForCountry = null;
            if (availableSignAuthMethods.length > 0) {
                const contactCountry = determineContactCountry(contact.email);
                defaultSignMethodForCountry = availableSignAuthMethods.find(x => x.defaultForCountry == contactCountry)?.value ?? availableSignAuthMethods[0]?.value;
            }

            return { key: uuidv4(), name: contact.name, email: contact.email, signatureAuthorizationChoosenMethod: defaultSignMethodForCountry }
        })
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={handleClose}
            size="xl"
            title={digitalSigningTranslations?.message || "Parties to Sign"}
        >
            {
                isSigningNow ? (
                    <>
                        <div>
                            {digitalSigningTranslations?.signNowDescription ?? 'I confirm I have authority to sign this document'}
                        </div>

                        <div className="mt-5 flex justify-end items-center space-x-2">
                            <button
                                onClick={handleSignNow}
                                type="button"
                                className=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {digitalSigningTranslations?.signNowBtn}
                            </button>
                            {/*Commented out until functionality is implemented*/}
                            {/* <button
                                                onClick={handleAskOther}
                                                type="button"
                                                className=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                {digitalSigningTranslations?.askOtherBtn ?? 'Send to signing'}
                                            </button> */}
                            <button
                                onClick={handleClose}
                                type="button"
                                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {digitalSigningTranslations?.signNowCancelBtn}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                partiesToSignForm: initPartiesToSign(),
                                signNow: false
                            }}
                            onSubmit={(values, { setSubmitting, ...props }) => {
                                const { partiesToSignForm, signNow } = values;
                                partiesToSignForm.forEach((party) => {
                                    if (party.signatureAuthorizationChoosenMethod === undefined)
                                        party.signatureAuthorizationChoosenMethod = availableSignAuthMethods[0]?.value;
                                });
                                handleSubmitPartiesToSign(partiesToSignForm, signNow)
                            }}
                            validationSchema={() => {
                                return yup.object().shape({
                                    partiesToSignForm: yup.array()
                                        .of(
                                            yup.object().shape({
                                                key: yup.string(),
                                                name: yup.string().required('Required'),
                                                email: yup.string().required('Required').email('Must be valid email')
                                                    .test("Unique", "Email needs to be unique", (value, context) => {
                                                        return checkForDuplicateEmail(value, context.options.index, context.options.context.partiesToSignForm);
                                                    }),
                                            })
                                        )
                                });
                            }}
                        >
                            {
                                () => (
                                    <PartiesToSignForm
                                        errorMessage={digitalSigningTranslations?.error}
                                        digitalSigningTranslations={digitalSigningTranslations}
                                        digitalSigningAllowDirectSigning={digitalSigningAllowDirectSigning}
                                        handleClose={handleClose}
                                        isSingleMode={isSingleMode}
                                        lockSignatures={isSingleMode && dialogDefinition.process === processTypes.multiStep}
                                        onSignDirect={handleSignDirect}
                                        availableSignAuthMethods={availableSignAuthMethods}
                                        digitalSigningProvider={digitalSigningProviderDetails?.providerId}
                                    />
                                )
                            }

                        </Formik>
                    </>
                )
            }

        </Modal>
    );
}