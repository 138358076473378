import React, { useState, useEffect, useContext } from 'react'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import InlineEditing from '../../components/InlineEditing'
import { getDialogValueKey } from '../../utils/features'
import { useDynamicStringReplacement } from '../../hooks/useDynamicStringReplacement'

const elements = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
  '5': 'h5',
  '6': 'h6',
};

function Heading({ type, children, ...props }) {
  return React.createElement(
    elements[type] || elements.h1,
    props,
    children
  );
}

Heading.defaultProps = {
  type: '1',
};

export default function HeaderLine({ data, ...props }) {
  const formContext = useContext(FormContext);

  const classPrefix = "header"
  const elementStyle = useElementStyle(data);
  const { width, ...borderStyles } = elementStyle.borderAndWidthStyle();
  const { justifyContent, ...textStyles } = elementStyle.textStyle(data?.text);

  const style = {
    ...borderStyles,
    ...textStyles,
  }

  const elementDesign = useElementDesign(data.id, data);
  const [textToShow, setTextToShow] = useState("");
  const textValue = formContext?.inputValues[getDialogValueKey(data)]?.value || data.value
  const type = elements[data?.text?.level];

  useEffect(() => {
    setTextToShow(elementDesign.translateTerm(textValue))
  }, [textValue, elementDesign])

  let translatedText = elementDesign.translateTerm(textValue)
  const textWithReusableValues = useDynamicStringReplacement(translatedText, formContext?.reusableProperties, formContext?.dialogValues?.dataTableValues);

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <InlineEditing
        initData={data}
        style={style} 
        classes={`element ${type} ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
        name='value'
        id={data.id}
        value={textToShow || ""}
        autoSize
        useHighlightWithinTextarea
      >
        <Heading
          type={data?.text?.level}
          id={data.id}
          className={`element break-words ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
          style={style}
        >
          {textWithReusableValues || textToShow}
          {elementDesign.translateHelpText(data)}
        </Heading>
      </InlineEditing>
    </InlineActionbarElementWrapper>
  )
}