import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useToastAction } from "../../../hooks/useToastAction";
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon, SearchIcon } from "@heroicons/react/outline";
import { Select } from "../../../components/Form/Select";
import { rollbackDialogValues, deleteDialogValues, getDialogValuesWithStepsByDefinitionId, resendDialogValues, transferDialogValues } from "../../../api/dialogValues";
import { searchArray } from "../../../utils/array";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import MenuContextList from "../../../components/MenuContextList";
import * as SolidIcons from "@heroicons/react/solid";
import * as OutlineIcons from "@heroicons/react/outline";
import { getActiveDialogDefinitions } from "../../../api/dialogDefinition";
import * as Constants from "../../../utils/constants";
import { SkeletonLoader } from "../../../components/Table";
import { Table, useModalHelper, useTableActions } from "@metaforcelabs/metaforce-core";
import { TrashIcon } from '@heroicons/react/outline'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useClickOutside from "../../../hooks/useClickOutside";
import moment from "moment";
import { UpdateParticipatingParties } from "./updateParticipatingParties";
import { useQueryFilter } from "../../../hooks/useQueryFilter";
import Enumerable from "linq";
import { DialogValuesStatusBadge } from "../../../components/DialogValuesStatusBadge";
import { replaceLastPathPart } from "../../../utils/links";
import { getAllUsers } from "../../../api/accessGroups";
import { OnTransferRecordModal } from "./onTransferRecordModal";
import { OidcRoutesContext } from "../../../contexts";
import { DayPicker } from "react-day-picker";

const dummyLoadData = [{}, {}, {}, {}, {}];

export default function ProcessDetails() {
  const { dialogKey } = useParams();
  const checkbox = useRef()
  const dateRangeRef = useRef();
  const navigate = useNavigate()
  const [showDateRangeCalendar, setShowDateRangeCalendar] = useState({ startDate: false, endDate: false });
  const close = useCallback(() => setShowDateRangeCalendar(false), []);
  useClickOutside(dateRangeRef, () => close());

  const selectionDateRangeIntialValue = { startDate: "", endDate: "", key: "selection" };
  const showDateRangeCalendarInitialValue = { startDate: false, endDate: false };

  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 150;
  const toYear = 2100;

  const [processModel, setProcessModel] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [records, setRecords] = useState(dummyLoadData);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);
  const [stepsOptions, setStepsOptions] = useState([]);
  // const [stepFilterSelected, setStepFilterSelected] = useState();
  const stepFilterQueryFilter = useQueryFilter("step", '')
  const [issuedByOptions, setIssuedByOptions] = useState([]);
  const [issuedByFilterSelected, setIssuedByFilterSelected] = useState();
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [allUsers, setAllUsers] = useState(null);
  const { userProfile } = useContext(OidcRoutesContext);

  const statusQueryFilter = useQueryFilter("status", "all")
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectionDateRange, setSelectionDateRange] = useState(selectionDateRangeIntialValue);
  const [dialogValuesToUpdateParticipatingParties, setDialogValuesToUpdateParticipatingParties] = useState()
  const dateRangeSelected = selectionDateRange.startDate !== "" || selectionDateRange.endDate !== "";

  const tableActions = useTableActions(recordsToShow, 8);

  const loadDialogValuesDetailsAction = useToastAction();
  const loadDialogDefinitionsAction = useToastAction();

  const deleteDialogValueAction = useToastAction();
  const rollbackDialogValueAction = useToastAction();
  const resendAction = useToastAction();
  const transferDefinitionAction = useToastAction();
  const transferModalHelper = useModalHelper();

  const [dialogDefinitionOptions, setDialogDefinitionOptions] = useState([]);

  const statusFilterOptions = [{ name: "All", value: 'all' }, { name: "Complete", value: 'complete' }, { name: "In Progress", value: 'inProgress' }];

  useEffect(() => {
    loadDialogValuesDetails();
    loadDialogDefinitions();
    loadAllUsers();
  }, [dialogKey]);

  useEffect(() => {
    setSelectedItems(selectAllChecked ? records : [])
  }, [selectAllChecked])

  const deselectAll = () => {
    setSelectAllChecked(false)
    setSelectedItems([])
  }

  useEffect(() => {
    performFilter();
  }, [issuedByFilterSelected, stepFilterQueryFilter?.value, statusQueryFilter.value, selectionDateRange, records]);

  const performFilter = () => {
    let recordsFiltered = records;
    recordsFiltered = [...searchArray(recordsFiltered, issuedByFilterSelected?.toLowerCase(), [a => a.dialogValue?.sentByName?.toLowerCase()])];

    const stepFilter = stepFilterQueryFilter?.value?.toLowerCase()
    if (stepFilter) {
      recordsFiltered = recordsFiltered.filter(r => getStepName(r).toLowerCase() === stepFilter)
    }

    if (statusQueryFilter.value === 'complete') {
      recordsFiltered = recordsFiltered.filter(record => record.dialogValue?.isComplete === true)
    }
    else if (statusQueryFilter.value === 'inProgress') {
      recordsFiltered = recordsFiltered.filter(record => record.dialogValue?.isComplete === false)
    }

    if (dateRangeSelected) {
      recordsFiltered = recordsFiltered.filter((record) => {
        const date = moment(record.dialogValue?.createdDate);
        const startDate = moment(selectionDateRange.startDate);
        const endDate = moment(selectionDateRange.endDate);

        const recordDate = moment(date);
        return recordDate.isBetween(startDate, endDate);
      }
      )
    }

    setRecordsToShow(recordsFiltered);
  }

  const loadDialogDefinitions = async () => {
    loadDialogDefinitionsAction.execute(async () => {
      const records = await getActiveDialogDefinitions()

      setDialogDefinitionOptions(records.map(record => ({
        value: record.id,
        name: record.name
      })))

    }, "Failed to load")
  }

  const loadDialogValuesDetails = async () => {
    loadDialogValuesDetailsAction.execute(async () => {
      const model = await getDialogValuesWithStepsByDefinitionId(dialogKey)

      createIssuedByOptions(model.items);
      createStepOptions(model.items);

      setProcessModel(model)
      setRecords(model.items);
      setRecordsToShow(model.items);
      deselectAll()
    }, "Failed to load")
  }

  const createIssuedByOptions = (items) => {
    let distinctSentBy = [...new Set(items.map(record => record.dialogValue?.sentByName))];

    setIssuedByOptions(distinctSentBy.map(item => ({
      value: item,
      name: item
    })
    ));
  }

  /**
   * Build all step options from all possible steps.
   * Increase step order by 1 to match the dashboard page. 
   * Insert a Start step at order 0.
   * Rename the last step to Complete to match what is being rendered in the table data.
   */
  const createStepOptions = (items) => {
    const stepOptions = [{ order: 0, name: "Start" }]
    for (const item of items) {
      if (!item?.dialogValuesSteps) {
        stepOptions.push({ order: 'empty', name: 'Empty' })
      }
      for (const step of item.dialogValuesSteps || []) {
        if (step.id === item.lastStep.id) {
          stepOptions.push({ order: step.order + 1, name: "Complete" })
        }
        else {
          stepOptions.push({ order: step.order + 1, name: `Step ${step.order + 1}` })
        }
      }
    }

    const uniqueByName = Enumerable.from(stepOptions)
      .groupBy(s => s.name)
      .select(g => g.orderBy(i => i.order).select(i => ({ order: i.order, name: i.name })).first())
      .orderBy(s => s.order)
      .toArray();

    if (stepFilterQueryFilter.value && parseInt(stepFilterQueryFilter.value) >= 0) {
      const match = uniqueByName.find(u => u.order === parseInt(stepFilterQueryFilter.value))
      if (match) {
        stepFilterQueryFilter.set(match.name)
      }
    }

    setStepsOptions(uniqueByName.map(item => ({ value: item.name, name: item.name })))
  }

  const handleSearch = (e) => {
    const searchBy = e.target.value;

    let recordsFiltered = records;

    recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.currentParty?.toLowerCase()])];
    // recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.dialogValue?.sentByName?.toLowerCase()])];
    // recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.currentParty?.toLowerCase()])];

    setRecordsToShow(recordsFiltered);
  };

  const rollback = (dialogValuesId) => {
    rollbackDialogValueAction.execute(async () => {
      try {
        await rollbackDialogValues(dialogValuesId)
      }
      finally {
        loadDialogValuesDetails();
      }
    }, "Failed to rollback", "Rolled back")
  }

  const rollbackSelected = () => {
    rollbackDialogValueAction.execute(async () => {
      try {
        for (let i = 0; i < selectedItems.length; i++) {
          await rollbackDialogValues(selectedItems[i].dialogValue.id)
        }
      }
      finally {
        loadDialogValuesDetails();
      }
    }, "Failed to rollback", "Rolled back")
  }

  const resendSelected = () => {
    resendAction.execute(async () => {
      try {
        for (let i = 0; i < selectedItems.length; i++) {
          await resendDialogValues(selectedItems[i].dialogValue.id)
        }
      }
      finally {
        deselectAll();
      }
    }, "Failed to resend", "Resent reminder")
  }

  const resend = (dialogValuesId) => {
    resendAction.execute(async () => {
      try {
        await resendDialogValues(dialogValuesId)
      }
      finally {
        deselectAll();
      }
    }, "Failed to resend", "Resent reminder")
  }

  const deleteSelected = async () => {
    deleteDialogValueAction.execute(async () => {
      try {
        for (let i = 0; i < selectedItems.length; i++) {
          await deleteDialogValues(selectedItems[i].dialogValue.id)
        }
      }
      finally {
        loadDialogValuesDetails();
      }
    }, "Failed to delete", "Deleted")
  }

  const handleDelete = async (dialogValuesId) => {
    deleteDialogValueAction.execute(async () => {
      try {
        await deleteDialogValues(dialogValuesId)
      }
      finally {
        loadDialogValuesDetails();
      }
    }, "Failed to delete", "Deleted")
  }

  const handleFormChange = (value) => {
    navigate(replaceLastPathPart(value))
  }

  const handleUpdateParticipatingRolesClicked = (dialogValuesId) => {
    const matchingRecord = records.find(r => r.id === dialogValuesId)
    setDialogValuesToUpdateParticipatingParties(matchingRecord)
  }

  const onParticipatingPartiesUpdated = async () => {
    setDialogValuesToUpdateParticipatingParties(null)
    await loadDialogValuesDetails()
  }

  const dateFormater = (date) => {
    if (date === "") return "";

    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  /**
   * Gets the dialogValuesProcessDetails previously completed step name to match the dashboard page.
   * If no steps have been completed, then "Start" is returned. 
   * If the entire process is completed, then "Complete" is returned. 
   */
  const getStepName = (dialogValuesProcessDetails) => {
    if (dialogValuesProcessDetails.dialogValue && dialogValuesProcessDetails.dialogValue.process !== Constants.processTypes.multiStep) {
      return dialogValuesProcessDetails.dialogValue.isComplete
        ? "1"
        : "Start"
    }

    if (dialogValuesProcessDetails?.dialogValue?.isComplete) {
      return "Complete"
    }
    if (dialogValuesProcessDetails.currentStep && !dialogValuesProcessDetails.previouslyCompletedStep) {
      return "Start"
    }

    return dialogValuesProcessDetails?.previouslyCompletedStep ? `Step ${dialogValuesProcessDetails?.previouslyCompletedStep.order + 1}` : 'Empty'
  }

  const loadAllUsers = async () => {
    const allUsersOptions = await getAllUsers();
    setAllUsers(allUsersOptions);
  }

  const openTransferModal = (dialogDefinition) => {
    setSelectedRecord(dialogDefinition);
    transferModalHelper.open();
  }

  const onTransferRecord = async (dialogValuesId, selectedUser) => {
    transferDefinitionAction.execute(async () => {
      try {
        await transferDialogValues(dialogValuesId, selectedUser);
      }
      finally {
        loadDialogValuesDetails();
      }
    }, 'Failed to transfer', 'Transfered')
  }

  const onSelectDate = (key, date) => {
    setSelectionDateRange({ ...selectionDateRange, [key]: date })
    setShowDateRangeCalendar({ startDate: false, endDate: false })
  }

  const onShowCalendarByKey = (key) => {
    setShowDateRangeCalendar({ ...showDateRangeCalendarInitialValue, [key]: !showDateRangeCalendar[key] })
  }

  return (
    <>
      <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Processes</h1>
      </div>
      <div className="flex justify-between flex-col mt-5 mb-5 pb-5 border-b border-gray-200">
        <div className="flex flex-row gap-6">
          <div className="w-72">
            <Select
              label="Form"
              options={dialogDefinitionOptions}
              onChange={handleFormChange}
              selectedValue={dialogKey}
            />
          </div>
          <div className="w-72">
            <Select
              label="Status"
              options={statusFilterOptions}
              onChange={statusQueryFilter.set}
              selectedValue={statusQueryFilter.value}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-3">
          <div className="w-72">
            <Select
              label="Issued by"
              name="process"
              options={issuedByOptions}
              onChange={setIssuedByFilterSelected}
              value={issuedByFilterSelected}
              defaultOptionText="All"
            />
          </div>
          <div className={`flex gap-2 ${!dateRangeSelected ? "w-76" : "w-80"}`} ref={dateRangeRef}>
            <div className="relative z-50">
              <label className={'block text-sm font-medium text-gray-700 pb-1'}>
                From Date
              </label>
              <input
                type="text"
                className="hidden flex-1 focus:ring-gray-400 focus:border-gray-400 w-full rounded-md sm:block sm:text-sm border-gray-300"
                placeholder="Start date"
                value={selectionDateRange.startDate ? dateFormater(selectionDateRange.startDate) : ""}
                onChange={(e) => e.preventDefault()}
                onClick={(e) => onShowCalendarByKey("startDate")}
              />

              {showDateRangeCalendar.startDate &&
                <div className="absolute bg-brand-background rounded-sm shadow-sm">
                  <DayPicker
                    mode="single"
                    fromYear={fromYear}
                    toYear={toYear}
                    defaultMonth={new Date()}
                    onSelect={(e) => onSelectDate("startDate", e)}
                    captionLayout="dropdown"
                  />
                </div>
              }

            </div>
            <div className="flex flex-col justify-center">
              <div className="relative z-50">
                <label className="block text-sm font-medium text-gray-700 pb-1">
                  To date
                </label>
                <input
                  type="text"
                  className="hidden flex-1 focus:ring-gray-400 focus:border-gray-400 w-full rounded-md sm:block sm:text-sm border-gray-300"
                  placeholder="End date"
                  onChange={(e) => e.preventDefault()}
                  value={selectionDateRange.endDate ? dateFormater(selectionDateRange.endDate) : ""}
                  onClick={(e) => onShowCalendarByKey("endDate")}
                />
                {showDateRangeCalendar.endDate &&
                  <div className="absolute bg-brand-background rounded-sm shadow-sm">
                    <DayPicker
                      mode="single"
                      fromYear={fromYear}
                      toYear={toYear}
                      defaultMonth={new Date()}
                      onSelect={(e) => onSelectDate("endDate", e)}
                      captionLayout="dropdown"
                    />
                  </div>}
              </div>
            </div>
            {dateRangeSelected &&
              <div className="flex justify-center mt-4 cursor-pointer">
                <TrashIcon className="w-6" onClick={() => setSelectionDateRange(selectionDateRangeIntialValue)} />
              </div>
            }
          </div>
          {
            processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
            <div className="w-72">
              <Select
                label="Steps"
                name="process"
                options={stepsOptions}
                onChange={(value) => stepFilterQueryFilter.set(value)}
                selectedValue={stepFilterQueryFilter?.value}
                defaultOptionText="All"
              />
            </div>
          }
          <div className="w-72 mt-6">
            <label
              htmlFor="desktop-search-candidate"
              className="sr-only"
            >
              Filter by text search...
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="desktop-search-candidate"
                  id="desktop-search-candidate"
                  className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Search..."
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-4 flex justify-between">
        <div className="relative flex items-start pl-6">
          <div className="flex items-center h-full">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              ref={checkbox}
              checked={selectAllChecked}
              onChange={() => setSelectAllChecked(!selectAllChecked)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="flex items-center h-full ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              Select all submissions
            </label>
          </div>
        </div>

        <div className="pb-3 flex justify-end gap-3">
          <button
            type="button"
            disabled={selectedItems?.length <= 0 || rollbackDialogValueAction.isExecuting}
            onClick={() => rollbackSelected()}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.RefreshIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Rollback selected
          </button>
          <button
            type="button"
            disabled={selectedItems?.length <= 0 || resendAction.isExecuting}
            onClick={() => resendSelected()}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Send reminder to selected
          </button>
          <button
            type="button"
            onClick={() => deleteSelected()}
            disabled={selectedItems?.length <= 0 || deleteDialogValueAction.isExecuting}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Delete selected
          </button>
        </div>
      </div>

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">

          <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">

                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() =>
                      tableActions.orderBy("dialogDefinitionName")
                    }
                  >
                    Belongs to form
                    {tableActions.getSortIcon("dialogDefinitionName")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("currentParty")
                    }
                  >
                    Current party
                    {tableActions.getSortIcon("currentParty")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("dialogValue.sentByName")
                    }
                  >
                    Issued by
                    {tableActions.getSortIcon("dialogValue.sentByName")}
                  </th>
                  {
                    processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                      onClick={() =>
                        tableActions.orderBy("dialogValue.isComplete")
                      }
                    >
                      Step
                      {tableActions.getSortIcon("dialogValue.isComplete")}
                    </th>
                  }

                  {
                    processModel.dialogDefinitionProcessType !== Constants.processTypes.multiStep &&
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    >
                      Status
                    </th>
                  }
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("createdDate")
                    }
                  >
                    Created
                    {tableActions.getSortIcon("createdDate")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("updatedDate")
                    }
                  >
                    Updated
                    {tableActions.getSortIcon("updatedDate")}
                  </th>
                  <th
                    scope="col"
                    className="relative px-6 py-3 w-20"
                  >
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  tableActions.pageData?.map(record => {
                    return (
                      <tr key={record.Id}>
                        <td
                          className="px-6 py-8 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                        >
                          <SkeletonLoader element={record}>
                            {selectedItems.includes(record) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                              value={record.id}
                              checked={selectedItems.includes(record)}
                              onChange={(e) =>
                                setSelectedItems(
                                  e.target.checked
                                    ? [...selectedItems, record]
                                    : selectedItems.filter((p) => p !== record)
                                )
                              }
                            />
                          </SkeletonLoader>
                        </td>
                        <td
                          className="px-6 py-8 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                        >
                          <SkeletonLoader element={record}>
                            {processModel.dialogDefinitionName}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {record.currentParty || '-'}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {record.dialogValue?.sentByName || 'N/A'}
                          </SkeletonLoader>
                        </td>
                        {
                          processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
                          <td className="py-8 whitespace-nowrap text-sm text-center relative">
                            <SkeletonLoader element={record}>
                              <>
                                <DialogValuesStatusBadge record={record?.dialogValue} stepName={getStepName(record)} />
                              </>
                            </SkeletonLoader>
                          </td>
                        }

                        {
                          processModel.dialogDefinitionProcessType !== Constants.processTypes.multiStep &&
                          <td className="py-8 whitespace-nowrap text-sm text-center relative">
                            <SkeletonLoader element={record}>
                              <>
                                {
                                  <DialogValuesStatusBadge record={record?.dialogValue} />
                                }
                              </>
                            </SkeletonLoader>
                          </td>
                        }
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {stringToLocaleDateTimeString(record.createdDate)}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {stringToLocaleDateTimeString(record.updatedDate)}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-right text-sm font-medium absolute">
                          <SkeletonLoader element={record}>
                            {
                              <MenuContextList
                                deleteType={"Dialog Values"}
                                element={record}
                                actions={[
                                  {
                                    name: "View",
                                    lineAfter: true,
                                    icon: ArrowRightIcon,
                                    href: `/admin/dialog/${processModel?.dialogDefinitionId}/form/${record?.id}`,
                                  },
                                  {
                                    name: "Rollback",
                                    icon: SolidIcons.RefreshIcon,
                                    onClick: () => rollback(record.id)
                                  },
                                  ...(processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep ?
                                    [{
                                      name: "Participating parties",
                                      icon: SolidIcons.UserCircleIcon,
                                      onClick: () => handleUpdateParticipatingRolesClicked(record.id)
                                    }] : []),
                                  {
                                    name: "Send reminder",
                                    icon: SolidIcons.MailIcon,
                                    onClick: () => resend(record.id),
                                    disabled: record?.dialogValue?.isComplete || record?.dialogValue?.isSigned
                                  },
                                  {
                                    name: "Transfer to another user",
                                    onClick: () => openTransferModal(record.id),
                                    icon: SolidIcons.UserGroupIcon,
                                    disabled: record?.dialogValue?.createdBy !== userProfile.userId
                                  },
                                  {
                                    name: "Delete",
                                    icon: SolidIcons.TrashIcon,
                                    onClick: () => handleDelete(record.id)
                                  },
                                ]}
                              />
                            }
                          </SkeletonLoader>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <Table.Pagination tableActions={tableActions} />
          </div>
        </div>
      </div>
      {
        dialogValuesToUpdateParticipatingParties &&
        <UpdateParticipatingParties
          dialogValuesProcessDetails={dialogValuesToUpdateParticipatingParties}
          onClose={() => setDialogValuesToUpdateParticipatingParties(null)}
          onSaved={onParticipatingPartiesUpdated}
        />
      }
      <OnTransferRecordModal
        onTransferRecord={onTransferRecord}
        modalHelper={transferModalHelper}
        selectedRecord={selectedRecord}
        allUsers={allUsers}
      />
    </>
  );
}