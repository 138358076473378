import Modal from '../../../components/Modal';
import { useToastAction } from '../../../hooks/useToastAction';
import { createDialogObject } from '../../../api/dialogObjects';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { objectToJsonFile } from '../../../utils/file';

export const ExportContainer = ({
  isOpen,
  setIsOpen,
  containerData,
  exportToFile = false
}) => {
  const createDialogObjectAction = useToastAction()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size="small"
    >
      <div>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={() => {
            return yup.object({
              name: yup.string().required('Required'),
            })
          }}
          onSubmit={async (values, actions) => {
            const { name } = values;
            if (exportToFile) {
              const object = {
                name,
                container: containerData,
              }
              objectToJsonFile(document, object, name)
              actions.setSubmitting(false);
              setIsOpen(false)
            }
            else {
              createDialogObjectAction.execute(async () => {              
                await createDialogObject(containerData, name);
                actions.setSubmitting(false);
                setIsOpen(false)
              }, "Failed to export", "Created object")
            }
          }}
        >
          {
            props => (
              <Form>
                <div>
                  <FormikTextInput
                    label="Name"
                    name="name"
                    required={true}
                    formikProps={props}
                  />
                </div>
                <div className="mt-4">
                  <FormikSubmitButton
                    formikProps={props}
                    text="Save"
                  />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Modal>
  )
}