import React, { useState, useContext } from 'react'
import { ColumnContext, ContainerLockedContext, DialogModes, ElementContext, FormContext, RowContext } from "../contexts";
import PropertiesInputTextLine from './InputTextLine/properties';
import PropertiesHeaderLine from './HeaderLine/properties';
import PropertiesTextLine from './TextLine/properties';
import PropertiesOrderTable from './OrderTable/properties';
import PropertiesTextArea from './TextArea/properties';
import PropertiesBitmap from './Bitmap/properties';
import PropertiesResource from './Resource/properties';
import PropertiesDatePicker from './DatePicker/properties';
import PropertiesButton from './Button/properties';
import PropertiesCancelButton from './CancelButton/properties';
import PropertiesCheckbox from './Checkbox/properties';
import PropertiesSwitch from './Switch/properties';
import PropertiesDivider from './Divider/properties';
import PropertiesSmartTable from './SmartTable/properties';
import PropertiesRadioList from './RadioList/properties';
import PropertiesFileUpload from './FileUpload/properties';
import PropertiesDisclaimer from './Disclaimer/properties';
import PropertiesDropdown from './Dropdown/properties';
import PropertiesFlowSelector from './FlowSelector/properties'
import PropertiesSignature from './Signature/properties'
import PropertiesAttachment from './Attachment/properties'
import PropertiesTimePicker from './TimePicker/properties'
import PropertiesVideo from "./Video/properties"
import PropertiesHyperLink from "./HyperLink/properties"
import PropertiesRichText from "./RichText/properties"
import PropertiesRadioButtonGroup from './RadioButtonGroup/properties';
import PropertiesSpacer from './Spacer/properties';
import PropertiesDynamicList from './DynamicList/properties';
import PropertiesMatrix from './Matrix/properties';
import PropertiesColumn from '../structures/Column/properties'
import PropertiesRow from '../structures/Row/properties'
import PropertiesMap from './MapElement/properties'
import { designContexts, elementTypes } from '../utils/constants';
import { Tooltip } from './Tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Locked } from '../structures/Element/locked';
import { dependenciesType } from '../utils/constants';
import { isValidInputTextLine } from '../utils/validators';
import { useErrorDetails } from '../hooks/useErrorDetails';
import { getDialogValueKey } from '../utils/features';
import { useSelector } from 'react-redux';
import { useDefinitionManager } from "../screens/Admin/DialogDesign/useDefinitionManager";

export const useElementDesign = (elementId, data) => {
  const elementContext = useContext(ElementContext);
  const formContext = useContext(FormContext);
  const containerLockedContext = useContext(ContainerLockedContext);
  const { isPdfMode } = React.useContext(DialogModes);
  const [touched, setTouched] = useState(false)
  const [isValid, setIsValid] = useState(null);
  const isContextActive = elementContext && elementContext.enabled
  const isLocked = containerLockedContext?.isLocked
  const { errorsWithDetail } = formContext || {};
  const { generateError } = useErrorDetails(data);
  const definitionManager = useDefinitionManager();
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const columnContext = useContext(ColumnContext);
  const rowContext = useContext(RowContext);

  let showRowOnDoubleClick = true;

  const getPropertiesOnElement = () => {
    let propertiesOnElement;
    if (data) {
      switch (data.type) {
        case elementTypes.textLine:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesTextLine initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.textArea:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesTextArea initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.inputTextLine:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesInputTextLine initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.headerLine:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesHeaderLine initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.bitmatp:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesBitmap initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.resource:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesResource initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.datePicker:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesDatePicker initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.button:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesButton initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.switch:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesSwitch initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.divider:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesDivider initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.smartTable:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesSmartTable initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.radioList:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesRadioList initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.orderTable:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesOrderTable initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.fileUpload:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesFileUpload initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.disclaimer:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesDisclaimer initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.dropDown:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesDropdown initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.flowSelector:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesFlowSelector initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.checkbox:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesCheckbox initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.cancelButton:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesCancelButton initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.signature:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesSignature initData={element} process={elementContext.process} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.attachment:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesAttachment initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.timePicker:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesTimePicker initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.video:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesVideo initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.hyperLink:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesHyperLink initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.richText:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesRichText initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.radioButtonGroup:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesRadioButtonGroup initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.spacer:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesSpacer initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.dynamicList:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesDynamicList initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.matrix:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesMatrix initData={element} actions={elementContext.actions} />}
          />
          break;
        case elementTypes.map:
          propertiesOnElement = <PropertiesWrapper
            elementId={elementId}
            renderElementProperties={(element) => <PropertiesMap initData={element} actions={elementContext.actions} />}
          />
          break;
        default:
          propertiesOnElement = <></>
          break;
      }
    }
    return propertiesOnElement
  }

  const selectElement = (evt, openPanel = true) => {
    if (elementContext) {
      try {
        // prevent default event behavior if there is any
        evt.preventDefault()
      }
      catch (err) {
        // do nothing
      }

      elementContext.selectedLayoutType !== designContexts.element && elementContext.handleSelectLayoutType(evt, designContexts.element)
      elementContext.setBadgeSelectedType(designContexts.element)

      if (isLocked) {
        elementContext.showPropertiesPanel(elementId, data, isLocked, <Locked />, openPanel);
      }
      else {
        elementContext.showPropertiesPanel(elementId, data, isLocked, getPropertiesOnElement(), openPanel);
      }
    }
  }

  const selectColumn = (evt, openPanel = false) => {
    const columnOfElement = definitionManager.findColumnFromElement(dialogDefinition, elementId);
    const isSelectedColumn = columnOfElement.id === columnContext.currentColumnId

    if (columnOfElement) {
      elementContext.selectedLayoutType !== designContexts.column && elementContext.handleSelectLayoutType(evt, designContexts.column)
      elementContext.setBadgeSelectedType(designContexts.column)

      if (isLocked) {
        columnContext.showPropertiesPanel(columnOfElement.id, columnOfElement, isLocked, (<Locked />), openPanel);
      }
      else {
        columnContext.showPropertiesPanel(columnOfElement.id, columnOfElement, isLocked, (<PropertiesColumn initData={columnOfElement} actions={columnContext.actions} />), openPanel);
      }
    }

    elementContext.setShowRowOnDoubleClick(isSelectedColumn ? !elementContext.showRowOnDoubleClick : false);

  }

  const selectRow = (evt, openPanel = false) => {
    const rowOfElement = definitionManager.findRowFromElementId(dialogDefinition, elementId);
    const isSelectedRow = rowOfElement.id === rowContext.currentRowId

    if (rowOfElement) {
      elementContext.selectedLayoutType !== designContexts.row && elementContext.handleSelectLayoutType(evt, designContexts.row)
      elementContext.setBadgeSelectedType(designContexts.row)
      if (isLocked) {
        rowContext.showPropertiesPanel(rowOfElement.id, rowOfElement, isLocked, (<Locked />), openPanel);
      }
      else {
        rowContext.showPropertiesPanel(rowOfElement.id, rowOfElement, isLocked, (<RowWrapper rowId={rowOfElement.id}
          renderRowProperties={(row) => <PropertiesRow initData={row} actions={rowContext.actions} />}
        />), openPanel);
      }
    }

    elementContext.setShowRowOnDoubleClick(isSelectedRow ? !elementContext.showRowOnDoubleClick : false);
  }


  return {
    data,
    selectElement,
    selectColumn,
    selectRow,
    isActive: () => {
      return isContextActive;
    },
    onClick: (evt, openPanel = true) => {
      selectElement(evt, openPanel)
    },
    onDoubleClick: (evt) => {
      if (elementContext.showRowOnDoubleClick) {
        selectRow(evt);
      }
      else {
        selectColumn(evt);
      }

    },
    onTripleClick: (evt) => {

    },
    elementsClasses: () => {
      let classes = '';

      const isNew = elementContext?.newElementId === elementId;

      if (isContextActive || isNew) {
        const isSelected = elementContext.currentElementId === elementId;

        classes = 'design-element pointer';
        classes += isSelected ? ' active ' : ''
        classes += isNew ? ' new' : ''
        classes += ` open-element-actionbar-${elementId}`
      }

      return classes;
    },
    isSelected: () => {
      return elementContext?.currentElementId === elementId
    },
    isActionBarSelectedElement: () => {
      return elementContext?.actionBarSelectedElementId === elementId
    },
    removeActionBarSelectedElement: () => {
      return elementContext?.removeSelectedActionBarElement(elementId)
    },
    hasContext: () => {
      return isContextActive;
    },
    requiredFieldIndicator: (isRequired) => {
      if (!isRequired || isPdfMode) {
        return null
      }

      if (formContext?.processStep && formContext?.processStep !== data.processStep) {
        return null;
      }
      const errorsLength = errorsWithDetail?.length;

      return <span className={`${errorsLength ? "text-red-500" : ""}`}>*</span>
    },
    errorMessage: () => {
      const translatedCustomErrorMessage = {
        byRequired: formContext?.translateCustomErrorMessage(data, true),
        byValidation: formContext?.translateCustomErrorMessage(data, false)
      }

      var error = generateError(errorsWithDetail, getDialogValueKey(data), translatedCustomErrorMessage);

      return error?.messageWithSpan;
    },
    validFieldIndicator: () => {
      if (typeof data?.validateData === "number" && !isValid && isValid !== null) {
        return <span className="text-red-500 pb-2"> Invalid data</span>
      }

      if (formContext?.processStep && formContext?.processStep !== data.processStep) {
        return null;
      }

      return null;
    },
    handleBlur: async (value, inputRef, countryCode) => {
      setTouched(true);
      const errorObj = {
        id: data.id,
        property: data.property,
        field: data.label,
        validateData: data.validateData,
        customErrorMessage: data.customErrorMessage
      }

      if (data.requiredField && !value) {
        formContext?.addOrRemoveErrorInput({ ...errorObj, required: true })
      }

      if (!inputRef || !value) {
        return;
      }

      const isValidInput = await isValidInputTextLine(data, value, countryCode);
      if (isValidInput) {
        formContext?.addOrRemoveErrorInput(errorObj, "remove")
        setIsValid(true);
      } else {
        // Commented out this setTimeout thing. I'm guessing it was here for a reason but on top of it being extremely 
        // weird (I've never seen anythign like it before), it also causes a critical bug when there are two text inputs 
        // with invalid input format. If you try to leave one input that has invalid format, and enter another one with 
        // invalid format, this will cause an infinite cycle of rapidly forcing the cursor between the two inputs with 
        // no chance to ever resolve the problem. If this is truly needed for some strange reason, it needs to be thought out better.
        // setTimeout(() => inputRef.current.focus(), 0)

        // pass in required: false because for some reason the thing that renders error messages doesn't
        // take into account the value the input has. If required is true, it will blindly just say the input is required
        // even if it has a value
        formContext?.addOrRemoveErrorInput({ ...errorObj, required: false })
        setIsValid(false);
      }
    },
    inputErrorClasses: (isRequired, value) => {
      if (isRequired && touched && !value) {
        return 'border-2 border-red-500'
      }

      if (isRequired && touched && !value && formContext?.processStep && formContext?.processStep !== data.processStep) {
        return 'border-2 border-red-500'
      }

      if (isValid !== null && !isValid) {
        return 'border-2 border-red-500'
      }

      return ''
    },
    readOnlyElementClasses: () => {
      if (!formContext) {
        return ''
      }

      if (!data.readOnly && formContext?.isFormEnabled) {
        return ''
      }

      if (data.type === elementTypes.datePicker
        || data.type === elementTypes.signature
        || data.type === elementTypes.fileUpload
        || data.type === elementTypes.timePicker
        || data.type === elementTypes.button
        || data.type === elementTypes.cancelButton
        || data.type === elementTypes.video
      ) {
        return 'opacity-50 cursor-not-allowed'
      }
      return 'opacity-50'
    },
    isReadOnly: () => {
      return data.readOnly || (formContext && !formContext.isFormEnabled) || formContext?.isAborted
    },
    isReadOnlyDependency: (value, type) => {
      if (type === dependenciesType.none) return false;
      const checkboxDependency = value === 'none' || value === null ? null : document.getElementById(value);
      return checkboxDependency !== null ? !checkboxDependency.checked : false;
    },
    isLocked: () => {
      return isLocked;
    },
    translateTerm: (fallbackText, propertyName = 'term') => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateTerm(data, fallbackText, propertyName)
    },
    translateOption: (optionId, fallbackText, propertyName) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateOption(data, optionId, fallbackText, propertyName)
    },
    translateHelpText: () => {
      if (!formContext || isPdfMode) {
        return ''
      }
      const helpText = formContext.translateHelpText(data, data.helpText)
      return helpText
        ? <Tooltip text={helpText} icon={InformationCircleIcon} />
        : null;
    },
    translateRow: (rowIndex, columnIndex, fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateRow(data, rowIndex, columnIndex, fallbackText)
    },
    translateColumnsSmartTable: (columnId, fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateColumnsSmartTable(data, columnId, fallbackText);
    },
    translateOptionForSmartTable: (columnId, optionIndex, fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateOptionForSmartTable(data, columnId, optionIndex, fallbackText)
    },
    translateRichText: (fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateRichText(data, fallbackText)
    },
    translateMatrixOption: (optionId, fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext?.translateMatrixOption(data, optionId, fallbackText)
    },
    translateMatrixQuestion: (questionId, fallbackText) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext?.translateMatrixQuestion(data, questionId, fallbackText)
    },
    hideConnectedElement: (value, type) => {
      if (type === dependenciesType.none) return false;
      const element = value === 'none' || value === null ? null : document.getElementById(value);
      return element !== null ? !element.checked : false;
    },
  }
}

const PropertiesWrapper = ({ renderElementProperties, elementId }) => {
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const definitionManager = useDefinitionManager();
  const element = definitionManager.findElement(dialogDefinition, elementId)
  const elementProperties = renderElementProperties(element)
  return (
    <>
      {elementProperties}
    </>
  )
}

const RowWrapper = ({ renderRowProperties, rowId }) => {
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const definitionManager = useDefinitionManager();
  const row = definitionManager.findRow(dialogDefinition, rowId)
  const rowProperties = renderRowProperties(row)
  return (
    <>
      {rowProperties}
    </>
  )
}