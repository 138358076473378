import { useState } from 'react';
import { Button } from '../../components/Form/Button';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import * as Icon from "@heroicons/react/outline"
import * as Data from "../../data";
import { useSelector } from 'react-redux';
import _ from 'lodash';


export default function Properties({ initData, actions, hideActions = false }) {
  const [data, setSetData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <>
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Column"
        />
      </Property.Group>
      {
        hideActions &&
        <>
          <Property.Group
            title="Basic"
          >
            <Button
              disabled={!initData}
              text="Add text line"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.TextLineNew())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add HyperLink"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.HyperLinkNew())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add input text line"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.InputTextLine())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add input text area"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.InputTextArea())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add checkbox"
              theme="white"
              icon={Icon.CheckCircleIcon}
              onClick={() => actions.addElement(initData.id, Data.Checkbox())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add switch"
              theme="white"
              icon={Icon.CheckCircleIcon}
              onClick={() => actions.addElement(initData.id, Data.Switch())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add list"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.RadioList())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add bitmap"
              theme="white"
              icon={Icon.PhotographIcon}
              onClick={() => actions.addElement(initData.id, Data.Bitmap())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add bitmap"
              theme="white"
              icon={Icon.PhotographIcon}
              onClick={() => actions.addElement(initData.id, Data.Video())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add divider"
              theme="white"
              icon={Icon.MinusIcon}
              onClick={() => actions.addElement(initData.id, Data.Divider())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add file upload"
              theme="white"
              icon={Icon.PaperClipIcon}
              onClick={() => actions.addElement(initData.id, Data.FileUpload())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add dropdown"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.Dropdown())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add button"
              theme="white"
              icon={Icon.ViewListIcon}
              onClick={() => actions.addElement(initData.id, Data.Button())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add datepicker"
              theme="white"
              icon={Icon.CalendarIcon}
              onClick={() => actions.addElement(initData.id, Data.DatePicker())}
              useNewLine={true}
            />
            <Button
              disabled={!initData}
              text="Add timepicker"
              theme="white"
              icon={Icon.ClockIcon}
              onClick={() => actions.addElement(initData.id, Data.TimePicker())}
              useNewLine={true}
            />
          </Property.Group>
        </>
      }
      {
        !hideActions &&
        <>
          <Property.Group
            title='Style'>

            <Property.Color
              onChange={handleOnChange}
              label={'Background color'}
              name={'backgroundColor'}
              initColor={data.backgroundColor}
              enableTransparent
            />

            <Property.Color
              onChange={handleOnChange}
              label={'Border color'}
              name={'borderColor'}
              initColor={data.borderColor}
            />

            <Property.BorderStyleAndWidth
              onChange={handleOnChange}
              label={'Border'}
              name={'borderWidth'}
              value={data.borderWidth}
            />

            <Property.Checkbox
              onChange={handleOnChange}
              label={'Rounded corners'}
              name={'roundedCorners'}
              value={data.roundedCorners}
            />

            <Property.Checkbox
              onChange={handleOnChange}
              label={'Full height'}
              name={'fullHeight'}
              value={data.fullHeight}
            />

          </Property.Group>

          <Property.Group
            title='Placement'>

            <Property.MarginPadding
              onChange={handleOnChange}
              label={'Margin'}
              name={'margin'}
              value={data.margin}
              type={'margin'}
            />

            <Property.MarginPadding
              onChange={handleOnChange}
              label={'Padding'}
              name={'padding'}
              value={data.padding}
              type={'padding'}
            />

            <Property.Text
              onChange={(e) => handleOnChange(e, 'number')}
              label={'Span'}
              name={'span'}
              value={data.span}
            />

            <Property.Select
              onChange={(e) => handleOnChange(e, 'number')}
              label={'Gap'}
              name={'space'} // property name on backend is called "space"
              value={data.space}
              options={[
                { name: "None", value: 0 },
                { name: "Small", value: 2 },
                { name: "Medium", value: 3 },
                { name: "Large", value: 5 },
                { name: "Extra Large", value: 8 },
              ]}
            />

            <Property.Select
              onChange={handleOnChange}
              label={'Content Align'}
              name={'contentAlign'}
              value={data.contentAlign}
              options={[
                { name: "Left", value: 'left' },
                { name: "Right", value: 'right' },
                { name: "Center", value: 'center' },
              ]}
            />

            <Property.Select
              onChange={handleOnChange}
              label={'Vertical Align'}
              name={'verticalAlign'}
              value={data.verticalAlign}
              options={[
                { name: "Top", value: 'top' },
                { name: "Bottom", value: 'bottom' },
                { name: "Center", value: 'center' },
              ]}
            />

          </Property.Group>
        </>
      }
    </>
  )
}
