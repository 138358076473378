import { useContext } from "react";
import {  OidcRoutesContext } from "../contexts";

export const useLicense = () => {
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const hasLicense = (licenseId) => {
    return !!oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === licenseId);
  }
  
  const hasLicenseRerouteCheck = (licenseId) => {
    if(oidcRoutesContext.userProfile.customerUserAccess !== undefined) {
      if(!oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === licenseId)) {
        window.location.href = '/missing-license';
      }
    }
    return !!oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === licenseId);
  } 
  
  return {
    hasLicenseRerouteCheck: (licenseId) => {
      return !licenseId || hasLicenseRerouteCheck(licenseId);
    },
    hasLicense: (licenseId) => {
      return !licenseId || hasLicense(licenseId);
    }
  }
}