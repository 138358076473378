import { XIcon } from '@heroicons/react/solid';
import React from 'react'
import { Base64Upload } from '../../../../../components/Properties';

export default function Bitmap({ element, onChange }) {

    const handleBitmapChange = ({ target }) => {
        onChange(target, element, "")
    }
    return (
        <div>
            <div className='mb-1'><label className='font-semibold'>Element:</label> {element.property}</div>
            <div className='inline-block flex space-x-2 items-center'
            >
                {
                    element?.value && (
                        <div className="relative">
                            <img
                                className={"w-40 border border-gray-300 rounded-md p-2 relative"}
                                alt={`${""}`}
                                src={`${element?.value}`}
                            />
                            <button
                                type="button"
                                title="Clear logo"
                                className="absolute -top-2 -right-2 hover:text-red-500 w-auto inline-flex justify-center rounded-full border border-gray-300 shadow-sm p-1 bg-white text-base font-medium text-gray-700 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                                onClick={() => {
                                    handleBitmapChange({ target: { name: "value", value: "" } })
                                }}
                            >
                                <XIcon className='h-3 w-3' />
                            </button>
                        </div>
                    )
                }
                <div>
                <Base64Upload onChange={e => handleBitmapChange(e)} name={"value"} value={element?.value} />
                </div>
            </div>
        </div>
    )
}
