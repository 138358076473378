import React from "react";
import Modal from "../../../../components/Modal";

export const ConvertToStandardDesignAlerModal = ({ open, setOpen, onConfirm }) => {

    const closeModal = () => {
        setOpen(false);
    }

    return (
        <Modal title={"Are you sure?"} isOpen={open} onClose={closeModal}>
            <div className="space-y-4">
                <div>
                    This is a permanent action and cannot be reverted.
                </div>
                <div className="grid grid-cols-2 space-x-2">
                    <button
                        type="button"
                        className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        id={"next-btn"}
                        onClick={e => {
                            onConfirm();
                        }}
                        className={`w-1/2 sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-sm`}
                    >
                        <span>Confirm</span>
                    </button>
                </div>
            </div>
        </Modal>
    )
}