import { DialogModes, ElementContext } from "../../contexts";
import { setProperty } from "../../utils/property";
import { useElementDesign } from "../../elements/useElementDesign";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { OpenElementProperties } from "../../utils/elements";
import { useAutoSize } from "../../hooks/useAutoSize";
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'

export default function HighlightInput({
    initData,
    style,
    classes = "",
    name,
    id = null,
    value = null,
    autoSize = false,
    onChange
}) {
    const { isEditMode } = useContext(DialogModes);
    const elementContext = useContext(ElementContext) || {};
    const [clonedData, setClonedData] = useState(_.cloneDeep(initData));
    const [inputValue, setInputValue] = useState(value ?? initData[name] ?? '');
    const editorWrapper = useRef();
    const inputRef = useRef();

    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
    const elementDesign = useElementDesign(initData.id, initData);
    useAutoSize(inputRef.current, inputValue, autoSize, false, style)

    useEffect(() => {
        setClonedData(_.cloneDeep(initData));
        setInputValue(initData[name])

        if(editorWrapper.current) {
            const wrapper = editorWrapper.current;
            let editor = wrapper.getElementsByClassName("DraftEditor-root")[0];

            editor = editor || wrapper.getElementsByClassName(`inline-editing-${clonedData.id}`)[0];

            if(editor) {
                // string to array of strings separated by space
                let classesToAdd =`${classes} bg-transparent resize-none border-0 px-0 py-0 w-full inline-editing-${clonedData.id}`.split(' ');
                classesToAdd = classesToAdd.filter((item) => item !== '');

                editor.classList.remove(...editor.classList);
                editor.classList.add(...classesToAdd)

                for(var styleCamelCase in style) {
                    const styleSnakeCase = styleCamelCase.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
                    editor.style[styleSnakeCase] = style[styleCamelCase];
                }
            }
        }
    }, [initData, name]);


    const onChangeEditMode =  useCallback((value) => {
        const type = "text"
        const verifiedValue = value
        const customEventObject = {
            target: {
                name,
                value: verifiedValue
            }
        };

        elementContext?.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)
        setProperty(clonedData, name, verifiedValue, type);
        setClonedData((data) => ({
            ...data,
        }));
        setInputValue(verifiedValue)
    }, [clonedData, dialogDefinition, elementContext?.actions]);

    return (
        <div ref={editorWrapper} className={`w-full`}>
            {isEditMode ?
            <div onClick={(e) => OpenElementProperties(e, elementDesign.onClick)}>
                <HighlightWithinTextarea
                    id={id ?? `inline-editing-${clonedData.id}`}
                    rows={1}
                    ref={editorWrapper}
                    style={style}
                    name={name}
                    value={inputValue}
                    onChange={(value) => onChangeEditMode(value)}
                    highlight={[
                        { highlight: /\{{ (.*?)\ }}/g, className: 'bg-yellow-200' },
                        { highlight: /\[\[ (.*?)\ ]\]/g, className: 'bg-blue-200' },
                        { highlight: /\<< (.*?)\ >>/g, className: 'bg-green-200' },
                    ]}
                />
            </div>
            :
            <>
            <textarea
                id={id}
                ref={inputRef}
                style={style} 
                className={`pt-2 text-sm w-full px-0 border-0 resize-none`}
                name={name}
                value={value}
                onChange={onChange}
            />
          </>
            }
        </div>
    );
}