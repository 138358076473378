import { destroy, get, post, put } from './api'

const controllerName = "dialogDefinitions"

export const getDialogSecurity = async (dialogId) => {
    return await get(`${controllerName}/${dialogId}/security`);
}

export const updateDialogSecurity = async (dialogId, accessGroups) => {
    return await put(`${controllerName}/${dialogId}/security`, accessGroups);
}