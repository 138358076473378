import { useEffect, useState } from "react"
import { getAllDialogObjects, deleteDialogObject, activateDialogObject, deactivateDialogObject } from "../../../api/dialogObjects"
import { Table, TableActions, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../components/Table"
import { useToastAction } from "../../../hooks/useToastAction"
import { stringToLocaleDateTimeString } from "../../../utils/date"
import { objectToJsonFile } from "../../../utils/file"
import { useTableSort } from "../../../hooks/useTableSort"
import { replaceItem } from "../../../utils/array"
import { isDialogObjectActive } from "../../../utils/dialogObjects"
import { Badge } from "../../../components/Badge"
import { ImportObject } from "./importObject"
import { RenameObject } from "./renameObject"
import MenuContextList from '../../../components/MenuContextList/index';
import { NavLink } from "react-router-dom"
import { NewObject } from "./newObject"

export const Objects = () => {
  const [dialogOjbects, setDialogObjects] = useState([])
  const [showObjectImport, setShowObjectImport] = useState(false)
  const [objectToRename, setObjectToRename] = useState();
  const [showNewObject, setShowNewObject] = useState(false);
  const tableSort = useTableSort(dialogOjbects)
  const loadDialogObjectsAction = useToastAction()
  const deleteDialogObjectAction = useToastAction()
  const activateObjectAction = useToastAction();
  const deactivateObjectAction = useToastAction();

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    loadDialogObjectsAction.execute(async () => {
      const records = await getAllDialogObjects()
      setDialogObjects(records)
    }, 'Failed to load objects')
  }

  const onDelete = async (id) => {
    deleteDialogObjectAction.execute(async () => {
      await deleteDialogObject(id)
      await load()
    }, 'Failed to delete dialog object', 'Deleted record')
  }

  const onExport = (customObject) => {
    objectToJsonFile(document, customObject, customObject.name)
  }

  const onActivate = (id) => {
    activateObjectAction.execute(async () => {
      const result = await activateDialogObject(id)
      replaceItem(dialogOjbects, d => d.id === result.id, result)
      setDialogObjects(dialogOjbects);
    }, 'Failed to activate', 'Activated')
  }

  const onDeactivate = (id) => {
    activateObjectAction.execute(async () => {
      const result = await deactivateDialogObject(id)
      replaceItem(dialogOjbects, d => d.id === result.id, result)
      setDialogObjects(dialogOjbects);
    }, 'Failed to deactivate', 'Deactivated')
  }

  const onRename = (customObject) => {
    setObjectToRename(customObject)
  }

  const locationHRef = (objectId) => `/admin/templates/objects/${objectId}`

  const onCreateSuccess = (customObject) => {
    load()
    window.location.href = locationHRef(customObject.id)
  }

  return (
    <>
      <div className="flex justify-between">
        <TableActions
          buttons={[{
            text: 'Import',
            onClick: () => setShowObjectImport(true)
          }]}
        />
        <div className="mt-5 pb-5">
          <button
            type="button"
            className="mt-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
            onClick={() => setShowNewObject(true)}
          >
            Create
          </button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableHeader
            tableSort={tableSort}
            sortBy="status"
          >
            Status
          </TableHeader>
          <TableHeader
            tableSort={tableSort}
            sortBy="name"
          >
            Name
          </TableHeader>
          <TableHeader
            tableSort={tableSort}
            sortBy="createdDate"
          >
            Created
          </TableHeader>
          <TableHeader />
        </TableHead>
        <TableBody>
          {
            tableSort?.tableData?.map(element => {
              return (
                <TableRow id={element.id} key={element.id}>
                  <TableColumn>
                    {isDialogObjectActive(element) ? (
                      <Badge
                        type="success"
                        text="Active"
                      />
                    ) : (
                      <Badge
                        type="error"
                        text="Inactive"
                      />
                    )}
                  </TableColumn>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative" style={{ minWidth: "250px" }}>
                    <span className="overflow-hidden left-0 right-0 whitespace-nowrap overflow-ellipsis">
                      <NavLink to={locationHRef(element.id)} className="text-black underline hover:text-gray-700">
                        {element.name}
                      </NavLink>
                    </span>
                  </td>
                  <TableColumn>{stringToLocaleDateTimeString(element.createdDate)}</TableColumn>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500 absolute">
                    <MenuContextList
                      element={element}
                      deleteType={"object template"}
                      actions={[
                        {
                          name: "Edit",
                          disabled: false,
                          href: locationHRef(element.id)
                        },
                        {
                          name: "Rename",
                          disabled: deleteDialogObjectAction.isExecuting,
                          onClick: () => onRename(element),
                        }, isDialogObjectActive(element) ?
                          {
                            name: "Deactivate",
                            disabled: deactivateObjectAction.isExecuting,
                            onClick: () => onDeactivate(element.id),
                          } : {
                            name: "Activate",
                            disabled: activateObjectAction.isExecuting,
                            onClick: () => onActivate(element.id),
                          }, {
                          name: "Export",
                          onClick: () => onExport(element),
                          disabled: deleteDialogObjectAction.isExecuting
                        }, {
                          name: "Delete",
                          disabled: deleteDialogObjectAction.isExecuting,
                          onClick: () => onDelete(element.id),
                        }]
                      }
                    />
                  </td>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <NewObject 
      isOpen={showNewObject}
      onClose={() => setShowNewObject(false)}
      onCreateSuccess={onCreateSuccess}
      />
      <ImportObject
        isOpen={showObjectImport}
        onClose={() => setShowObjectImport(false)}
        onImportSuccess={load}
      />
      <RenameObject
        objectToRename={objectToRename}
        onClose={() => setObjectToRename(null)}
        onRenameSuccess={load}
      />
    </>
  )
}