import { useState } from 'react';
import { useClassNames } from '../../hooks/useClassNames';

export const TextInput = ({
  name,
  label = null,
  value,
  onChange,
  type = null,
  placeholder = null,
  required = false,
  onKeyPress = null,
  validation: validate,
  horizontal = false,
  maxLength = null,
  disabled = false,
  hasError = false,
  onBlur = null
}) => {
  const [blurred, setBlurred] = useState(false)
  const classes = useClassNames()

  let hasValidationError = hasError && blurred
  if (blurred && validate) {
    hasValidationError = !validate(value)
  }

  const classNames = classes.classNames(
    "flex-1 block w-full sm:text-sm pr-10 py-2 text-base border-gray-300 rounded-md shadow-sm",
    hasValidationError
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-gray-400 focus:border-gray-400',
    disabled ? 'bg-gray-100' : 'bg-white',
  )

  return (
    <>
      {
        label &&
        <div className={classes.classNames(horizontal
          ? 'flex items-center mt-1'
          : '',
        )}>
          <label htmlFor={name} className={classes.classNames(horizontal
            ? 'flex-1'
            : '',
            'block text-sm font-medium text-gray-700'
          )}>
            {label}
            {required === true && <span className="text-red-400"> *</span>}
          </label>
          <input
            type={type || "text"}
            name={name}
            value={value || ''}
            onChange={(evt) => onChange(evt.target.value, name)}
            onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
            className={classNames}
            placeholder={placeholder}
            onBlur={(e) => {
              setBlurred(true)
              if(onBlur){
                onBlur(e)
              }
            }}
            disabled={disabled}
            maxLength={maxLength}
          />
        </div>
      }
      {
        !label &&
        <input
          type={type || "text"}
          name={name}
          value={value || ''}
          onChange={(evt) => onChange(evt.target.value, name)}
          onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
          className={classNames}
          placeholder={placeholder}
          onBlur={(e) => {
            setBlurred(true)
            if(onBlur){
              onBlur(e)
            }
          }}
          disabled={disabled}
          maxLength={maxLength}
        />
      }
    </>
  )
}