import React from 'react'
import { ColumnContext, ContainerLockedContext } from "../contexts";
import { Locked } from '../structures/Column/locked';
import Properties from '../structures/Column/properties';

export const useColumnDesign = (columnId, data) => {
  const columnContext = React.useContext(ColumnContext);
  const containerLockedContext = React.useContext(ContainerLockedContext);

  const isContextActive = columnContext && columnContext.enabled
  const isLocked = containerLockedContext?.isLocked
  return {
    onClick: (evt) => {
      if (isContextActive) {
        try {
          // prevent default event behavior if there is any
          evt.preventDefault()
        }
        catch (err) {
          // do nothing
        }

        if (isLocked) {
          columnContext.showPropertiesPanel(columnId, data, isLocked, (<Locked />));
        }
        else {
          columnContext.showPropertiesPanel(columnId, data, isLocked, (<Properties initData={data} actions={columnContext.actions} />));
        }
      }
    },
    isActive: () => {
      return isContextActive;
    },
    isSelected: () => {
      return columnContext?.currentColumnId === columnId;
    },
    columnsClasses: (editModeAndNotLocked) => {
      let classes = ''

      if (columnContext?.showGrid) {
        classes = 'grid-column '
      }

      const isNew = columnContext?.newColumnId === columnId;

      if (isContextActive || isNew) {
        const isSelected = columnContext?.currentColumnId === columnId;

        classes += 'design-column pointer ';
        classes += isSelected ? ' active ' : ''
        classes += isNew ? ' new ' : ''
        classes += ` open-column-actionbar-${columnId}`
      }

      if (editModeAndNotLocked) {
        // Only add when editing. On an actual form, we don't want this blindly added because it can cut off the 
        // last bit of the last element when printing. Also, as far as I can tell, this is only needed for the
        // time picker because for whatever reason, the default html time picker (at least in chrome) doesn't 
        // behave as expected when it comes to setting it's width to be 100% of it's parent - it will fill the
        // space, but there seems to be a minimum amount of space it takes up and will go beyond the parent's width.
        classes += " overflow-hidden"
      }

      return classes
    },
    hasContext: () => {
      return isContextActive;
    }
  }
}