import { BaseUrl, get } from '../api'
import { config } from './publicConfig';

export const getContactOnContactList = async (contactListId, phoneNumber) => {
  const options = {
    statusCodeHandlers: [{
      statusCode: 404,
      handler: () => {
        return false
      }
    }]
  }
  return await get(`${config.basePath}/contactLists/${contactListId}/contacts?phoneNumber=${encodeURIComponent(phoneNumber)}`, BaseUrl.Api, options);
}

export const getContactListContacts = async (contactListId) => {
  return await get(`${config.basePath}/contactLists/${contactListId}/contacListContacts`, BaseUrl.Api);
}
