import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Badge } from '../../../components/Badge';
import { useTableSort } from '../../../hooks/useTableSort';
import { useToastAction } from '../../../hooks/useToastAction';
import { editStates } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { getDialogDefinitions } from '../../../api/dataLoad';
import { useTableSearch } from '../../../hooks/useTableSearch';
import { useFileSaver } from '../../../hooks/useFileSaver';
import { Button } from '../../../components/Form/Button';
import { exportData } from '../../../api/dialogValues';

export default function DataExport() {
    const [allDialogs, setAllDialogs] = useState([]);
    const [dialogsToShow, setDialogsToShow] = useState([]);
    const tableSort = useTableSort(dialogsToShow);
    const tableSearch = useTableSearch(allDialogs);
    const fileSaver = useFileSaver();
    const loadAction = useToastAction();
    const createAction = useToastAction();

    const loadData = () => {
        loadAction.execute(async () => {
            const activeDefinitions = await getDialogDefinitions();
            setAllDialogs(activeDefinitions);
            setDialogsToShow(activeDefinitions);
        }, "Failed to load dialogs")
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleSearch = (e) => {
        const searchBy = e.target.value

        tableSearch.search(searchBy, setDialogsToShow, "name");
    }

    const onHandleExport = async (dialogDefinitionId) => {
        await exportData(dialogDefinitionId);   
    }

    return (
        <div className="max-w-screen-3xl">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-xl leading-6 font-medium text-gray-900">Data export</h3>
                <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                    <label htmlFor="desktop-search-candidate" className="sr-only">
                        Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                placeholder="Search"
                                onChange={handleSearch}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("name")}
                                    >
                                        SmartForm {tableSort.getSortIcon('name')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("editState")}
                                    >
                                        State {tableSort.getSortIcon('editState')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        
                                    >
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    !!dialogsToShow.length &&
                                    (tableSort?.tableData?.map((element) => (
                                        <tr key={element.dialogDefinitionId}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {element.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {
                                                    element.editState === editStates.draft
                                                        ? <Badge type="warn" text="Draft" />
                                                        : <Badge type="success" text="Published" />
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <Button
                                                theme="text"
                                                text="Export"
                                                className="mr-5"
                                                onClick={() => onHandleExport(element.dialogDefinitionId)}
                                            />
                                            </td>
                                        </tr>
                                    )))
                                }
                                {
                                    !dialogsToShow.length && !loadAction.isExecuting && (
                                        <tr>
                                            <td colSpan={6} className="py-4">
                                                <div className="flex justify-center items-center">
                                                    {
                                                        !allDialogs.length ?
                                                            (<>
                                                                No active dialogs.
                                                                {/* <button
                                                                    className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                    onClick={() => { setShowNewDialog(true) }}>
                                                                    + Create new dialog
                                                                </button> */}
                                                            </>)
                                                            : (<>Search yielded no result</>)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
