import { securityType } from '../utils/constants';
import { post, get, destroy, patch, put } from './api'

export const getDefinitionProcess = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/definitionProcess`);
}

export const getDefinitionProcessSteps = async (definitionProcessId) => {
  return await get(`definitionProcesses/${definitionProcessId}/steps`);
}

export const createDefinitionProcessStep = async (definitionProcessId, participatingPartyId) => {
  return await post(`definitionProcesses/${definitionProcessId}/steps`, {
    participatingPartyId,
    securityType: securityType.none,
  });
}

export const deleteDefinitionProcessSteps = async (definitionProcessId, stepId) => {
  return await destroy(`definitionProcesses/${definitionProcessId}/steps/${stepId}`);
}

export const patchDefinitionProcessStep = async (definitionProcessId, stepId, props) => {
  return await patch(`definitionProcesses/${definitionProcessId}/steps/${stepId}`, props)
}

export const getDefinitionProcessPhoneNumbers = async (definitionProcessId) => {
  return await get(`definitionProcesses/${definitionProcessId}/phoneNumbers`);
}

export const createDefinitionProcessPhoneNumber = async (definitionProcessId, phoneNumber) => {
  return await post(`definitionProcesses/${definitionProcessId}/phoneNumbers`, {
    phoneNumber
  });
}

export const updateDefinitionProcessPhoneNumber = async (definitionProcessId, definitionProcessPhoneNumberId, phoneNumber) => {
  return await put(`definitionProcesses/${definitionProcessId}/phoneNumbers/${definitionProcessPhoneNumberId}`, {
    phoneNumber
  });
}

export const deleteDefinitionProcessPhoneNumber = async (definitionProcessId, definitionProcessPhoneNumberId) => {
  return await destroy(`definitionProcesses/${definitionProcessId}/phoneNumbers/${definitionProcessPhoneNumberId}`);
}

export const setProcessHasAutomaticTranslationOff = async (dialogDefinitionId, hasAutomaticTranslationOff) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/automaticTranslationOff`, { hasAutomaticTranslationOff });
};