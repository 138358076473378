import { useEffect, useRef } from "react"
import { useDebounceCallback } from "@react-hook/debounce";

/**
 * Useful for debouncing a form save. Tracks if there is a pending
 * save on unmount so that a quick navigation away from the page after a
 * change is not missed. 
 */
export const useDebouncedSave = (saveFunction, wait = 1000) => {
  const valuesToSave = useRef(null)

  const debounced = useDebounceCallback((values) => {
    saveFunction(values);
    valuesToSave.current = null; // null out values to save because they have been saved
  }, wait)

  useEffect(() => {
    return async () => {
      // Save pending values if they are set
      if (valuesToSave.current) {
        await saveFunction(valuesToSave.current)
      }
    }
  }, [])

  return {
    save: (values) => {
      valuesToSave.current = values; // track next values to save
      debounced(values)
    },
  }
}