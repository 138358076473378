import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Hook for getting/setting query string parameters 
 */
export const useQueryFilter = (queryParamName, defaultValue) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get(queryParamName) || defaultValue)

  useEffect(() => {
    setValue(searchParams.get(queryParamName) || defaultValue)
  }, [searchParams])

  return {
    set: (value, includeInBrowserHistory = false) => {
      searchParams.set(queryParamName, value)
      setSearchParams(searchParams, { replace: !includeInBrowserHistory })
    },
    value,
  }
}