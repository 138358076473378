import { get, post } from './api';

const controller = "dataload";

export const createNewDataLoad = async (dialogDefinitionId, data, fileName, dataLoadName) => {
    return await post(`${controller}`, { fileContent: data, dialogDefinitionId, fileName, dataLoadName });
}

export const generateDataLoadDialogs = async (dialogDefinitionId, dataLoadId) => {
    return await post(`${controller}/generate`, { dialogDefinitionId, dataLoadId });
}

export const validateImportFile = async (dialogDefinitionId, data, fileName, dataLoadName) => {
    return await post(`${controller}/validate`, { fileContent: data, dialogDefinitionId, fileName, dataLoadName });
}

export const saveNotificationMessageDraft = async (dialogKey, dataLoadId, subject, message) => {
    return await post(`${controller}/notifiy/savedraft`, { dialogDefinitionId: dialogKey, dataLoadId, subject, message });
}
export const issueDataloadNotifications = async (dialogKey, dataLoadId, subject, message) => {
    return await post(`${controller}/notifiy`, { dialogDefinitionId: dialogKey, dataLoadId, subject, message });
}

export const getDialogDefinitions = async () => {
    return await get(`${controller}/DialogDefinitions`);
}

export const getDataLoad = async (dialogDefinitionId, dataLoadId) => {
    return await get(`${controller}/${dialogDefinitionId}/${dataLoadId}`);
}

export const getDataLoadReceipt = async (dialogDefinitionId, dataLoadId) => {
    return await get(`${controller}/${dialogDefinitionId}/${dataLoadId}/receipt`);
}

export const getDataLoadNotificationPlaceHolders = async (dialogDefinitionId, dataLoadId) => {
    return await get(`${controller}/${dialogDefinitionId}/${dataLoadId}/notificationplaceholders`);
}

export const getDialogDefinitionDataLoads = async (dialogDefinitionId) => {
    return await get(`${controller}/${dialogDefinitionId}`);
}

export const getDialogDefinitionTemplate = async (dialogDefinitionId) => {
    return await get(`${controller}/${dialogDefinitionId}/template`);
}

export const getDialogDefinitionLog = async (dialogDefinitionId) => {
    return await get(`${controller}/${dialogDefinitionId}/log`);
}