import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToastAction } from "../../../../hooks/useToastAction";
import { getContactList, patchContactList } from "../../../../api/contactLists";
import { createContactListContact, deleteContactListContact, getContactListContacts, updateContactListContact } from "../../../../api/contactListContacts";
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/Table";
import { useTableSort } from "../../../../hooks/useTableSort";
import { SearchIcon } from '@heroicons/react/outline'
import { useModalHelper } from "@metaforcelabs/metaforce-core";
import { ContactListContactForm } from "./contactListContactForm";
import { replaceItem } from "../../../../utils/array";
import MenuContextList from "../../../../components/MenuContextList";
import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { TextInput } from "../../../../components/Form/TextInput";
import { useDebounceCallback } from '@react-hook/debounce';

export const EditContactList = () => {
  const { contactListId } = useParams();
  const [searchBy, setSearchBy] = useState()
  const [contactList, setContactList] = useState()
  const [contactListContacts, setContactListContacts] = useState()
  const [contact, setContact] = useState()
  const loadAction = useToastAction()
  const saveAction = useToastAction(true)
  const deleteAction = useToastAction(true)
  const updateNameAction = useToastAction(true)
  const formModalHelper = useModalHelper()
  const tableSort = useTableSort(contactListContacts, "name", "asc")

  useEffect(() => {
    loadAction.execute(async () => {
      const [list, contacts] = await Promise.all([
        getContactList(contactListId),
        getContactListContacts(contactListId)
      ])
      setContactList(list)
      setContactListContacts(contacts)
    }, "Failed to load record")
  }, [])

  const handleSave = (values) => {
    const isUpdate = !!values.id
    saveAction.execute(async () => {
      let result
      if (!isUpdate) {
        result = await createContactListContact({
          ...values,
          contactListId
        })
        contactListContacts.push(result)
      }
      else {
        result = await updateContactListContact(values.id, {
          ...values,
          contactListId
        })
        replaceItem(contactListContacts, c => c.id === values.id, result)
      }
      setContactListContacts([...contactListContacts])
      formModalHelper.close()
    }, "Failed to save contact", isUpdate ? "Updated contact" : "Created contact")
  }

  useEffect(() => {
    if (!formModalHelper.isOpen) {
      setContact(null)
    }
  }, [formModalHelper.isOpen])

  const onEditClick = (id) => {
    setContact(contactListContacts.find(c => c.id === id))
    formModalHelper.open()
  }

  const onDeleteClick = (id) => {
    deleteAction.execute(async () => {
      await deleteContactListContact(id)
      setContactListContacts([...contactListContacts.filter(c => c.id !== id)])
    }, "Failed to delete contact", "Deleted contact")
  }

  const saveName = (name) => {
    if (name) {
      updateNameAction.execute(async () => {
        await patchContactList(contactListId, {
          name
        })
      }, "Failed to update name")
    }
  }
  const debouncedSaveName = useDebounceCallback(saveName, 250)

  const onNameChange = (value) => {
    setContactList({
      ...contactList,
      name: value
    })
    debouncedSaveName(value)
  }

  return (
    <>
      <div>
        <div className="mt-6 mb-7 pb-5 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold">Manage contact lists</h1>
            <p>
              This is the list of phone numbers connected to the list. You can add or delete numbers with connected Metadata.
            </p>
          </div>
          <div className='flex justify-end mt-5 pb-5'>
            <div className="w-72">
              <label htmlFor="desktop-search-candidate" className="sr-only">
                Search
              </label>
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="desktop-search-candidate"
                    id="desktop-search-candidate"
                    className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                    placeholder="Search..."
                    value={searchBy}
                    onChange={(evt) => setSearchBy(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                onClick={formModalHelper.open}
              >
                New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-72 mb-5">
        <TextInput
          label={"Name"}
          name={"name"}
          value={contactList?.name}
          validation={(value) => !!value}
          onChange={onNameChange}
          required={true}
        />
      </div>
      <Table>
        <TableHead>
          <TableHeader tableSort={tableSort} sortBy={"name"}>
            Name
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"address"}>
            Address
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"city"}>
            City
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"postalCode"}>
            Postal code
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"country"}>
            Country
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"department"}>
            Department
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"phoneNumber"}>
            Phone number
          </TableHeader>
          <TableHeader />
        </TableHead>
        <TableBody>
          {
            tableSort.tableData?.map(element => {
              return (
                <TableRow id={element.id} key={`contact-list-contact-${element.id}`}>
                  <TableColumn>
                    {element.name}
                  </TableColumn>
                  <TableColumn>
                    {element.address}
                  </TableColumn>
                  <TableColumn>
                    {element.city}
                  </TableColumn>
                  <TableColumn>
                    {element.postalCode}
                  </TableColumn>
                  <TableColumn>
                    {element.country}
                  </TableColumn>
                  <TableColumn>
                    {element.department}
                  </TableColumn>
                  <TableColumn>
                    {element.phoneNumber}
                  </TableColumn>
                  <td className="pt-6 pb-10 whitespace-nowrap text-right text-sm font-medium absolute">
                    <MenuContextList
                      element={element}
                      deleteType={"Contact"}
                      actions={[
                        {
                          name: "Edit",
                          disabled: false,
                          icon: PencilAltIcon,
                          onClick: () => onEditClick(element.id)
                        },
                        {
                          name: "Delete",
                          onClick: () => onDeleteClick(element.id),
                          disabled: deleteAction.isExecuting,
                          icon: TrashIcon,
                        }
                      ]}
                    />
                  </td>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <ContactListContactForm
        modalHelper={formModalHelper}
        contactListContact={contact}
        onSave={handleSave}
        isSaving={saveAction.isExecuting}
      />
    </>
  )
}