export const useElementStyle = (data) => {
    return {
        textStyle: (dataProperty, alignment) => {
            let textColorStyle = "inherit";
            
            if (dataProperty?.color && dataProperty.color.startsWith("#")) {
                textColorStyle = dataProperty.color;
            }

            const textFontSizeStyle = dataProperty?.size ? dataProperty.size : "inherit";
            const textFontAlignmentStyle = dataProperty?.alignment ? `${dataProperty.alignment}` : `left`;
            const textFontWidthStyle = dataProperty?.width || dataProperty?.width === '0' ? `${dataProperty.width}px` : '100%';

            return {
                color: textColorStyle,
                fontSize: textFontSizeStyle + 'px',
                textAlign: alignment || textFontAlignmentStyle,
                lineHeight: 1.4,
                width: textFontWidthStyle,
            };
        },
        textClassName: (dataProperty) => {
            let classes = '';
            classes += dataProperty?.bold ? ` font-bold ` : " ";
            classes += dataProperty?.italic ? ` italic ` : " ";
            classes += dataProperty?.underline ? ` underline ` : " ";
        
            return classes;
        },
        prefixClassName: (classPrefix) => {
            let classes = '';
            let colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color} ` : ''
        
            if (data?.text?.color && data?.text?.color.startsWith('#')) {
                colorClass = ''
            }
        
            classes += data?.text?.alignment ? `${classPrefix}--textalign-${data.text.alignment} ` : ''
            classes += data?.text?.bold ? `${classPrefix}--bold ` : ''
            classes += data?.text?.italic ? `${classPrefix}--italic ` : ''
            classes += data?.text?.underline ? `${classPrefix}--underline ` : ''
            classes += colorClass

            return classes;
        },
        borderAndWidthStyle: (widthInPercent) => {
            const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit';
            const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit';
            const widthStyle = data?.width || data?.width === '0' ? `${data.width}${widthInPercent ? '%' : 'px'}` : '100%';
        
            return {
                borderWidth: borderWidthStyle,
                borderColor: borderColorStyle,
                width: widthStyle
            }   
        },
        flexAlignmentClass: (dataProperty) => {
            let justifyClass; 

            switch(dataProperty) {
                case 'center':
                justifyClass = 'justify-center';
                break;
                
                case 'right':
                justifyClass = 'justify-end';
                break;
                
                default:
                case 'left':
                justifyClass = 'justify-start';
                break;
            }

            return justifyClass;
        }
    };
};
