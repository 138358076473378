import { Field, ErrorMessage } from 'formik';
import * as _ from "lodash"
import { Label } from '../Label';

export const FormikTextInput = ({
  name,
  formikProps,
  required = false,
  label = null,
  onBlur = null,
  onChange = null,
  type = null,
  placeholder = null,
  readOnly = false,
  rows = null, // if set, will render as a text area
}) => {
  const hasError = formikProps?.errors && _.get(formikProps?.touched || {}, name) && _.get(formikProps.errors, name);
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'

  let textAreaProps = {}
  if (rows > 0) {
    textAreaProps.as = "textarea"
    textAreaProps.rows = rows
  }

  const handleBlur = (evt) => {
    if (onBlur) {
      onBlur(evt)
    }
    if (formikProps?.handleBlur) {
      formikProps.handleBlur(evt)
    }
  }

  return (
    <div>
      {
        label && <Label name={name} label={label} required={required} />
      }
      <div className="mt-1">
        <Field
          type={type || "text"}
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''} ${readOnly ? 'bg-gray-200 cursor-not-allowed' : ''}`}
          placeholder={placeholder}
          onBlur={handleBlur}
          readOnly={readOnly}
          {...textAreaProps}
        />
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
    </div>
  )
}