import { useState, useEffect } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import { alignmentOptions } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Video"
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={handleOnChange}
          label={data?.video.type === 3 ? 'Video ID' : 'Url'}
          name={'value'}
          value={data?.value}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={"Video Type"}
          name={"video.type"}
          value={data?.video.type}
          defaultSelected={'1'}
          options={[
            { name: "General", value: 1 },
            { name: "YouTube", value: 2 },
            { name: "Vimeo", value: 3 }
          ]}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Autoplay"}
          name={"video.autoplay"}
          value={data?.video?.autoplay}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Contiouse play"}
          name={"video.contiousePlay"}
          value={data?.video?.contiousePlay}
        />

      </Property.Group>

      <Property.Group title="Element Style">

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Width'}
          name={'width'}
          value={data?.width}
          type={'number'}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label='Height'
          name='height'
          value={data?.height}
          type='number'
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={alignmentOptions}
        />
      </Property.Group>
    </div>
  )
}
