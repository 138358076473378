import { XIcon } from "@heroicons/react/outline"
import { useContext } from "react";
import { FormContext } from "../../contexts";
import { useErrorDetails } from "../../hooks/useErrorDetails";
import { getErrorKey } from "../../utils/features";

export const ErrorsList = () => {
	const formContext = useContext(FormContext);
	const { errorsWithDetail, translateTerm, translateCustomErrorMessage } = formContext || {};
	const { generateError } = useErrorDetails();
	const translatedCustomErrorMessage = (data) => {
		const translatedMessages = {
			byRequired: translateCustomErrorMessage(data, true),
			byValidation: translateCustomErrorMessage(data, false)
		}

		return translatedMessages
	}

	return (
		<>
			{
				errorsWithDetail?.length > 0 &&
				<div className='flex justify-center'>
					<div className='sf-error-message-container bg-red-100 rounded-sm p-3  w-full'>
						<div className="flex flex-col">
							<div>
								<div className="flex items-center ">
									<XIcon className='sf-error-message-icon w-5 p-0.5 font-bold bg-red-600 text-white rounded-full ' />
									<div className='text-lg font-bold pl-3 '>There are errors in document</div>
								</div>
								<div className="pl-8">Unable to submit document before all errors are fixed:</div>
							</div>

							<ul className='flex-row ml-12 mt-2'>
								{
									errorsWithDetail.map((error, i) =>
										<li className="list-disc" key={`error-summary-${error.id}-${i}`}>
											<div className="flex">
												<a href={`#${error.id}`} className="mr-1 text-brandBlue underline">
													{translateTerm(error, error.field || error.property, "term")}
												</a>
												<div>
													{generateError(errorsWithDetail, getErrorKey(error), translatedCustomErrorMessage(error))?.message}
												</div>
											</div>
										</li>
									)
								}
							</ul>
						</div>
					</div>
				</div>
			}
		</>
	)
} 