import { get, post, destroy, put } from './api'


export const getContactListContacts = async (contactListId) => {
  return await get(`contactLists/${contactListId}/contactListContacts`);
}

export const createContactListContact = async (record) => {
  return await post(`contactListContacts`, record);
}

export const updateContactListContact = async (id, record) => {
  return await put(`contactListContacts/${id}`, record)
}

export const deleteContactListContact = async (id) => {
  return await destroy(`contactListContacts/${id}`);
}