import { useHeadings } from "../../hooks/useHeadings";
import { useScrollSpy } from "../../hooks/useScrollSpy";
import './styles.scss'

export const TableOfContent = ({elementsToTableInfo, customStyles}) => {
    const headings = useHeadings(elementsToTableInfo);

    const activeId = useScrollSpy(
        headings.map(({ id }) => id),
      { rootMargin: "0% 0% 25% 0%" }
    );

    const isHeading = (element, tagName, className) => {
      return element?.tagName === tagName || element.className.includes(className)
    }

    const roundedCornerClass = customStyles?.roundedCorners ? 'rounded-lg' : ''
    const borderWidthStyle = customStyles?.borderWidth ? `${customStyles.borderWidth}` : 'inherit';
    const borderColorStyle = customStyles?.borderWidth ? `${customStyles.borderColor}` : 'inherit';

    const navStyles = {
      borderWidth: borderWidthStyle,
      borderColor: borderColorStyle,
    }


    const headlineStyles = {
      background: customStyles?.headlineBackgroundColor,
      color: customStyles?.headlineTextColor
    }

    const bodyStyles = {
      background: customStyles?.bodyBackgroundColor,
      color: customStyles?.bodyTextColor
    }

    const activeBorderWidthStyle = customStyles?.selectedBorderWidth ? `${customStyles.selectedBorderWidth}` : 'inherit';
    const activeBorderColorStyle = customStyles?.selectedBorderWidth ? `${customStyles.selectedBorderColor}` : 'inherit';
    const activeElementStyles = {
      borderWidth: activeBorderWidthStyle,
      borderColor: activeBorderColorStyle,
    }

    return (
        <nav className={`toc sticky top-16 w-40 h-0 -ml-72 ${roundedCornerClass}`} style={navStyles} >
          <ul className={`overflow-hidden space-y-2 ${ customStyles?.roundedCorners ? "rounded-b-lg" : ""}`} style={bodyStyles}>
            {
              headings.map((heading) => (
              <li key={heading.id} 
                className={`break-words text-xs ${isHeading(heading, "H2", "h2") ? "ml-1" : ""} ${isHeading(heading, "H3", "h3") ? "ml-2" : ""}`}
                style={activeId === heading.id ? activeElementStyles : undefined}
              >
                <a
                  style={{
                    marginLeft: `${heading.level - 2}em`,
                    fontWeight: activeId === heading.id ? "bold" : "normal",
                    color: customStyles?.bodyTextColor
                  }}
                  href={`#${heading.id}`}
                >
                 {isHeading(heading, "H2", "h2") || isHeading(heading, "H3", "h3")  ? "- " : ""} {heading?.tagName !== "TEXTAREA" ? heading.textContent : heading.value}
                </a>
              </li>
              ))
            }
          </ul>
        </nav>
    );
  }