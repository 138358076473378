import { positionTypes } from "../../utils/constants"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import { useEffect, useRef, useContext } from "react"
import { DialogModes } from "../../contexts/index"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SimpleStepper({ steps, currentStep, position }) {
  const { isPreviewMode, isEditMode } = useContext(DialogModes)
  let isMobile = window.matchMedia("only screen and (max-width: 480px)").matches;
  const scrolLValue = isMobile ? 140 : 235

  if (isPreviewMode) {
    return null
  }

  switch (position) {
    case positionTypes.top:
      return <TopStepper />

    case positionTypes.left:
      return <LeftStepper />

    default:
      return <LeftStepper />
  }

  function TopStepper() {
    const stepperRef = useRef()

    useEffect(() => {
      const currentStepChild = stepperRef?.current?.children?.[0]?.children?.[currentStep];
      const newScrollLeft = currentStepChild.offsetWidth * currentStep
      currentStepChild && (stepperRef.current.scrollLeft = newScrollLeft);
    }, [currentStep])

    const scroll = (e, scrollOffset) => {
      e.preventDefault()
      e.stopPropagation()
      stepperRef.current.scrollLeft += scrollOffset;
    };

    return (
      <nav className="sf-stepper-container flex mb-5 print:hidden">
        <button type="button" className="sf-stepper-button mb-2 mr-6" >
          <FontAwesomeIcon className="sf-stepper-button-icon text-gray-600" size={'lg'} icon={faChevronLeft} onClick={(e) => scroll(e, -scrolLValue)} />
        </button>
        <section className="sf-stepper-navigation flex overflow-x-auto overflow-y-hidden flex-nowrap w-full no-scrollbar" ref={stepperRef} id="horizontalStepper">
          <ol className="flex space-x-4">
            {
              steps.map((step, stepIdx) => (
                <li key={`step-${stepIdx}`} className='sf-stepper-navigation-item w-32 sm:w-56'>
                  <div className="flex items-center w-full">
                    <label className={`sf-stepper-navigation-item-indicator rounded-full h-7 w-7 flex items-center justify-center text-xs font-semibold tracking-wide ${stepIdx === currentStep ? "sf-stepper-navigation-item-indicator-current bg-gray-600 text-white" : "bg-opacity-0 text-gray-400 border-2 border-gray-400"}`}>{stepIdx + 1}</label>
                    <label className={`sf-stepper-navigation-item-text text-xs font-semibold tracking-wide break-all overflow-ellipsis overflow-hidden whitespace-nowrap ml-2 ${stepIdx === currentStep ? "sf-stepper-navigation-item-text-current text-gray-600" : "text-gray-400"}`}
                      style={{ maxWidth: "184px" }}
                    >
                      {step.name}
                    </label>
                  </div>
                  <div className={`sf-stepper-navigation-item-border w-full h-1 mt-2 ${stepIdx === currentStep ? "sf-stepper-navigation-item-border-current bg-gray-600" : "bg-gray-400"}`} />
                </li>
              ))
            }
          </ol>
        </section>
        <button type="button" className="sf-stepper-button mb-2 ml-6" onClick={(e) => scroll(e, scrolLValue)}>
          <FontAwesomeIcon className="sf-stepper-button-icon text-gray-600" size={'lg'} icon={faChevronRight} />
        </button>
      </nav>
    )
  }
  function LeftStepper() {
    return (
      <nav aria-label="Progress" className={`${isEditMode ? 'top-16 pl-4' : 'top-4 -mt-8'}  sticky -ml-72  h-0 print:hidden`}>
        <ol className={`overflow-hidden pt-5 ${isEditMode ? 'pl-4' : ''}`}>
          {steps.map((step, stepIdx) => (
            <li key={`step-${stepIdx}`} style={{ maxWidth: "14rem" }} className={classNames(stepIdx !== steps.length - 1 ? '' : '', 'relative')}>
              {
                <div className={`${stepIdx === currentStep ? "bg-gray-200 rounded-md border border-gray-300" : ""} px-3 py-1`}>

                  <div className="relative flex place-items-start group">
                    <span className="h-8 flex items-center">
                      <span className={`relative z-10 w-4 h-4 flex items-center justify-center ${stepIdx === currentStep ? "bg-gray-400" : "bg-gray-400"} rounded-full `}>
                      </span>
                    </span>
                    <span className="text-xs ml-1 mt-2 font-semibold tracking-wide overflow-ellipsis break-all">{step.name}</span>
                  </div>
                </div>
              }
              {
                stepIdx !== steps.length - 1 ? (
                  <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-gray-400" aria-hidden="true" />
                ) : null
              }
            </li>
          ))}
        </ol>
      </nav>
    )
  }
}