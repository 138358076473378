import { v4 as uuidv4 } from 'uuid';
import { elementTypes } from '../../utils/constants';

export const Blank = {
    id: uuidv4(),
    process: null,
    externalRedirectUrl: null,
    borderWidth: null,
    space: 0,
    size: 0,
    generatePdfOnSubmit: false,
    sendPdfToViewpoint: false,
    backgroundColor: null,
    toolbarColor: null,
    type: 1,
    name: "Blank",
    containers: [
        {
            id: uuidv4(),
            space: null,
            backgroundColor: null,
            margin: null,
            padding: null,
            borderWidth: null,
            borderColor: null,
            roundedCorners: false,
            static: false,
            rows: [
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: null,
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    inputType: 0,
                                    video: null,
                                    layout: null,
                                    text: {
                                        level: null,
                                        alignment: null,
                                        fontFamily: null,
                                        size: 18,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000"
                                    },
                                    labelStyle: null,
                                    displayLabel: null,
                                    valueLabel: null,
                                    property: "test",
                                    value: "Create the dial",
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    rows: null,
                                    options: null,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: []
                                }
                            ],
                            contentAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: null,
                            margin: "0 2px 0 2px",
                            borderColor: null,
                            roundedCorners: false
                        }
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: null,
                    margin: null,
                    padding: null,
                    borderWidth: null,
                    borderColor: null,
                    roundedCorners: false
                }
            ]
        }
    ],
    dialogValueIds: [],
    editState: null,
    status: 1,
    metaData: {},
    margin: null,
    padding: null,
    isDeleted: false
}