import { destroy, get, post, put } from './api'

const controllerName = "Resources";

export const getResources = async (category = null) => {
    const query = category != null ? '?category=' + category : ''
    return await get(controllerName + query);
}

export const createResource = async (request) => {
    return await post(controllerName, request);
}

export const destroyResource = async (id) => {
    return await destroy(`${controllerName}/${id}`);
}