import { get } from './api'

const controllerName = "AccessGroups"

export const getAllAccessGroups = async () => {
    return await get(`${controllerName}`);
}

export const getUserAccessGroups = async () => {
    return await get(`${controllerName}/currentUser`)
  }

export const getAllUsers = async () => {
    return await get(`${controllerName}/allUsers`)
}