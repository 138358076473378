import { useDefinitionManager } from "./useDefinitionManager"
import { useNewIdGenerator } from "./useNewIdGenerator";
import * as Data from "../../../data";
import _ from 'lodash';
import { setProperty } from '../../../utils/property'

export const useRowActions = (onSuccess, setRowContext, themeStyle) => {
  const definitionManager = useDefinitionManager();
  const idGenerator = useNewIdGenerator();

  return {
    setRowContext: setRowContext,
    addAbove: (dialogDefinition, rowId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newRow = _.cloneDeep(Data.RowNew(themeStyle))
      idGenerator.newIdsOnRow(newRow);

      let container = definitionManager.findContainerFromRow(payload, rowId)
      definitionManager.addItemAbove(container?.rows, rowId, newRow)

      onSuccess(payload);
      setRowContext(newRow)
    },
    addBelow: (dialogDefinition, rowId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newRow = _.cloneDeep(Data.RowNew(themeStyle))
      idGenerator.newIdsOnRow(newRow);

      let container = definitionManager.findContainerFromRow(payload, rowId)
      definitionManager.addItemBelow(container?.rows, rowId, newRow)

      onSuccess(payload);
      setRowContext(newRow)
    },
    addFromSidebar: (dialogDefinition, containerId, rowIndexTo, openActionBar, skipSave, rowId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newRow = _.cloneDeep(Data.RowNew(themeStyle))
      idGenerator.newIdsOnRow(newRow);
      rowId && (newRow.id = rowId)

      let container = definitionManager.findContainer(payload, containerId);

      definitionManager.addItemByIndex(container.rows, newRow, rowIndexTo)
      setRowContext(newRow)
      if (skipSave) {
        return payload
      }

      onSuccess(payload);
      openActionBar && definitionManager.openRowActionBar(newRow)

    },
    remove: (dialogDefinition, rowId, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      let container = definitionManager.findContainerFromRow(payload, rowId)

      if (container?.rows.length === 1) {
        //If only one line, column, and element exists in this container, delete the container
        definitionManager.removeItem(payload.containers, container.id)
      } else {
        definitionManager.removeItem(container?.rows, rowId)
      }

      definitionManager.updateLogicControls(payload);

      if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    hiddenOrShow: (dialogDefinition, rowId, hidden, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
       definitionManager.hiddenOrShowRow(payload, rowId, hidden);

      if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    duplicate: (dialogDefinition, rowId, row) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newRow = _.cloneDeep(row);
      idGenerator.newIdsOnRow(newRow);
      let container = definitionManager.findContainerFromRow(payload, rowId)
      definitionManager.addItemBelow(container?.rows, rowId, newRow)

      onSuccess(payload);
    },
    addColumn: (dialogDefinition, row) => {
      let lastColumn = row.columns[row.columns.length - 1];
      if (lastColumn) {
        definitionManager.addItemBelow(row.columns, lastColumn.id, _.cloneDeep(Data.ColumnNew(themeStyle)))
      }
      else {
        definitionManager.addItem(row.columns, _.cloneDeep(Data.ColumnNew(themeStyle)))
      }

      onSuccess(dialogDefinition);
    },
    moveUp: (dialogDefinition, rowId, row) => {
      const payload = _.cloneDeep(dialogDefinition)
      let container = definitionManager.findContainerFromRow(payload, rowId);
      let itemIndex = container.rows?.findIndex((item) => item.id === rowId);

      // Commented out this code because it crashes if moving row up when the row is the first row in a container      
      // if (itemIndex <= 0) {
      //   let currentContainerIndex = definitionManager.findContainerIndexFromRow(payload, rowId)

      //   if (currentContainerIndex > 0) {
      //     definitionManager.addItemBelow(dialogDefinition.containers[currentContainerIndex].rows, dialogDefinition.containers[currentContainerIndex - 1].rows, row)
      //   }
      // } else {
      //   definitionManager.swapItems(itemIndex, itemIndex - 1, container.rows);
      // }

      definitionManager.swapItems(itemIndex, itemIndex - 1, container.rows);

      onSuccess(payload);
    },
    moveDown: (dialogDefinition, rowId, row) => {
      const payload = _.cloneDeep(dialogDefinition)
      let container = definitionManager.findContainerFromRow(payload, rowId);
      let itemIndex = container.rows?.findIndex((item) => item.id === rowId);

      // Commented out this code because it crashes if moving row down when the row is the last row in a container
      // if (itemIndex + 1 >= container.rows.length) {
      //   let currentContainerIndex = definitionManager.findContainerIndexFromRow(payload, rowId)

      //   console.log(currentContainerIndex, dialogDefinition.containers.length);
      //   if (currentContainerIndex + 1 < dialogDefinition.containers.length) {
      //     definitionManager.moveItemTop(dialogDefinition.containers[currentContainerIndex].rows, dialogDefinition.containers[currentContainerIndex + 1].rows, row)
      //   }
      // } else {
      //   definitionManager.swapItems(itemIndex, itemIndex + 1, container.rows);
      // }

      definitionManager.swapItems(itemIndex, itemIndex + 1, container.rows);

      onSuccess(payload);
    },
    handleChange: (dialogDefinition, e, rowId, type) => {
      const payload = _.cloneDeep(dialogDefinition)
      const { name, value } = e.target;

      payload.containers?.forEach(container => {
        let row = container?.rows?.find(e => e.id === rowId);

        if (row) {
          setProperty(row, name, value, type)
          onSuccess(payload);
        }
      });
    },
    dragAndDrop: (dialogDefinition, sourceParentId, destinationParentId, rowIndexFrom, rowIndexTo, skipSave) => {
      const payload = _.cloneDeep(dialogDefinition)
      const sourceContainer = definitionManager.findContainer(payload, sourceParentId)

      if (sourceParentId === destinationParentId) {
        definitionManager.reorder(sourceContainer.rows, rowIndexFrom, rowIndexTo)
      } else {
        const destinationContainer = payload?.containers?.find(container => container.id === destinationParentId)
        definitionManager.reorderBetweenTwo(sourceContainer.rows, destinationContainer.rows, rowIndexFrom, rowIndexTo)
      }

      if (skipSave) {
        return payload;
      }

      onSuccess(payload);
    },
    addRowFromSidebar: (dialogDefinition, row, containerId, containerIndexTo, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      const container = definitionManager.findContainer(payload, containerId)
      definitionManager.addItemByIndex(container.rows, row, containerIndexTo)

      setRowContext(row)

      if (skipSave) {
        return payload
      }

      onSuccess(payload);
    },
    createRow: () => {
      return _.cloneDeep(Data.RowNew(themeStyle))
    }
  }
}