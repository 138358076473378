import { useEffect, useRef, useState } from "react"
import { getAutomaticTranslationRuns } from "../../../api/automaticTranslationRuns"

export const useAutomaticTranslationProgress = (dialogDefinitionId, onComplete) => {
  const [automaticTranslationRunsResponse, setAutomaticTranslationRunsResponse] = useState()
  const [isChecking, setIsChecking] = useState(false)
  const intervalRef = useRef(null)
  const previousAutomaticTranslationRunsResponse = useRef()
  const consecutiveChecksWithNoActiveRuns = useRef(0);

  const pollingChecksPerSecond = 4
  const secondsToPollFor = 45

  useEffect(() => {
    load()
    return () => stopChecking()
  }, [])

  /**
   * Do an initial check on load if there are any active runs.
   * If so, call startChecking() to start polling
   */
  const load = async () => {
    try {
      const result = await getAutomaticTranslationRuns(dialogDefinitionId)
      const hasActiveRuns = result?.active?.length > 0
      if (hasActiveRuns) {
        startChecking()
      }
    }
    catch {
      // swallow error
    }
  }

  useEffect(() => {
    const previous = previousAutomaticTranslationRunsResponse.current
    const hasActiveRuns = automaticTranslationRunsResponse?.active?.length > 0

    const translationRunIsComplete = !hasActiveRuns && previous?.active?.length > 0 // // previous check had runs, but current one doesn't

    if (automaticTranslationRunsResponse?.automaticTranslationsEnabled === false || translationRunIsComplete) {
      stopChecking()

      if (translationRunIsComplete) {
        onComplete()
      }
    }

    if (!hasActiveRuns) {
      consecutiveChecksWithNoActiveRuns.current = consecutiveChecksWithNoActiveRuns.current + 1
    }
    else {
      consecutiveChecksWithNoActiveRuns.current = 0
    }

    // Stop polling after max number of consecutive polls has been exceeded with no active runs
    if (consecutiveChecksWithNoActiveRuns.current > (secondsToPollFor * pollingChecksPerSecond)) {
      stopChecking();
    }

    previousAutomaticTranslationRunsResponse.current = automaticTranslationRunsResponse
  }, [automaticTranslationRunsResponse])

  const check = async () => {
    try {
      const result = await getAutomaticTranslationRuns(dialogDefinitionId)
      setAutomaticTranslationRunsResponse(result)
    }
    catch {
      // Swallow error. Could be because hook is no longer mounted.
    }
  }

  const startChecking = () => {
    setIsChecking(true)
    intervalRef.current = setInterval(() => {
      check();
    }, 1000 / pollingChecksPerSecond)
  }

  const stopChecking = () => {
    try {
      setIsChecking(false)
      consecutiveChecksWithNoActiveRuns.current = 0
      clearInterval(intervalRef.current)
    }
    catch {
      // swallow error
    }
  }

  const inProgress = automaticTranslationRunsResponse?.active?.length > 0
  const inProgressJobProgress = inProgress
    ? automaticTranslationRunsResponse.active[0].progress * 100 // grab first one. In case of multiple active jobs, server returns newest job first
    : null
  return {
    startChecking,
    isChecking,
    inProgress,
    progress: inProgressJobProgress
  }
}