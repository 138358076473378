import { get, put } from '../api'
import { config } from './publicConfig';

export const getSignatureValuesByToken = async (token) => {
  return await get(`${config.basePath}/signatures/token/${token}/values`)
}

export const updateSignatureValue = async (token, value) => {
  return await put(`${config.basePath}/signatures/token/${token}`, { value });
}

export const completeSignature = async (token) => {
  return await put(`${config.basePath}/signatures/token/${token}/sign`);
}