import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { activateSmsNotificationTemplate, createSmsNotificationTemplate, destroySmsNotificationTemplate, disableSmsNotificationTemplate, getNotificationEventTypes, getSmsNotificationTemplates } from '../../../../api/notificationTemplates';
import { TextInput } from '../../../../components/Form/TextInput';
import Modal from '../../../../components/Modal';
import { useFeature } from '../../../../hooks/useFeature';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useTableSort } from '../../../../hooks/useTableSort';
import { useToastAction } from '../../../../hooks/useToastAction';
import * as Constants from '../../../../utils/constants';
import { onKeyPressEnter } from '../../../../utils/onKeyPress';
import TemplateListTableBody from '../templateListTableBody';

export default function SmsTemplateList() {
  const { hasFeature } = useFeature();
  const [notificationEventTypes, setNotificationEventTypes] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([])
  const [filterTemplates, setFilterTemplates] = useState([]);
  const navigate = useNavigate();
  const tableSort = useTableSort(filterTemplates);
  const tableSearch = useTableSearch(smsTemplates);
  const loadAction = useToastAction(null, "load-sms");
  const updateAction = useToastAction();
  const newAction = useToastAction();
  const [showNewModal, setShowNewModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");

  const loadData = async () => {
    loadAction.execute(async () => {
      const eventTypesData = await getNotificationEventTypes();
      setNotificationEventTypes(eventTypesData);
      const loadedTemplates = await getSmsNotificationTemplates();
      setSmsTemplates(loadedTemplates);
      setFilterTemplates(loadedTemplates);
    }, "Load failed");
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleSearch = ({ target }) => {
    tableSearch.search(target.value, setFilterTemplates, "name")
  }

  const handleCreateTemplate = () => {
    setNewTemplateName("");
    setShowNewModal(true);
  }

  const onKeyPress = (event) => {
    onKeyPressEnter(event, "submitButton")
  }

  const handleSubmitNewTemplate = () => {
    newAction.execute(async () => {
      const created = await createSmsNotificationTemplate(newTemplateName);
      navigate(`./${created?.id}`);
    })
  }

  const handleDeletedRow = (id) => {
    updateAction.execute(async () => {
      await destroySmsNotificationTemplate(id);
      const removedList = smsTemplates.filter(x => x.id !== id);
      setSmsTemplates([...removedList])
      setFilterTemplates([...filterTemplates.filter(x => x.id !== id)]);
    }, "Error deleting", "Template deleted")
  }

  const handleRowChanged = (item) => {
    filterTemplates.splice(filterTemplates.findIndex(x => x.id === item.id), 1, item);
    setFilterTemplates([...filterTemplates]);
  }

  const handleDisableRow = (id) => {
    updateAction.execute(async () => {
      const disabled = await disableSmsNotificationTemplate(id);
      handleRowChanged(disabled);
    }, "Error disabling", "Template disabled")
  }

  const handleActivateRow = (id) => {
    updateAction.execute(async () => {
      const activated = await activateSmsNotificationTemplate(id);
      handleRowChanged(activated);
    }, "Error activating", "Template activated")
  }

  return (<>
    <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
      <h1 className="text-3xl font-bold">SMS Notification Templates</h1>
      <div className='flex justify-end mt-5 pb-5'>
        <div className="w-72">
          <label htmlFor="desktop-search-candidate" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder="Search..."
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
          onClick={() => handleCreateTemplate()}
        >
          New Template
        </button>
      </div>

    </div>

    <div className="my-2 sm:my-4 md:my-6 sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
        <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() =>
                    tableSort.orderBy("name")
                  }
                >
                  Name {tableSort.getSortIcon("name")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => tableSort.orderBy("status")}
                >
                  Status {tableSort.getSortIcon("status")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => tableSort.orderBy("notificationEventType")}
                >
                  Event {tableSort.getSortIcon("notificationEventType")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => tableSort.orderBy("isDefaultForType")}
                >
                  Event Default {tableSort.getSortIcon("isDefaultForType")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() =>
                    tableSort.orderBy("createdDate")
                  }
                >
                  Created {tableSort.getSortIcon("createdDate")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() =>
                    tableSort.orderBy("updatedDate")
                  }
                >
                  Updated {tableSort.getSortIcon("updatedDate")}
                </th>
                {
                  hasFeature(Constants.designFeatureCode) && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                    </th>
                  )
                }
              </tr>
            </thead>
            <TemplateListTableBody
              data={tableSort.tableData}
              notificationEventTypes={notificationEventTypes}
              isExecuting={updateAction.isExecuting}
              onDelete={id => {
                handleDeletedRow(id);
              }}
              onDisable={id => {
                handleDisableRow(id);
              }}
              onActivate={id => {
                handleActivateRow(id);
              }}
              itemPath={'sms'}
            />
          </table>
        </div>
      </div>
    </div>
    <Modal
      isOpen={showNewModal}
      onClose={() => {
        setShowNewModal(false)
      }}
      size="medium"
    >
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
        <TextInput
          name={`template-name`}
          value={newTemplateName}
          onChange={value => {
            setNewTemplateName(value);
          }}
          onKeyPress={onKeyPress}
          required
        />
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          id="submitButton"
          disabled={newTemplateName.length === 0}
          type="button"
          className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-base font-medium text-white sm:text-sm"
          onClick={(event) => {
            handleSubmitNewTemplate();
            setShowNewModal(false);
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={(event) => {
            event.preventDefault();
            setNewTemplateName("");
            setShowNewModal(false)
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>

  </>

  )
}
