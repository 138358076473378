import { useState, useEffect } from "react";
import * as Property from "../../components/Properties";
import { setProperty } from "../../utils/property";
import OptionsPopup from "../optionsPopup";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import RowOptions from "./rowsOptions";

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();

  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  
  const [openColumnsPopup, setOpenColumnsPopup] = useState(false)
  const [openItemsPopup, setOpenItemsPopup] = useState(false)

  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type);

    setProperty(data, name, value, type);
    setData((data) => ({
      ...data,
    }));
  };

  return (
    <div className="popover-container-items">

      <Property.Group title="Information">
        <Property.Label
          label="Type"
          value="Order Table"
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />
      </Property.Group>

      <Property.Group title="Order table">
        <Property.ButtonWithLabel
          label="Columns"
          onClick={() => setOpenColumnsPopup(true)}
          text='Edit columns'
        />

        <Property.ButtonWithLabel
          label="Items"
          onClick={() => setOpenItemsPopup(true)}
          text='Edit items'
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Add sum row"}
          name={"orderTable.sum"}
          value={data?.orderTable?.sum}
        />

        {
          data?.orderTable?.sum &&
          <Property.Text
            onChange={handleOnChange}
            label="Sum"
            name="orderTableValue.sum"
            value={data?.orderTableValue?.sum}
          />
        }

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Add discount row"}
          name={"orderTable.discount"}
          value={data?.orderTable?.discount}
        />

        {
          data?.orderTable?.discount &&
          <Property.Text
            onChange={handleOnChange}
            label="Discount"
            name="orderTableValue.discount"
            value={data?.orderTableValue?.discount}
          />
        }

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Add vat row"}
          name={"orderTable.vat"}
          value={data?.orderTable?.vat}
        />

        { 
          data?.orderTable?.vat &&
          <Property.Text
            onChange={handleOnChange}
            label="Vat"
            name="orderTableValue.vat"
            value={data?.orderTableValue?.vat}
          />
        }
        
      </Property.Group>

      <Property.Group
        title='Design'>
        <Property.Subgroup
         title="General">

          <Property.Checkbox
            onChange={handleOnChange}
            label={'Borders'}
            name={'orderTable.borders'}
            value={data?.orderTable?.borders}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Border color'}
            name={'orderTable.borderColor'}
            initColor={data?.orderTable?.borderColor}
          />

         </Property.Subgroup>
      
        <Property.Subgroup 
          title="Headers">

          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="labelStyle"
            property={data?.labelStyle}
          />

        </Property.Subgroup>

        <Property.Subgroup 
          title="Footer">
            <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'orderTable.footerTextStyle.color'}
            initColor={data?.orderTable?.footerTextStyle?.color}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="orderTable.footerTextStyle"
            property={data?.orderTable?.footerTextStyle}
          />
        </Property.Subgroup>

        <Property.Subgroup 
          title="Items">
            
          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'text.color'}
            initColor={data?.text?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'text.size'}
            type={'number'}
            value={data?.text?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="text"
            property={data?.text}
          />

        </Property.Subgroup>

        <Property.Subgroup
         title="Stripes">

          <Property.Checkbox
            onChange={handleOnChange}
            label={'Striped Rows'}
            name={'orderTable.stripedRows'}
            value={data?.orderTable?.stripedRows}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Stripes Odd'}
            name={'orderTable.stripesOddColor'}
            initColor={data?.orderTable?.stripesOddColor}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Stripes Even'}
            name={'orderTable.stripesEvenColor'}
            initColor={data?.orderTable?.stripesEvenColor}
        />

         </Property.Subgroup>

      </Property.Group>

      <OptionsPopup title={"Edit columns"} showSort={false} data={data} onChange={handleOnChange} open={openColumnsPopup} setOpen={setOpenColumnsPopup} />
      <RowOptions data={data} onChange={handleOnChange} open={openItemsPopup} setOpen={setOpenItemsPopup} />

    </div>
  );
}