import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  createWebhook,
  deleteWebhook,
  getWebhooksByDialogDefinitionId,
  updateWebhook,
  activateWebhook,
  deactivateWebhook,
} from "../../../api/webhook";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { useToastAction } from "../../../hooks/useToastAction";
import { Button } from "../../../components/Form/Button";
import Modal from "../../../components/Modal";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import MenuContextList from "../../../components/MenuContextList/index";
import { replaceItem } from "../../../utils/array";
import { isWebhookActive } from "../../../utils/webhooks";
import { useModalHelper } from "../../../hooks/useModalHelper";
import { Badge } from '../../../components/Badge';
import { webhookStatuses, webhookTypes, webhookSources } from '../../../utils/constants'

export const Webhooks = ({ dialogKey }) => {
  const [webhooks, setWebhooks] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const [activeWebhook, setActiveWebhook] = useState({});

  const loadAction = useToastAction();
  const createDefinitionAction = useToastAction();
  const updateDefinitionAction = useToastAction();
  const activateDefinitionAction = useToastAction();
  const deactivateDefinitionAction = useToastAction();
  const deleteAction = useToastAction()

  const addEditModalHelper = useModalHelper();

  useEffect(() => {
    load();
  }, []);

  async function load() {
    loadAction.execute(async () => {
      const webhooksResult = await getWebhooksByDialogDefinitionId(dialogKey);
      setWebhooks(webhooksResult);
    }, "Failed to load webhooks");
  }

  const onCreateWebhook = async (webhook) => {
    const { url, name, sendData } = webhook;
    createDefinitionAction.execute(async () => {
      const newWebhook = await createWebhook(dialogKey, url, name, sendData, webhookStatuses.active, webhookTypes.manual, webhookSources.smartForms);
      setWebhooks([newWebhook, ...webhooks]);
    }, "Failed to add new webhook", "New webhook added");
  };

  const onUpdateWebhook = async (webhook) => {
    const { url, name, sendData } = webhook;
    const { id, status, type, source } = activeWebhook;
    updateDefinitionAction.execute(async () => {
      const result = await updateWebhook(id, dialogKey, url, name, sendData, status, type, source);
      replaceItem(webhooks, (d) => d.id === result.id, result);
      setWebhooks(webhooks);
    }, "Failed to update a webhook", "Updated");
  };

  const onActivate = async (webhookId) => {
    activateDefinitionAction.execute(async () => {
      const result = await activateWebhook(webhookId);
      replaceItem(webhooks, (d) => d.id === result.id, result);
      setWebhooks(webhooks);
    }, "Failed to activate", "Activated");
  };

  const onDeactivate = async (webhookId) => {
    deactivateDefinitionAction.execute(async () => {
      const result = await deactivateWebhook(webhookId);
      replaceItem(webhooks, (d) => d.id === result.id, result);
      setWebhooks(webhooks);
    }, "Failed to Deactivate", "Deactivated");
  };

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteWebhook(id);
      const remainingWebhooks = webhooks.filter((d) => d.id !== id);
      setWebhooks([...remainingWebhooks]);
    }, "Failed to delete webhook", "Webhook deleted");
  };

  const AddEditModal = ({ modalHelper, onCreate, onEdit }) => {
    const cancel = () => {
      modalHelper.close();
    };

    let initialValues = {
      name: "",
      url: "",
      sendData: false,
    };

    if (!isAdd) {
      initialValues = {
        name: activeWebhook.name,
        url: activeWebhook.url,
        sendData: activeWebhook.sendData,
      };
    }

    return (
      <Modal
        isOpen={modalHelper.isOpen}
        onClose={modalHelper.close}
        size="medium"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={() => {
            return yup.object({
              name: yup.string().required("Required"),
              url: yup
                .string()
                .required("Required")
                .url("Must be a URL"),
            });
          }}
          onSubmit={async (values, actions) => {
            if (isAdd) {
              await onCreate(values);
            } else {
              await onEdit(values);
            }
            actions.setSubmitting(false);
            modalHelper.close();
          }}
        >
          {(props) => (
            <Form>
              <div className="flex flex-col">
                <div>
                  <FormikTextInput
                    required
                    label="Name"
                    name="name"
                    formikProps={props}
                  />
                </div>
                <div className="mt-6">
                  <FormikTextInput
                    required
                    label="Url"
                    name="url"
                    formikProps={props}
                  />
                </div>
                <div className="mt-6">
                  <FormikCheckbox
                    label="Send data in webhook"
                    name="sendData"
                    formikProps={props}
                    styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                  />
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex justify-between">
                <FormikSubmitButton
                  formikProps={props}
                  text="Save"
                />
                <Button
                  onClick={cancel}
                  text="Close"
                  theme="white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  return (
    <>
      <section aria-labelledby="webhooks-heading">
        <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
          <div className="flex justify-between items-center px-4 sm:px-6">
            <h2
              id="webhooks-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Webhooks
            </h2>
            <Button
              text="New"
              onClick={() => {
                setIsAdd(true);
                addEditModalHelper.open();
              }}
            />
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-t border-gray-200">
                  <table className="min-w-full mb-1 divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          WebHook
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Data
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >

                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {webhooks?.map((element, index) => (
                        <tr key={element.id ? element.id : index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {isWebhookActive(element)
                              ? <Badge type="success" text="Active" />
                              : <Badge type="warn" text="Deactive" />
                            }
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                            style={{ minWidth: "250px" }}
                          >
                            {element.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative"
                            style={{ minWidth: "250px" }}
                          >
                            <span className="px-3 text-black underline hover:text-gray-700 overflow-hidden block right-0 left-0 absolute whitespace-nowrap overflow-ellipsis"
                              style={{ top: "32%" }}
                            >
                              {element.url}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {element.sendData ? "Yes" : "No"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {stringToLocaleDateTimeString(element.createdDate)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {element.type === 0 && (
                              <MenuContextList
                                element={
                                  element
                                }
                                deleteType={
                                  "webhook"
                                }
                                actions={[
                                  isWebhookActive(element)
                                    ? {
                                      name: "Deactivate",
                                      disabled: deactivateDefinitionAction.isExecuting,
                                      onClick: () => onDeactivate(element.id),
                                    }
                                    : {
                                      name: "Activate",
                                      disabled: activateDefinitionAction.isExecuting,
                                      onClick: () => onActivate(element.id),
                                    },
                                  {
                                    name: "Edit",
                                    onClick: () => {
                                      setIsAdd(false);
                                      setActiveWebhook(element);
                                      addEditModalHelper.open();
                                    },
                                  },
                                  {
                                    name: "Delete",
                                    onClick: () => onDelete(element.id),
                                  },
                                ]}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddEditModal
        modalHelper={addEditModalHelper}
        onCreate={onCreateWebhook}
        onEdit={onUpdateWebhook}
        element={activateWebhook}
      />
    </>
  );


}


