import { useContext, useEffect, useState } from 'react'
import { useClassNames } from "../../../../hooks/useClassNames";
import { DesignActionbarContext } from '../../../../contexts';
import * as Property from "../../../../components/Properties";
import { useToastAction } from '../../../../hooks/useToastAction';
import { getFolders } from '../../../../api/archive';
import { getActiveAuthenticationTemplates } from '../../../../api/authenticationTemplates';
import { getRetentionPolicies } from '../../../../api/retentionPolicies';
import { createRetentionPolicyOptions } from '../../../../utils/dialogDefinitions';
import { getDefinitionLanguages, setDefinitionLanguageAsDefault } from '../../../../api/definitionLanguage';

export default function Page() {
    const classNames = useClassNames();
    const loadAction = useToastAction();
    const [archviveFolders, setArchiveFolders] = useState([]);
    const [retentionPoliciesOptions, setRetentionPoliciesOptions] = useState([])
    const [defaultLanguage, setDefaultLanguage] = useState({})
    const [authenticationTemplateOptions, setAuthenticationTemplateOptions] = useState([])
    const [definitionLanguagesOptions, setDefinitionLangaguesOptions] = useState([])
    const setDefinitionLanguageAsDefaultAction = useToastAction()

    const designActionbarContext = useContext(DesignActionbarContext);
    const {
        dialogDefinition,
        handleGeneralChange,
        handleSmartformName,
        smartformName,
        setMultipleDialogDefinitionValues
    } = designActionbarContext || {}

    useEffect(() => {
        load()
    }, [])

    const load = async () => {
        loadAction.execute(async () => {
            const [foldersResult, retentionPoliciesResult, definitionLanguagesResult, authenticationTemplatesResult] = await Promise.all([
                getFolders(),
                getRetentionPolicies(),
                getDefinitionLanguages(dialogDefinition.id),
                getActiveAuthenticationTemplates()
            ]);


            let archiveFolderOptions = foldersResult.map(archiveFolder => {
                return {
                    value: archiveFolder.id,
                    name: `${archiveFolder.name} (${archiveFolder.fullPath})`
                }
            })

            let authenticationTemplateOptions = authenticationTemplatesResult.map(authenticationTemplate => {
                return {
                    value: authenticationTemplate.id,
                    name: authenticationTemplate.name
                }
            })

            let definitionLanguageOptions = definitionLanguagesResult.map(definitionLanguage => {
                return {
                    value: definitionLanguage.id,
                    name: definitionLanguage.language
                }
            })

            addNotSelectedOption(archiveFolderOptions)

            const defaultLanguage = definitionLanguagesResult.find(lang => lang.default);
            setDefaultLanguage(defaultLanguage?.id)

            const mapperRetentionPolicyOptions = createRetentionPolicyOptions(retentionPoliciesResult, dialogDefinition.retentionPolicy);
            addNotSelectedOption(mapperRetentionPolicyOptions)

            setArchiveFolders(archiveFolderOptions)
            setRetentionPoliciesOptions(mapperRetentionPolicyOptions)
            setAuthenticationTemplateOptions([{ value: null, name: "None" }, ...authenticationTemplateOptions]);
            setDefinitionLangaguesOptions(definitionLanguageOptions)

        }, "Failed to load")
    }

    const addNotSelectedOption = (options) => {
        options.unshift({
            value: 0,
            name: 'None'
        })
    }

    const handleArchieveFolderChange = async (e) => {
        const newValue = e.target.value === "0" ? null : e.target.value;

        const archiveValues = {
            archiveFolder: e.target.value,
            hasArchiveWhenCreated: newValue ? true : false,
            hasSendToArchive: newValue ? true : false,
            name: dialogDefinition.name
        }

        setMultipleDialogDefinitionValues(archiveValues)
    }

    const onSetLanguageDefinitionAsDefault = async (e) => {
        setDefinitionLanguageAsDefaultAction.execute(async () => {
            const selectedDefinitionLanguageId = e.target.value;
            await setDefinitionLanguageAsDefault(selectedDefinitionLanguageId, dialogDefinition.id);

            setDefaultLanguage(selectedDefinitionLanguageId);
        }, "Failed to Activate the language as default", "Language is default")
    }

    const handleValueChange = async (e) => {
        setMultipleDialogDefinitionValues({ [e.target.name]: e.target.value })
    }

    const handleChangeCheckValue = (e) => {
        setMultipleDialogDefinitionValues({ [e.target.name]: e.target.checked })
    }

    return (
        <div
            className={classNames.classNames(
                'h-full '
            )}
        >
            <Property.Group>
                <Property.Text
                    onChange={handleSmartformName}
                    label={'Smartform name'}
                    name={'name'}
                    value={smartformName}
                />

                <Property.Select
                    onChange={(e) => handleArchieveFolderChange(e)}
                    label={"Archieve Smartforms"}
                    name={"archiveFolder"}
                    value={dialogDefinition?.archiveFolder}
                    options={archviveFolders}
                    disabled={loadAction.isExecuting}
                />
            </Property.Group>

            <Property.Group>
                <Property.Checkbox
                    onChange={(e) => handleChangeCheckValue(e)}
                    label={"Digital Signature required"}
                    name={"hasDigitalSigning"}
                    value={dialogDefinition?.hasDigitalSigning}
                    disabled={loadAction.isExecuting}
                />

                <Property.Select
                    onChange={(e) => handleValueChange(e)}
                    label={"Authentication template"}
                    name={"authenticationTemplate"}
                    value={dialogDefinition?.authenticationTemplate}
                    options={authenticationTemplateOptions}
                    disabled={loadAction.isExecuting}
                />

            </Property.Group>

            <Property.Group>
                <Property.Select
                    onChange={(e) => handleValueChange(e)}
                    label={"Retention Policy"}
                    name={"retentionPolicyId"}
                    value={dialogDefinition.retentionPolicyId}
                    options={retentionPoliciesOptions}
                    disabled={loadAction.isExecuting}
                />

                <Property.Select
                    onChange={(e) => onSetLanguageDefinitionAsDefault(e)}
                    label={"Language used"}
                    value={defaultLanguage}
                    options={definitionLanguagesOptions}
                    disabled={loadAction.isExecuting}
                />

            </Property.Group>
        </div>


    )
}
