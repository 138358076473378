import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FormContext } from '../../contexts';
import * as Structures from "../../structures";
import _ from 'lodash';
import moment from 'moment';
import { valuesModelToKeyValuePair } from '../../utils/dialogValues';
import { useToastAction } from "../../hooks/useToastAction";

import './styles.scss';
import { completeSignature, getSignatureValuesByToken, updateSignatureValue } from '../../api/public/signatures';

export default function SignForm() {
  const { signatureToken } = useParams();
  const completeSignatureAction = useToastAction();
  const updateSignatureAction = useToastAction();

  const [isLoading, setIsLoading] = useState(true);
  const [dialogDefinition, setDialogDefinition] = useState();
  const [dialogFormValues, setDialogFormValues] = useState();
  const [signatureId, setSignatureId] = useState();
  const [signatures, setSignatures] = useState();
  const [saveMessage, setSaveMessage] = useState();


  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const { dialogValues, dialogDefinition, signatures, currentSignatureId } = await getSignatureValuesByToken(signatureToken)

    if (dialogValues.updatedDate) {
      const message = dialogValues.isComplete ? "Completed" : "Last updated";
      setSaveMessage(`${message} ${moment(dialogValues.updatedDate).fromNow()}`)
    }

    setDialogFormValues(valuesModelToKeyValuePair(dialogValues));
    setDialogDefinition(dialogDefinition);
    setSignatures(signatures)
    setSignatureId(currentSignatureId)

    setIsLoading(false);
  }

  const onUpdate = async (value) => {
    updateSignatureAction.execute(async () => {
      await updateSignatureValue(signatureToken, value)
    }, 'Failed to update signature')
  }

  const onCompleteSignature = async () => {
    completeSignatureAction.execute(async () => {
      await completeSignature(signatureToken);
      await load();
    }, "Failed to complete signature")
  }

  if (isLoading) {
    return null;
  }

  return (
    <FormContext.Provider
      value={{
        inputValues: dialogFormValues,
        isFormEnabled: false,
        updateSignature: (value) => {
          onUpdate(value)
        },
        completeSignature: onCompleteSignature,
      }}
    >
      <span className={'print:hidden'}>
        {saveMessage}
      </span>
      <Structures.Dialog
        data={dialogDefinition}
        signatures={signatures}
        currentSignature={signatures?.find(s => s.id === signatureId)}
      />
    </FormContext.Provider>
  )
}
