import { processTypes } from "../../utils/constants";
import { documentSigningModes } from "../Admin/DialogProcess/normalProcessDigitalSigning";

const useDigitalSigningHelper = (dialogDefinition, dialogValues, step) => {
    const process = dialogDefinition?.process;
    const isSigned = dialogValues?.isSigned;
    const hasDigitalSigning = dialogDefinition?.hasDigitalSigning;

    const isStepPendingSignature = (signUrl) => {
        const isPending = hasDigitalSigning && !isSigned && dialogDefinition?.lastPartyToSignFromSmartform && dialogValues?.isComplete && signUrl && !dialogValues.isAborted;
        return isPending;
    }

    const isNormalPendingSignature = (signUrl)=>{
        return hasDigitalSigning && !isSigned && dialogValues?.isComplete && signUrl && !dialogValues.isAborted;
    }

    const isPendingSignature = hasDigitalSigning && !isSigned && dialogValues?.signingNeeded && dialogValues.isComplete && !dialogValues.isAborted;

    const documentSignatories = hasDigitalSigning ? dialogValues?.emailsToSign || [] : [];

    const normalAndDigitalSigning = process === processTypes.normal && hasDigitalSigning;
    const processAndDigitalSigning = process === processTypes.multiStep && hasDigitalSigning;

    const canSignNow = processAndDigitalSigning && dialogDefinition.lastPartyToSignFromSmartform;
    const canDefinePartiesToSign =
        //process digital signing flags
        (
            (processAndDigitalSigning &&
                dialogDefinition.lastPartyDefineSignatories) ||

            //Normal process digital signing flags
            (normalAndDigitalSigning &&
                (dialogDefinition?.digitalSigningDocumentSigningMode === documentSigningModes.single ||
                    dialogDefinition?.digitalSigningDocumentSigningMode === documentSigningModes.multiple))
        )

    const isSingleMode = (dialogDefinition?.digitalSigningDocumentSigningMode === documentSigningModes.single && dialogDefinition?.process === processTypes.normal) ||
    
    (dialogDefinition?.process === processTypes.multiStep && !canDefinePartiesToSign);

    const digitalSigningAllowDirectSigning = dialogDefinition?.process === processTypes.normal ? dialogDefinition?.digitalSigningAllowDirectSigning : dialogDefinition?.lastPartyToSignFromSmartform;

    const shouldOpenDigitalSigning = dialogDefinition?.hasDigitalSigning && (!step || step?.isLastStep);

    return {
        isSigned: isSigned,
        isPendingSignature: isPendingSignature,
        documentSignatories: documentSignatories,
        isStepPendingSignature: isStepPendingSignature,
        isNormalPendingSignature:isNormalPendingSignature,
        canSignNow: canSignNow,
        canDefinePartiesToSign: canDefinePartiesToSign,
        isSingleMode: isSingleMode,
        digitalSigningAllowDirectSigning: digitalSigningAllowDirectSigning,
        shouldOpenDigitalSigning: shouldOpenDigitalSigning
    }
}

export default useDigitalSigningHelper;