export const CustomErrorMessage = ({ byRequired, onChange, value }) => {

  const name = `customErrorMessage.${byRequired ? "byRequired" : "byValidation"}`
  const label = `Custom ${byRequired ? "required" : "validation"} error message`

  return (
    <div className={`px-4 sm:px-6 pt-3 flex flex-col justify-between`}>
      <span className="flex-grow flex flex-col justify-center" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={"text"}
          name={name}
          className={`focus:ring-gray-400 focus:border-gray-400 block sm:text-sm border-gray-300 rounded-md w-full`}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  )
}