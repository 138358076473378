import { ErrorMessage } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { forwardRef } from "react"
import { PhoneInputConfig } from "../../../utils/config";
import { allowedPrefixes } from '../../../utils/constants';

const CustomPhoneInput = forwardRef(({ value, onChange, ...rest }, ref) => {

  // Format phone number like +xx xxx xx xxx
  const formatPhoneNumber = (input) => {
    const shouldFormat = allowedPrefixes.some(prefix => value.startsWith(prefix))
    if(!shouldFormat) return input
    if (!input) return ''
    const digits = input.replace(/\D/g, '')
    return `+${digits.slice(0, 2)} ${digits.slice(2, 5)} ${digits.slice(5, 7)} ${digits.slice(7, 10)}`
  };
  
  return (
    <input
      value={formatPhoneNumber(value)}
      onChange={(e) => {
        const unformattedValue = e.target.value.replace(/\D/g, '')
        onChange(unformattedValue)
      }}
      ref={ref}
      {...rest}
    />
  );
});


export const FormikPhoneInput = forwardRef((props, ref) => {
  let {
    name,
    label,
    formikProps,
    placeholder = null,
    required = false,
  } = props;

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="p1 pl-2 mt-1 border flex w-full shadow-sm  sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400">
        {
          <PhoneInput
            defaultCountry={PhoneInputConfig.defaulPhoneInputCountry}
            ref={ref}
            name={name}
            international
            placeholder={placeholder}
            onChange={e => formikProps.setFieldValue(name, e)}
            onBlur={formikProps.handleBlur}
            className={`w-72 border-0 sm:text-sm flex w-full`}
            countryCallingCodeEditable={false}
            value={formikProps.values[name]}
            inputComponent={CustomPhoneInput}
          />
        }
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
    </div>
  )
})