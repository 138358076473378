import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from "../../../../components/Modal"
import { FormikTextInput } from "../../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import { Button } from "../../../../components/Form/Button"
import { yupPhoneValidation } from "../../../../utils/validators";
import { FormikPhoneInput } from "../../../../components/Form/Formik/FormikPhoneInput";

export const ContactListContactForm = ({ modalHelper, contactListContact, onSave, isSaving }) => {
  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      title={!contactListContact ? "Create contact" : "Update contact"}
      size={"large"}
    >
      <Formik
        initialValues={contactListContact || {
          name: '',
          address: '',
          city: '',
          postalCode: '',
          country: '',
          department: '',
          phoneNumber: '',
        }}
        validationSchema={() => {
          return yup.object().shape({
            name: yup.string().required("Required"),
            phoneNumber: yupPhoneValidation(yup).required("Required")
          });
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, contactListContact?.id)
          setSubmitting(false);
        }}
      >
        {
          props => (
            <Form>
              <div className="mt-4">
                <FormikTextInput
                  label={"Name"}
                  name={"name"}
                  id="name"
                  required={true}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikPhoneInput
                  label="Phone"
                  name="phoneNumber"
                  required={true}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikTextInput
                  label={"Address"}
                  name={"address"}
                  id="address"
                  required={false}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikTextInput
                  label={"City"}
                  name={"city"}
                  id="city"
                  required={false}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikTextInput
                  label={"Postal code"}
                  name={"postalCode"}
                  id="postalCode"
                  required={false}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikTextInput
                  label={"Country"}
                  name={"country"}
                  id="country"
                  required={false}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikTextInput
                  label={"Department"}
                  name={"department"}
                  id="department"
                  required={false}
                  formikProps={props}
                />
              </div>
              <div className="mt-5 sm:mt-6 flex justify-between">
                <FormikSubmitButton
                  formikProps={props}
                  text="Save"
                  disabled={isSaving}
                />
                <Button
                  onClick={modalHelper.close}
                  text="Close"
                  theme="white"
                  disabled={isSaving}
                />
              </div>

            </Form>
          )
        }
      </Formik>
    </Modal>
  )
}