import { useState } from "react"
import { useParams } from "react-router-dom";
import { getDialogTemplate, updateDialogTemplate } from "../../../../api/dialogTemplates"
import { DialogDesign } from "../../DialogDesign/DialogDesign";

export const SmartformTemplateEdit = () => {
  const { templateId } = useParams()
  const [dialogTemplate, setDialogTemplate] = useState(null)

  const onLoad = async () => {
    const result = await getDialogTemplate(templateId)    
    setDialogTemplate(result)
    return result
  }

  const onSave = async (dialogDefinition, lastUpdatedAt) => {
    if (dialogDefinition?.containers && dialogTemplate) {
      const payload = lastUpdatedAt
        ? { ...dialogDefinition, updatedDate: lastUpdatedAt }
        : { ...dialogDefinition }

      const updateResult = await updateDialogTemplate(templateId, payload);
      setDialogTemplate(payload);
      return updateResult
    }
  }

  return (
    <DialogDesign
      onLoad={onLoad}
      onSave={onSave}
      disableAddObjects={true}
      disableAddContainer={false}
      disableSettings={false}
      disableLayoutSettings={true}
      disablePublish={true}
      disableActive={false}
      disablePreview={true}
      disableExport={true}
      disableDuplicateContainer={false}
      isDialogTemplate={true}
      schemaAditionalProperties
    />
  )
}