import "./styles.scss";
import { useElementDesign } from "../useElementDesign";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";
import { useContext } from "react";
import { DialogModes } from "../../contexts";

export default function Spacer({ data, ...props }) {
    const widthStyle = `${data?.width ? data.width : 5}%`;
    const heightStyle = `${data?.height ? data.height : 5}px`;
    const { isEditMode } = useContext(DialogModes);

    const style = {
        width: widthStyle,
        height: heightStyle,
    };

    const elementDesign = useElementDesign(data.id, data);

    return (
        <div className={`${isEditMode ? "hover:bg-gray-100" : ""} transition-all`}>
            <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
                <div className="bg-transparent" style={style} />
            </InlineActionbarElementWrapper>
        </div>
    );
}