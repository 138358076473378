import { useContext } from "react"
import { FormContext } from "../../contexts"
import React from "react"
import { useClassNames } from "@metaforcelabs/metaforce-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-thin-svg-icons";

export default function SmartformStatusBanner() {
    const {classNames} = useClassNames();
    const formContext = useContext(FormContext)

    return formContext?.isAborted ? (
        <div className="flex space-x-4 sm:space-x-10 bg-yellow-200 py-4 px-4 sm:px-6 rounded-md border border-yellow-600">
            <span className="flex items-center text-yellow-600">
                <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
            </span>
            <div className={classNames("text-yellow-900 text-sm flex items-center")} >
                <div>
                    This document has been aborted
                </div>
            </div>
        </div >
    ) : (<></>)
}