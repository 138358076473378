export const useResourcesHelper = () => {

    const readFileBytes = (blob, resolve) => {
        let reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = function () {
            let arrayBuffer = reader.result
            const base64 = Buffer.from(arrayBuffer).toString('base64');
            resolve(base64);
        }
    }

    const createAddResourceRequestAsync = async (values) => {
        let { file, category, ...request } = values;

        const bytes = await new Promise((resolve, reject) => { readFileBytes(file, resolve) });
        const size = file.size;

        request = { ...request, fileName: file.name, content: bytes, size, category: +category, contentType: file.type }

        return request;
    }

    return {
        createAddResourceRequestAsync: createAddResourceRequestAsync
    }
}