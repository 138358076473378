import { SkeletonLoader } from "../Table";
import './styles.scss';

export default function CardBigSmallLabel({ smallLabel, bigLabel, loading, }) {
  return (
    <div className="px-3 py-4 bg-white shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg card-container">
      <dd className="mt-1 mb-1 text-center text-3xl h-8 font-semibold text-gray-900">
        <SkeletonLoader showOverride={loading} className={'h-6 w-10 bg-gray-200 m-auto mb-2'}>
          {bigLabel}
        </SkeletonLoader>
      </dd>
      <dt className="text-xs font-normal text-gray-500 text-center">{smallLabel}</dt>
    </div>
  )
}