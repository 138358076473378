import { useEffect, useRef } from "react";

/**
 * Wrapper around useEffect to prevent execution if not mounted yet (first load) 
 */
export const useEffectAfterMount = (effect, dependencies) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return effect();
    }
    mounted.current = true;
  }, dependencies);
};
