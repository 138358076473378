import { useContext, useEffect, useRef, useState } from 'react';
import { setProperty } from '../../utils/property';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLabelUpdate } from '../useLabelUpdate';
import { validateDataTypeOptions, validateDataTypes, countriesOptions, dependenciesOptions, alignmentOptions } from "../../utils/constants";
import { getAllCheckboxesAndRadioButtonOptions, getAllCheckboxesOptions } from "../../utils/elements";
import { getWorkflowResources } from '../../api/workflowDefinition';
import { DesignActionbarContext } from '../../contexts';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  const requiredFieldRef = useRef()
  const readOnlyRef = useRef()
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const [workflowResourceOptions, setWorkflowResourceOptions] = useState([]);
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const designActionbarContext = useContext(DesignActionbarContext);
  const {apiDefinitionOptions} = designActionbarContext || {}

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  useEffect(() => {
    loadWorkflowResources();
  }, [])

  const loadWorkflowResources = async () => {

    const workflowResources = await getWorkflowResources();
    // console.log(workflowResources);

    setWorkflowResourceOptions([{ value: "", name: "None" }, ...workflowResources.map(({ id, name }) => ({ value: id, name }))])

  }

  let checkboxOptions = getAllCheckboxesOptions(dialogDefinition.containers);
  let radioButtonGroupAndCheckboxOptions = getAllCheckboxesAndRadioButtonOptions(dialogDefinition.containers);

  return (
    <div className='popover-container-items'>
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Input line"
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Placeholder'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Initial value"}
          name={"initialValue"}
          value={data?.initialValue}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

      </Property.Group>

      <Property.Group title="Formatting">

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={"Validate data"}
          name={"validateData"}
          value={data?.validateData}
          options={validateDataTypeOptions}
        />

        {data?.validateData === validateDataTypes.text &&
          <>
            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Max number of characters'}
              name={'maxLength'}
              value={data?.maxLength}
              allowEmptyNumber
            />

            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Min number of characters'}
              name={'minLength'}
              value={data?.minLength}
              allowEmptyNumber
            />
          </>
        }

        {data?.validateData === validateDataTypes.number &&
          <>
            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Max value'}
              name={'maxValue'}
              value={data?.maxValue}
              minNumber={isNaN(data?.minValue) ? 0 : data?.minValue + 1}
              allowEmptyNumber
            />

            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Min value'}
              name={'minValue'}
              value={data?.minValue}
              maxNumber={isNaN(data?.maxValue) ? 99999999999 : data?.maxValue - 1}
              allowEmptyNumber
            />
          </>
        }

        {data?.validateData === validateDataTypes.SSN &&
          <>
            <Property.Select
              onChange={(e) => handleOnChange(e, 'number')}
              label={"Country"}
              name={"country"}
              value={data?.country}
              options={countriesOptions}
            />
          </>
        }

        {data?.validateData === validateDataTypes.Api &&
          <>
            <Property.Select
              onChange={handleOnChange}
              label={"Api Validation"}
              name={"apiValidationId"}
              value={data?.apiValidationId}
              options={apiDefinitionOptions}
            />
          </>
        }
        <Property.CustomErrorMessage onChange={handleOnChange} value={data?.customErrorMessage?.byValidation} />

        <Property.RequiredWithCustomMessage
          onChange={handleOnChange}
          data={data}
          ref={requiredFieldRef}
          dependency={readOnlyRef}
        />
        
        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
          ref={readOnlyRef}
          dependency={requiredFieldRef}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Prevent data insert"}
          name={"preventDataInsert"}
          value={data?.preventDataInsert}
        />
      </Property.Group>


      <Property.Group title="Element Style">
        <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data?.backgroundColor}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Label top"}
          name={"labelTop"}
          value={data?.labelTop}
          disabled={data?.hideLabel}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide label"}
          name={"hideLabel"}
          value={data?.hideLabel}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Rounded Corners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

      </Property.Group>

    {/* <Property.Group title='Connect element'>

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Type'}
          name={'connectedElement.type'}
          value={data?.connectedElement?.type}
          options={dependenciesOptions}
        />

        {data?.connectedElement?.type === 1 &&
          <Property.Select
            onChange={handleOnChange}
            label={'Element list'}
            name={'connectedElement.value'}
            value={data?.connectedElement?.value}
            options={radioButtonGroupAndCheckboxOptions}
          />
        }
      </Property.Group> */}
      

      <Property.Group title='Dependencies'>

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Type'}
          name={'dependencies.type'}
          value={data?.dependencies?.type}
          options={dependenciesOptions}
        />

        {data?.dependencies?.type === 1 &&
          <Property.Select
            onChange={handleOnChange}
            label={'Checkbox'}
            name={'dependencies.value'}
            value={data?.dependencies?.value}
            options={checkboxOptions}
          />
        }

      </Property.Group>

      <Property.Group title='Api Retrieve'>

        <Property.Select
          onChange={handleOnChange}
          label={"Api Retrieve"}
          name={"apiRetrieveId"}
          value={data?.apiRetrieveId}
          options={apiDefinitionOptions}
        />

        <Property.Select
          onChange={handleOnChange}
          label={"Workflow Retrieve"}
          name={"workflowRetrieveId"}
          value={data?.workflowRetrieveId}
          options={workflowResourceOptions}
        />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Subgroup
          title="Label"
        >

          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color || '#000'}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'labelStyle.width'}
            type={'number'}
            value={data?.labelStyle?.width}
          />

          <Property.Select
            onChange={(e) => handleOnChange(e)}
            label={"Alignment"}
            name={"labelStyle.alignment"}
            value={data?.labelStyle?.alignment}
            options={alignmentOptions}
          />

          <Property.FontStyleAndWeight
            onChange={handleOnChange}
            name={"labelStyle"}
            property={data?.labelStyle}
          />
          
        </Property.Subgroup>      

        <Property.Subgroup title="Input">

          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'text.color'}
            initColor={data?.text.color}
          />
 
          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'text.size'}
            type={'number'}
            value={data?.text?.size}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'width'}
            type={'number'}
            value={data?.width}
          />

          <Property.Select
            onChange={(e) => handleOnChange(e)}
            label={"Alignment"}
            name={"text.alignment"}
            value={data?.text?.alignment}
            options={alignmentOptions}
          />

          <Property.FontStyleAndWeight
            onChange={handleOnChange}
            name={"text"}
            property={data?.text}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Suffix'}
            name={'trailingText'}
            value={data?.trailingText}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Prefix'}
            name={'leadingText'}
            value={data?.leadingText}
          />

        </Property.Subgroup>
      </Property.Group>
    </div>
  )
}
