import { post, get, destroy, put, postAndValidateSuccess } from './api'

export const createApiDefinition = async (props) => {
  return await post('apidefinitions', props);
}

export const updateApiDefinition = async (id, props) => {
  return await put(`apidefinitions/${id}`, props);
}

export const getAllApiDefinitions = async () => {
  return await get('apidefinitions' );
}

export const deleteApiDefinitions = async (id) => {
  return await destroy(`apidefinitions/${id}`);
}

export const validateValues = async (id, values) => {
  return await post(`apidefinitions/${id}/validate`, values);
}

export const getInformation = async (id, values) => {
  return await post(`apidefinitions/${id}/retrieve`, values);
}

export const getInformationUnformatted = async (id, values) => {
  return await post(`apidefinitions/${id}/retrieve/unformatted`, values);
}

export const getInformationWorkflow = async (dialogDefinitionId, dialogValuesId, workflowId, values) => {
  return await post(`apidefinitions/${dialogDefinitionId}/${dialogValuesId}/${workflowId}/workflow/retrieve`, values);
}