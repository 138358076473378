import _ from 'lodash';

export const useEllipsis = () => {
  return (text, maxLength) => {
    if ((!text || !maxLength) || text.length <= maxLength) {
      return text
    }
    return (
      <span title={text}>
        {_.truncate(text, {
          length: maxLength
        })}
      </span>
    )
  }
}