import { smartFormEnvironmentNames, smartFormEnvironments } from "../../utils/constants";
import React from "react";
import { Badge } from "../Badge";

export const SmartFormEnvironmentBadge = ({
    environment = null,
    isLatest = false
}) => {

    const envName = smartFormEnvironmentNames[environment];
    const determineBadgeType = () => {
        if (isLatest) {
            switch (environment) {
                case smartFormEnvironments.development:
                    return "info-alt";
                case smartFormEnvironments.test:
                    return "info";
                case smartFormEnvironments.preProd:
                    return "warn";
                case smartFormEnvironments.production:
                    return "success";
                default:
                    return "default"
            }
        }
        return "default";
    }

    return (
        <>
            <Badge type={determineBadgeType()} text={envName} />
        </>
    );
}