import axios from 'axios'
import { config } from '../utils/config'
import { apiErrorHandler } from './apiErrorHandler'
import { saveAs } from 'file-saver';
import { loadProgressBar } from 'axios-progress-bar'

const axiosWithProgress = axios.create()
const axiosWithoutProgress = axios.create()

loadProgressBar({}, axiosWithProgress)


/**
 * Custom handler for status code
 * @callback StatusCodeHandler
 */

/**
 * @typedef {Object} StatusCodeHandlers
 * @prop {number} statusCode
 * @prop {StatusCodeHandler} handler
 */

/**
 * @typedef {Object} ApiOptions
 * @prop {Ojbect} axiosParams - see axios documentation for possible values
 * @prop {StatusCodeHandlers[]} statusCodeHandlers
 * @prop {boolean} hideProgressBar
 */

export function setAuthHeader(token) {
  axiosWithProgress.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
  axiosWithoutProgress.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export const setHeader = (header, value) => {
  axiosWithProgress.defaults.headers.common[header] = value
  axiosWithoutProgress.defaults.headers.common[header] = value
}

export const BaseUrl = {
  Api: config.apiBaseUrl,
  Identity: config.identityBaseUrl,
  Centerpoint: config.centerpointBaseUrl,
  WebEditor: config.webEditorBaseUrl,
  Archive: config.archiveBaseUrl,
  WorkflowApi: config.workflowApiUrl,
  InteractAdmin: config.interactAdminBaseUrl,
}

/**
 * @param {string} path 
 * @param {string} baseUrl 
 * @param {ApiOptions} options  
 */
export const get = async (path, baseUrl = BaseUrl.Api, options = null) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "get",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers, options?.ignore404)
  }
}

/** 
 * @param {string} path 
 * @param {string} filename 
 * @param {string} baseUrl 
 * @param {ApiOptions} options 
 */
export const download = async (path, filename = "smartform.pdf", baseUrl = BaseUrl.Api, options = null) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "get",
      responseType: 'blob', // important
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })

    saveAs(result.data, filename);
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * @param {string} path 
 * @param {object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const put = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "put",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * @param {string} path 
 * @param {Object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const post = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "post",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers,options?.ignore404)
  }
}

/**
 * @param {string} path 
 * @param {Object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const postAndValidateSuccess = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "post",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.status === 200
  }
  catch (err) {
    return false
  }
}

/** 
 * @param {string} path 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const destroy = async (path, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "delete",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/** 
 * @param {string} path 
 * @param {object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const destroyWithHttpBody = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "delete",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * @param {string} path 
 * @param {object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const patch = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  const patchData = Object.keys(data).map(key => {
    let value = `${data[key] == null ? null : data[key]}`;

    if (Array.isArray(data[key])) {
      value = data[key]
    }

    return {
      value: value,
      path: `/${key}`,
      op: "replace",
    }
  })
  try {
    const result = await axiosInstance(options?.hideProgressBar)({
      method: "patch",
      url: `${baseUrl}/${path}`,
      data: {
        operations: patchData
      },
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * Converts an object into FormData so that a file can uploaded on the HTTP call 
 * @returns FormDatda
 */
export const objectToFormData = (object) => {
  const formData = new FormData();
  const keys = Object.keys(object)
  for (const key of keys) {
    formData.append(key, object[key])
  }
  return formData;
}

const axiosInstance = (hideProgressBar) => {
  if (hideProgressBar === true) {
    return axiosWithoutProgress
  }
  return axiosWithProgress
}