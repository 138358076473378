import _ from "lodash";
import React, { useEffect, useState } from "react"
import * as Structures from '../../../../structures';
import { useToastAction } from "../../../../hooks/useToastAction";
import { convertQuickDesignToStandard } from "../../../../api/quickDesign";
import { ConvertToStandardDesignAlerModal } from "./convertToStandardDesignAlerModal";
import { ensureUniquePropertyNamesForAllElements, getAllElements } from '../../../../utils/dialogDefinitions';

const removeElements = (objects, removedElementIds) => {
    const clonedObjects = _.cloneDeep(objects);
    clonedObjects.forEach(obj => {
        obj?.rows.forEach(row => {
            row?.columns.forEach(column => {
                removedElementIds.forEach(id => {
                    let elementIdx = column.elements?.findIndex(e => e.id === id);
                    if (elementIdx !== -1) {
                        column?.elements.splice(elementIdx, 1);
                    }
                });
            });
            row.columns = row?.columns.filter(col => (col.elements?.length || 0) > 0);
        });
        obj.rows = obj?.rows.filter(row => (row.columns?.length || 0) > 0);
    })
    return clonedObjects;
}

export const PreviewQuickDesignStep = ({ quickDesignDefinition, dialogDefinition, onLoad }) => {
    const [generatedSmartForm, setGeneratedSmartForm] = useState(null)
    const [showAlertModal, setShowAlertModal] = useState(false);
    const convertAction = useToastAction();

    const generateSmartForm = () => {
        const base = _.cloneDeep(dialogDefinition);
        const updatedObjects = removeElements(quickDesignDefinition.selectedObjects, quickDesignDefinition.removedElementIds);
        base.containers = updatedObjects;
        base.backgroundColor = quickDesignDefinition.backGroundColor;

        const allElements = getAllElements(base)
        ensureUniquePropertyNamesForAllElements(allElements);
        setGeneratedSmartForm(base);
    }

    const handleConfirmConvertToStandardDesign = () => {
        convertAction.execute(async () => {
            await convertQuickDesignToStandard(generatedSmartForm);
            setShowAlertModal(false);
            onLoad()
        })
    }

    useEffect(() => {
        generateSmartForm();
    }, [])

    return (<>
        <div className="space-y-4">
            <div className='flex justify-between  border-b  pb-4 mb-2'>
                <div>
                    <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">Preview</h1>
                    <p className="mb-2 max-w-4xl text-sm text-gray-500">
                        Review your work
                    </p>
                </div>
                <div className='hidden sm:block'>
                    <button
                        type="button"
                        id={"next-btn"}
                        onClick={e => {
                            // handleNextClick();
                            setShowAlertModal(true);
                        }}
                        className={`w-1/2 sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                    >
                        <span>Convert to standard</span>
                    </button>
                </div>
            </div>
            <div>
                {
                    generatedSmartForm ? (
                        <Structures.Dialog data={generatedSmartForm} isPreviewMode={true} />
                    ) : (<div>Generating...</div>)
                }
            </div>
        </div>
        <ConvertToStandardDesignAlerModal open={showAlertModal} setOpen={setShowAlertModal} onConfirm={handleConfirmConvertToStandardDesign} />
    </>
    )
}