import { destroy, get, objectToFormData, post, put } from './api'

const controllerName = "authenticationTemplates"

export const getAuthenticationTemplates = async () => {
  return await get(`${controllerName}`);
}

export const getActiveAuthenticationTemplates = async () => {
  return await get(`${controllerName}/active`);
}

export const getAuthenticationTemplate = async (templateId) => {
  return await get(`${controllerName}/${templateId}`);
}

export const updateAuthenticationTemplate = async (templateId, template) => {
  const {
    logo,
    backgroundImage,
    ...payload
  } = template
  const updateResult = await put(`${controllerName}/${templateId}`, payload);

  // file properties will have size if user set the file
  if (logo?.size || logo === "delete") {
    await put(`${controllerName}/${templateId}/logo`, objectToFormData({
      file: logo
    }));
  }
  if (backgroundImage?.size || backgroundImage === "delete") {
    await put(`${controllerName}/${templateId}/backgroundImage`, objectToFormData({
      file: backgroundImage
    }));
  }
  return {
    ...updateResult,
    backgroundImage,
    logo,
  }
}

export const createAuthenticationTemplate = async (name) => {
  return await post(`${controllerName}`, { name });
}

export const activateAuthenticationTemplate = async (templateId) => {
  return await post(`${controllerName}/activate`, { templateId })
}

export const disableAuthenticationTemplate = async (templateId) => {
  return await post(`${controllerName}/deactivate`, { templateId })
}

export const destroyAuthenticationTemplate = async (templateId) => {
  return await destroy(`${controllerName}/${templateId}`);
}