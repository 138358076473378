import { elementTypes } from "../../../utils/constants";
import { CheckIcon, XIcon } from "@heroicons/react/outline"

const LogicMatchSelect = ({ onChange, value, options }) => <select
    onChange={onChange}
    name="match"
    className="focus:ring-gray-400 focus:border-gray-400 border-gray-400 pr-6 bg-white border rounded-md m-1 px-1 py-px font-semibold text-xs"
    defaultChecked={value}
    defaultValue={value}
>
    {options?.map((option, index) => <option key={index} value={option.value}>{option.value}</option>)}
</select>

const LogicMatchCheckbox = ({ onChange, value, options }) => {
    const handleChange = (e) => {
        const formatedEvent = { ...e, target: { ...e.target, name: "match", value: e.target.checked ? "true" : "false" } }
        onChange(formatedEvent)
    }

    return (
        <input
            type="checkbox"
            onChange={handleChange}
            defaultChecked={value === "true"}
            className="focus:ring-gray-400 focus:border-gray-400 border-gray-400 bg-white border rounded-md m-1 px-1 py-px font-semibold"
        />
    )
}

const MatchInputWithElementTypeValidation = ({ newCondition, onChange, groupedElementsOfContainer }) => {
    const elementType = newCondition?.element?.type;
    switch (elementType) {
        case elementTypes.inputTextLine:
            return (
                <div className="p-1 text-sm bg-brand-background flex-nowrap w-full">
                    <input
                        name={'match'}
                        onChange={onChange}
                        className="focus:ring-gray-400 w-full focus:border-gray-400 border-gray-400 pr-6 bg-white borde text-gray-800 sm:text-sm rounded-md"
                        defaultValue={newCondition.condition.match}
                    />
                </div>
            )
        case elementTypes.dropDown:
            return <LogicMatchSelect onChange={onChange} value={newCondition?.condition.match} options={newCondition.element.optionWithValues} />
        case elementTypes.radioList:
            return <LogicMatchSelect onChange={onChange} value={newCondition?.condition.match} options={newCondition.element.optionWithValues} />
        case elementTypes.radioButtonGroup:
            const radioGroupOptions = groupedElementsOfContainer.find(x => x.rowId === newCondition.element.rowId)
            return <LogicMatchSelect onChange={onChange} value={newCondition?.condition.match} options={radioGroupOptions?.elements} />
        case elementTypes.checkbox:
            return <LogicMatchCheckbox onChange={onChange} value={newCondition?.condition.match} />

        default:
            return <div className="text-sm">The selected element cannot be conditioned.</div>
    }
}

export const InputWirthElementValidationAndConfirmCancelButton = ({ newCondition, onChange, groupedElementsOfContainer, onCancelClick, onConfirmClick }) => {
    return (
        <div className="flex w-full">
            <MatchInputWithElementTypeValidation newCondition={newCondition} onChange={onChange} groupedElementsOfContainer={groupedElementsOfContainer} />
            <div className="flex">
                <CheckIcon
                    className={`w-5 cursor-pointer ${!newCondition?.condition.match
                        ? "bg-gray-300"
                        : "bg-yellow-400"
                        } ml-1`}
                    onClick={(e) =>
                        onConfirmClick(e, newCondition)
                    }
                />
                <XIcon
                    className="w-5 cursor-pointer bg-yellow-400 ml-1"
                    onClick={() => onCancelClick()}
                />
            </div>
        </div>
    )
}



