import { get } from '../api'
import { config } from './publicConfig';

/**
 * Fetches the draft mode of the specified dialog definition. 
 * Falls back to the currently published record if there is no draft recrord 
 */
export const getDialogDefinitionEditMode = async (id) => {
  return await get(`${config.basePath}/dialogDefinitions/${id}/editMode`);
}

/**
 * Fetches the currently published dialog definition 
 */
export const getDialogDefinition = async (id) => {
  return await get(`${config.basePath}/dialogDefinitions/${id}`);
}

export const getDialogDefinitionAndSkipGenerateIdInLockedObjects = async (id, environment) => {
  const basePath = environment ? `${config.basePath}/${environment}` : config.basePath;
  return await get(`${basePath}/dialogDefinitions/${id}/getDialogDefinitionSkipGenerateIdLockedObjects`);
}

export const getDialogDefinitionProcess = async (id) => {
  return await get(`${config.basePath}/dialogDefinitions/${id}/process`);
}