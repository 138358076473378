import { useEffect, useState } from "react"
import { getDialogObjectSchema } from "../api/dialogObjects"

const validate = require('jsonschema').validate;

/**
 * Validate a dialog object against the server side schema 
 */
export const useDialogObjectSchemaValidation = () => {
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const definitionSchema = await getDialogObjectSchema();
    setSchema(definitionSchema);
  }

  return {
    validate: (dialogObject) => {
      const result = validate(dialogObject, schema);
      return result.errors;
    }
  }
}