import { get, put, post, destroy, patch } from './api'


export const getDialogDefinitions = async ({ folderId, inTrash } = {}) => {
  const parameters = inTrash ? "?inTrash=true" : `${folderId ? `?folderId=${folderId}` : ""}`;
  return await get(`dialogDefinitions/list${parameters}`,);
}

export const getActiveDialogDefinitions = async () => {
  return await get('dialogDefinitions/active/list');
}

export const getDialogDefinitionsOnCompany = async (companyId) => {
  return await get(`dialogDefinitions/company/${companyId}`);
}

/**
 * Fetches the currently published dialog definition 
 */
export const getDialogDefinition = async (id) => {
  return await get(`dialogDefinitions/${id}`);
}

export const getDialogDefinitionForExport = async (id) => {
  return await get(`dialogDefinitions/${id}/getForExport`);
}

/**
 * Fetches the draft mode of the specified dialog definition. 
 * Falls back to the currently published record if there is no draft recrord 
 */
export const getDialogDefinitionEditMode = async (id) => {
  return await get(`dialogDefinitions/${id}/editMode`);
}

export const getDialogDefinitionSchema = async () => {
  return await get('dialogDefinitions/schema');
}

export const getDialogDefinitionElements = async (id) => {
  return await get(`dialogDefinitions/${id}/elements`);
}

export const getDialogDefinitionContainersWithElements = async (id) => {
  return await get(`dialogDefinitions/${id}/containersWithelements`);
}

export const getDialogDefinitionVersions = async (id) => {
  return await get(`dialogDefinitions/${id}/versions`);
}

export const getDialogDefinitionVersion = async (versionId) => {
  return await get(`dialogDefinitions/versions/${versionId}`);
}

export const getDefaultDialogDefinition = async () => {
  return await get(`dialogDefinitions/default`);
}

export const rollbackDialogDefinition = async (id, versionId, replaceDraft) => {
  return await post(`dialogDefinitions/${id}/versions/${versionId}/rollback`, {
    replaceDraft,
  });
}

/**
 * Updates an entire DialogDefinition
 * @param {json object} dialogDefinition - full json representation of a dialog defintion 
 */
export const updateDialogDefinition = async (dialogDefinition) => {
  return await put('dialogDefinitions', dialogDefinition);
}

/**
 * Updates some properties of a DialogDefinition
 * @param {*} id 
 * @param {*} dialogDefinition  
 */
export const updateDialogDefinitionProperties = async (id, dialogDefinition) => {
  return await put(`dialogDefinitions/${id}`, dialogDefinition)
}

export const patchDialogDefinition = async (id, props) => {
  return await patch(`dialogDefinitions/${id}`, props);
}

export const updateDialogDefinitionProcess = async (id, process) => {
  return await put(`dialogDefinitions/${id}/process`, { process })
}

export const duplicateDialogDefinition = async (id) => {
  return await post(`dialogDefinitions/${id}/duplicate`);
}

export const createDefaultDialogDefinition = async (name) => {
  return await post(`dialogDefinitions/default`, { name });
}

export const createManyDialogDefinition = async (number) => {
  return await post(`dialogDefinitions/createMany/${number}`);
}

export const createDialogDefinitionFromTemplate = async (template, folderId = null) => {
  return await post(`dialogDefinitions/template${folderId ? `?folderId=${folderId}` : ""}`, template);
}

export const publishDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/publish`)
}

export const activateDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/activate`)
}

export const deactivateDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/deactivate`)
}

export const searchDialogDefinitions = async (tag) => {
  return await get(`dialogDefinitions/search?tag=${tag}`)
}

export const deleteDialogDefinition = async (id) => {
  return await destroy(`dialogDefinitions/${id}`)
}

// TODO Move dialogDefinitionFolders calls to their own api file
export const getDialogDefinitionFolders = async () => {
  return await get(`dialogDefinitionFolders`)
}

export const createDialogDefinitionFolder = async (dialogDefinitionFolder) => {
  return await post(`dialogDefinitionFolders`, dialogDefinitionFolder);
}

export const deleteDialogDefinitionFolder = async (dialogDefinitionFolder) => {
  return await destroy(`dialogDefinitionFolders/${dialogDefinitionFolder}`)
}

/**  Obsolete. Use moveDialogDefinitionToFolder. Delete this after META-1681 is deployed, and all related backend code */
export const addFolderToDialogDefinition = async (folderToDialogDefinition) => {
  return await post(`dialogDefinitions/addFolderToDialogDefinition`, folderToDialogDefinition);
}

export const moveDialogDefinitionToFolder = async (dialogDefinitionId, folderId) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/folder`, { folderId })
}

export const promoteDialogDefinitionVersion = async (versionId) => {
  return await put(`dialogDefinitions/versions/${versionId}/promote`);
}

export const getEditableElementsProperty = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/editableElementsProperty`)
}