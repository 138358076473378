export const Badge = ({
  type = "success", // 'success', 'error', 'warn'
  text,
  className = "",
}) => {

  const colorClasses = () => {
    switch (type) {
      case "success":
        return "bg-green-200 text-green-800";
      case "error":
        return 'bg-red-200 text-red-800';
      case "warn":
        return "bg-yellow-200 text-yellow-800";
      case "info":
        return "bg-blue-200 text-blue-800";
      case "info-alt":
        return "bg-purple-300 text-purple-800";
      case "default":
      default:
        return "bg-gray-100 text-gray-800";
    }
  }


  return (
    <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${colorClasses()} ${className}`}>
      {text}
    </span>
  );
}