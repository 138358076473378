import { useState, useEffect, useContext } from 'react';
import {
  duplicateDialogDefinition,
  activateDialogDefinition,
  deactivateDialogDefinition,
  deleteDialogDefinition,
  getDialogDefinitionFolders,
  patchDialogDefinition,
  getDialogDefinitionForExport,
} from '../../../api/dialogDefinition';
import './styles.scss';
import { useToastAction } from '../../../hooks/useToastAction';
import { SearchIcon } from '@heroicons/react/outline';
import ImportDialog from '../importDialog';
import { useNewIdGenerator } from '../DialogDesign/useNewIdGenerator';
import { FolderContext, OidcRoutesContext } from '../../../contexts';
import TemplateDialog from '../templateGallery';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../../utils/constants';
import { useFeature } from '../../../hooks/useFeature';
import TableBody from './tableBody';
import FoldersSidebar from './foldersSidebar';
import { isTrashFolder, systemFolderIds } from '../../../utils/folders';
import { useServerSideTableActions } from '../../../hooks/useServerSideTableActions';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';
import { TableServerSidePagination } from '../../../components/TableServerSidePagination/index';
import { ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core';


export default function DialogSmartForm() {
  const feature = useFeature();
  const searchDebounce = useSearchDebounce()
  const [folders, setFolders] = useState(null)
  const duplicateDefinitionAction = useToastAction();
  const activateDefinitionAction = useToastAction(true);
  const deactivateDefinitionAction = useToastAction();
  const deleteDefinitionAction = useToastAction();
  const renameDefinitionAction = useToastAction();

  const [showTemplateDialog, setShowTemplateDialog] = useState(false)
  const [showImportDialog, setShowImportDialog] = useState(false)
  const navigate = useNavigate()
  const [onlyCurrentUserDialogs, setOnlyCurrentUserDialog] = useState(navigate?.location?.state?.onlyUser)
  const serverSideTableActions = useServerSideTableActions('dialogDefinitions/paged', {
    pageSize: 10,
    descending: true,
    sortBy: "updatedDate",
  })

  const idGenerator = useNewIdGenerator();
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const currentUserId = oidcRoutesContext.userProfile?.userId
  const dummyLoadData = [{}, {}, {}, {}, {}];
  const homeFolder = { index: 0, folderId: systemFolderIds.home }
  const { currentFolder } = useContext(FolderContext)
  const [selectedFolder, setSelectedFolder] = useState(currentFolder)

  useEffect(() => {
    loadFolders()
  }, [])

  const loadFolders = async () => {
    const foldersResult = await getDialogDefinitionFolders();
    setFolders(foldersResult)
  }

  const onFolderDeleted = async () => {
    await loadFolders()
    setSelectedFolder(homeFolder)
  }

  const onSelectFolderClick = ({ folderId, index }) => {
    setSelectedFolder({ folderId, index })
  }

  const onMoveToFolderSuccess = () => {
    serverSideTableActions.search();
  }

  useEffect(() => {
    const params = isTrashFolder(selectedFolder.folderId)
      ? { inTrash: true }
      : { folderId: selectedFolder.folderId }

    if (onlyCurrentUserDialogs) {
      params.createdOrUpdatedBy = currentUserId
    }

    if (searchDebounce.debouncedSearchTerm && searchDebounce.debouncedSearchTerm !== '') {
      params.search = searchDebounce.debouncedSearchTerm
    }

    serverSideTableActions.setQueryStringParams(params)
  }, [selectedFolder, onlyCurrentUserDialogs, searchDebounce.debouncedSearchTerm])

  const handleSearch = (e) => {
    searchDebounce.setSearchTerm(e.target.value)
  }

  const onDuplicate = async (dialogDefinitionId) => {
    duplicateDefinitionAction.execute(async () => {
      await duplicateDialogDefinition(dialogDefinitionId);
      serverSideTableActions.search();
    }, "Failed to duplicate", "Duplicated")
  }

  const onHandleExport = async (dialogDefinitionId) => {
    let dialogDefinitionToExport = await getDialogDefinitionForExport(dialogDefinitionId)
    dialogDefinitionToExport.dialogValueIds = [];

    if (dialogDefinitionToExport) {
      delete dialogDefinitionToExport.id
      delete dialogDefinitionToExport.dialogValueIds
      delete dialogDefinitionToExport.createdDate
      delete dialogDefinitionToExport.editState
      delete dialogDefinitionToExport.metaData
      delete dialogDefinitionToExport.status
      delete dialogDefinitionToExport.updatedDate

      idGenerator.newIdsOnDialog(dialogDefinitionToExport);
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(dialogDefinitionToExport)], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `${dialogDefinitionToExport.name}.json`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  const onActivate = async (dialogDefinitionId) => {
    activateDefinitionAction.execute(async () => {
      await activateDialogDefinition(dialogDefinitionId);
      serverSideTableActions.search();
    }, "Failed to activate", "Activated")
  }

  const onDeactivate = async (dialogDefinitionId) => {
    deactivateDefinitionAction.execute(async () => {
      await deactivateDialogDefinition(dialogDefinitionId);
      serverSideTableActions.search();
    }, "Failed to deactivate", "Deactivated")
  }

  const onDelete = async (dialogDefinitionId) => {
    deleteDefinitionAction.execute(async () => {
      await deleteDialogDefinition(dialogDefinitionId);
      serverSideTableActions.search();
    }, 'Failed to delete', 'Deleted')
  }

  const onRenameSmartForm = async (dialogDefinitionId, name) => {
    renameDefinitionAction.execute(async () => {
      await patchDialogDefinition(dialogDefinitionId, { name })
      serverSideTableActions.search();
    }, 'Failed to rename', 'Renamed')
  }

  return (
    <>
      <div className="relative mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
        <h1 className="text-3xl font-bold">All SmartForms</h1>
        <div className='flex justify-end mt-5 pb-5'>
          <div className="w-72">
            <label htmlFor="desktop-search-candidate" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="desktop-search-candidate"
                  id="desktop-search-candidate"
                  className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Search..."
                  onChange={handleSearch}
                  value={searchDebounce.searchTerm}
                />
              </div>
            </div>
          </div>
          <div className="">
            {
              feature.hasFeature(Constants.designFeatureCode) &&
              <div className='ml-10 space-x-2'>
                <ButtonV2
                  type={CoreButtonTypes.white}
                  label={'Import SmartForm'}
                  onClick={() => setShowImportDialog(true)}
                />
                <ButtonV2
                  type={CoreButtonTypes.cta}
                  label={'Create form'}
                  onClick={() => setShowTemplateDialog(true)}
                />
              </div>
            }
          </div>
        </div>

      </div>
      {
        feature.hasFeature(Constants.designFeatureCode) &&
        <div className="flex justify-end mt-5 pb-5">
          <div className="mt-2 flex items-center">
            <input
              checked={onlyCurrentUserDialogs}
              onChange={() => setOnlyCurrentUserDialog(!onlyCurrentUserDialogs)}
              type="checkbox"
              className="focus:ring-0 h-4 w-4 text-brand-pink border-gray-300 rounded"
            />
            <span className="pl-1 text-sm font-medium text-gray-500">Show only SmartForms created by me</span>
          </div>
        </div>
      }
      <div className='flex gap-6'>
        <FoldersSidebar
          folders={folders}
          selectedFolder={selectedFolder}
          setSelectedFolder={onSelectFolderClick}
          onFolderCreated={loadFolders}
          onFolderDeleted={onFolderDeleted}
        />
        <div className='w-full'>
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          serverSideTableActions.orderBy("metaData.Name")
                        }
                      >
                        Name {serverSideTableActions.getSortIcon("metaData.Name")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => serverSideTableActions.orderBy("metaData.Status")}
                      >
                        Status {serverSideTableActions.getSortIcon("metaData.Status")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          serverSideTableActions.orderBy("metaData.EditState")
                        }
                      >
                        Publish State {serverSideTableActions.getSortIcon("metaData.EditState")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          serverSideTableActions.orderBy("createdDate")
                        }
                      >
                        Created {serverSideTableActions.getSortIcon("createdDate")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          serverSideTableActions.orderBy("updatedDate")
                        }
                      >
                        Updated {serverSideTableActions.getSortIcon("updatedDate")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          serverSideTableActions.orderBy("metaData.Category")
                        }
                      >
                        Category {serverSideTableActions.getSortIcon("metaData.Category")}
                      </th>
                      {
                        feature.hasFeature(Constants.designFeatureCode) &&
                        <th
                          scope="col"
                          className="relative px-6 py-3"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      }
                    </tr>
                  </thead>
                  <TableBody
                    data={serverSideTableActions.isLoaded ? serverSideTableActions?.pagedData : dummyLoadData}
                    feature={feature}
                    onClick={{
                      onDuplicate,
                      onDeactivate,
                      onActivate,
                      onHandleExport,
                      onDelete,
                      onRenameSmartForm
                    }}
                    onMoveToFolderSuccess={onMoveToFolderSuccess}
                    selectedFolder={selectedFolder}
                    definitionAction={{
                      duplicate: duplicateDefinitionAction,
                      deactivate: deactivateDefinitionAction,
                      activate: activateDefinitionAction,
                      rename: renameDefinitionAction
                    }}
                    folders={folders}
                  />
                </table>
                <TableServerSidePagination serverSideTableActions={serverSideTableActions} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TemplateDialog open={showTemplateDialog} dialogs={serverSideTableActions.pagedData || []} setOpen={setShowTemplateDialog} />
      <ImportDialog open={showImportDialog} setOpen={setShowImportDialog} folderId={selectedFolder?.folderId} />
    </>
  );
}
