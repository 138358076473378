import _ from 'lodash';
import React, { useContext, useState } from 'react'
import { QuickDesignContext } from '../../../../contexts';
import { useClassNames } from '../../../../hooks/useClassNames'
import { elementTypes } from '../../../../utils/constants';
import { setProperty } from '../../../../utils/property';
import * as ElementEditors from './ElementEditors'

export default function ModifyObjectDataStep({ onComplete, quickDesignDefinition, save }) {
    const [objectsToEdit, setObjectsToEdit] = useState(quickDesignDefinition.selectedObjects);
    const [activeItem, setActiveItem] = useState(quickDesignDefinition.selectedObjects[0] || null);
    const { classNames } = useClassNames();

    const handleUpdateSuccess = (updatedObject) => {
        setActiveItem(updatedObject);
        const idx = objectsToEdit.findIndex(x => x.id === updatedObject.id);
        if (idx > -1) {
            objectsToEdit.splice(idx, 1, updatedObject);
            setObjectsToEdit(objectsToEdit)
        }

    }

    const handleNextClick = () => {
        onComplete(objectsToEdit);
    }

    const handleResetClick = () => {
        const original = quickDesignDefinition.selectedObjects.find(x => x.id === activeItem.id);
        setActiveItem(original);
        const idx = objectsToEdit.findIndex(x => x.id === original.id);
        if (idx > -1) {
            objectsToEdit.splice(idx, 1, original);
            setObjectsToEdit(prev => ([...objectsToEdit]))
        }
    }

    return (
        <div className='space-y-4'>
            <div className='flex justify-between  border-b  pb-4 mb-2'>
                <div>
                    <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">Data</h1>
                    <p className="mb-2 max-w-4xl text-sm text-gray-500">
                        Modify and and toggle included elements for each selected object
                    </p>
                </div>
                <div className='hidden sm:block'>
                    <button
                        type="button"
                        id={"next-btn"}
                        onClick={e => {
                            handleNextClick();
                        }}
                        className={`w-1/2 sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                    >
                        <span>Next</span>
                    </button>
                </div>
            </div>
            <div className='grid grid-cols-12 sm:space-x-4 space-y-4 sm:space-y-2'>
                <div className="col-span-12 sm:col-span-5">
                    <div className='mb-2'>
                        <label className='font-semibold'>Selected Objects</label>
                    </div>
                    <ul
                        className="border border-gray-200 rounded-md h-80 overflow-y-auto shadow"
                    >
                        {objectsToEdit.map((item, index) => (
                            <li key={item.id}>
                                <div
                                    className={classNames("px-2 sm:px-4 py-2 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md border-b cursor-pointer flex items-center",
                                        activeItem?.id === item.id ? "shadow-inner rounded" : "")}
                                    onClick={e => {
                                        setActiveItem(item);
                                    }}
                                >
                                    <input
                                        id={item.id}
                                        name="notification-method"
                                        type="radio"
                                        onChange={e => { }}
                                        checked={activeItem?.id === item.id}
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label className="ml-3 block text-sm font-medium text-gray-700">
                                        <span>{index + 1}.</span> <span className='font-semibold'>{item.name}</span>
                                    </label>

                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-span-12 sm:col-span-7">
                    {/* <pre>{JSON.stringify(activeItem, null, 2)}</pre> */}
                    <ObjectDataEditor object={activeItem} onSuccess={handleUpdateSuccess} save={save} />
                </div>
            </div>
            <div className='flex justify-end space-x-4 sm:hidden'>
                <button
                    type="button"
                    id={"next-btn"}
                    onClick={e => {
                        handleNextClick();
                    }}
                    className={`w-1/2 sm:w-auto flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                >
                    <span>Next</span>
                </button>
                {/* <button
                    type="button"
                    id={"next-btn"}
                    onClick={e => {
                        handleResetClick();
                    }}
                    className={`w-1/2 sm:w-auto flex items-center w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm`}
                >
                    <span>Reset</span>
                </button> */}
            </div>
        </div>
    )
}

const ElementEditorBase = ({ element, children }) => {

    const { quickDesignDefinition, onRemoveElementToggle } = useContext(QuickDesignContext)

    const isRemoved = () => {
        return quickDesignDefinition.removedElementIds.findIndex(id => id === element.id) === -1
    }

    const handleRemoveClick = () => {
        // onRemove(element)
        onRemoveElementToggle(element.id);
    }

    return (
        <div className='flex space-x-4'>
            <div className='grow-0 flex items-center'>
                {/* <div className="rounded-full shadow-sm border p-1.5 hover:text-red-500 cursor-pointer" title='Remove element' onClick={e => handleRemoveClick()}>
                    <XIcon className='h-4 w-4 font-semibold' />
                </div> */}
                <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    title="Include element"
                    checked={isRemoved()}
                    onChange={e => { }}
                    onClick={e => {
                        // e.preventDefault();
                        handleRemoveClick()
                    }}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
            </div>
            <div className='flex-1 grow min-w-0'>
                {children}
            </div>
        </div>
    )
}

const ElementEditorFromType = ({ element, onRemove, removedElementIds, ...props }) => {

    switch (element.type) {
        case elementTypes.textLine:
            return <ElementEditors.TextValue element={element} {...props} />
        // case elementTypes.hyperLink:
        //     return <Elements.HyperLink data={element} />
        case elementTypes.textArea:
            return <ElementEditors.TextValue data={element} {...props} />
        // case elementTypes.inputTextLine:
        //     return <Elements.InputTextLine data={element} />
        case elementTypes.headerLine:
            return (
                <ElementEditorBase element={element}>
                    <ElementEditors.TextValue element={element} {...props} />
                </ElementEditorBase>
            );
        case elementTypes.bitmatp:
            return <ElementEditorBase element={element}>
                <ElementEditors.Bitmap element={element} {...props} />
            </ElementEditorBase>
        // case elementTypes.video:
        //     return <Elements.Video data={element} />
        // case elementTypes.datePicker:
        //     return <Elements.DatePicker data={element} />
        case elementTypes.button:
            return <ElementEditorBase element={element}>
                <ElementEditors.Button element={element} {...props} />
            </ElementEditorBase>
        // case elementTypes.radioButton:
        //     return <Elements.RadioButton data={element} />
        // case elementTypes.divider:
        //     return <Elements.Divider data={element} />
        // case elementTypes.radioList:
        //     return <Elements.RadioList data={element} />
        // case elementTypes.radioListAsGroup:
        //     return <Elements.RadioListAsGroup data={element} />
        // case elementTypes.fileUpload:
        //     return <Elements.FileUpload data={element} />
        // case elementTypes.radioButtons:
        //     return <Elements.RadioButtons data={element} />
        // case elementTypes.disclaimer:
        //     return <Elements.Disclaimer data={element} />
        // case elementTypes.dropDown:
        //     return <Elements.Dropdown data={element} />
        // case elementTypes.flowSelector:
        //     return <Elements.FlowSelector data={element} />
        // case elementTypes.checkbox:
        //     return <Elements.Checkbox data={element} />
        // case elementTypes.cancelButton:
        //     return <Elements.CancelButton data={element} />
        // case elementTypes.signature:
        //     return <Elements.Signature process={process} data={element} />
        // case elementTypes.timePicker:
        //     return <Elements.TimePicker data={element} />
        // case elementTypes.attachment:
        //     return <Elements.Attachment data={element} />
        // case elementTypes.richText:
        //     return <Elements.RichText data={element} />
        // case elementTypes.dynamicList:
        //     return <Elements.DynamicList data={element}/>
        default:
            // return <div className='mb-1'><label className='font-semibold'>property:</label> {element.property}</div>;
            return <ElementEditorBase element={element}>
                <ElementEditors.Property  element={element} {...props} />
            </ElementEditorBase>;
    }
}

const ObjectDataEditor = ({ object, onSuccess, removedElementIds, save }) => {
    // console.log(JSON.stringify(object))
    const { classNames } = useClassNames();
    if (!object) {
        return <></>;
    }
    const getAllElements = () => {
        return object.rows.flatMap(r => r.columns).flatMap(c => c.elements);
    }

    const updatePropertyValue = ({ name, value }, elementId, type) => {
        // const {name, value} = e.target;
        const payload = _.cloneDeep(object)
        payload?.rows.forEach(row => {
            row?.columns.forEach(column => {
                let element = column?.elements?.find(e => e.id === elementId);

                if (element) {
                    setProperty(element, name, value, type)
                    onSuccess(payload);
                }
            });
        });
        save()
    }

    const handleElementProprtyChange = (target, element2, type) => {
        // console.log("handleElementProprtyChange", { value: target.value, name: target.name });
        updatePropertyValue(target, element2.id, type);
    }

    return (
        <div>
            <div className='mb-2'>
                <label className='font-semibold'>Object Elements</label>
            </div>
            <div className='divide-y divide-solid divide gray-200 space-y-4'>
                {
                    getAllElements().map((el, i) => (
                        <ElementEditorFromType key={el.id} element={el} removedElementIds={removedElementIds} className={classNames(i > 0 ? "pt-2" : "")}
                            onChange={handleElementProprtyChange}/>
                    ))
                }
            </div>
        </div>
    );

}
