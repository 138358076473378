import "./styles.scss";
import { useElementDesign } from "../useElementDesign";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useElementStyle } from "../useElementStyle";
import { useContext } from "react";
import { DialogModes } from "../../contexts";

const defaultVideoData = {
    type: 22,
    property: "Logo",
    value: "no value",
    placeholder: "Metaforce logo",
    layout: {
        order: 5,
    },
    styles: {
        borderWidth: 0,
    },
};

export default function Video({ data, ...props }) {
    const classPrefix = "video";
    data = data || defaultVideoData;
    const { isEditMode } = useContext(DialogModes);
    const elementDesign = useElementDesign(data.id, data);
    const { borderAndWidthStyle, flexAlignmentClass } = useElementStyle(data);

    const videoType = data?.video.type ? data.video.type : 1;
    const roundedCornerClass = data?.roundedCorners ? "rounded-md" : "";
    const alignClass = flexAlignmentClass(data?.text?.alignment)
    const heightStyle = `${data?.height}px`;

    const videoStyle = {
        ...borderAndWidthStyle(),
        height: heightStyle
    }

    const getId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        return (match && match[2].length === 11)
          ? match[2]
          : null;
    }

    const videoComponent = (videoType) => {
        const videoId = getId(data.value);
        if(data?.value.length === 0) {
            return <div 
                        className={`flex flex-col justify-center items-center bg-gray-500 ${roundedCornerClass}`}
                        style={videoStyle}
                    >
                        <InformationCircleIcon height={40} className="mb-4 text-indigo-300"/>
                        <label className="text-white text-sm font-bold">Please enter a url on element configuration</label>
                    </div>
        }

        switch (videoType) {

            //General
            case 1:
                return (
                    <video
                        className={`${elementDesign.elementsClasses()} ${roundedCornerClass}`}
                        alt={`${data.property}`}
                        src={`${data.value}`}
                        style={videoStyle}
                        autoPlay={!isEditMode && data?.video?.autoplay}
                        controls
                        loop={data?.video?.contiousePlay}
                    />
                );

            //Youtube
            case 2:
                return (
                    <iframe
                        className={`${roundedCornerClass} ${elementDesign.elementsClasses()} p-3`}
                        width={data?.width}
                        height={data?.height}
                        title={`${data?.property}`}
                        style={videoStyle}
                        src={`https://www.youtube.com/embed/${videoId}?${!isEditMode && data.video.autoplay ? "autoplay=1&mute=1" : ''}${data?.video?.contiousePlay ? `&loop=1&playlist=${videoId}` : ''}`}
                        allowFullScreen
                    ></iframe>
                );

            //Vimeo
            case 3:
                return (
                    <iframe
                        className={`${roundedCornerClass} ${elementDesign.elementsClasses()} m-6`}
                        src={`https://player.vimeo.com/video/${data.value}?${!isEditMode && data.video.autoplay ? "&autoplay=1" : ''}${data?.video?.contiousePlay ? "&loop=1" : ''}`}
                        width={data?.width}
                        title={`${data?.property}`}
                        style={videoStyle}
                        height={data?.height}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                );

            default:
                return <> </>;
        }
    };

    return (
        <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
            <div className={`flex ${alignClass} ${isEditMode ? 'relative' : ''} print:hidden`}>
                {videoComponent(videoType)}
                { isEditMode && <div className="h-full w-full absolute" onClick={(e) => elementDesign.onClick(e, true)}></div>}
            </div>
        </InlineActionbarElementWrapper>
    );
}
