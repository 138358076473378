import { Button } from "../../../components/Form/Button"
import { CheckCircleIcon } from '@heroicons/react/solid'
import { useState } from "react"
import { useToastAction } from '../../../hooks/useToastAction';
import { rollbackDialogDefinition } from "../../../api/dialogDefinition"

export const Rollback = ({ dialogDefinitionId, versionId, onSuccess, onFinally }) => {
  const [selected, setSelected] = useState()
  const rollbackAction = useToastAction()

  const onSelectionClicked = (value) => {
    setSelected(value)
  }



  const onRollbackClicked = () => {
    rollbackAction.execute(async () => {
      try {
        await rollbackDialogDefinition(dialogDefinitionId, versionId, selected === "liveAndDraft")
        onSuccess()
      }
      finally {
        onFinally()
      }
    }, "Failed to rollback", "Rolled back version")
  }

  return (
    <>
        <div className="space-y-4">
          <Option
            heading={"Live Smart form"}
            description="Will only replace the live Smart form. Any in progress work on the current draft will be maintained."
            isSelected={selected === "live"}
            onClick={onSelectionClicked}
            value="live"
          />
          <Option
            heading={"Live and Draft Smart form"}
            description="Will replace the live Smart form and the current draft Smart form. Any in progress work will be lost."
            isSelected={selected === "liveAndDraft"}
            onClick={onSelectionClicked}
            value="liveAndDraft"
          />
        </div>
        <div className="flex mt-4 justify-end">
          <Button
            theme="primary"
            text={"Rollback"}
            disabled={!selected}
            onClick={selected && onRollbackClicked}
          />
        </div>
    </>
  )
}

const Option = ({ heading, description, value, isSelected, onClick }) => {
  const classes = isSelected
    ? "ring-2 ring-indigo-600"
    : "ring-1 ring-indigo-700/10"
  return (
    <div
      onClick={() => onClick(value)}
      className={`cursor-pointer pointer-events-auto rounded-lg bg-white p-4 shadow-xl hover:bg-gray-50 ${classes}`}>
      <div className="flex justify-between">
        <div className="font-medium">{heading}</div>
        {
          isSelected && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
        }
      </div>
      <div className="mt-1">
        {description}
      </div>
    </div>
  )
}