import _, { remove } from "lodash";
import { useEffect, useState } from "react";
import { format } from 'date-fns';

export const useDynamicStringReplacement = (string, object, dataTableValues, replaceNewLine = false, elements = []) => {
    let dataTable;
    
    try {
      dataTable = JSON.parse(dataTableValues) || {}
    }
    catch(e) { }

    const [replacedString, setReplacedString] = useState('');
  
    const doReusableVariables = (content, object) => {
      // const reusableVariablesRegex = /{{\s*([\w.-]+)\s*}}/gi;
      const reusableVariablesRegex = /\{{ (.*?)\ }}/g;
      const reusableVariables = string.match(reusableVariablesRegex);

      reusableVariables?.forEach((variable) => {
        // const key = variable.replace(/[{}]/gi, '').trim();
        const key = variable.replace('{{', '').replace('}}', '').trim();
        let prop = key.split('.').pop(); // Get the last part of the key without the prefix "company."
        let datePattern = ''

        if (key.includes('::')) {
          datePattern = key.split('::')[1].trim()
          prop = key.split('::')[0]?.trim()  
        }

        const objectKeys = Object.keys(object);
        const objectKey = objectKeys.find((objectKey) => objectKey.toLowerCase() === prop.toLowerCase());
        const value = object[objectKey];

        if(value) {
          if (datePattern) {
            const formattedValue = format(new Date(value), datePattern);
            content = content.replace(variable, formattedValue);
          }
          else {
            content = content.replace(variable, value);
          }
        }
      });

      return content.replace(reusableVariablesRegex, '')
    }

    const doDataTableVariables = (content) => {
      const dataTableVariablesRegex = /\[\[ (.*?)\ ]\]/gi;
      const dataTableVariables = string.match(dataTableVariablesRegex);
  
      dataTableVariables?.forEach((variable) => {
        const key = variable.replace('[[', '').replace(']]', '').trim();

        const value = _.get(dataTable, key);

        if(value) content = content.replace(variable, value);
      });

      return content.replace(dataTableVariablesRegex, '')
    }

    const doSmartTableVariables = (content, object) => {
      const reusableVariablesRegex = /\<< (.*?)\ >>/g;
      const reusableVariables = string.match(reusableVariablesRegex);
      
      reusableVariables?.forEach((variable) => {
        const key = variable.replace('<<', '').replace('>>', '').trim();
        const parts = key.split('||'); // Get the last part of the key without the prefix "company."
        const prop = parts[0].trim();
        const format = parts.length > 1 ? parts[1] : ''

        const smartTableElement = elements.find(e => e.property === prop)

        const objectKeys = Object.keys(object);
        const objectKey = objectKeys.find((objectKey) => objectKey.toLowerCase() === prop.toLowerCase());
        const value = JSON.parse(object[objectKey] ?? '[]') || [];

        let contentToReplace = '';

        value?.filter(m => m.selected || !smartTableElement?.smartTable?.makeSelectable)?.forEach((item, index) => {
          delete item['selected']
          delete item['id']
          
          contentToReplace += contentToReplace.length > 0 ? '\n' : '';

          if(format) {
            const formattedItem = format.split(/(?=\s|\[)/).map((word) => {
              if (word.startsWith('[') && word.endsWith(']')) {
              const index = parseInt(word.replace('[', '').replace(']', ''));
              return getByIndex(item, index);
              }
              return word;
            }).join('');

            contentToReplace += formattedItem;
          }
          else {
            const keys = Object.keys(item);
            let contentItem = '';

            keys.forEach((key, index) => {
              contentItem += contentItem.length > 0 ? ' - ' : '';
              contentItem += item[key];
            })

            contentToReplace += contentItem;
          }
        })

        if(value) content = content.replace(variable, contentToReplace);
      });

      return content.replace(reusableVariablesRegex, '')
    }

    useEffect(() => {
      if (string && object) {
        let result = string; 
  
        result = doReusableVariables(result, object);
        result = doDataTableVariables(result);
        result = doSmartTableVariables(result, object);

        // if ends with onlu one newline add another one
        if(result.endsWith('\n')) result += '\n';
        // // replace new line characters with <br> tags

        setReplacedString(result);
      }
    }, [string, object]);
  
    return replacedString;
  };

  function getByIndex(object, index) {
    return object[Object.keys(object)[index]];
  }
