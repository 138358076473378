import { post, get, destroy, put } from './api'

export const createSmartObject = async (smartObject) => {
  return await post('smartobjects', smartObject);
}

export const addNewProperty = async (smartObjectId, newProperty) => {
  return await post(`smartobjects/${smartObjectId}/property`, newProperty);
}

export const updateProperty = async (smartObjectId, id, property) => {
  return await put(`smartobjects/${smartObjectId}/property/${id}`, property);
}

export const deleteProperty = async (smartObjectId, id) => {
  return await destroy(`smartobjects/${smartObjectId}/property/${id}`);
}

export const updateSmartObject = async (id, smartObject) => {
  return await put(`smartobjects/${id}`, smartObject);
}

export const getAllSmartObjects = async () => {
  return await get('smartobjects' );
}

export const getSmartObject = async (id) => {
  return await get(`smartobjects/${id}`);
}

export const deleteSmartObjects = async (id) => {
  return await destroy(`smartobjects/${id}`);
}