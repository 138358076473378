import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { createThemeStyle, deleteThemeStyle, getAllThemeStyle, updateThemeStyle } from '../../../../api/themeStyles';
import { Button } from '../../../../components/Form/Button';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { FormikTextInput } from '../../../../components/Form/Formik/FormikTextInput';
import MenuContextList from '../../../../components/MenuContextList';
import Modal from '../../../../components/Modal';
import * as yup from "yup";
import { Form, Formik } from 'formik';
import { useModalHelper } from '../../../../hooks/useModalHelper';
import { useState, useEffect } from 'react'
import { stringToLocaleDateTimeString } from '../../../../utils/date';
import { useToastAction } from '../../../../hooks/useToastAction';
import { Color, MarginPadding, TextArea } from '../../../../components/Properties';
import { defaultTextSize, defaultLabelSize } from '../../../../utils/constants';

import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import { githubLight } from '@uiw/codemirror-theme-github';

export default function ThemeStyle() {
  const [themeStyles, setThemeStyles] = useState()
  const [themeStyle, setThemeStyle] = useState();
  const loadAction = useToastAction();
  const createAction = useToastAction();
  const updateAction = useToastAction();
  const deleteAction = useToastAction(true);
  const createModalHelper = useModalHelper()
  const editModalHelper = useModalHelper()

  useEffect(() => {
    load()
  }, [])

  async function load() {
    loadAction.execute(async () => {
      const [themeStylesResult] = await Promise.all([
        await getAllThemeStyle()
      ]);

      setThemeStyles(themeStylesResult);
    }, "Failed to load")
  }

  const onCreate = async (values) => {
    createAction.execute(async () => {
     await createThemeStyle(values)
      load()
    }, "Failed to create", "Created")
  }

  const onUpdate = async (values) => {
    updateAction.execute(async () => {
      await updateThemeStyle(themeStyle.id, values)
      setThemeStyle(null)
      load();
    }, "Failed to update", "Updated")
  }

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteThemeStyle(id);
      setThemeStyles(themeStyles.filter(p => p.id !== id))
    }, "Failed to delete", "Deleted")
  }

  const onEditClick = (selected) => {
    setThemeStyle(selected)
    editModalHelper.open()
  }

  return (
    <>
      <section aria-labelledby="integrations-heading">
      <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
        <div className="flex justify-between items-center px-4 sm:px-6">
          <h2
            id="integrations-heading"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Theme Styles
          </h2>
          <Button
            text="New"
            onClick={createModalHelper.open}
          />
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border-t border-gray-200">
                <table className="min-w-full mb-1 divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {themeStyles?.map(element => (
                      <tr key={element.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {element.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {stringToLocaleDateTimeString(element.createdDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {
                            <MenuContextList
                              element={element}
                              deleteType="Theme Style"
                              actions={[
                                {
                                  name: "Edit",
                                  onClick: () => onEditClick(element),
                                  icon: PencilAltIcon,
                                },
                                {
                                  name: "Delete",
                                  onClick: () => onDelete(element.id),
                                  icon: TrashIcon,
                                },
                              ]}
                            />
                          }
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <SaveModal
        modalHelper={createModalHelper}
        onSave={onCreate}
      />

      <SaveModal
        modalHelper={editModalHelper}
        onSave={onUpdate}
        record={themeStyle}
      />  
    </>
  )
}

const SaveModal = ({ record, modalHelper, onSave }) => {
  const cancel = () => {
    modalHelper.close();
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="large"
      title="Theme Style"
    >
      <Formik
        initialValues={{
          name: record?.name || '',
          config: {
            css: record?.config.css || '#dialog-body {\nfont-family: "Arial", sans-serif\n}',
          }
        }}
        validationSchema={() => {
          return yup.object({
            name: yup.string().required('Required'),
            config: yup.object().shape({
              css: yup.string(),
            })
          })
        }}
        onSubmit={async (values, actions) => {
          await onSave(values)
          actions.setSubmitting(false)
          modalHelper.close();
        }}
      >
        {
          props => (
            <Form>
              <div className="mt-5">
              <FormikTextInput
                label="Name"
                name="name"
                required={true}
                formikProps={props}
              />
              </div>
              
              <div className="mt-5 border border-gray-300">
                {/* <TextArea 
                  onChange={props.handleChange}
                  name='config.css'
                  rows="40"
                  value={props.values.config.css}
                /> */}
                <CodeMirror height="650px" theme={githubLight}  value={props.values.config.css} extensions={[css({ })]} onChange={(value) => props.handleChange({ target: { name: 'config.css', value } })} />
              </div>

              <div className="mt-10 flex justify-end gap-4">
                <Button
                  onClick={cancel}
                  text="Cancel"
                  theme="white"
                />
                <FormikSubmitButton
                  formikProps={props}
                  text="Save"
                />
              </div>
            </Form>
          )
        }
      </Formik>
    </Modal>
  )
}