import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getDialogDefinition } from '../../../api/dialogDefinition';
import { useToastAction } from '../../../hooks/useToastAction';
import { JavascriptIntegrations } from './javascriptIntegrations';
import { SystemIntegrations } from './systemIntegrations';
import { Webhooks } from './webhooks'

export default function DialogConfiguration() {
  let { dialogKey } = useParams();
  const [dialogDefinition, setDialogDefinition] = useState()
  const loadAction = useToastAction();

  useEffect(() => {
    load()
  }, [])

  async function load() {
    loadAction.execute(async () => {
      const [dialogDefinitionResult] = await Promise.all([
        getDialogDefinition(dialogKey),
      ]);

      setDialogDefinition(dialogDefinitionResult);
    }, "Failed to load")
  }

  return (
    <>
      {
        !loadAction.isExecuting && dialogDefinition &&
        <>
            <Webhooks dialogKey={dialogKey}/>
            <JavascriptIntegrations dialogKey={dialogKey} />
            <SystemIntegrations dialogKey={dialogKey} />
        </>
      }

      {
       !loadAction.isExecuting && !dialogDefinition &&
        <h1>Could not find dialog definition. Or no access.</h1>
      }
    </>
  )
}