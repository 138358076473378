import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { createApiDefinition, deleteApiDefinitions, getAllApiDefinitions, updateApiDefinition } from '../../../api/apiDefinition';
import { Button } from '../../../components/Form/Button';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import MenuContextList from '../../../components/MenuContextList';
import Modal from '../../../components/Modal';
import * as yup from "yup";
import { Form, Formik } from 'formik';
import { useModalHelper } from '../../../hooks/useModalHelper';
import { useState, useEffect } from 'react'
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '../../../hooks/useToastAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle, faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { smartFormEnvironmentNames, smartFormEnvironments } from '../../../utils/constants';
import { SmartFormEnvironmentBadge } from '../../../components/SmartFormEnvironmentBadge';

export default function Api() {
  const [apiDefinitions, setApiDefinitions] = useState()
  const [apiDefinition, setApiDefinition] = useState();
  const loadAction = useToastAction();
  const createAction = useToastAction();
  const updateAction = useToastAction();
  const deleteAction = useToastAction(true);
  const createModalHelper = useModalHelper()
  const editModalHelper = useModalHelper()

  useEffect(() => {
    load()
  }, [])

  async function load() {
    loadAction.execute(async () => {
      const [apiDefinitionsResult] = await Promise.all([
        await getAllApiDefinitions()
      ]);

      setApiDefinitions(apiDefinitionsResult);
    }, "Failed to load")
  }

  const onCreate = async (values) => {
    createAction.execute(async () => {
      const environment = parseInt(values.environment, 10)
      await createApiDefinition({ name: values.name, url: values.url, headers: values.headers, environment })
      load()
    }, "Failed to create", "Created")
  }

  const onUpdate = async (values) => {
    updateAction.execute(async () => {
      const environment = parseInt(values.environment, 10)
      await updateApiDefinition(apiDefinition.id, { name: values.name, url: values.url, headers: values.headers, environment })
      setApiDefinition(null)
      load();
    }, "Failed to update", "Updated")
  }

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteApiDefinitions(id);
      setApiDefinitions(apiDefinitions.filter(p => p.id !== id))
    }, "Failed to delete", "Deleted")
  }

  const onEditClick = (selected) => {
    setApiDefinition(selected)
    editModalHelper.open()
  }

  return (
    <>
      <section aria-labelledby="integrations-heading">
      <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
        <div className="flex justify-between items-center px-4 sm:px-6">
          <h2
            id="integrations-heading"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Api definitions
          </h2>
          <Button
            text="New"
            onClick={createModalHelper.open}
          />
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border-t border-gray-200">
                <table className="min-w-full mb-1 divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Envirionment
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {apiDefinitions?.map(element => (
                      <tr key={element.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {element.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          Validation
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {stringToLocaleDateTimeString(element.createdDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium"><SmartFormEnvironmentBadge environment={element.environment} isLatest /></td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {
                            <MenuContextList
                              element={
                                element
                              }
                              deleteType={
                                "Api definition"
                              }
                              actions={[
                                {
                                  name: "Edit",
                                  onClick: () => onEditClick(element),
                                  icon: PencilAltIcon,
                              },
                                {
                                  name: "Delete",
                                  onClick: () => onDelete(element.id),
                                  icon: TrashIcon,
                                },
                              ]}
                            />
                          }
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <SaveModal
        modalHelper={createModalHelper}
        onSave={onCreate}
      />

      <SaveModal
        modalHelper={editModalHelper}
        onSave={onUpdate}
        record={apiDefinition}
      />  
    </>
  )
}

const SaveModal = ({ record, modalHelper, onSave }) => {
  const [render, setRender] = useState(false);

  const cancel = () => {
    modalHelper.close();
  }

  const addHeader = (headers) => {
    headers?.push({name: '', value: ''})
    setRender(!render);
  }

  const deleteHeader = (headers, index, formikProps) => {
    const errors = formikProps.errors;    
    errors?.headers?.length && errors?.headers?.splice(index, 1)
    !errors?.headers?.length && delete errors.headers;
    formikProps.setErrors(errors)
    headers.splice(index, 1)
    setRender(!render);
  }

  const environmentOptions = Object.values(smartFormEnvironments).map((key) => {
    return { name: smartFormEnvironmentNames[key], value: key }
  })

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title="Api definition"
    >
      <Formik
        initialValues={{
          name: record?.name || '',
          url: record?.url || '',
          headers: record?.headers || [],
          environment: record?.environment || smartFormEnvironments.development
        }}
        validationSchema={() => {
          return yup.object({
            name: yup.string().required('Required'),
            url: yup.string().required('Required').url("Must be a URL"),
            headers: yup.array()
                .of(
                  yup.object().shape({
                    name: yup.string().required("Required"),
                    value: yup.string().required("Required"),
                  })
                )
          })
        }}
        onSubmit={async (values, actions) => {
          await onSave(values)
          actions.setSubmitting(false)
          modalHelper.close();
        }}
      >
        {
          props => (
            <Form>
              <FormikTextInput
                label="Name"
                name="name"
                required={true}
                formikProps={props}
              />
              <div className="mt-4">
                <FormikTextInput
                  label="Url"
                  name="url"
                  required={true}
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikSelectInput
                  label="Enviroment"
                  name="environment"
                  formikProps={props}
                  options={environmentOptions}
                />
              </div>
              { props.values?.headers.length > 0 && 
                props.values.headers.map((element, index) => {
                  return (
                  <div className="mt-5">
                    <label className=' mb-2'>Header {index + 1}</label>
                    <FontAwesomeIcon className="ml-3 text-red-600 cursor-pointer"icon={faXmarkCircle} onClick={() => deleteHeader(props.values.headers, index, props)}/>
                    <div className='mt-4'>
                      <FormikTextInput
                        label="Name"
                        name={`headers[${index}].name`}
                        required={true}
                        formikProps={props}
                      />
                    </div>
                    <div className='mt-4'>
                      <FormikTextInput
                        label="Value"
                        name={`headers[${index}].value`}
                        required={true}
                        formikProps={props}
                      />       
                    </div>         
                  </div>
                  )
                })
              }

              <div className='mt-6'>
                <button 
                  className='cursor-pointer bg-green-600 p-2 items-center px-4 py-2 border border-transparent font-medium rounded text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-sm'
                  onClick={() => addHeader(props.values?.headers)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faCirclePlus} />
                  Add new header
                </button>
              </div>

              <div className="mt-5 flex justify-between">
                <FormikSubmitButton
                  formikProps={props}
                  text="Save"
                />
                <Button
                  onClick={cancel}
                  text="Cancel"
                  theme="white"
                />
              </div>
            </Form>
          )
        }
      </Formik>
    </Modal>
  )
}