import { useEffect, useRef, useState } from 'react';
import { useClassNames } from '../../hooks/useClassNames';

export const TextAreaInput = ({
  name,
  label = null,
  value,
  onChange,
  placeholder = null,
  required = false,
  validation: validate,
  horizontal = false,
  disabled = false,
}) => {
  const [blurred, setBlurred] = useState(false)
  const classes = useClassNames()

  let hasValidationError = false
  if (blurred && validate) {
    hasValidationError = !validate(value)
  }

  const classNames = classes.classNames(
    "flex-1 block w-full max-w-full sm:text-sm pr-10 py-2 text-base border-gray-300 rounded-md shadow-sm resize overflow-y-scroll h-auto",
    hasValidationError
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-gray-400 focus:border-gray-400',
    disabled ? 'bg-gray-100' : 'bg-white',
  )

  const textareaRef = useRef();

  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChange = (evt) => {
    adjustTextareaHeight();
    onChange(evt.target.value, name)
  }

  return (
    <>
      {
        label &&
        <div className={classes.classNames(horizontal
          ? 'flex items-center mt-1'
          : '',
        )}>
          <label htmlFor={name} className={classes.classNames(horizontal
            ? 'flex-1'
            : '',
            'block text-sm font-medium text-gray-700'
          )}>
            {label}
            {required === true && <span className="text-red-400"> *</span>}
          </label>
            <textarea
              ref={textareaRef}
              name={name}
              value={value || ''}
              onChange={handleChange}
              className={classNames}
              placeholder={placeholder}
              onBlur={() => setBlurred(true)}
              disabled={disabled}
            />
          </div>
      }
      {
        !label &&
        <textarea
          ref={textareaRef}
          name={name}
          value={value || ''}
          onChange={handleChange}
          className={classNames}
          placeholder={placeholder}
          onBlur={() => setBlurred(true)}
          disabled={disabled}
        />
      }
    </>
  )
}