import { BaseUrl, get } from '../api'
import { config } from './publicConfig';

export const getDialogValuesFromPhoneNumber = async (id, dialogValuesId, phoneNumber) => {
  const options = {
    statusCodeHandlers: [{
      statusCode: 404,
      handler: () => {
        return false
      }
    }]
  }
  return await get(`${config.basePath}/definitionProcesses/${id}/form/${dialogValuesId}/phoneNumbers?phoneNumber=${encodeURIComponent(phoneNumber)}`, BaseUrl.Api, options);
}