import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getDataLoad, getDataLoadNotificationPlaceHolders, issueDataloadNotifications, saveNotificationMessageDraft } from '../../../api/dataLoad';
import { getDialogDefinition } from '../../../api/dialogDefinition';
import { useAutoSave } from '../../../hooks/useAutoSave';
import { useToastAction } from '../../../hooks/useToastAction';
import ImportStatusBar from './importStatusBar';
import * as yup from "yup";
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { dataLoadNotificationStates } from '../../../utils/constants';
import Modal from '../../../components/Modal';
import DataLoadNotificationPlaceholderModalContent from './dataLoadNotificationPlaceholderModalContent';

export default function DataLoadIssueNotification() {

    const { dialogKey, dataLoadId } = useParams();
    const [importStatus, setImportStatus] = useState({ statusText: "New message", status: "info" });
    const [dialog, setDialog] = useState(null);
    const [dataLoad, setDataLoad] = useState(null);
    const [placeHoldersModalOpen, setPlaceHoldersModalOpen] = useState(false);
    const [dialogPlaceholders, setDialogPlaceholders] = useState([])
    const [initialValues, setInitialValues] = useState({ subject: '', message: '' });

    const loadAction = useToastAction();
    const sendAction = useToastAction();
    const draftAction = useToastAction();

    const evaluateNotificationStatus = (status) => {
        if (status === 1) {
            setImportStatus({ statusText: "New message", status: "info" });
        } else if (status < 10) {
            setImportStatus({ statusText: "Draft", status: "info" });
        } else {
            setImportStatus({ statusText: "Notifications Issued", status: "success" });
        }

    }

    const loadData = async () => {
        loadAction.execute(async () => {
            const serverDialog = await getDialogDefinition(dialogKey);
            const serverDataLoad = await getDataLoad(dialogKey, dataLoadId);
            const serverPlaceHolders = await getDataLoadNotificationPlaceHolders(dialogKey, dataLoadId);
            evaluateNotificationStatus(serverDataLoad.notificationState);

            setInitialValues(prev => {
                return { ...prev, message: serverDataLoad.notificationMessage || '', subject: serverDataLoad.notificationSubject || '' };
            });
            setDialogPlaceholders(serverPlaceHolders);
            setDialog(serverDialog);
            setDataLoad(serverDataLoad);
        }, "Failed to load");
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleSaveAsDraft = async (values) => {
        draftAction.execute(async () => {
            const updated = await saveNotificationMessageDraft(dialogKey, dataLoadId, values.subject, values.message);
            setDataLoad(updated);
            evaluateNotificationStatus(updated.notificationState);
        }, "Failed to save draft", "Draft saved");
    }

    const handleSendNotifications = async (values) => {
        sendAction.execute(async () => {
            const updatedDataload = await issueDataloadNotifications(dialogKey, dataLoadId, values.subject, values.message);
            setDataLoad(updatedDataload);
            evaluateNotificationStatus(updatedDataload.notificationState);
        }, "Failed to send", "Send complete");

    }

    return (
        <>
            <div className="max-w-4xl">
                <Formik
                    initialValues={initialValues}
                    
                    enableReinitialize={true}
                    
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSendNotifications(values);
                        setSubmitting(false);
                    }}

                    validationSchema={() => {
                        return yup.object().shape({
                            subject: yup.string().required("Required"),
                            message: yup.string().required("Required"),
                        });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        validateForm
                        /* and other goodies */
                    }) => (
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e)
                        }}
                            onReset={e => { }}
                            className="mt-4">
                            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Issue Notifications: {dialog?.name} - {dataLoad?.dataLoadName}</h3>
                            </div>
                            <div className="mt-4 pb-4 border-b border-gray-200">
                                {dataLoad?.notificationState < dataLoadNotificationStates.issued ? 'Emails to generate:' : 'Emails issued:'} {dataLoad?.dataLoadDetails.dialogsIncludingEmail}
                            </div>
                            <div className="mt-4">
                                <ImportStatusBar status={importStatus} />
                            </div>
                            {
                                dataLoad?.notificationState === dataLoadNotificationStates.issued &&
                                (<div className="mt-4 block text-md font-medium text-gray-700">
                                    Issued {stringToLocaleDateTimeString(dataLoad.updatedDate)}
                                </div>)
                            }
                            <div className="mt-4">
                                <div className="grid grid-cols-2">
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Subject*
                                    </label>
                                    <span className="text-sm font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer col-end-3 place-self-end mb-2" onClick={e => setPlaceHoldersModalOpen(true)}>Placeholders</span>
                                </div>
                                <input type="text" name="subject" placeholder="Subject..." required autoComplete="off"
                                    value={values.subject}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={dataLoad?.notificationState >= dataLoadNotificationStates.issued}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50" />
                                <div className="mt-1 text-red-400">
                                    {touched.subject && (errors.subject)}
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="grid grid-cols-2">
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Message*</label>
                                    <span className="text-sm font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer col-end-3 place-self-end mb-2" onClick={e => setPlaceHoldersModalOpen(true)}>Placeholders</span>
                                </div>
                                <textarea name="message" rows={7} placeholder="Message..."
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={dataLoad?.notificationState >= dataLoadNotificationStates.issued}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50" >
                                </textarea>
                                <div className="mt-1 text-red-400">
                                    {touched.message && (errors.message)}
                                </div>
                            </div>
                            <div className="mt-4 pt-4 flex border-t border-gray-200 justify-between">
                                <div className="flex space-x-4">
                                    <button
                                        type="submit"
                                        disabled={dataLoad?.notificationState >= dataLoadNotificationStates.issued || sendAction.isExecuting}
                                        className="justify-center flex items-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                                    >
                                        {
                                            sendAction.isExecuting && (
                                                <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            )
                                        }
                                        Send
                                    </button>
                                    <Link
                                        to={`/admin/dataload/${dialogKey}`}
                                        className="w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"

                                    >
                                        Exit
                                    </Link>
                                </div>
                                <button
                                    type="button"
                                    className="justift-self-end w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={e => {
                                        e.preventDefault();
                                        handleSaveAsDraft(values);
                                    }}
                                >
                                    Save as Draft
                                </button>
                            </div>

                        </form>
                    )}
                </Formik>
            </div>
            <Modal
                isOpen={placeHoldersModalOpen}
                onClose={() => { }}
                size={'medium'}
            >
                <DataLoadNotificationPlaceholderModalContent title="Place holders" setOpen={setPlaceHoldersModalOpen} placeHolders={dialogPlaceholders} />
            </Modal>
        </>
    )
}
