import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Structures from "../../../structures"
import { useDispatch } from 'react-redux';
import { useToastAction } from '../../../hooks/useToastAction';
import { getDialogDefinitionEditMode } from "../../../api/public/dialogDefinition";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { setDialogDefinition as setDialogDefinitionReducer } from "../../../slices/dialogDefinitionSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faDesktop } from '@fortawesome/pro-light-svg-icons'

const DialogPreview = () => {
  const loadAction = useToastAction();
  const dispatch = useDispatch();
  const { dialogKey } = useParams();
  const [dialogDefinition, setDialogDefinition] = useState()
  const [dismissAlert, setDismissAlert] = useState(false);
  const [isMobile, setIsMobile] = useState(false)

  const searchParams = new URLSearchParams(useLocation().search)
  const isPdf = searchParams.get("pdf") === "true";

  useEffect(() => {
    load()
  }, []);

  const load = async () => {
    await loadAction.execute(async () => {
      const dialogDefinition = await getDialogDefinitionEditMode(dialogKey);
      setDialogDefinition(dialogDefinition);
      dispatch(setDialogDefinitionReducer({
        dialogDefinition
      }))
    }, "Failed to load");
  }

  if (loadAction.isExecuting || !dialogDefinition) {
    return null;
  }

  return (
    <div style={{ backgroundColor: dialogDefinition.backgroundColor }}>
      <div className="absolute top-0 right-0 px-5 py-1">
        <div
          className={`flex items-center rounded-sm p-4 text-sm cursor-pointer`}
          onClick={() => setIsMobile(!isMobile)}
        >
          {
            <div className='w-6 mr-3'>
              <FontAwesomeIcon className="" size={'lg'} icon={isMobile ? faDesktop : faMobile} />
            </div>
          }
          <div className="flex">
            {isMobile ? "Toggle Desktop" : "Toggle Mobile"}
          </div>
        </div>
      </div>
      {
        searchParams.get("pdf") !== 'true' &&
        <div className={` ${(dismissAlert ? "hidden" : "")} max-w-screen-md mx-auto rounded-md bg-yellow-50 p-4 `}>
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Attention</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Please note that this is a preview of the draft. No actions will work.
                </p>
              </div>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => setDismissAlert(true)}
                  className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <Structures.Dialog data={dialogDefinition} isPreviewMode={true} isPdfMode={isPdf} isMobileMode={isMobile} />
    </div>
  );
}
export default DialogPreview