import { Form, Formik, useFormikContext } from "formik";
import Modal from "../../../../components/Modal";
import * as Constants from '../../../../utils/constants';
import React from "react";
import { FormikTextInput } from "../../../../components/Form/Formik/FormikTextInput";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { FormikFileInput } from "../../../../components/Form/Formik/FormikFileInput";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import * as yup from "yup";
import { getReadableFileSizeString } from "../../../../utils/file";
export const ResourceModal = ({ isOpen, setOpen, onSubmit, categoryLimits = null }) => {

    const MAX_FILE_SIZE = 102400 * 5; //500KB

    const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

    const isValidFileType = (fileName, fileType) => {
        return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
    }
    const initialCategoryValue = categoryLimits ? categoryLimits[0] : Constants.resourceCategories.element

    return (<Modal
        isOpen={isOpen}
        onClose={() => {
            setOpen(false)
        }}
        size="medium"
        title={'Create Resource'}
    >
        <Formik
            initialValues={{
                name: '',
                category: initialCategoryValue,
                file: null,
            }}

            validationSchema={
                () => {
                    return yup.object({
                        name: yup.string().required('Required'),
                        file: yup
                            .mixed()
                            .required("Required")
                            .test("is-valid-type", "Not a valid image type",
                                value => isValidFileType(value && value?.name?.toLowerCase(), "image"))
                            .test("is-valid-size", `Max allowed size is ${getReadableFileSizeString(MAX_FILE_SIZE)}`,
                                value => value && value.size <= MAX_FILE_SIZE)
                    })
                }
            }
            onSubmit={(values, { setSubmitting, ...opts }) => {
                onSubmit(values, () => {
                    setSubmitting(false);
                });

            }}
        >
            <ResourcesForm onCancel={() => setOpen(false)} categoryLimits={categoryLimits} />
        </Formik>

    </Modal>)
}
const ResourcesForm = ({ onCancel, categoryLimits }) => {
    const props = useFormikContext();
    
    const categories = Object.keys(Constants.resourceCategoryNames).filter(x => (categoryLimits ? categoryLimits.includes(+x) : true)).map(k => ({ value: k, name: Constants.resourceCategoryNames[k] }));

    return (
        <Form>
            <div className="mt-4 space-y-4">
                <FormikTextInput
                    formikProps={props}
                    label={'Name'}
                    name={'name'}
                />
                <FormikSelectInput
                    formikProps={props}
                    label={'Category'}
                    name={'category'}
                    options={categories}
                />
                <div className="mt-4">
                    <FormikFileInput
                        maxFileSizeInMb={10}
                        name={'file'}
                        formikProps={props}
                        accept="image/*"
                    />
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <FormikSubmitButton
                    text={'Save'}
                    formikProps={props}
                />
                <button
                    type="button"
                    className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={(event) => {
                        onCancel();
                    }}
                >
                    Cancel
                </button>
            </div>
        </Form>
    )
}