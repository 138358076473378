export const Label = ({
  label,
  name,
  required = false,
}) => {
  return (
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
      {required === true && <span className="text-red-400"> *</span>}
    </label>
  )
}