import { useState, useEffect, useContext } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { alignmentOptions } from '../../utils/constants';
import { getAllApiDefinitions } from '../../api/apiDefinition';
import { DesignActionbarContext } from '../../contexts';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const designActionbarContext = useContext(DesignActionbarContext);
  const {apiDefinitionOptions} = designActionbarContext || {}

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges, 'value')
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Map"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data?.label}
        />

        <Property.Select
          onChange={handleOnChange}
          label={"Api Retrieve"}
          name={"apiRetrieveId"}
          value={data?.apiRetrieveId}
          options={apiDefinitionOptions}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide dropdown"}
          name={`map.hideDropdown`}
          value={data?.map?.hideDropdown}
          rowReverse
        />

      </Property.Group>

      <Property.Group title='Text Style'>

        <Property.Subgroup
          title="Label"
        >
          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'labelStyle.width'}
            type={'number'}
            value={data?.labelStyle?.width}
          />

          <Property.FontStyleAndWeight
            onChange={handleOnChange}
            name="labelStyle"
            property={data?.labelStyle}
          />

        </Property.Subgroup>

       
      </Property.Group>
    </div>
  )
}
