import { useClassNames } from "../../hooks/useClassNames"
import { useEllipsis } from "../../hooks/useEllipsis"

export const VerticalStepper = ({ steps, onClick, currentId, participatingParties }) => {
  const cl = useClassNames()
  const ellipsis = useEllipsis()

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  const visibleIndex = (index) => {
    return zeroPad(index + 1, 2)
  }

  const name = (step) => {
    return step?.name || '--'
  }

  const description = (step) => {
    return ellipsis(participatingParties.find(p => p.id === step.participatingPartyId)?.name || '--', 24)
  }
  return (
    <nav className="w-full px-4 sm:px-6" aria-label="Progress">
      <ol className="rounded-md overflow-hidden">
        {
          steps.map((step, stepIndex) => {
            return (
              <li key={step.id} className="relative overflow-hidden cursor-pointer" onClick={() => onClick(step.id)}>
                <div
                  className={steps.length === 1 ? "border border-gray-200 rounded-t-md" : cl.classNames(
                    stepIndex === 0 ? 'border-b-0 rounded-t-md' : '',
                    stepIndex === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                    'border border-gray-200 overflow-hidden'
                  )}
                >
                  {
                    step.id === currentId ? (
                      <a href={step.href} aria-current="step">
                        <span
                          className="absolute top-0 left-0 w-1 h-full bg-indigo-600"
                          aria-hidden="true"
                        />
                        <span
                          className={'px-6 py-5 flex items-start text-sm font-medium'}
                        >
                          <span className="flex-shrink-0">
                            <span className="w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                              <span className="text-indigo-600">
                                {visibleIndex(stepIndex)}
                              </span>
                            </span>
                          </span>
                          <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                            <span className="text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                              {name(step)}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {description(step)}
                            </span>
                          </span>
                        </span>
                      </a>
                    ) : (
                      step.status === 'complete' ? (
                        <a href={step.href} className="group">
                          <span
                            className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200"
                            aria-hidden="true"
                          />
                          <span
                            className={'px-6 py-5 flex items-start text-sm font-medium'}
                          >
                            <span className="flex-shrink-0">
                              <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-400 rounded-full">
                                <span className="text-gray-400">
                                  {visibleIndex(stepIndex)}
                                </span>
                              </span>
                            </span>
                            <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                              <span className="text-xs font-semibold tracking-wide uppercase text-gray-400">
                                {name(step)}
                              </span>
                              <span className="text-sm font-medium text-gray-300">
                                {description(step)}
                              </span>
                            </span>
                          </span>
                        </a>) : (
                        <a href={step.href} className="group">
                          <span
                            className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200"
                            aria-hidden="true"
                          />
                          <span
                            className={'px-6 py-5 flex items-start text-sm font-medium'}
                          >
                            <span className="flex-shrink-0">
                              <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                                <span className="text-gray-500">
                                  {visibleIndex(stepIndex)}
                                </span>
                              </span>
                            </span>
                            <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                              <span className="text-xs font-semibold text-gray-500 tracking-wide uppercase">
                                {name(step)}
                              </span>
                              <span className="text-sm font-medium text-gray-500">
                                {description(step)}
                              </span>
                            </span>
                          </span>
                        </a>
                      )
                    )
                  }

                  {stepIndex !== 0 ? (
                    <>
                      {/* Separator */}
                      <div className="hidden absolute top-0 left-0 w-3 inset-0" aria-hidden="true">
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            )
          })
        }
      </ol>
    </nav>
  )
}