import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";

export const FormikFormObserver = ({ children, onFormChange }) => {
  const { values } = useFormikContext();
  const initalLoadRef = useRef(true)

  useEffect(() => {
    // Fixing the triggering of onFormChange on initial load.
    if (initalLoadRef.current) {
      initalLoadRef.current = false
      return
    }

    onFormChange(values);
  }, [values])


  return (children)
}