import { updateDialogObjectName } from "../../../api/dialogObjects";
import Modal from '../../../components/Modal';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useToastAction } from "../../../hooks/useToastAction";

export const RenameObject = ({
  objectToRename,
  onClose,
  onRenameSuccess,
}) => {
  const updateNameAction = useToastAction();
  return (
    <Modal
      isOpen={!!objectToRename}
      onClose={onClose}
      title='Rename object'
      size="small"
    >
      <div className="pt-2">
        {
          objectToRename &&
          <Formik
            initialValues={{
              name: objectToRename.name,
            }}
            validationSchema={() => {
              return yup.object({
                name: yup.string().required('Required'),
              })
            }}
            onSubmit={async (values, actions) => {
              updateNameAction.execute(async () => {
                await updateDialogObjectName(objectToRename.id, values.name)
                actions.setSubmitting(false)
                onClose()
                onRenameSuccess()
              }, "Failed to rename object", "Renamed object")
            }}
          >
            {
              props => (
                <Form>
                  <div>
                    <FormikTextInput
                      label="Name"
                      name="name"
                      required={true}
                      formikProps={props}
                    />
                  </div>
                  <div className="mt-4">
                    <FormikSubmitButton
                      formikProps={props}
                      text="Save"
                    />
                  </div>
                </Form>
              )
            }
          </Formik>
        }
      </div>
    </Modal>
  );
}