import { useContext, useState } from 'react';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalDndContext } from '../../contexts';
import { ArrowsExpandIcon } from '@heroicons/react/outline'
import { elementTypes } from '../../utils/constants';

export const DragHandle = ({
  children,
  isHandleVisible,
  dragHandleProps,
  forSortableType,
  tooltip,
  id
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { onDragHandleClick, hasActiveDrag, activeDndId, activeFromSidebar } = useContext(GlobalDndContext) || {}
  const isActiveById = activeDndId === id;

  const handleDragHandleClick = (e) => {
    
    const draggableId = dragHandleProps['data-rbd-drag-handle-draggable-id']
    
    if (draggableId) {
      onDragHandleClick(draggableId)
    }
  }

  const handleProps = isMouseOver && dragHandleProps
    ? { ...dragHandleProps }
    : {}

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseLeave = () => {
    setIsMouseOver(false)
  }

  const getPositioningClasses = () => {
    switch (forSortableType) {
      case "column":
        return "absolute top-0 right-1 z-50"
      case "row":
        return "absolute -left-12 h-full"
      case "container":
        return "absolute -right-16 top-8 z-50"
      default:
        return "absolute z-50"
    }
  }

  const getWrapperClassName = () => {

    switch (forSortableType) {
      case "element":
        return "flex flex-row"
      case "column":
        return "relative flex-1"
      default:
        return "relative"
    }
  }

  const getDraggingIconBoxSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-8 h-8"
      case "container":
        return "w-14 h-14"
      default:
        return "w-10 h-10"
    }
  }

  const getDraggingIconSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-3 h-3"
      case "container":
        return "h-5 w-5"
      default:
        return "h-3 w-3"
    }
  }

  const getDraggeableIconPositionByElementType = () => {
    const elementType = children?.props?.children?.props?.element?.type
    switch(elementType) {
      case elementTypes.table:
        return 'mr-8 ml-1'

      default:
        return 'mr-1 ml-1'
    }
  }

  const getDragHandlerFromType = (type) => {
    switch (type) {
      case "element":
        return (
          <div className='bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M5,11v9c0,0.552,0.448,1,1,1h12 c0.552,0,1-0.448,1-1V4c0-0.552-0.448-1-1-1h-3"></path><path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M12 7V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v3c0 .552.448 1 1 1h5C11.552 8 12 7.552 12 7zM5 15L19 15M15 9L19 9M12 11L12 21"></path><path d="M12,7V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v3c0,0.552,0.448,1,1,1h5C11.552,8,12,7.552,12,7z" opacity=".3"></path>
            </svg>
          </div>
        )
      case "column":
        return (
          <div className='bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M14 3h4c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1h-4M11 20V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v16c0 .552.448 1 1 1h4C10.552 21 11 20.552 11 20z"></path><path d="M11,20V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v16c0,0.552,0.448,1,1,1h4 C10.552,21,11,20.552,11,20z" opacity=".3"></path><path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M14 9L19 9M5 9L11 9M14 15L19 15M5 15L11 15"></path>
            </svg>
          </div>
        )
      case "row":
        return (
          <div className='bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M19 11v9c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1v-9M19 7V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v3c0 .552.448 1 1 1h12C18.552 8 19 7.552 19 7zM5 15L19 15M12 11L12 21M12 6L12 8"></path><path d="M19,7V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v3c0,0.552,0.448,1,1,1h12C18.552,8,19,7.552,19,7z" opacity=".3"></path>
            </svg>
          </div>
        )
      default:
        return (
          <DraggingIcon />
        )
    }
  }



  const DraggingIcon = () => (<div className={`bg-white shadow sm:rounded-lg flex justify-around items-center ${getDraggingIconBoxSizeClasses()} text-sm `} style={{ cursor: 'grabbing' }}>
    <ArrowsExpandIcon className={`transform rotate-45  group-hover:text-gray-400 ${getDraggingIconSizeClasses()}`} />
  </div>)

  return (
    <div
      className={getWrapperClassName() }
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {(isHandleVisible && hasActiveDrag() && isActiveById && !activeFromSidebar) && <div className={`z-50 ${getPositioningClasses()}`}> {getDragHandlerFromType(forSortableType)} </div>}
      {
        (isHandleVisible && isMouseOver && !hasActiveDrag()) &&
        <div
          className={` ${getPositioningClasses()}`}
        >
          {
            forSortableType === "container" && (
              <div
                    className='bg-white rounded border border-gray-300 pl-2 pr-2 pt-1 pb-1'
                    style={{ cursor: 'grab' }}
                    onClick={handleDragHandleClick}

                    title={tooltip}
                    {...handleProps}
                  >
                    <FontAwesomeIcon size={'sm'} icon={faArrowsAltV} />
              </div>
            )

          }
          {
            forSortableType === "row" &&
            <div className='h-full'>
              <div className='m-auto mr-12'>
                <div
                  className=''
                  style={{ cursor: 'grab' }}
                  onClick={handleDragHandleClick}
                  title={tooltip}
                  {...handleProps}
                >
                  {getDragHandlerFromType('row')}
                </div>
              </div>
            </div>
          }
          {
            forSortableType === "column" && (<div
                  className=''
                  style={{ cursor: 'grab' }}
                  onClick={handleDragHandleClick}
                  title={tooltip}
                  {...handleProps}
                >
                  {getDragHandlerFromType('column')}
                </div>
            )
          }
          {
            forSortableType === "element" &&
            // <div className='h-full flex relative'>
              <div
                className={` z-50 ${getDraggeableIconPositionByElementType()} absolute -left-2`}
                style={{ cursor: 'grab', alignSelf: "center" }}
                onClick={handleDragHandleClick}
                title={tooltip}
                {...handleProps}                
              >
                {getDragHandlerFromType('element')}
              </div>
            // </div>
          }
        </div>
      }
      {children}
    </div>
  )
}