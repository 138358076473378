import * as _ from "lodash"
import { v4 as uuidv4 } from 'uuid';
import Enumerable from 'linq'
import * as Structures from "../structures"
import { findFieldsWithNoValue, requiredFieldsObjectsToArray } from "../utils/elements";
import { valuesKeyValuePairToModel } from "../utils/dialogValues";
import { objectToArray } from "../utils/array";

export const useDynamicRows = () => {
  const renderDynamicRows = (row, inputValues, dialogData, isPdfMode) => {
    const nodes = []
    if (!row?.dynamicRowConfig?.enableRowRepeat || !inputValues) {
      return nodes
    }
    const matches = findDynamicElementsByDynamicRowId(inputValues, row.id)
    if (matches?.length > 0) {
      let dynamicRowCount = Enumerable.from(matches).max(m => m.dynamicRowIndex)

      if(isPdfMode) {
        dynamicRowCount -= 1
      }

      for (let i = 0; i <= dynamicRowCount; i++) {
        const clonedRow = _.cloneDeep(row)
        const dynamicElementsForRow = matches.filter(m => m.dynamicRowIndex === i)
        for (let columnIndex = 0; columnIndex < clonedRow.columns.length; columnIndex++) {
          for (let elementIndex = 0; elementIndex < clonedRow.columns[columnIndex].elements.length; elementIndex++) {
            const matchingDynamicElement = dynamicElementsForRow.find(d => d.clonedFromElementProperty === clonedRow.columns[columnIndex].elements[elementIndex].property)
            if (!matchingDynamicElement) {
              continue
            }
            clonedRow.columns[columnIndex].elements[elementIndex].elementId = matchingDynamicElement.elementId
            clonedRow.columns[columnIndex].elements[elementIndex].property = matchingDynamicElement.property
            clonedRow.columns[columnIndex].elements[elementIndex].clonedFromElementProperty = matchingDynamicElement.clonedFromElementProperty
            clonedRow.columns[columnIndex].elements[elementIndex].dynamicRowIndex = matchingDynamicElement.dynamicRowIndex
            clonedRow.columns[columnIndex].elements[elementIndex].dynamicRowId = matchingDynamicElement.dynamicRowId
          }
        }
        nodes.push(<>
          <Structures.Row
            key={`row-${clonedRow.id}-${i}`}
            data={{
              ...clonedRow,
            }}
            dialogData={dialogData}
          />
        </>)
      }
    }
    return nodes
  }

  const findDynamicElementsByDynamicRowId = (dialogFormValues, dynamicRowId) => {
    return Object.entries(dialogFormValues)
      .filter(([key, value]) => value.dynamicRowId === dynamicRowId)
      .map(([key, value]) => value)
  }

  const findDynamicElementsByDynamicRowIdAndRowIndex = (dialogFormValues, dynamicRowId, dynamicRowIndex) => {
    return Object.entries(dialogFormValues)
      .filter(([key, value]) => value.dynamicRowId === dynamicRowId && value.dynamicRowIndex === dynamicRowIndex)
      .map(([key, value]) => value)
  }

  const missingRequiredFields = (obj) => {
    return requiredFieldsObjectsToArray(obj)?.length > 0
  }

  const addRequiredElementsFromDynamicRows = (requiredElements, data) => {
    const newRequiredElements = _.cloneDeep(requiredElements)
    return [...newRequiredElements, ...getRequiredElementsFromDynamicRows(requiredElements, data)]
  }

  const getRequiredElementsFromDynamicRows = (requiredElements, data) => {
    const newRequiredElements = []
    for (const [key, value] of Object.entries(data)) {
      if (value.dynamicRowId) {
        const match = requiredElements.find(e => e.property === value.clonedFromElementProperty)
        if (match) {
          const clonedElement = _.cloneDeep(match)
          clonedElement.id = value.elementId
          clonedElement.property = value.property
          newRequiredElements.push(clonedElement)
        }
      }
    }

    return newRequiredElements
  }

  /**
   * Called when a user tries to submits a form   
   */
  const onFormSubmit = (dialogFormValues, requiredFields) => {
    const values = objectToArray(dialogFormValues)
    const dynamicRowIds = Enumerable.from(values)
      .distinct(v => v.dynamicRowId)
      .select(r => r.dynamicRowId)
      .where(d => d)
      .toArray()

    // Find all elements that are in the last row of a dynamic row, where all elements in the row are empty    
    const elementsToDelete = []
    for (const dynamicRowId of dynamicRowIds) {
      const dynamicRowElements = values.filter(v => v.dynamicRowId === dynamicRowId)
      if (dynamicRowElements?.length === 0) {
        continue
      }
      const lastRowIndex = Enumerable.from(dynamicRowElements).max(d => d.dynamicRowIndex)
      const lastRowElements = dynamicRowElements.filter(d => d.dynamicRowIndex === lastRowIndex)
      const obj = findFieldsWithNoValue(lastRowElements, valuesKeyValuePairToModel(dialogFormValues, true))
      const allRequiredFields = requiredFieldsObjectsToArray(obj)

      // if all elements in the last row have no value
      if (allRequiredFields.length === lastRowElements.length) {
        for (const element of lastRowElements) {
          elementsToDelete.push(element.property)
        }
      }
    }

    // Only remove empty elements in the last dynamic rows if all other elements have passed their validations
    const allOthers = valuesKeyValuePairToModel(dialogFormValues, true).filter(d => !elementsToDelete.includes(d.property))
    const obj = findFieldsWithNoValue(addRequiredElementsFromDynamicRows(requiredFields, allOthers), allOthers)
    const allOtherRequiredFields = requiredFieldsObjectsToArray(obj)
    if (allOtherRequiredFields.length === 0) {
      for (const propertyName of elementsToDelete) {
        delete dialogFormValues[propertyName]
      }
      return elementsToDelete
    }
    else {
      // Do nothing. At least one other field is not passing it's required field check
      return []
    }
  }

  /**
   * Called on form value change. Determines if a new dynamic row needs to be added   
   */
  const addFormValuesForDynamicRowIfNeeded = (dialogFormValues,
    elementKey,
    value,
    definitionManager,
    dialogDefinition,
  ) => {
    const dynamicRowDetails = getDynamicRowsDetailsFromCurrentElement(dialogFormValues, elementKey, definitionManager, dialogDefinition)
    if (!value || !dynamicRowDetails) {
      return
    }

    const rowElements = Enumerable.from(dynamicRowDetails.row.columns)
      .selectMany(c => c.elements)
      .toArray()

    const dynamicElements = findDynamicElementsByDynamicRowId(dialogFormValues, dynamicRowDetails.row.id)
    const nextIndex = dynamicElements?.length === 0
      ? 0
      : Enumerable.from(dynamicElements).max(e => e.dynamicRowIndex) + 1

    // in original row from dialog definition, and no dynamic rows yet
    const editingFirstRow = !dynamicRowDetails.matchingValue.dynamicRowId && nextIndex === 0
    // editing an element the last dynamic row
    const editingLastDynamicRow = dynamicRowDetails.matchingValue.dynamicRowId && dynamicRowDetails.matchingValue.dynamicRowIndex === nextIndex - 1

    if (editingFirstRow || editingLastDynamicRow) {
      // Use all rowElements if newRowRequiresAllFieldsToBeFilled is true
      const requiredElementsInRow = dynamicRowDetails.row.dynamicRowConfig?.newRowRequiresAllFieldsToBeFilled
        ? rowElements
        : []

      if (editingFirstRow) {
        const requiredFields = findFieldsWithNoValue(requiredElementsInRow, valuesKeyValuePairToModel(dialogFormValues, true))
        if (missingRequiredFields(requiredFields)) {
          return
        }
      }
      else if (editingLastDynamicRow) {
        const dynamicElementsAsArray = findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, dynamicRowDetails.matchingValue.dynamicRowId, nextIndex - 1)
        let obj = {}
        for (const dynamicElement of dynamicElementsAsArray) {
          obj[dynamicElement.property] = dynamicElement
        }
        const elementsModel = valuesKeyValuePairToModel(obj, true)
        const requiredElements = getRequiredElementsFromDynamicRows(requiredElementsInRow, elementsModel)
        const requiredFields = findFieldsWithNoValue(requiredElements, elementsModel)
        if (missingRequiredFields(requiredFields)) {
          return
        }
      }

      for (const element of rowElements) {
        const match = dialogFormValues[element.property]
        const cloned = _.cloneDeep(match)
        cloned.property = `${match.property}-dynamic-${uuidv4()}`
        cloned.clonedFromElementProperty = element.property
        cloned.dynamicRowId = dynamicRowDetails.row.id
        cloned.dynamicRowIndex = nextIndex
        cloned.elementId = uuidv4();
        cloned.value = null;
        cloned.files = null;
        cloned.tableValues = null;
        cloned.smartTableValues = null;
        cloned.orderTableValue = null;
        cloned.connectedValues = null;
        cloned.signatureName = null;

        dialogFormValues[cloned.property] = cloned
      }
    }
  }

  const elementsAsArrayToObj = (elementsAsArray) => {
    let obj = {}
    for (const dynamicElement of elementsAsArray) {
      obj[dynamicElement.property] = dynamicElement
    }
    return obj
  }

  const removeLastDynamicRowIfNeeded = (dialogFormValues,
    elementKey,
    value,
    definitionManager,
    dialogDefinition
  ) => {
    const details = getDynamicRowsDetailsFromCurrentElement(dialogFormValues, elementKey, definitionManager, dialogDefinition)
    if (!details || value) {
      return
    }

    const editingFirstRow = !details.matchingValue.dynamicRowId
    const editingSecondLastDynamicRow = details.matchingValue.dynamicRowId && details.matchingValue.dynamicRowIndex === details.nextIndex - 2
    const elementsToDelete = []
    if (details.nextIndex > 0) {

      // Find elements in currently editing row that have no value
      let fieldsWithNoValueArray
      if (editingFirstRow && details.nextIndex === 1) {
        fieldsWithNoValueArray = requiredFieldsObjectsToArray(findFieldsWithNoValue(details.rowElements, valuesKeyValuePairToModel(dialogFormValues, true)))
      }
      if (editingSecondLastDynamicRow) {
        fieldsWithNoValueArray = findDynamicFieldsWithNoValues(dialogFormValues, details.matchingValue.dynamicRowId, details.matchingValue.dynamicRowIndex, details.rowElements)
      }

      // Find elements in the last dynamic row index if conditions are met on the currently edited row
      let elementsInLastRow
      if (details.row.dynamicRowConfig?.newRowRequiresAllFieldsToBeFilled) {
        if (fieldsWithNoValueArray?.length > 0) { // at least one empty field in the row
          elementsInLastRow = editingFirstRow
            ? findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, details.row.id, 0)
            : findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, details.matchingValue.dynamicRowId, details.nextIndex - 1)
        }
      }
      else {
        if (fieldsWithNoValueArray?.length === details.rowElements.length) { // all fields are empty in the row
          elementsInLastRow = editingFirstRow
            ? findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, details.row.id, 0)
            : findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, details.matchingValue.dynamicRowId, details.nextIndex - 1)
        }
      }

      if (elementsInLastRow) {
        // remove elements in the last row if they are ALL empty
        const emptyFieldsInLastRow = editingFirstRow
          ? findDynamicFieldsWithNoValues(dialogFormValues, details.row.id, 0, details.rowElements)
          : findDynamicFieldsWithNoValues(dialogFormValues, details.matchingValue.dynamicRowId, details.nextIndex - 1, details.rowElements)
        if (emptyFieldsInLastRow.length === elementsInLastRow.length) {
          for (const dynamicElement of elementsInLastRow) {
            elementsToDelete.push(dynamicElement.property)
            delete dialogFormValues[dynamicElement.property]
          }
        }
      }
    }

    return elementsToDelete
  }

  const findDynamicFieldsWithNoValues = (dialogFormValues, dynamicRowId, dynamicRowIndex, rowElements) => {
    const elementsAsArray = findDynamicElementsByDynamicRowIdAndRowIndex(dialogFormValues, dynamicRowId, dynamicRowIndex)
    const obj = elementsAsArrayToObj(elementsAsArray);
    const elementsModel = valuesKeyValuePairToModel(obj, true)
    const requiredElements = getRequiredElementsFromDynamicRows(rowElements, elementsModel)
    const fieldsWithNoValue = findFieldsWithNoValue(requiredElements, elementsModel)
    return requiredFieldsObjectsToArray(fieldsWithNoValue)
  }

  const getDynamicRowsDetailsFromCurrentElement = (dialogFormValues, elementKey, definitionManager, dialogDefinition) => {
    const matchingValue = dialogFormValues[elementKey]
    const row = matchingValue.dynamicRowId
      ? definitionManager.findRow(dialogDefinition, matchingValue.dynamicRowId)
      : definitionManager.findRowFromElementId(dialogDefinition, matchingValue.elementId)

    // return null if not dynamic row
    if (!row?.dynamicRowConfig?.enableRowRepeat) {
      return null
    }

    const rowElements = Enumerable.from(row.columns)
      .selectMany(c => c.elements)
      .toArray()
    const dynamicElements = findDynamicElementsByDynamicRowId(dialogFormValues, row.id)
    const nextIndex = dynamicElements?.length === 0
      ? 0
      : Enumerable.from(dynamicElements).max(e => e.dynamicRowIndex) + 1

    return {
      matchingValue,
      row,
      rowElements,
      dynamicElements,
      nextIndex,
    }
  }

  return {
    renderDynamicRows,
    findDynamicElementsByDynamicRowId,
    addFormValuesForDynamicRowIfNeeded,
    addRequiredElementsFromDynamicRows,
    onFormSubmit,
    removeLastDynamicRowIfNeeded,
  }
}