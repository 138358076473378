import { useState, useEffect } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import { alignmentOptions, resourceCategories, templateFeatureCode } from '../../utils/constants';
import React from 'react';
import { getResources } from '../../api/resources';
import { useFeature } from '../../hooks/useFeature';

export default function Properties({ initData, actions }) {
    const [data, setData] = useState();
    const [resourceOptions, setResourceOptions] = useState([]);
    const { hasFeature } = useFeature();

    useEffect(() => {
        setData(_.cloneDeep(initData))
    }, [initData]);

    useEffect(() => {
        load();
    }, [])

    const load = async () => {
        const resources = await getResources(resourceCategories.element);
        setResourceOptions(prev => ([{ name: 'None', value: null }, ...resources.map(x => ({ name: x.name, value: x.assetUrl, id: x.id }))]));
    }

    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

    const handleOnChange = (events, type) => {
        if (Array.isArray(events)) {
            actions.handleChanges(dialogDefinition, events, data.id, type)
            events.forEach(e => {
                const { name, value } = e.target;
                setProperty(data, name, value, type)
            });
        } else {
            const e = events;
            const { name, value } = e.target;
            actions.handleChange(dialogDefinition, e, data.id, type)

            setProperty(data, name, value, type)
        }

        setData(prevData => ({
            ...data
        }));
    }

    const handleResourcesChanged = () => {
        load();
    }

    return (
        <div className="popover-container-items">

            <Property.Group
                title='Information'>
                <Property.Label
                    label="Type"
                    value="Resource"
                />

                <Property.Text
                    onChange={handleOnChange}
                    label={'Property'}
                    name={'property'}
                    value={data?.property}
                />

                <Property.ResourceSelect
                    onChange={handleOnChange}
                    label={'Resource'}
                    name={'value'}
                    value={data?.value}
                    optionalPropSet={{ name: 'resourceId', optionsTarget: 'id' }}
                    options={resourceOptions}
                    resourceCategories={[resourceCategories.element]}
                    onResourcesChanged={handleResourcesChanged}
                    showContextMenu={hasFeature(templateFeatureCode)}
                />

            </Property.Group>

            <Property.Group title="Element Style">

                <Property.Text
                    onChange={(e) => handleOnChange(e, 'number')}
                    label={'Size'}
                    name={'width'}
                    value={data?.width}
                    type={'number'}
                />

                <Property.Checkbox
                    onChange={handleOnChange}
                    label={"Rounded corners"}
                    name={"roundedCorners"}
                    value={data?.roundedCorners}
                />

                <Property.Color
                    onChange={handleOnChange}
                    label={'Border color'}
                    name={'borderColor'}
                    initColor={data?.borderColor}
                />

                <Property.BorderStyleAndWidth
                    onChange={handleOnChange}
                    label={'Border'}
                    name={'borderWidth'}
                    value={data?.borderWidth}
                />

            </Property.Group>

            <Property.Group
                title='Placement'>

                <Property.Select
                    onChange={(e) => handleOnChange(e)}
                    label={"Alignment"}
                    name={"text.alignment"}
                    value={data?.text?.alignment}
                    options={alignmentOptions}
                />

            </Property.Group>
        </div>
    )
}
