import { Formik, Form, FieldArray } from 'formik';
import * as yup from "yup";
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from "../../components/Modal"
import { Button } from "../../components/Form/Button"
import { FormikTextInput } from '../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../components/Form/Formik/FormikSubmit';

export const Items = ({
  modalHelper,
  matrix,
  onSave,
}) => {
  const initialOption = { label: '' }
  const initialQuestion = { label: '' }

  const handleSave = async (values) => {
    await onSave(values)
    modalHelper.close()
  }
  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size={"medium"}
    >
      <Formik
        initialValues={{
          questions: matrix?.questions || [],
          options: matrix?.options || [],
        }}
        validationSchema={() => {
          return yup.object({
            questions: yup.array()
              .of(
                yup.object().shape(
                  {
                    label: yup.string().required("Required"),
                  }
                )
              )
              .required("Must have questions")
              .min(1, "Need at least 1 question"),
            options: yup.array()
              .of(
                yup.object().shape(
                  {
                    label: yup.string().required("Required"),
                  }
                )
              )
              .required("Must have options")
              .min(1, "Need at least 1 option"),
          })
        }}
        onSubmit={(values, actions) => {
          handleSave(values)
          actions.setSubmitting(false);
        }}
      >
        {
          props => (
            <Form>
              <div className='mb-6'>
                <h4 className='mb-2 font-bold'>Questions</h4>
                <FieldArray
                  name='questions'
                  render={arrayHelpers => (
                    <div>
                      {
                        !props.values?.questions?.length &&
                        <div>
                          <span className='mr-2'>No Questions are created.</span>
                          <Button text={"Add question"} theme='white' onClick={() => arrayHelpers.push(initialQuestion)} />
                        </div>
                      }
                      {
                        props.values?.questions?.map((q, index) => {
                          return (
                            <div
                              key={`question-${index}`}
                              className='flex'
                            >
                              <div className='flex-1'>
                                <FormikTextInput
                                  name={`questions[${index}].label`}
                                  formikProps={props}
                                />
                              </div>
                              <div className="flex ml-2">
                                <PlusCircleIcon className="w-5 ml-1 cursor-pointer" onClick={() => arrayHelpers.push(initialOption)} />
                                <TrashIcon className="w-5 ml-1 cursor-pointer" onClick={() => arrayHelpers.remove(index)} />
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                />
              </div>
              <hr />
              <div className='mb-2 mt-2'>
                <h4 className='mb-2 font-bold'>Options</h4>
                <FieldArray
                  name='options'
                  render={arrayHelpers => (
                    <div>
                      {
                        !props.values?.options?.length &&
                        <div>
                          <span className='mr-2'>No Options are created.</span> <Button text={"Add option"} theme='white' onClick={() => arrayHelpers.push(initialOption)} />
                        </div>
                      }
                      {
                        props.values?.options?.map((o, index) => {
                          return (
                            <div
                              key={`option-${index}`}
                              className='flex'
                            >
                              <div className='flex-1'>
                                <FormikTextInput
                                  name={`options[${index}].label`}
                                  formikProps={props}
                                />
                              </div>
                              <div className="flex ml-2">
                                <PlusCircleIcon className="w-5 ml-1 cursor-pointer" onClick={() => arrayHelpers.push(initialOption)} />
                                <TrashIcon className="w-5 ml-1 cursor-pointer" onClick={() => arrayHelpers.remove(index)} />
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                />
              </div>
              <div className='mt-5  sm:mt-6 flex justify-between'>
                <FormikSubmitButton
                  formikProps={props}
                  text={"Save"}
                />
                <Button
                  theme='white'
                  text={'Cancel'}
                  className='ml-2'
                  onClick={modalHelper.close}
                />
              </div>
            </Form>
          )
        }

      </Formik>
    </Modal>
  )
}