import { v4 as uuidv4 } from "uuid";

export const CompanyDetails = () => {
    return {
        id: uuidv4(),
        dialogObjectId: null,
        lockToDialogObject: false,
        space: null,
        backgroundColor: "",
        margin: "20px 0",
        padding: null,
        borderWidth: null,
        borderColor: null,
        roundedCorners: false,
        static: false,
        name: "Section-1",
        newPage: false,
        backgroundImage: null,
        backgroundSize: 0,
        backgroundRepeat: false,
        avoidBreakInside: false,
        rows: [
            {
                id: uuidv4(),
                columns: [
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 0,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: null,
                                dependencies: null,
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "s10a-title-1",
                                value: "xx Company",
                                placeholder: null,
                                borderWidth: null,
                                borderColor: null,
                                width: null,
                                height: null,
                                label: null,
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: false,
                                backgroundColor: null,
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 3,
                        span: null,
                        margin: "0",
                        padding: null,
                        borderColor: null,
                        roundedCorners: false,
                    },
                ],
                space: 5,
                gap: null,
                backgroundColor: "#f5f5f5",
                margin: "5px 0",
                padding: "10px",
                borderWidth: "0",
                borderColor: null,
                roundedCorners: true,
            },
            {
                id: uuidv4(),
                columns: [
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 2,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "vatnumber",
                                value: "New input text line",
                                placeholder: null,
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "VAT Number",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 3,
                        span: null,
                        margin: "0",
                        padding: "0 20px 0 0",
                        borderColor: null,
                        roundedCorners: false,
                    },
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 2,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "companyname",
                                value: "New input text line",
                                placeholder: null,
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "Company name",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 0,
                        span: 2,
                        margin: "0",
                        padding: null,
                        borderColor: null,
                        roundedCorners: false,
                    },
                ],
                space: 5,
                gap: null,
                backgroundColor: null,
                margin: "5px 0",
                padding: "0",
                borderWidth: "0",
                borderColor: null,
                roundedCorners: true,
            },
            {
                id: uuidv4(),
                columns: [
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 2,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "street",
                                value: "New input text line",
                                placeholder: null,
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "Street",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                            {
                                id: uuidv4(),
                                type: 15,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 5,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "country",
                                value: null,
                                placeholder: "Select from country",
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "Country",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: [
                                    "Value 1",
                                    "Value 2",
                                    "Value 3",
                                    "Value 4",
                                ],
                                optionWithValueAlphabeticalOrder: true,
                                optionWithValues: [
                                    {
                                        id: uuidv4(),
                                        display: "Norway",
                                        description: "Value 1",
                                        value: "Norway",
                                    },
                                    {
                                        id: uuidv4(),
                                        display: "Sweden",
                                        description: "Value 2",
                                        value: "Sweden",
                                    },
                                    {
                                        id: uuidv4(),
                                        display: "Denmark",
                                        description: "Value 3",
                                        value: "Denmark",
                                    },
                                    {
                                        id: uuidv4(),
                                        display: "Finland",
                                        description: "Value 4",
                                        value: "Finalnd",
                                    },
                                ],
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: 10,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 3,
                        span: 2,
                        margin: "0",
                        padding: "0 20px 0 0",
                        borderColor: null,
                        roundedCorners: false,
                    },
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 2,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "postalcode",
                                value: "New input text line",
                                placeholder: null,
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "Postalcode",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 0,
                        span: null,
                        margin: "0",
                        padding: "0 20px 0 0",
                        borderColor: null,
                        roundedCorners: false,
                    },
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 2,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: {
                                    order: 2,
                                    right: null,
                                },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                labelStyle: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 14,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0,
                                },
                                dependencies: {
                                    value: "none",
                                    type: 0,
                                },
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "postalcity",
                                value: "New input text line",
                                placeholder: null,
                                borderWidth: "1px",
                                borderColor: "#000",
                                width: null,
                                height: null,
                                label: "City",
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: true,
                                backgroundColor: "#fff",
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null,
                            },
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 0,
                        span: 2,
                        margin: "0",
                        padding: null,
                        borderColor: null,
                        roundedCorners: false,
                    },
                ],
                space: 5,
                gap: null,
                backgroundColor: null,
                margin: "5px 0",
                padding: "0",
                borderWidth: "0",
                borderColor: null,
                roundedCorners: true,
            },
        ],
        showLogicControl: null,
        hasAutomaticTranslationOff: false,
        readOnly: null,
    };
};
