import { useState } from "react"
import { useFileReader } from "../../hooks/useFileReader";
import { useToastAction } from "../../hooks/useToastAction";
import { Button } from "../Form/Button";
import Modal from "../Modal";

export const ImportFileModal = ({
  isOpen,
  onClose,
  onImportSuccess,
  importAction,
  title,
  schemaValidator,
  formatAccepted,
}) => {
  const [file, setFile] = useState();
  const [errors, setErrors] = useState();

  const fileReader = useFileReader()
  const toastAction = useToastAction()

  const onFileChange = async (e) => {
    try {
      const fileResult = await fileReader.handleFileInputChange(e)
      const jsonObject = JSON.parse(fileResult.content)
      const errors = schemaValidator && schemaValidator.validate(jsonObject)

      if (errors && errors.length > 0) {
        setErrors('Invalid file format')
        setFile(null)
      }
      else {
        setErrors(null)
        setFile({
          fileName: fileResult.fileName,
          content: jsonObject
        })
      }
    }
    catch (error) {
      setErrors("Invalid file format")
      setFile(null)
    }
  }

  const onImport = () => {
    toastAction?.execute(async () => {
      await importAction(file.content)
      setFile(null)
      onClose()
      onImportSuccess()
    }, "Failed to import", `Imported ${title}`)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Import ${title}`}
      size="small"
    >
      <div className="pt-2">
        {
          errors && <div className="text-red-500">{errors}</div>
        }
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-500">{file?.fileName ? `${file.fileName} will be imported` : 'Select file to import'}</span>
          </span>
          <div className="cursor-pointer w-24 inline-flex items-center mr-2 mt-3 tems-center px-3 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null">
            <div className="cursor-pointer absolute">
              <span className='cursor-pointer text-sm'>Select File</span>
            </div>
            <input type="file" className='cursor-pointer hover:cursor-pointer opacity-0 py-2 ' name="file" accept={formatAccepted} onChange={onFileChange} />
          </div>
        </div>
        <Button
          text="Import"
          className="mt-2"
          disabled={!file}
          onClick={onImport}
        />
      </div>
    </Modal>
  );
}