import { useNewIdGenerator } from "./useNewIdGenerator";
import * as Data from "../../../data";
import _ from 'lodash';
import { setProperty } from '../../../utils/property'
import { v4 as uuidv4 } from 'uuid';
import { useDefinitionManager } from "./useDefinitionManager";

export const useElementActions = (onSuccess, setElementContext, handleCurrentElementChange, themeStyle) => {
  const idGenerator = useNewIdGenerator();
  const definitionManager = useDefinitionManager()

  const transformSaveOptions = (saveOptions, propertyName) => {

    // API has validator to prevent duplicate property names.
    // If field being updated is called "property", we'll show the validation errors so user can see what is causing the problem.
    if (propertyName === "property") {
      saveOptions.showValidationErrors = true
    }
  }

  return {
    setElementContext: setElementContext,
    addLeft: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newElement = _.cloneDeep(Data.TextLineNew(themeStyle))
      idGenerator.newIdOnElement(newElement);

      let column = definitionManager.findColumnFromElement(payload, elementId)
      definitionManager.addItemAbove(column.elements, elementId, newElement)

      onSuccess(payload);
      setElementContext(newElement);
    },
    addRight: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newElement = _.cloneDeep(Data.TextLineNew(themeStyle))
      idGenerator.newIdOnElement(newElement);

      let column = definitionManager.findColumnFromElement(payload, elementId)
      definitionManager.addItemBelow(column.elements, elementId, newElement)

      onSuccess(payload);
      setElementContext(newElement);
    },
    remove: (dialogDefinition, elementId, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      let column = definitionManager.findColumnFromElement(payload, elementId)
      const container = definitionManager.findContainerFromColumn(payload, column.id)
      const row = definitionManager.findRowFromColumn(payload, column.id)
      const elementLength = column.elements.length;
      const columnLength = row.columns.length;
      const rowLength = container.rows.length;

      if (rowLength === 1 && columnLength === 1 && elementLength === 1) {
        //If only one line, column, and element exists in this container, delete the container
        definitionManager.removeItem(payload.containers, container.id)
      } else if (columnLength === 1 && elementLength === 1) {
        //If there is only one item in the column, delete the row
        definitionManager.removeItem(container.rows, row.id)
      } else if (columnLength !== 1 && elementLength === 1) {
        //If there are more than one column in the row and there is only one element in the column, delete the column.
        definitionManager.removeItem(row.columns, column.id)
      } else {
        //otherwise, delete the element
        definitionManager.removeItem(column.elements, elementId)
      }

      definitionManager.updateLogicControls(payload);

      if (!skipSave) {
        onSuccess(payload, { dismissActions: true });
      }
      return payload
    },
    hiddenOrShow: (dialogDefinition, elementId, hidden, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
       definitionManager.hiddenOrShowElement(payload, elementId, hidden);

       if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    duplicate: (dialogDefinition, elementId, element) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newElement = _.cloneDeep(element)
      idGenerator.newIdOnElement(newElement)

      let column = definitionManager.findColumnFromElement(payload, elementId)
      definitionManager.addItemBelow(column.elements, elementId, newElement)

      onSuccess(payload);
    },
    moveUp: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let column = definitionManager.findColumnFromElement(payload, elementId)
      let itemIndex = column.elements?.findIndex((item) => item.id === elementId);
      if (itemIndex === 0) {
        // is the first element of column
        const element = definitionManager.findElement(payload, elementId)
        const row = definitionManager.findRowFromElementId(payload, elementId)
        const beforeRow = definitionManager.findBeforeRowOfRowId(payload, row?.id) //find before row
        if (beforeRow) {
          definitionManager.removeItem(column.elements, elementId) // remove from current column

          if (beforeRow.columns.length > 1) {
            //if the previous row has more than one column
            const lastColumn = definitionManager.findLastColumn(beforeRow)
            definitionManager.addItem(lastColumn.elements, element)
          } else {
            //if the previous row has only one column
            definitionManager.addItem(beforeRow.columns[0].elements, element)
          }
        }
      }
      else {
        definitionManager.swapItems(itemIndex, itemIndex - 1, column.elements);
      }

      onSuccess(payload);
    },
    moveDown: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let column = definitionManager.findColumnFromElement(payload, elementId)
      let itemIndex = column.elements?.findIndex((item) => item.id === elementId);

      if (itemIndex === column.elements.length - 1) {
        // is the first element of column
        const element = definitionManager.findElement(payload, elementId)
        const row = definitionManager.findRowFromElementId(payload, elementId)
        const nextRow = definitionManager.findNextRowOfRowId(payload, row?.id) //find before row
        if (nextRow) {
          definitionManager.removeItem(column.elements, elementId) // remove from current column

          if (nextRow.columns.length > 1) {
            //if the previous row has more than one column
            const firstColumn = nextRow.columns[0]
            definitionManager.addItemByIndex(firstColumn.elements, element, 0)
          } else {
            //if the previous row has only one column
            definitionManager.addItemByIndex(nextRow.columns[0].elements, element, 0)
          }
        }
      }
      else {
        definitionManager.swapItems(itemIndex, itemIndex + 1, column.elements);
      }

      onSuccess(payload);
    },
    canMoveRight: (dialogDefinition, elementId) => {
      const column = definitionManager.findColumnFromElement(dialogDefinition, elementId)
      if (column?.elements[column.elements.length - 1].id === elementId) { // is the last element in the column
        const row = definitionManager.findRowFromColumn(dialogDefinition, column.id)
        const columnIndex = row.columns.findIndex(c => c.id === column.id)
        const nextColumn = row.columns[columnIndex + 1]

        if (nextColumn != null) { // if there is a column to the right of the current column
          return true
        }
      }
    },
    moveRight: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      const column = definitionManager.findColumnFromElement(payload, elementId)
      const element = column.elements.find(e => e.id === elementId)
      if (column.elements[column.elements.length - 1].id === elementId) { // is the last element in the column
        const row = definitionManager.findRowFromColumn(payload, column.id)
        const columnIndex = row.columns.findIndex(c => c.id === column.id)
        const nextColumn = row.columns[columnIndex + 1]

        if (nextColumn != null) { // if there is a column to the right of the current column
          definitionManager.removeItem(column.elements, elementId) // remove from current column
          if (nextColumn.elements.length > 0) {
            definitionManager.addItemAbove(nextColumn.elements, nextColumn.elements[0].id, element) // add above first element in next column
          }
          else {
            nextColumn.elements.push(element) // push into empty array
          }
          onSuccess(payload);
        }
      }
    },
    canMoveLeft: (dialogDefinition, elementId) => {
      const column = definitionManager.findColumnFromElement(dialogDefinition, elementId)
      if (column?.elements[0].id === elementId) { // is the first element in the column
        const row = definitionManager.findRowFromColumn(dialogDefinition, column.id)
        const columnIndex = row.columns.findIndex(c => c.id === column.id)

        if (columnIndex > 0) { // if there is a column to the left (has a lower index)
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    moveLeft: (dialogDefinition, elementId) => {
      const payload = _.cloneDeep(dialogDefinition)
      const column = definitionManager.findColumnFromElement(payload, elementId)
      const element = column.elements.find(e => e.id === elementId)
      if (column.elements[0].id === elementId) { // is the first element in the column
        const row = definitionManager.findRowFromColumn(payload, column.id)
        const columnIndex = row.columns.findIndex(c => c.id === column.id)

        if (columnIndex > 0) { // if there is a column to the left (has a lower index)
          const previousColumn = row.columns[columnIndex - 1];
          definitionManager.removeItem(column.elements, elementId) // remove from current column
          previousColumn.elements.push(element); // push element to end of previous column
          onSuccess(payload);
        }
      }
    },
    handleChange: (dialogDefinition, e, elementId, type) => {
      const { name, value } = e.target;
      const payload = _.cloneDeep(dialogDefinition)
      let saveOptions = {}

      transformSaveOptions(saveOptions, name);

      payload.containers?.forEach(container => {
        container?.rows.forEach(row => {
          row?.columns.forEach(column => {
            let element = column?.elements?.find(e => e.id === elementId);

            if(!element) {               
              column?.elements?.forEach(elementForConnectedElements => {
                elementForConnectedElements?.connectedElements?.forEach(connectedElement => {
                  if (connectedElement?.id === elementId) {
                    element = connectedElement;
                  }
                })
              })
            }

            if (element) {
              setProperty(element, name, value, type)
              onSuccess(payload, {
                ...saveOptions,
                currentElementId: element.id,
              });
            }
          });
        });
      });
    },
    handleChanges: (dialogDefinition, events, elementId, type) => {
      const payload = _.cloneDeep(dialogDefinition)
      let saveOptions = {}

      payload.containers?.forEach(container => {
        container?.rows.forEach(row => {
          row?.columns.forEach(column => {
            let element = column?.elements?.find(e => e.id === elementId);

            if(!element){
              column?.elements?.forEach(elementForConnectedElements => {
                elementForConnectedElements?.connectedElements?.forEach(connectedElement => {
                  if (connectedElement?.id === elementId) {
                    element = connectedElement;
                  }
                })
              })
            }

            events?.forEach(event => {
              const { name, value } = event.target;

              transformSaveOptions(saveOptions, name);

              if (element) {
                setProperty(element, name, value, type)
              }
            })
            if (element) {
              onSuccess(payload, {
                ...saveOptions,
                currentElementId: element.id,
              });
            }
          });
        });
      });
    },
    handleChangeFile: (dialogDefinition, e, elementId, type) => {
      const { name, value, label } = e.target;
      const payload = _.cloneDeep(dialogDefinition)
      let saveOptions = {}

      transformSaveOptions(saveOptions, name);

      payload.containers?.forEach(container => {
        container?.rows.forEach(row => {
          row?.columns.forEach(column => {
            let element = column?.elements?.find(e => e.id === elementId);

            if (element) {
              setProperty(element, name, value, type)
              setProperty(element, "label", label, type);
              onSuccess(payload, {
                ...saveOptions,
                currentElementId: element.id,
              });
            }
          });
        });
      });
    },
    handleAddElement: (dialogDefinition, element) => {
      element.id = uuidv4();
      const payload = _.cloneDeep(dialogDefinition)

      let lastContainer = payload.containers[payload.containers.length - 1];
      let lastRow = lastContainer.rows[lastContainer.rows.length - 1];
      let lastColumn = lastRow.columns[lastRow.columns.length - 1];

      lastColumn.elements.push(element);

      onSuccess(payload)
      setElementContext(element)
    },
    dragAndDrop: (dialogDefinition, sourceParentId, destinationParentId, indexFrom, indexTo, skipSave) => {
      const payload = _.cloneDeep(dialogDefinition)
      const sourceContainer = definitionManager.findColumn(payload, sourceParentId)
      if (sourceParentId === destinationParentId) {
        definitionManager.reorder(sourceContainer.elements, indexFrom, indexTo)
      } else {
        const destinationContainer = definitionManager.findColumn(payload, destinationParentId)
        definitionManager.reorderBetweenTwo(sourceContainer.elements, destinationContainer.elements, indexFrom, indexTo)
      }

      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    addFromSidebar: (dialogDefinition, newElement, columnId, columnIndexTo, skipSave) => {
      const payload = _.cloneDeep(dialogDefinition)

      const column = definitionManager.findColumn(payload, columnId)
      definitionManager.addItemByIndex(column.elements, newElement, columnIndexTo)

      if (skipSave) {
        return payload
      }
      onSuccess(payload);
      // handleCurrentElementChange(newElement)
    }
  }
}