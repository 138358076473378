import Enumerable from "linq";
import { useEffect, useState } from "react"
import { getDefinitionLanguages } from '../../api/public/definitionLanguage';
import { useToastAction } from "../../hooks/useToastAction";
import { getElementKey, getLanguageTermKey } from "../../utils/features";
import { validateDataDefaultMessage } from "../../utils/constants";
import _ from "lodash";
import { generateRowsFromTableValues } from "../../utils/elements";

export const useTranslations = (dialogKey, defaultLanguageCode = null) => {
  const [definitionLanguages, setDefinitionLanguages] = useState([])
  const [definitionLanguageOptions, setDefinitionLanguageOptions] = useState([])
  const [selectedDefinitionLanguageId, setSelectedDefinitionLanguageId] = useState(null)

  const loadAction = useToastAction()

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    loadAction.execute(async () => {
      const definitionLanguages = await getDefinitionLanguages(dialogKey)
      const languageOptions = Enumerable.from(definitionLanguages)
        .orderBy(d => d.language)
        .select(d => ({ name: d.language, value: d.id, languageCode: d.code, default: d.default }))
      setDefinitionLanguages(definitionLanguages);
      setDefinitionLanguageOptions(languageOptions.toArray())

      let selectedLanguageId
      if (defaultLanguageCode) {
        selectedLanguageId = languageOptions.firstOrDefault(l => l.languageCode === defaultLanguageCode)?.value
      }
      if (!selectedLanguageId) {
        const defaultLanguage = languageOptions.firstOrDefault(l => l.default === true)
        selectedLanguageId = defaultLanguage?.value || languageOptions.firstOrDefault()?.value
      }
      setSelectedDefinitionLanguageId(selectedLanguageId)
    }, "Failed to load languages")
  }


  return {
    isLoading: loadAction.isExecuting,
    definitionLanguageOptions,
    selectedLanguageOption: definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId),
    onLanguageChange: (languageId) => {
      setSelectedDefinitionLanguageId(languageId)
    },
    translateTerm: (element, fallbackText, propertyName) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (match && match[propertyName]) {
        return match[propertyName];
      }

      return fallbackText
    },
    translateOption: (element, optionId, fallbackText, propertyName) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (!match) {
        return fallbackText
      }

      const matchingOption = match.options?.find(o => o.optionId === optionId);
      if (matchingOption && matchingOption[propertyName]) {
        return matchingOption[propertyName]
      }

      return fallbackText
    },
    translateContainer: (element, fallbackText, propertyName) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.containerLanguageTerms.find(l => l.containerId === element.id)
      if (match && match[propertyName]) {
        return match[propertyName];
      }

      return fallbackText
    },
    translateHelpText: (element, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (match && match.helpText) {
        return match.helpText;
      }

      return fallbackText
    },
    translateCustomErrorMessage: (element, isRequired) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      let fallbackText;
      if (isRequired) {
        fallbackText = element?.customErrorMessage?.byRequired || "Is Required"
      } else {
        fallbackText = element?.customErrorMessage?.byValidation || validateDataDefaultMessage[element.validateData]
      }
      const errorType = isRequired ? 'byRequired' : 'byValidation'
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (match) {
        return match.customErrorMessage?.[errorType] ? match.customErrorMessage[errorType] : fallbackText;
      }

      return fallbackText
    },
    translateDigitalSigning: (digitalSigningFallbacks) => {

      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      const match = currentLanguage?.digitalSigningTerm;

      const digitalSigning = {
        message: match?.message || digitalSigningFallbacks?.message,
        description: match?.description || digitalSigningFallbacks?.description,
        firstColumn: match?.firstColumn || digitalSigningFallbacks?.firstColumn,
        secondColumn: match?.secondColumn || digitalSigningFallbacks?.secondColumn,
        error: match?.error || digitalSigningFallbacks?.error,
        add: match?.add || digitalSigningFallbacks?.add,
        cancel: match?.cancel || digitalSigningFallbacks?.cancel,
        submit: match?.submit || digitalSigningFallbacks?.submit,

        signNowMessage: match?.signNowMessage || digitalSigningFallbacks?.signNowMessage,
        signNowDescription: match?.signNowDescription || digitalSigningFallbacks?.signNowDescription,
        signNowBtn: match?.signNowBtn || digitalSigningFallbacks?.signNowBtn,
        askOtherBtn: match?.askOtherBtn || digitalSigningFallbacks?.askOtherBtn,
        signNowCancelBtn: match?.signNowCancelBtn || digitalSigningFallbacks?.signNowCancelBtn
      }

      return digitalSigning;
    },
    translateRow: (element, rowIndex, columnIndex, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))

      if (!match) {
        return fallbackText
      }

      const orderTable = _.cloneDeep(match);
      orderTable.rows = generateRowsFromTableValues(orderTable.rows, element.optionWithValues?.length);
      const matchingRow = orderTable?.rows[rowIndex][columnIndex]?.value;
      if (matchingRow) {
        return matchingRow;
      }

      return fallbackText
    },
    translateColumnsSmartTable: (element, columnId, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (!match) {
        return fallbackText
      }

      const matchingColumn = match?.smartObjectColumns.find(column => column.id === columnId)?.name;
      if (matchingColumn) {
        return matchingColumn;
      }

      return fallbackText
    },
    translateOptionForSmartTable: (element, columnId, optionIndex, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (!match) {
        return fallbackText
      }

      const matchingOption = match?.smartObjectColumns.find(column => column.id === columnId)?.dropdownOptions[optionIndex];
      if (matchingOption) {
        return matchingOption
      }

      return fallbackText
    },
    translateRichText: (element, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)
      if (!currentLanguage) {
        return fallbackText;
      }

      const match = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (match && match?.richTextValues?.contentInHtml) {
        return match.richTextValues.contentInHtml;
      }

      return fallbackText
    },
    translateMatrixOption: (element, optionId, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      if (!currentLanguage) {
        return fallbackText;
      }

      const languageTerm = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (!languageTerm) {
        return fallbackText
      }

      const option = languageTerm.matrixOptions?.find(q => q.id === optionId);
      if (!option?.label) {
        return fallbackText
      }

      return option.label;
    },
    translateMatrixQuestion: (element, questionId, fallbackText) => {
      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      if (!currentLanguage) {
        return fallbackText;
      }

      const languageTerm = currentLanguage.languageTerms.find(l => getLanguageTermKey(l) === getElementKey(element))
      if (!languageTerm) {
        return fallbackText
      }

      const question = languageTerm.matrixQuestions?.find(q => q.id === questionId);
      if (!question?.label) {
        return fallbackText
      }

      return question.label
    },
    translateProcess: (processFallbacks) => {

      const currentLanguage = definitionLanguages?.find(d => d.id === selectedDefinitionLanguageId)

      const match = currentLanguage?.processTerm;

      const process = {
        header: match?.header || processFallbacks?.header,
        description: match?.description || processFallbacks?.description,
        step: match?.step || processFallbacks?.step,
        current: match?.current || processFallbacks?.current,
        signingDescription: match?.signingDescription || processFallbacks?.signingDescription,
        signatory: match?.signatory || processFallbacks?.signatory,
      }

      return process;
    },
  }
}