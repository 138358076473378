import Modal from "../../../components/Modal";
import { Button } from "../../../components/Form/Button";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { useToastAction } from "../../../hooks/useToastAction";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { sendDialogValues } from "../../../api/dialogValues";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { securityType, processTypes, smartFormEnvironmentNames, smartFormEnvironments } from "../../../utils/constants";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useEffect, useRef, useState } from "react";
import { getParticipatingParties } from "../../../api/participatingParties";
import { FormikPhoneInput } from "../../../components/Form/Formik/FormikPhoneInput";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { featureFlags } from "../../../utils/features";

export const SendDialog = ({
  dialogDefinition,
  modalHelper,
}) => {
  const { oidcUser } = useReactOidc();
  const [participatingParties, setParticipatingParties] = useState([])
  const [existRequiredSmsAuthenticationInSteps, setExistRequiredSmsAuthenticationInSteps] = useState(false)
  const sendSmsRef = useRef();
  sendSmsRef.current = false;
  const receiverPhoneRef = useRef();
  const senderPhoneRef = useRef();
  const sendAction = useToastAction(true)
  const loadAction = useToastAction()
  const isMultiStepProcess = dialogDefinition?.process === processTypes.multiStep;
  const { oidcUser: { profile: { name, email } } } = useReactOidc();

  const environmentOptions = Object.keys(smartFormEnvironmentNames).map(k => ({ name: smartFormEnvironmentNames[k], value: k }))

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    loadAction.execute(async () => {
      if (isMultiStepProcess) {
        const participatingParties = await getParticipatingParties(dialogDefinition.id, { activeInSteps: true })
        let existSmsAuthentication = false;
        const mapped = participatingParties.map(p => {
          const { name, ...rest } = p

          if (p.securityOptions && p.securityOptions.some(so => so === securityType.phone)) {
            existSmsAuthentication = true
          }

          return {
            ...rest,
            partyName: name
          }
        })
        
        setExistRequiredSmsAuthenticationInSteps(existSmsAuthentication)
        setParticipatingParties(mapped)
      }
    }, "Failed to load")
  }

  const cancel = (evt) => {
    evt.preventDefault();
    modalHelper.close();
  }

  const onSendSmsClick = (evt) => {
    sendSmsRef.current = evt.target.checked;
  }

  const hasBankIdSecurityOption = (securityOptions) => {
    const securityOptionsVerify = securityOptions.find(so => so === securityType.bankId);
    return isMultiStepProcess && securityOptionsVerify ? true : false;
  };

  const hasPhoneSecurityOption = (securityOptions) => {
    const securityOptionsVerify = securityOptions.some(so => so === securityType.phone);
    return isMultiStepProcess && securityOptionsVerify ? true : false;
  };

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="large"
      title="Send dialog"
    >
      <div>
        <Formik
          initialValues={{
            receiverName: '',
            receiverEmail: '',
            receiverPhone: '',
            receiverSSN: '',
            senderName: name,
            senderEmail: email,
            senderPhoneNumber: '',
            privateSecret: oidcUser.profile.name,
            dialogValuesSecurity: securityType.none,
            sendEmail: true,
            sendSms: existRequiredSmsAuthenticationInSteps,
            isMultiStepProcess: isMultiStepProcess,
            parties: isMultiStepProcess ? participatingParties : [],
            environment: smartFormEnvironments.development,
          }}
          validationSchema={() => {
            return yup.object().shape({
              receiverPhone: yup.string().when(["isMultiStepProcess", "sendSms"], {
                is: (isMultiStepProcess, sendSms) => isMultiStepProcess === false && sendSms === true,
                then: yup.string().test("validate-phone-number", function (value) {

                  const { path, createError } = this;
                  const isValid = value ? isPossiblePhoneNumber(value) : false
                  if (!isValid) {
                    return createError({ path, message: 'Receiver phone number must be valid.' })
                  }

                  return true;
                }).required("Required"),
              }),
              receiverName: yup.string().when("isMultiStepProcess", {
                is: false,
                then: yup.string().required("Required")
              }),
              receiverEmail: yup.string().email().when("isMultiStepProcess", {
                is: false,
                then: yup.string().required("Required")
              }),
              privateSecret: yup.string().required("Required"),
              parties: yup.array()
                .of(
                  yup.object().shape({
                    name: yup.string().required("Required"),
                    email: yup.string().email("Must be an email").required("Required"),
                    phone: yup.string().when(["sendSms", "securityOptions"], {
                      is: (sendSms, securityOptions) => sendSmsRef.current === true || hasPhoneSecurityOption(securityOptions) || existRequiredSmsAuthenticationInSteps, // hasPhoneSecurityOption - need to use ref because inner yup arrays can't get values of outter object
                      then: yup.string().test("validate-phone-number", function (value) {

                        const { path, createError } = this;
                        const isValid = value ? isPossiblePhoneNumber(value) : false
                        if (!isValid) {
                          return createError({ path, message: 'Sender phone number must be valid.' })
                        }

                        return true;
                      }).required("Required")
                    })
                  })
                )
            })
          }}
          onSubmit={async (values, actions) => {
            delete values.isMultiStepProcess;
            sendAction.execute(async () => {
              const payload = {
                dialogDefinitionId: dialogDefinition.id,
                environment: +values.environment,
                dialogValuesSecurity: parseInt(values.dialogValuesSecurity),
                sendEmail: values.sendEmail,
                sendSms: values.sendSms,
                receiverEmail: values.receiverEmail,
                receiverName: values.receiverName,
                receiverPhone: values.receiverPhone,
                receiverSSN: values.receiverSSN,
                privateSecret: values.privateSecret,
                participatingParties: values.parties.map(p => {
                  return (
                    {
                      participatingPartyId: p.id,
                      email: p.email,
                      name: p.name,
                      phone: p.phone,
                      ssn: p.ssn
                    }
                  )
                })                
              }
              await sendDialogValues(payload)
              actions.setSubmitting(false)
              modalHelper.close();
            }, "Failed to send form", "Form has been sent!", "...Sending")
          }}
        >
          {
            props => (
              <Form>
                {
                  isMultiStepProcess && participatingParties.map((p, i) => {
                    return (
                      <div className={i > 0 ? 'mt-8' : ''}>
                        <div className="border-b border-gray-300 mb-4">
                          <h3 className="text-gray-900 text-md">{p.partyName}</h3>
                        </div>
                        <div className={i > 0 ? 'mt-4 grid grid-cols-2 gap-2' : 'grid grid-cols-2 gap-2'}>
                          <FormikTextInput
                            label={`Name`}
                            name={`parties[${i}].name`}
                            required={true}
                            formikProps={props}
                          />
                          <FormikTextInput
                            label={`Email`}
                            name={`parties[${i}].email`}
                            required={true}
                            formikProps={props}
                          />
                        </div>
                        <div className="mt-4 grid grid-cols-2 gap-2">
                          <FormikPhoneInput
                            label={`Phone`}
                            name={`parties[${i}].phone`}
                            required={sendSmsRef.current || hasPhoneSecurityOption(p.securityOptions)}
                            formikProps={props}
                            ref={senderPhoneRef}
                          />
                          {
                            hasBankIdSecurityOption(p.securityOptions) &&
                            <FormikTextInput
                              label={`SSN`}
                              name={`parties[${i}].ssn`}
                              required={true}
                              formikProps={props}
                            />
                          }
                        </div>
                      </div>
                    );
                  })
                }
                {
                  !isMultiStepProcess &&
                  <>
                    <FormikTextInput
                      label="Receiver name"
                      name="receiverName"
                      required={true}
                      formikProps={props}
                    />
                    <div className="mt-4">
                      <FormikTextInput
                        label="Receiver email"
                        name="receiverEmail"
                        required={true}
                        formikProps={props}
                      />
                    </div>
                    <div className="mt-4">
                      <FormikPhoneInput
                        label="Receiver phone number"
                        name="receiverPhone"
                        required={sendSmsRef.current}
                        formikProps={props}
                        ref={receiverPhoneRef}
                      />
                    </div>
                  </>
                }
                <div className="mt-10 pt-4 flex">
                  <FormikCheckbox
                    label="Send email"
                    name="sendEmail"
                    disabled={!props.values.sendSms}
                    formikProps={props}
                  />
                  <div className="ml-8">
                    <FormikCheckbox
                      label="Send SMS"
                      name="sendSms"
                      formikProps={props}
                      disabled={!props.values.sendEmail|| existRequiredSmsAuthenticationInSteps}
                      onClick={onSendSmsClick}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <FormikTextInput
                    label="Private Secret (can be replaced with your own)"
                    name="privateSecret"
                    required={true}
                    formikProps={props}
                  />
                </div>
                {
                  featureFlags.smartformEnvironment && (<div className="mt-4">
                  <FormikSelectInput
                    name={'environment'}
                    label={'Environment'}
                    formikProps={props}
                    options={environmentOptions}
                  />
                </div>)
                }
                <div className="mt-10 flex justify-end gap-2">
                  <Button
                    onClick={cancel}
                    text="Close"
                    theme="white"
                  />
                  <FormikSubmitButton
                    formikProps={props}
                    disabled={sendAction.isExecuting}
                    text={"Send"}
                  />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Modal>
  );
}