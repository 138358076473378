import React, { useContext } from "react";
import { FormContext } from "../../contexts";
import FormSignedBanner from "./formSignedBanner";
import PendingSignaturesBanner from "./pendingSignaturesBanner";

export default function DigitalSigningBanner() {
    const formContext = useContext(FormContext);

    if (!formContext) {
        return (<></>);
    }

    return formContext?.isPendingSignature ? (
        <PendingSignaturesBanner signatories={formContext?.documentSignatories} dialogValuesId={formContext?.dialogValues?.id} />
    ) :
        formContext?.formIsSigned ? (
            <FormSignedBanner signatories={formContext?.documentSignatories} dialogValuesId={formContext?.dialogValues?.id}/>
        ) : (<></>)
}