import { v4 as uuidv4 } from 'uuid';
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'
import { useClassNames } from "../hooks/useClassNames";
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/solid';

export default function AddExtraElementsPopup({ data, onChange, open, setOpen, addDescription, title = "Edit items", showSort = true }) {
  const [initOptions, setInitOptions] = useState([])
  const [options, setOptions] = useState([])
  const [alphabeticalOrderOptions, setAlphabeticalOrderOptions] = useState([])
  useEffect(() => {
    const clonedData = _.cloneDeep(data)
    setInitOptions(clonedData?.optionWithValues || [])
    setOptions(clonedData?.optionWithValues || [])
    setAlphabeticalOrderOptions(clonedData?.optionWithValueAlphabeticalOrder)
  }, [data])
  
  const [validationMessage, setValidationMessage] = useState();
  const classNames = useClassNames();

  const handleOnChange = (e) => {
    const { name, value } = e.target
    const optionType = e.target.getAttribute('data-option-type')
    let newOptions = [...options]

    if (newOptions.length > 0) {
      let option = newOptions.find(m => m.id === name)

      if (optionType === 'display') {
        option.display = value
      }
      else if (optionType === 'description') {
        option.description = value
      }
      else {
        option.value = value
      }
    }

    setOptions(newOptions)
  }

  const onAlphabeticalOrderChange = (e) => {
    const { checked } = e.target;

    onChange({
      target: {
        name: 'optionWithValueAlphabeticalOrder',
        value: checked
      }
    })
    setAlphabeticalOrderOptions(checked)
  }

  const createNewRow = () => {
    return {
      id: uuidv4(),
      display: "",
      description: "",
      value: ""
    }
  }

  const handleSave = () => {
    if (!isValid()) {
      setValidationMessage('Each value should be unique')
      return;
    }

    onChange({
      target: {
        name: 'optionWithValues',
        value: options
      }
    })

    setOpen(false)
  }

  const handleCancel = () => {
    let newOptions = [...(initOptions || [])]
    setOptions(newOptions)
    setValidationMessage('')
    setOpen(false)
  }

  const handleAdd = (index = -1) => {
    let newOptions = [...options]

    newOptions.splice(index + 1, 0, createNewRow())

    setOptions(newOptions);
  }

  const handleRemove = (id) => {
    setOptions(options.filter(m => m.id !== id))
  }

  const isValid = () => {
    const arrayValues = options?.map(opt => opt.value);
    return arrayValues?.length === new Set(arrayValues)?.size
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames.classNames(
                addDescription
                  ? 'sm:max-w-2xl'
                  : 'sm:max-w-2xl',
                'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6'
              )}
            >
              <div className='flex justify-between items-center'>

                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
                  {title}
                </Dialog.Title>
                {
                  showSort &&
                  <div className={`px-4 sm:px-6 pt-3 flex items-center justify-between flex-row-reverse`}>
                    <span className="flex-grow flex flex-col justify-center" id="availability-label">
                      <span className="text-sm font-medium text-gray-500 pl-2">Sort in alphabetical order</span>
                    </span>
                    <input
                      checked={alphabeticalOrderOptions}
                      onChange={onAlphabeticalOrderChange}
                      type="checkbox"
                      className="focus:ring-gray-400 focus:border-gray-400  h-4 w-4 text-indigo-600 border-gray-300 rounded mt-px"
                    />
                  </div>
                }

              </div>
              <div className="">
                {
                  validationMessage &&
                  <div className="rounded-md bg-red-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{validationMessage}</h3>
                      </div>
                    </div>
                  </div>
                }
                {
                  options?.length > 0 &&
                  <div className="grid grid-cols-11 gap-3 mb-1">
                    <div className="col-span-5">
                      Display
                    </div>
                    <div className="col-span-5">
                      Value
                    </div>
                    <div className="col-span-1"></div>
                  </div>
                }
                {
                  options?.map((option, index) => (
                    <div key={option.id} >
                      <div
                        className={classNames.classNames(
                          addDescription
                            ? 'mb-2 '
                            : 'mb-3',
                          'grid grid-cols-11 gap-3')}
                      >
                        <input
                          name={option.id}
                          type={"text"}
                          data-option-type={"display"}
                          value={option.display}
                          placeholder="Display..."
                          className={`col-span-5 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                          onChange={(e) => handleOnChange(e)}
                        />
                        <input
                          name={option.id}
                          type={"text"}
                          data-option-type={"value"}
                          value={option.value}
                          placeholder="Value..."
                          className={`col-span-5 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                          onChange={(e) => handleOnChange(e)}
                        />
                        <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                          <PlusCircleIcon className="w-5" onClick={() => handleAdd(index)} />
                          <TrashIcon className="w-5" onClick={() => handleRemove(option.id)} />
                        </div>
                      </div>
                      {
                        addDescription &&
                        <input
                          name={option.id}
                          type={"text"}
                          data-option-type={"description"}
                          value={option.description}
                          placeholder="Description..."
                          className={`focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md mb-5`}
                          onChange={(e) => handleOnChange(e)}
                        />
                      }
                    </div>

                  ))
                }

                {
                  options?.length < 1 &&
                  <div className="">
                    No options are created.
                    <button
                      type="button"
                      onClick={handleAdd}
                      className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                    >
                      Add option
                    </button>
                  </div>
                }

              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}