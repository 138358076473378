import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';

export default function Divider({ data, ...props }) {
    const classPrefix = "divider"
    const elementDesign = useElementDesign(data.id, data);
    const { borderAndWidthStyle } = useElementStyle(data);
    const style = borderAndWidthStyle()

    return (
        <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
            <div
                style={style}
            />
        </InlineActionbarElementWrapper>
    )
}