import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { javascriptIntegration } from "../../api/public/dialogValues";

export const Embedded = () => {
  let { token } = useParams();
  let [ message, setMessage ] = useState("Redirecting to SmartForm");

  useEffect(() => {
    getSmartFormUrl(token);
  }, [])

  const getSmartFormUrl = async (token) => {
    try {
      const paramsArray = [];
      const url = window.location.href;
      const params = new URLSearchParams(url);
      
      let isFirstParam = true;
      for (const [key, value] of params.entries()) {
        const isUrl = key === url;
        if(!isUrl) {
          const paramName = isFirstParam ? key.split('?').pop() : key;
          paramsArray.push({ [paramName]: value });
          if (isFirstParam) isFirstParam = false;
        }
      }

      const response = await javascriptIntegration(token, paramsArray)
      window.location = response.url;
    }
    catch(error) {
      setMessage("Unable to find SmartForm. Please try again later.");
    }
  }

  return (
    <main>
      { message } 
    </main>
  )
}