import { Form, Formik } from "formik";
import * as yup from "yup";
import React from "react";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { useDebouncedSave } from "../../../hooks/useDebouncedSave";
import { FormikFormObserver } from "../../../components/Form/Formik/FormikFormObserver";

const DigitalSigning = ({ dialogDefinition, onSaveDigitalSigning, digitalSigningProvider }) => {

  const { hasDigitalSigning,
    showSigningOnFormComplete,
    digitalSigningMessage,
    notificationLinkUponCompletion,
    lastPartyToSignFromSmartform,
    lastPartyDefineSignatories,
    digitalSigningSignNowBtnText, digitalSigningCancelBtnText, digitalSigningAskOtherBtnText,
    digitalSigningSignNowMessage, digitalSigningSignNowDescription,
    digitalSigningSubmit, digitalSigningAdd, digitalSigningError, digitalSigningCancel,
    digitalSigningDescription, digitalSigningFirstColumn, digitalSigningSecondColumn } = dialogDefinition;

  const debouncedSave = useDebouncedSave((values) => {
    onSaveDigitalSigning(values);
  })
  return (
    <Formik
      initialValues={{
        hasDigitalSigning,
        showSigningOnFormComplete,
        digitalSigningMessage: digitalSigningMessage || '',
        digitalSigningSignNowMessage: digitalSigningSignNowMessage || '',
        notificationLinkUponCompletion,
        lastPartyToSignFromSmartform,
        lastPartyDefineSignatories,
        digitalSigningSignNowDescription: digitalSigningSignNowDescription || 'I confirm i have have authority to sign.',
        digitalSigningSignNowBtnText: digitalSigningSignNowBtnText || 'Sign Now',
        digitalSigningAskOtherBtnText: digitalSigningAskOtherBtnText || 'Ask Somebody else',
        digitalSigningSubmit: digitalSigningSubmit || 'Submit',
        digitalSigningAdd: digitalSigningAdd || 'Add',
        digitalSigningCancel: digitalSigningCancel || 'Cancel',
        digitalSigningCancelBtnText: digitalSigningCancelBtnText || 'Cancel',
        digitalSigningError: digitalSigningError || 'Error',
        digitalSigningDescription: digitalSigningDescription || 'No parties',
        digitalSigningFirstColumn: digitalSigningFirstColumn || 'Name',
        digitalSigningSecondColumn: digitalSigningSecondColumn || 'Email'
      }}
      validationSchema={() => {
        return yup.object().shape({
          digitalSigningSignNowMessage: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningSignNowBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningCancelBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningAskOtherBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),

        });
      }}

      onSubmit={async (values, actions) => {
        onSaveDigitalSigning(values);
        actions.setSubmitting(false);
      }}
    >
      {
        props => (
          <FormikFormObserver onFormChange={(values) => debouncedSave.save(values)}>
            <Form>
              <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 ">
                <div className="shadow sm:rounded-md sm:overflow-hidden mb-6">
                  <div className="bg-white py-6 px-4 sm:p-6">
                    <div className="my-2">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">General</h3>
                    </div>

                    <div className="my-6 grid grid-cols-4 gap-6">

                      <div className="col-span-4 sm:col-span-4">
                        <FormikCheckbox
                          label={<>Require digital signing1.<span className="ml-2 font-bold">(Provider: {digitalSigningProvider?.name})</span></>}
                          secondaryLabel={`Smartform require digital signing`}
                          name="hasDigitalSigning"
                          formikProps={props}
                          styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                        />
                      </div>
                    </div>

                    {
                      props.values.hasDigitalSigning && (
                        <>
                          <div className="w-full border-t border-gray-200 my-5" />
                          <div className="my-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Configuration</h3>
                          </div>

                          <div className="my-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-2">
                              <FormikCheckbox
                                label="Notification link upon smartform completion"
                                secondaryLabel="Always send a notification link on e-mail to all parties that shall sign upon smartform completion"
                                name="notificationLinkUponCompletion"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikCheckbox
                                label="Allow last party in process to sign directly from smartform if a signator"
                                secondaryLabel=""
                                name="lastPartyToSignFromSmartform"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2 md:sm:col-span-2">
                              <FormikCheckbox
                                label="Allow last party in process to define e-mail adresses that shall sign the document"
                                secondaryLabel=""
                                name="lastPartyDefineSignatories"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>
                          </div>
                          <div className="w-full border-t border-gray-200 my-5" />
                          <div className="my-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Sign Now dialog</h3>
                          </div>
                          <div className="mt-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-4">
                              <FormikTextInput
                                label="Sign now message"
                                name="digitalSigningSignNowMessage"
                                formikProps={props}
                                rows={4}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <FormikTextInput
                                label="Sign now description"
                                name="digitalSigningSignNowDescription"
                                formikProps={props}
                                rows={4}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Sign now button"
                                name="digitalSigningSignNowBtnText"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Ask somebody else button"
                                name="digitalSigningAskOtherBtnText"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Cancel button"
                                name="digitalSigningCancelBtnText"
                                formikProps={props}
                              />
                            </div>
                          </div>
                          <div className="w-full border-t border-gray-200 my-5" />
                          <div className="my-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Define Signatories dialog</h3>
                          </div>
                          <div className="mt-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-4">
                              <FormikTextInput
                                label="Digital Signing message"
                                name="digitalSigningMessage"
                                formikProps={props}
                                rows={4}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="First Column"
                                name="digitalSigningFirstColumn"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Second Column"
                                name="digitalSigningSecondColumn"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Description"
                                name="digitalSigningDescription"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Error"
                                name="digitalSigningError"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Add button"
                                name="digitalSigningAdd"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Cancel button"
                                name="digitalSigningCancel"
                                formikProps={props}
                              />
                            </div>
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Submit button"
                                name="digitalSigningSubmit"
                                formikProps={props}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }


                  </div>
                </div>
              </div>
            </Form>
          </FormikFormObserver>
        )
      }
    </Formik >
  )
}

export default DigitalSigning;