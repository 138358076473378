import { v4 as uuidv4 } from 'uuid';

export const YesAndNoSelection = () => {
    return {
        name: "Selection Yes or No",
        container: {
            id: uuidv4(),
            dialogObjectId: null,
            lockToDialogObject: null,
            space: null,
            backgroundColor: "",
            margin: "20px 0",
            padding: null,
            borderWidth: null,
            borderColor: null,
            roundedCorners: false,
            static: false,
            name: "Section-c8f1",
            newPage: false,
            backgroundImage: null,
            backgroundSize: 0,
            backgroundRepeat: false,
            avoidBreakInside: false,
            rows: [
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableValueType: 0,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 18,
                                        bold: true,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                        width: 0,
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    orderTable: null,
                                    customErrorMessage: null,
                                    smartTable: null,
                                    smartTableHeaders: [],
                                    displayLabel: null,
                                    valueLabel: null,
                                    property: "HeadlineSignature",
                                    value: "xx Selection",
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: null,
                                    labelSetManually: false,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    workflowRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    preventDataInsert: false,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    connectedParentElementId: null,
                                    connectedElements: null,
                                    initialValue: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                    hideLabel: false,
                                    orderTableValue: null,
                                    hasAutomaticTranslationOff: false,
                                    connectedElement: null,
                                },
                            ],
                            contentAlign: null,
                            verticalAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: null,
                            margin: "0",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: "#f5f5f5",
                    margin: "5px 0",
                    padding: "10px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: true,
                },
                {
                    id: uuidv4(),
                    columns: [
                        {
                            id: uuidv4(),
                            borderWidth: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    inputType: 0,
                                    validateData: 0,
                                    country: 0,
                                    tableValueType: 0,
                                    dynamicListValues: null,
                                    video: null,
                                    richTextValues: null,
                                    bitmap: null,
                                    layout: { order: 2, right: null },
                                    text: {
                                        level: null,
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                        width: 0,
                                    },
                                    labelStyle: null,
                                    dependencies: null,
                                    orderTable: null,
                                    customErrorMessage: null,
                                    smartTable: null,
                                    smartTableHeaders: [],
                                    displayLabel: null,
                                    valueLabel: null,
                                    property: "txtSignature",
                                    value: 'In this series of questions, we\'ll delve into the realms of "yes," "no," and the intriguing realm of "don\'t know."\n',
                                    placeholder: null,
                                    borderWidth: null,
                                    borderColor: null,
                                    width: null,
                                    height: null,
                                    label: null,
                                    labelSetManually: false,
                                    helpText: null,
                                    leadingText: null,
                                    trailingText: null,
                                    labelTop: false,
                                    backgroundColor: null,
                                    roundedCorners: false,
                                    defaultCheckedState: null,
                                    isButton: null,
                                    margin: null,
                                    processStep: 0,
                                    signatureProcess: null,
                                    leadingIcon: null,
                                    minValue: null,
                                    maxValue: null,
                                    minLength: null,
                                    maxLength: null,
                                    rows: null,
                                    apiValidationId: null,
                                    apiRetrieveId: null,
                                    workflowRetrieveId: null,
                                    options: null,
                                    optionWithValueAlphabeticalOrder: false,
                                    optionWithValues: null,
                                    flowOptions: null,
                                    preventDataInsert: false,
                                    maxFileSizeInMb: null,
                                    readOnly: false,
                                    maxRows: null,
                                    connectedParentElementId: null,
                                    connectedElements: null,
                                    initialValue: null,
                                    lockRows: false,
                                    requiredField: null,
                                    requiredSignatures: [],
                                    hideLabel: false,
                                    orderTableValue: null,
                                    hasAutomaticTranslationOff: false,
                                    connectedElement: null,
                                },
                            ],
                            contentAlign: null,
                            verticalAlign: null,
                            backgroundColor: null,
                            space: 3,
                            span: null,
                            margin: "0",
                            padding: null,
                            borderColor: null,
                            roundedCorners: false,
                        },
                    ],
                    space: 5,
                    gap: null,
                    backgroundColor: null,
                    margin: "5px 0",
                    padding: "0 0 0 20px",
                    borderWidth: "0",
                    borderColor: null,
                    roundedCorners: true,
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2-1",
                                    value: " ",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 18,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-5-1",
                                    value: "Yes",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: true,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-4-1",
                                    value: "No",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: true,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            verticalAlign: "top",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-3-1",
                                    value: "Don't know",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 14,
                                        bold: true,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            contentAlign: "center",
                        },
                    ],
                    backgroundColor: "#ececec",
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2-2",
                                    value: "Question 1",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio1-Yes",
                                    value: "Yes",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio1-No",
                                    value: "No",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio1-Unknown",
                                    value: "Unknown",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "center",
                        },
                    ],
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2-3",
                                    value: "Question 2",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio2-Yes-1",
                                    value: "Yes",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio2-No-1",
                                    value: "No",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio2-Unknown-1",
                                    value: "Unknown",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "center",
                        },
                    ],
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2-4",
                                    value: "Question 3",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio3-Yes",
                                    value: "Yes",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio3-No",
                                    value: "No",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio3-Unknown",
                                    value: "Unknown",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "center",
                        },
                    ],
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2-5",
                                    value: "Question 4",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio4-Yes",
                                    value: "Yes",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio4-No",
                                    value: "No",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio4-Unknown",
                                    value: "Unknown",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "center",
                        },
                    ],
                },
                {
                    id: uuidv4(),
                    margin: "5px 0",
                    padding: "5px",
                    borderWidth: "0",
                    roundedCorners: true,
                    space: 5,
                    columns: [
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 0,
                                    property: "test-2",
                                    value: "Question 5",
                                    layout: { order: 2 },
                                    text: {
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 16,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                },
                            ],
                            span: 3,
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio5-Yes",
                                    value: "Yes",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio5-No",
                                    value: "No",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "left",
                        },
                        {
                            id: uuidv4(),
                            space: 0,
                            borderWidth: "0",
                            roundedCorners: false,
                            margin: "0",
                            elements: [
                                {
                                    id: uuidv4(),
                                    type: 27,
                                    property: "Radio5-Unknown",
                                    value: "Unknown",
                                    label: "",
                                    layout: { order: 2 },
                                    text: {
                                        level: "1",
                                        alignment: "left",
                                        fontFamily: "sans-serif",
                                        size: 1,
                                        bold: false,
                                        italic: false,
                                        underline: false,
                                        color: "#000",
                                    },
                                    labelSetManually: true,
                                },
                            ],
                            verticalAlign: "center",
                            contentAlign: "center",
                        },
                    ],
                },
            ],
            showLogicControl: null,
            hasAutomaticTranslationOff: false,
            readOnly: null,
        },
    };
};