import { useEffect } from "react"
import { useSearchDebounce } from "../../hooks/useSearchDebounce"

/**
 * @typedef {Object} Props
 * @prop {import("../../hooks/useServerSideTableActions").ServerSideTableActions} serverSideTableActions ServerSideTableActions hook
 * @prop {} translations
 */

/** 
 * @param {Props}
 */
export const TableServerSidePagination = ({ serverSideTableActions, translations = null }) => {
  const { totalRecordsCount,
    currentPageStart,
    currentPageEnd,
    pageSize,
    setPageSize,
    setPage,
    previousPage,
    nextPage,
  } = serverSideTableActions || {}  

  const page = useSearchDebounce(1000, pageSize)
  
  useEffect(() => {
    setPageSize(page.searchTerm)
  }, [page.debouncedSearchTerm])

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="capitalize">{translations?.showing || 'showing'}</span>
          <span className="font-medium"> {currentPageStart}</span> {translations?.to || 'to'}
          <span className="font-medium"> {currentPageEnd}</span> {translations?.of || 'of'}{' '}
          <span className="font-medium"> {totalRecordsCount || 0}</span> {translations?.results || 'results'}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end items-center">
        <div>
          <p className="text-sm text-gray-700">
            <span className="capitalize">Per page: </span>
            <input
              type="number"
              className="border text-sm mr-2 w-16 border-gray-300 rounded-md"
              value={page.searchTerm}
              onChange={(evt) => page.setSearchTerm(evt.target.value)}
            />
          </p>
        </div>
        <button
          disabled={previousPage == null}
          onClick={() => setPage(previousPage)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {translations?.previous || 'Previous'}
        </button>

        <button
          disabled={nextPage == null}
          onClick={() => setPage(nextPage)}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {translations?.next || 'Next'}
        </button>
      </div>
    </nav>
  )
}