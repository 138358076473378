import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import {
  createDialogDefinitionFromTemplate,
} from '../../api/dialogDefinition';
import { useToastAction } from '../../hooks/useToastAction';
import { useNewIdGenerator } from './DialogDesign/useNewIdGenerator';
import { getFileExtension } from '../../utils/file';
// var data = require('json!../../templates/dialogs/template-B.json');

export default function ImportDialog({ open, setOpen, folderId, dialogTemplate }) {
  const isTemplateMode = !!dialogTemplate;
  const [name, setName] = useState('')
  const [fileName, setFileName] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [requiredFieldErrors, setRequiredFieldErrors] = useState([]);
  const createDefinitionFromTemplateAction = useToastAction();
  const navigate = useNavigate()
  const idGenerator = useNewIdGenerator();

  const handleClose = () => {
    setOpen(false)
    setFileName(null);
    setName(null);
    setFileContent(null);
  }

  const handleImport = () => {
    let newDialogDefinition = JSON.parse(fileContent);
    idGenerator.newIdsOnDialog(newDialogDefinition);

    delete newDialogDefinition.id
    delete newDialogDefinition.name
    delete newDialogDefinition.dialogValueIds
    delete newDialogDefinition.createdDate
    delete newDialogDefinition.editState
    delete newDialogDefinition.metaData
    delete newDialogDefinition.status
    delete newDialogDefinition.updatedDate

    createDefinitionFromTemplateAction.execute(async () => {
      const verifiedFolderId = folderId === "-1" ? null : folderId;
      const payload = {
        name,
        ...newDialogDefinition
      }
      const result = await createDialogDefinitionFromTemplate(payload, verifiedFolderId);
      navigate(`/admin/dialog/${result.id}/design`);
    }, "Failed to create", "Created")
  }

  const getFileContent = file => {
    return new Promise(resolve => {
      let content = "";
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result;
        resolve(content);
      };

      reader.readAsText(file);
    });
  };

  const handleFileInputChange = e => {
    let newFile = e.target.files[0];

    getFileContent(newFile)
      .then(result => {
        newFile["base64"] = result;
        const fileExtension = getFileExtension(newFile?.name).toLowerCase();
        if (fileExtension === "json") {
          setFileName(newFile.name)
          setFileContent(result)
        } else {
          createDefinitionFromTemplateAction.toastError("Invalid file. Only JSON files can be imported")
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  const loadFromTemplateFile = () => {
    setFileContent(JSON.stringify(dialogTemplate.template));
  }

  useEffect(() => {
    if (!isTemplateMode) {
      return;
    }

    loadFromTemplateFile();
  }, [dialogTemplate])


  const contentIsValid = () => {

    return !!fileContent;

  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div div className="pb-4 border-b border-solid">
                <div className="mt-2 flex items-center justify-between">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {
                      isTemplateMode ? "Create Dialog" : "Import Dialog"
                    }
                  </Dialog.Title>
                </div>
              </div>
              <div className="">
                <div className="pt-2">
                  <div className="mt-2 flex items-center justify-between">
                    <span className="flex-grow w-24 flex flex-col" id="availability-label">
                      <span className="text-sm font-medium text-gray-500">Name</span>
                    </span>
                    <div className="mt-1 relative w-full rounded-md shadow-sm">
                      <input
                        type={"text"}
                        name="name"
                        value={name}
                        className={`focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md ${requiredFieldErrors.find(e => e === "name") ? 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500' : ''}`}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {
                  !isTemplateMode && (
                    <div className="pt-2">
                      <div className="mt-2 flex items-center justify-between">
                        <span className="flex-grow flex flex-col" id="availability-label">
                          <span className="text-sm font-medium text-gray-500">{fileName ? `${fileName} will be imported` : 'Select file to import'}</span>
                        </span>
                        <div className="cursor-pointer w-24 inline-flex items-center mr-2 mt-3 tems-center px-3 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null">
                          <div className="cursor-pointer absolute">
                            <span className='cursor-pointer text-sm'>Select File</span>
                          </div>
                          <input type="file" accept=".json" className='cursor-pointer hover:cursor-pointer opacity-0 py-2 ' name="file" onChange={handleFileInputChange} />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  disabled={!name || (!contentIsValid())}
                  className="inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                  onClick={handleImport}
                >
                  {
                    isTemplateMode ? "Create" : "Import"
                  }
                </button>
                <button
                  type="button"
                  className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400  sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}