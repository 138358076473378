import { validateDataTypes } from "./constants";
import { norway, sweden, denmark, finland } from "verifiera";
import { validateValues } from '../api/apiDefinition'
import { isValidBBAN, electronicFormatIBAN } from "ibantools";
import { isPossiblePhoneNumber } from "react-phone-number-input";

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url#answer-49849482
export const isValidURL = (string) => {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
}

// https://ui.dev/validate-email-address-javascript/
export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const isValidPhoneNumber = (phone) => {
  return /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(phone)
}

export const isValidInputTextLine = async (data, value, countryCode) => {
  switch (data?.validateData) {
    case validateDataTypes.text:

      if (typeof value === "string") {
        if (data.minLength && value.length < data.minLength)
          return false;

        if (data.maxLength && value.length > data.maxLength)
          return false;

        return true;
      }

      return false;

    case validateDataTypes.number:
      const isNumber = !isNaN(value);
      const hasMax = data.maxValue !== null;
      const hasMin = data.minValue !== null;
      
      if ((isNumber && (hasMax ? value <= data.maxValue : true) && (hasMin ? value >= data.minValue : true)) || (!data.requiredField && value === ""))
        return true;

      return false;

    case validateDataTypes.email:
      if (!isValidEmail(value)) {
        return false;
      }
      return true;

    case validateDataTypes.SSN:
      let ssnValidate = {}

      switch (data.country) {
        case 1:
          ssnValidate = sweden(value);
          break;
        case 2:
          ssnValidate = finland(value);
          break;
        case 3:
          ssnValidate = denmark(value);
          break;
        case 0:
        default:
          ssnValidate = norway(value);
          break;

      }

      return ssnValidate.validate();

    case validateDataTypes.Api:
      var response = await validateValues(data.apiValidationId, [{ property: data.property, value: value }])
      return response.isValid;

    case validateDataTypes.BBAN:
      if (countryCode) {
        const formattedValue = electronicFormatIBAN(value);
        return isValidBBAN(formattedValue, countryCode);
      }

      return false;

    default:
      break;
  }

  return true;
};

export const yupPhoneValidation = (yup, message = null) => {
  return yup.string().test("validate-phone-format", function (value) {
    const { path, createError } = this;
    const isValid = value ? isPossiblePhoneNumber(value) : false
    if (!isValid) {
      return createError({ path, message: message || "Invalid phone number" })
    }
    return true
  })
}