import './styles.scss'

//We are using iPhoneX as default but we can add more.
export const MobileView = ({ children }) => {
    return (
        <div className='flex items-center justify-center'>
        <div className="marvel-device iphone-x">
            <div className="notch">
                <div className="camera"></div>
                <div className="speaker"></div>
            </div>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="bottom-bar"></div>
            <div className="volume"></div>
            <div className="overflow">
                <div className="shadow shadow--tr"></div>
                <div className="shadow shadow--tl"></div>
                <div className="shadow shadow--br"></div>
                <div className="shadow shadow--bl"></div>
            </div>
            <div className="inner-shadow"></div>
            <div className="screen">{children}</div>
        </div>
        </div>
    );
};