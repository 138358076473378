import React, { useContext } from 'react'
import * as _ from 'lodash'

import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';

import { saveAs } from 'file-saver';
import { FormContext } from '../../contexts';
import { getDialogValueKey } from '../../utils/features';

export default function Attachment({ data, ...props }) {
  const formContext = useContext(FormContext)
  const elementDesign = useElementDesign(data.id, data)
  
  const formValue = formContext?.inputValues[getDialogValueKey(data)]?.value
  const value = formValue ? formValue : data.value

  const downloadBase64File = () => {
    if (!elementDesign.hasContext()) {
      saveAs(value, data?.label);
    }
  }

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`${elementDesign.elementsClasses()}`}
        onClick={elementDesign.onClick}
      >
        <div className="relative">
          <h3 className="text-sm font-semibold text-gray-800">
            <a href="#" className="hover:underline focus:outline-none" onClick={() => downloadBase64File()}>
              {elementDesign.translateTerm(data.label)}
            </a>
            {elementDesign.translateHelpText(data)}
          </h3>
          <p className="mt-1 text-sm text-gray-600 line-clamp-2">
            {elementDesign.translateTerm(data.placeholder, 'description')}
          </p>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
