import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import React, { useEffect, useState } from "react";
import { getDialogValuesSigningProvider, getPendingSignatures } from "../../api/public/dialogValues";

export default function PendingSignaturesBanner({  dialogValuesId }) {
    const { classNames } = useClassNames();
    const loadAction = useToastAction();
    const [pendingSignatures, setPendingSignatures] = useState()
    const [signingProvider, setSigningProvider] = useState();

    useEffect(() => {
        loadPendingSignatures();
    }, [])

    const loadPendingSignatures = () => {
        loadAction.execute(async () => {
            const [signaturesRes, signingProvider] = await Promise.all([getPendingSignatures(dialogValuesId),getDialogValuesSigningProvider(dialogValuesId)])
            setPendingSignatures(signaturesRes);
            setSigningProvider(signingProvider?.name);
        }, "Error loading pending signatures")
    }

    return (
        <div className="flex space-x-4 sm:space-x-10 bg-yellow-200 py-4 px-4 sm:px-6 rounded-md border border-yellow-600">
            <span className="flex items-center text-yellow-600">
                <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />
            </span>
            <div className={classNames("text-yellow-900 text-sm", pendingSignatures?.length > 0 ? "" : "flex items-center")} >
                <div className="grid grid-cols-2">
                    <div>
                    This document is pending one or more digital signatures.
                    {
                        pendingSignatures?.length > 0 && (
                            <div>
                                <div>Awaiting signatures from: </div>
                                <ul className="">
                                    {
                                        pendingSignatures.map(s => <li className="font-semibold">{s.emailAddress}</li>)
                                    }
                                </ul>
                            </div>
                        )
                    }
                    </div>
                    {
                        signingProvider && (<div className="flex justify-end items-end">
                        Signing provider: <span className="font-semibold ml-1">{signingProvider}</span>
                    </div>)
                    }
                </div>
            </div>
        </div >
    )
}