import { get, put, destroy, post, setHeader, download, BaseUrl, destroyWithHttpBody } from '../api'
import { config } from './publicConfig';

/**
 * @typedef {Object} LoadOptions
 * @prop {string} dialogDefinitionId
 * @prop {string} dialogValuesId
 * @prop {string?} stepId
 * @prop {string?} pdfJwt
 * @prop {string?} dialogValuesJwt
 * @prop {string?} stepJwt
 */
/**
 * @param {LoadOptions} options 
 */
export const loadForm = async (options) => {
  return post(`${config.basePath}/dialogValues/load`, options)
}

/**
 * @typedef {Object} LoadAuthenticationOptions
 * @prop {string} dialogDefinitionId
 * @prop {string} dialogValuesId
 * @prop {string?} stepId
 * @prop {string?} dialogValuesJwt
 */
/**
 * @param {LoadAuthenticationOptions} options 
 */
export const loadFormAuthentication = async (options) => {
  return post(`${config.basePath}/dialogValues/load/authentication`, options)
}

export const getDialogValuesSignUrl = async (id) => {
  return await get(`${config.basePath}/dialogValues/${id}/signUrl`);
}

export const getStep = async (id, stepId) => {
  return await get(`${config.basePath}/dialogValues/${id}/steps/${stepId}`);
}

export const getStepSignUrl = async (id, stepId) => {
  return await get(`${config.basePath}/dialogValues/${id}/steps/${stepId}/signUrl`);
}

export const updateDialogValues = async (id, values, isComplete, unauthorizedHandler, partiesToSign, deletedDialogValueProperties, SignNowViaUrl, hiddenContainersIds) => {
  return await put(`${config.basePath}/dialogValues/${id}`, { values, isComplete, partiesToSign, deletedDialogValueProperties, SignNowViaUrl, hiddenContainersIds }, buildStatusCodeHandler(unauthorizedHandler));
}

export const deleteValuesByElementIdentifiers = async (id, elementIdentifiers, unauthorizedHandler) => {
  return await destroyWithHttpBody(`${config.basePath}/dialogValues/${id}/values`, { deletedDialogValueProperties: elementIdentifiers }, buildStatusCodeHandler(unauthorizedHandler))
}

export const deleteDialogValuesFile = async (id, elementIdentifier, fileId, unauthorizedHandler) => {
  return await destroy(`${config.basePath}/dialogValues/${id}/values/${elementIdentifier}/files/${fileId}`, buildStatusCodeHandler(unauthorizedHandler));
}

export const saveDialogValuesSmartTable = async (id, elementIdentifier, values, unauthorizedHandler) => {
  return await post(`${config.basePath}/dialogValues/${id}/values/${elementIdentifier}/smarttable`, values, buildStatusCodeHandler(unauthorizedHandler));
}

export const uploadDialogValuesFile = async (id, elementIdentifier, file, unauthorizedHandler) => {
  const formData = new FormData();
  formData.append('file', file);
  return await post(`${config.basePath}/dialogValues/${id}/values/${elementIdentifier}/files`, formData, buildStatusCodeHandler(unauthorizedHandler));
}

export const resetDialogValues = async (id, unauthorizedHandler) => {
  return await put(`${config.basePath}/dialogValues/${id}/reset`, null, buildStatusCodeHandler(unauthorizedHandler));
}

export const generateSecurityToken = async (id) => {
  return await post(`${config.basePath}/dialogValues/${id}/securityTokens`)
}

export const generateSecurityTokenForContactList = async (id, phoneNumber) => {
  return await post(`${config.basePath}/dialogValues/${id}/securityTokens/contactlist?phoneNumber=${encodeURIComponent(phoneNumber)}`)
}

export const generateSecurityTokenForStep = async (id, stepId, securityOption, token, contactListContactId) => {
  return await post(`${config.basePath}/dialogValues/${id}/steps/${stepId}/securityTokens`, {
    securityOption,
    token,
    contactListContactId: contactListContactId || '',
  })
}

export const generateBankIdUrl = async (dialogId, dialogValuesId, dialogValuesStepId) => {
  return await post(`${config.basePath}/dialogValues/bankid/url`, { dialogId, dialogValuesId, dialogValuesStepId })
}

export const exchangeBankIdToken = async (valuesKey, currentStepKey, code, securityToken) => {
  return await post(`${config.basePath}/dialogValues/${valuesKey}/steps/${currentStepKey}/bankid/token`, { code, securityToken })
}

export const validateSecurityToken = async (id, securityTokenId, token) => {
  return await put(`${config.basePath}/dialogValues/${id}/securityTokens/${securityTokenId}`, {
    token
  })
}

export const validateSecurityTokenForStep = async (id, stepId, securityTokenId, token) => {
  return await put(`${config.basePath}/dialogValues/${id}/steps/${stepId}/securityTokens/${securityTokenId}`, {
    token
  })
}

export const javascriptIntegration = async (token, parameters) => {
  return await post(`${config.basePath}/javascriptIntegrations/v1/dialogValues`, { token, parameters}, {
    statusCodeHandlers: [{
      statusCode: 404,
      handler: () => console.log('Not found')
    }]
  })
}

export const getCurrentSecurityToken = async (id, unauthorizedHandler = null) => {
  return await get(`${config.basePath}/dialogValues/${id}/securityTokens/current`,
    BaseUrl.Api,
    unauthorizedHandler ? buildStatusCodeHandler(unauthorizedHandler) : null)
}

export const getCurrentSecurityTokenForStep = async (id, stepId) => {
  return await get(`${config.basePath}/dialogValues/${id}/steps/${stepId}/securityTokens/current`)
}

export const getStepSecurityOptions = async (id, stepId) => {
  return await get(`${config.basePath}/dialogValues/${id}/steps/${stepId}/securityOptions`)
}
export const getPendingSignatures = async (id) => {
  return await get(`${config.basePath}/dialogValues/${id}/pendingsignatures`)
}

export const getDialogValuesSigningProvider = async (id)=> {
  return await get(`${config.basePath}/dialogValues/${id}/signingprovider`);
}

export const getHasSignedPdf = async (id) => {
  return await get(`${config.basePath}/dialogValues/${id}/hassignedarchivedpdf`)
}

export const downloadSignedPdf = async (id, fileName) => {
  return await download(`${config.basePath}/dialogValues/${id}/signedarchivedpdf`, fileName)
}

export const deleteCurrentSecurityToken = async (id) => {
  return await destroy(`${config.basePath}/dialogValues/${id}/securityTokens/current`)
}

export const deleteCurrentSecurityTokenForStep = async (id, stepId) => {
  return await destroy(`${config.basePath}/dialogValues/${id}/steps/${stepId}/securityTokens/current`)
}

export const generatePdf = async (id, stepId = null, languageCode = null) => {
  if (stepId) {
    return await download(`${config.basePath}/dialogValues/${id}/steps/${stepId}/pdf?languageCode=${languageCode}`);
  }
  else {
    return await download(`${config.basePath}/dialogValues/${id}/pdf?languageCode=${languageCode}`);
  }
}

export const generatePdfForArchivedDialogValues = async (id, archiveId, languageCode = null) => {
  return await download(`${config.basePath}/dialogValues/${id}/archives/${archiveId}/pdf?languageCode=${languageCode}`);
}

export const updateDialogValuesPowerOfAttorney = async (dialogValuesId, values, stepId = null) => {
  return stepId
    ? await put(`${config.basePath}/dialogValues/${dialogValuesId}/steps/${stepId}/powerOfAttorney`, values)
    : await put(`${config.basePath}/dialogValues/${dialogValuesId}/powerOfAttorney`, values)
}

export function setDialogValuesHeader(token) {
  setHeader('Authorization', token)
}

const buildStatusCodeHandler = (handler) => {
  return {
    statusCodeHandlers: [{
      statusCode: 401,
      handler: handler
    }]
  }
}