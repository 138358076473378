import { useContext, useRef, useEffect, useState } from 'react'
import { useElementDesign } from "../useElementDesign"
import './styles.scss'
import { DialogModes, FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle';
import { getDialogValueKey } from '../../utils/features'
import { bitmapPositionTypes } from '../../utils/constants'

export default function HyperLink({ data, ...props }) {
  const classPrefix = "hyperLink"
  const formContext = useContext(FormContext);
  const { isEditMode } = useContext(DialogModes);
  const designElement = useElementDesign(data.id, data);
  const elementStyle = useElementStyle(data);

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'white'
  const widthStyle = data?.width ? `${data.width}px` : 'min-content'
  const isButton = data?.isButton;
  const bitmapValue = data?.bitmap?.value;
  const bitmapPosition = data?.bitmap?.position ?? bitmapPositionTypes.top;
  const { width:widthFromBorderAndWidth, ...borderStyles } = elementStyle.borderAndWidthStyle();
  const {width: widthFromTextStyle, ...textLineStyle} = elementStyle.textStyle(data?.text)
  const prefixClass = elementStyle.prefixClassName(classPrefix);
  const buttonRef = useRef();
  const [buttonWidth, setButtonWidth] = useState();

  const buttonStyle = {
    ...textLineStyle,
    ...borderStyles,
    backgroundColor: backgroundColorStyle,
    width: widthStyle,
  }

  const buttonClass = isButton ? `items-center px-6 py-3 border border-transparent cursor-pointer ${roundedCornerClass}` : ""
  const buttonTextClass = bitmapValue ? 'button-text flex items-center' : 'button-text';

  const imageElement = bitmapValue && isButton && (
    <img 
      src={bitmapValue}
      className={`button-image ${bitmapPosition === bitmapPositionTypes.left ? 'mr-2' : ''} ${bitmapPosition === bitmapPositionTypes.right ? 'ml-2' : ''}`} 
      alt='' 
      width={data?.bitmap?.width}
      style={{ maxWidth: buttonWidth ? `${buttonWidth / 2}px` : 'auto', maxHeight: '100%' }} // Adjust image size and flex properties
    />
  );

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth)
      console.log("setting width", buttonRef.current.offsetWidth)
    }
  }, [data?.width, data?.bitmap?.position]);

  return (
    <InlineActionbarElementWrapper designElement={designElement} openElementProperties>
      <a className={`element inline-block ${classPrefix} ${prefixClass} ${buttonClass}`}
        style={isButton ? buttonStyle : textLineStyle}
        href={!isEditMode ? data?.value : false}
        target="_blank"
        rel="noreferrer"
        ref={buttonRef}
      >
        <div className={`overflow-hidden ${bitmapPosition === bitmapPositionTypes.right || bitmapPosition === bitmapPositionTypes.left ? "flex justify-center items-center" : "flex flex-col justify-center items-center"} `}>
          {(bitmapPosition === bitmapPositionTypes.top || bitmapPosition === bitmapPositionTypes.left) && imageElement}
          <label className={buttonTextClass}>
            {designElement.translateTerm(formContext?.inputValues[getDialogValueKey(data)]?.label || data.label)}
            {designElement.translateHelpText(data)}
          </label>

          {(bitmapPosition === bitmapPositionTypes.right || bitmapPosition === bitmapPositionTypes.bottom) && imageElement}
        </div>
      </a>
    </InlineActionbarElementWrapper>
  )
}