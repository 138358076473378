import { BanIcon, CheckIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import MenuContextList from '../../../components/MenuContextList'
import { useFeature } from '../../../hooks/useFeature';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from '../../../components/Badge';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import * as Constants from '../../../utils/constants';

const TemplateListTableBody = ({ data, notificationEventTypes, onDelete, onActivate, onDisable, isExecuting, itemPath }) => {
  const { hasFeature } = useFeature();

  const resolveNotificationEventType = (notificationEventType) => {
    if (!notificationEventType) {
      return "";
    }

    return notificationEventTypes.find(item => item.value === notificationEventType)?.name || "";

  }
  const isActive = (dataItem) => {
    return dataItem.status !== 99;
  }

  const handleDeleteTemplate = (id) => {
    onDelete(id)
  }

  const handleActivateTemplate = (id) => {
    onActivate(id);
  }

  const handleDisableTemplate = (id) => {
    onDisable(id);
  }

  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {
        data.map(item =>
          <tr key={item?.id}>
            <td className="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden"
              style={{ maxWidth: "300px" }}
            >
              {
                <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                  <NavLink to={`./${item?.id}`}
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {item.name}
                  </NavLink>
                </span>
              }
            </td>
            <td className="px-6 py-5 whitespace-nowrap text-sm font-medium">
              <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                <Badge type={item?.status === 10 ? "success" : "error"} text={item?.status === 10 ? "Active" : "Inactive"} />
              </span>
            </td>
            <td className="px-6 py-5 whitespace-nowrap text-sm font-medium">
              <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {resolveNotificationEventType(item?.notificationEventType)}
              </span>
            </td>
            <td className="px-6 py-5 whitespace-nowrap text-sm font-medium">
              <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {
                  item?.isDefaultForType ? (<CheckIcon className='h-4 w-4 text-gray-400' />) : ("")
                }
              </span>
            </td>
            <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {stringToLocaleDateTimeString(item?.createdDate)}
              </span>
            </td>
            <td className="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {stringToLocaleDateTimeString(item?.updatedDate)}
              </span>
            </td>
            {
              hasFeature(Constants.designFeatureCode) && (
                <td className="pt-6 pb-10 whitespace-nowrap text-right text-sm font-medium absolute">
                  {
                    item?.id &&
                    <MenuContextList
                      element={item}
                      deleteType={"template"}
                      actions={[
                        isActive(item)
                          ? {
                            name: "Deactivate",
                            disabled: isExecuting || item?.isDefaultForType,
                            onClick: () =>
                              handleDisableTemplate(item.id),
                            icon: BanIcon,
                            lineAfter: true,
                          }
                          : {
                            name: "Activate",
                            disabled: isExecuting,
                            onClick: () =>
                              handleActivateTemplate(item.id),
                            icon: CheckIcon,
                            lineAfter: true,
                          },
                        {
                          name: "Edit",
                          href: `./${item?.id}`,
                          icon: PencilAltIcon,
                        },
                        {
                          name: "Delete",
                          disabled: item?.isDefaultForType,
                          onClick: () => handleDeleteTemplate(item.id),
                          icon: TrashIcon,
                        },
                      ]}
                    />
                  }
                </td>
              )
            }
          </tr>
        )
      }
    </tbody>
  )
}
export default TemplateListTableBody;