import { destroy, get, post, put } from './api'


export const getAllDialogTemplates = async () => {
    return await get(`dialogTemplates`);
}

export const getDialogTemplate = async (id) => {
    return await get(`dialogTemplates/${id}`)
}

export const getAllActiveDialogTemplates = async () => {
    return await get(`dialogTemplates/active`);
}

export const createDialogTemplate = async (dialogTemplate, name) => {
    return await post(`dialogTemplates`, {
        ...dialogTemplate,
        name,
    });
}

export const importDialogTemplate = async (fileContent) => {
    return await post(`dialogTemplates`, fileContent);
}

export const deleteDialogTemplate = async (id) => {
    return await destroy(`dialogTemplates/${id}`)
}
export const getDialogTemplateSchema = async () => {
    return await get(`dialogTemplates/schema`)
}

export const updateDialogTemplateName = async (id, name) => {
    return await put(`dialogTemplates/${id}/name`, {
        name
    })
}

export const updateDialogTemplate = async (id, dialogTemplate) => {
    return await put(`dialogTemplates/${id}`, dialogTemplate)
}

export const activateDialogTemplate = async (id) => {
    return await put(`dialogTemplates/${id}/activate`)
}

export const deactivateDialogTemplate = async (id) => {
    return await put(`dialogTemplates/${id}/deactivate`)
}