import React from "react"
import ColorPicker from "../../../../../components/ColorPicker"
import BorderPicker from "../../../../../components/BorderPicker"

export const PropertyOfStandardWTable = ({data, handleChange}) => {
console.log(data)
    const handleOnChange = (e, valueType) => {
        handleChange(e, 'standardWTable', valueType)
    }

  return (
    <div className="w-full">
        <div className="  font-medium text-gray-900">
            Element style for Standard w/Table
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Headline Background color</span>
          </span>
          <ColorPicker
            name='headlineBackgroundColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.headlineBackgroundColor} />
        </div>   
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Headline Text color</span>
          </span>
          <ColorPicker
            name='headlineTextColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.headlineTextColor} />
        </div>     

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Body Background color</span>
          </span>
          <ColorPicker
            name='bodyBackgroundColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.bodyBackgroundColor} />
        </div>
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Body Text color</span>
          </span>
          <ColorPicker
            name='bodyTextColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.bodyTextColor} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Rounded corners</span>
          </span>
          <input
            name='roundedCorners'
            type='checkbox'
            checked={data?.roundedCorners}
            onChange={(e) => handleOnChange(e, 'boolean')}
            className="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 border-gray-300 rounded mt-px"
          >
          </input>
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Border color</span>
          </span>
          <ColorPicker
            name='borderColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.borderColor} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Border width</span>
          </span>
          <BorderPicker name={"borderWidth"} value={data?.borderWidth} onChange={handleOnChange} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Selected item Border color</span>
          </span>
          <ColorPicker
            name='selectedBorderColor'
            onChange={handleOnChange}
            alignRight={true}
            initColor={data?.selectedBorderColor} />
        </div>
             
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Selected item Border width</span>
          </span>
          <BorderPicker name={"selectedBorderWidth"} value={data?.selectedBorderWidth} onChange={handleOnChange} />
        </div>
    </div>
  )
}