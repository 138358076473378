import React, { useContext, useState } from "react";
import Modal from "../../components/Modal";
import { FormContext } from "../../contexts";

const SigningReadyModal = ({ modalHelper, signUrl }) => {
    const [validationMessage, setValidationMessage] = useState();
    const { digitalSigningValues, step, ...formContext } = useContext(FormContext);
    const digitalSigningTranslations = formContext?.translateDigitalSigning(digitalSigningValues);

    const handleClose = () => {
        setValidationMessage('')
        modalHelper.close();
    }


    const handleSubmit = (e) => {
        modalHelper.close();
    }
    const handleAskOther = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={handleClose}
            size="large"
            title={digitalSigningTranslations?.signNowMessage || "Parties to Sign"}
        ><>
                <div>
                    {'Signering tilgjengelig. Hvis dette er allerede gjort kan du ignorere dette. Automatisk åpning kan bli stoppe av et blokkert vindu'}
                </div>
                <div className="font-semibold hover:underline text-indigo-600">
                    <a href={signUrl} target="_blank" rel="noreferrer" className="flex items-center space-x-1">
                        <span>Go to signing</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>
                    </a>

                </div>

                <div className="mt-5 flex justify-end items-center space-x-2">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        {'Close'}
                    </button>
                </div>
            </>
        </Modal >
    );
}
export default SigningReadyModal;