import React, { useContext, useState } from "react";
import Modal from "../../components/Modal";
import { FormContext } from "../../contexts";
import Loader, { LoaderThreeDots } from "../../components/Loader";

const SignNowModal = ({ modalHelper, onSignNow }) => {
    const [validationMessage, setValidationMessage] = useState();
    const { digitalSigningValues, step, ...formContext } = useContext(FormContext);
    const digitalSigningTranslations = formContext?.translateDigitalSigning(digitalSigningValues);
    
    const handleClose = () => {
        setValidationMessage('')
        modalHelper.close();
    }

    
    const handleSubmit = (e) => {
        e.preventDefault();  
        e.stopPropagation();
        onSignNow(e);
    }
    const handleAskOther = (e) => {
        e.preventDefault(); 
        e.stopPropagation();
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={handleClose}
            size="large"
            title={digitalSigningTranslations?.signNowMessage}
        >
            <>
                <div>
                    {digitalSigningTranslations?.signNowDescription ?? 'I confirm I have authority to sign this document'}
                </div>

                <div className="mt-5 flex justify-end items-center space-x-2">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        {digitalSigningTranslations?.signNowBtn}
                    </button>
                    {/*Commented out until functionality is implemented*/}
                    {/* <button
                                    onClick={handleAskOther}
                                    type="button"
                                    className=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    {digitalSigningTranslations?.askOtherBtn ?? 'Send to signing'}
                                </button> */}
                    <button
                        onClick={handleClose}
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        {digitalSigningTranslations?.signNowCancelBtn}
                    </button>
                </div>
            </>
        </Modal >
    );
}
export default SignNowModal;