import Clock from './Clock'
import Duplicate from './Duplicate'
import Exclamation from './Exclamation'
import QuestionMark from './QuestionMark'
import CheckMark from './CheckMark'
function IconFromType({type, ...props}){
    switch (type) {
        case 0:
            return <Clock />
        case 1:
            return <Duplicate />
        case 2:
            return <Exclamation />
        case 3:
            return <QuestionMark />
        case 4:
            return <CheckMark />
        default:
            return <></>
    }
}

export default function Icon({type, ...props}) {
    return (
        <IconFromType type={type} />
    )
}
