import { useContext, useRef } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { useDoubleClick } from "../../hooks/useDoubleClick";
import { ElementContext } from "../../contexts";

export const InlineActionbarElementWrapper = ({ designElement, children, openElementProperties = false, additionalClasses = ''}) => {
  const { setDesignElementForContextMenu, setShowRightClickMenu, setRightClickMenuPosition } = useContext(ElementContext) || {};
  
  const ref = useRef();

  const handleSingleClick = (e) => {
    setShowRightClickMenu(false); 
    designElement.onClick(e, openElementProperties);
  }

  const hybridClick = useDoubleClick({
      click: (e) => handleSingleClick(e),
      doubleClick: (e) => designElement?.onDoubleClick(e),
      tripleClick: (e) => designElement?.onTripleClick(e),
      from: designElement
  });

  const handleContextMenu = (e) => {
    if(designElement.isLocked()) return e.preventDefault();
    
    if (setShowRightClickMenu) {
      if(!designElement?.data?.connectedParentElementId) {
        e.preventDefault(); // prevent the default behaviour when right clicked
        designElement.onClick(e, openElementProperties)
        setShowRightClickMenu(true);

        let elementForContextMenu = designElement;
        setDesignElementForContextMenu(elementForContextMenu);

        setRightClickMenuPosition({
          x: e.pageX,
          y: e.pageY,
        });
      }
    }
  }

  useClickOutside(ref, () => {
    designElement.removeActionBarSelectedElement();
    setShowRightClickMenu && setShowRightClickMenu(false);
  });

  return (
    <div 
      ref={ref} 
      onClick={(e) => {designElement?.isActive() && hybridClick(e)}} 
      className={`${additionalClasses} ${designElement.elementsClasses()}`}
      onContextMenu={handleContextMenu}
    >
      {/* <InlineActionbar type={'element-actionbar'} close={close} isOpen={designElement.isActionBarSelectedElement()} /> */}
      <div>
        {children}
      </div>
      {/* <Popover>
        <Popover.Button>Open</Popover.Button>
        <Popover.Panel>
          <div>Content</div>
        </Popover.Panel>
      </Popover> */}
    </div>
  );
}