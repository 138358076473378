import { useState, useEffect, useContext, useRef } from 'react'
import { useClassNames } from '../../../hooks/useClassNames';
import ContentElement from './Tabs/contentElement';
import Theme from './Tabs/theme';
import Actions from './Tabs/actions';
import { DesignActionbarContext } from '../../../contexts';
import { debounce } from 'lodash';
import ContentObjects from './Tabs/contentObjects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/outline';

export default function SidebarRight({ activeTab, setActiveTab, propertiesToShow, schemaErrors, data, dialogDefinition, setIsMobileVisible, isMobileVisible, disabled = false, }) {

  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    disableSettings
  } = designActionbarContext || {}

  let obj = {};
  obj[data?.id] = propertiesToShow
  const [properties, setProperties] = useState(obj)
  const classNames = useClassNames();
  const posicionRef = useRef(null);
  const [addComponentScrollPosition, setAddComponentScrollPosition] = useState(0)

  const handleScroll = debounce((e) => {
    const currentElementId = posicionRef.current.firstChild?.id;
    if (activeTab === "add" && currentElementId === "contentElement") {
      setAddComponentScrollPosition(posicionRef.current.scrollTop);
    }
  }, 100)

  useEffect(() => {
    /**
     * For some reason React wasn't re-rendering children propertiesToShow even though they were changing, and it only happened when clicking between
     * two of the same element types or rows/columns. I confirmed that 'propertiesToShow' was updating and so was 'data', but for some reason it wouldn't
     * render the changes. 
     * Made this "hack" to render from state based off of 'data.id' and it seems to work.
     * Really not sure why it wasn't just working as expected.
     */
    let obj = {};
    obj[data?.id] = propertiesToShow
    setProperties({ ...obj })
  }, [propertiesToShow])

  // console.log('SCHEMA ERRORS', schemaErrors);

  const tabs = [
    { name: 'Items', href: '#', current: activeTab === 'items', tab: 'items' },
    { name: 'Objects', href: '#', current: activeTab === 'objects', tab: 'objects' },
    { name: 'Properties', href: '#', current: activeTab === 'properties', tab: 'properties' },
    { name: 'Theme', href: '#', current: activeTab === 'theme', tab: 'theme', disabled: disableSettings },
  ]

  const propertiesEmptyState = () => {
    return (
      <div className='h-full px-4 sm:px-6 '>
        No properties to show. <br />
        Please pick an element or row/column.
      </div>
    )
  }

  const contentElementToSavedScrolling = () => {
    posicionRef.current.scrollTo({
      top: addComponentScrollPosition,
      behavior: "smooth",
    })
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case "properties":
        const renderedProps = properties[data?.id] === 'element' ? propertiesEmptyState() : properties[data?.id]
        return renderedProps;
      case "items":
        return <ContentElement finishedLoadCustomObject={contentElementToSavedScrolling} />
      case "objects":
        return <ContentObjects finishedLoadCustomObject={contentElementToSavedScrolling} />
      case "theme":
        return <Theme dialogDefinition={dialogDefinition} />

      default:
        return <></>;
    }
  };

  return (
    <div className={classNames.classNames(isMobileVisible ? "" : "hidden", "relative xl:block w-full h-full")}>
      <div className="absolute ml-2px  -left-9 xl:hidden top-16">
        <button
          type="button"
          className="border bg-gray-50 border-gray-300 border-r-0 p-2 shadow-md rounded-tl-md rounded-bl-md py-4"
          onClick={()=>{setIsMobileVisible(false)}}
        >
          <ChevronDoubleRightIcon className='h-4' />
        </button>
      </div>
      <div className="h-full flex flex-col bg-gray-50 shadow-xl">
        <div className='py-2 px-3 w-full border-b'>
          <div className="block pt-2 pb-2">
            <nav className="flex space-x-2 max-w-full overflow-x-auto" aria-label="Tabs">
              {tabs.filter(m => !m.disabled).map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => setActiveTab(tab.tab)}
                  className={classNames.classNames(
                    tab.current ? 'bg-gray-200 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'px-3 py-1 font-medium text-sm rounded-md'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {
          schemaErrors?.length > 0 &&
          <div className="px-4 sm:px-6 text-red-500">
            Invalid input. Cannot save design.
          </div>
        }
        <div ref={posicionRef} onScroll={handleScroll} className="my-6 flex-1 overflow-y-scroll">
          {!disabled && renderActiveTab()}
        </div>
      </div>
    </div>
  )
}

export const MobileRightSidebarToggle = ({ onSidebarToggle, }) => {

  return (
    <aside className=" xl:hidden lg:-right-8 top-16 -right-6 absolute z-50">
      <button
        type="button"
        className="bg-gray-50 border border-gray-300 p-2 py-4 shadow-md"
        onClick={onSidebarToggle}
      >
        <FontAwesomeIcon icon={faToolbox} />
      </button>
    </aside>
  )
}