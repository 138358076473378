import { useContext } from 'react'
import { DesignActionbarContext, GlobalDndContext } from '../../contexts';
import { Button as FormButton } from '../Form/Button';
import IconButton from '../IconButton';
import { ShowHideGroup } from "../ShowHideGroup";
import { SidebarButton } from '../SidebarButton';
import { DndSortable } from '../../components/DragAndDrop/sortable';

/**
 * @param {*} objectGroup 
 */
export const ObjectGroup = ({
  handleOnClick,
  label,
  objectsToAdd,
  hasShowAndHide = true,
  useFormButton = true,
  defaultOpen = false,
  useGrid = false,
  hideWhenEmpty = false,
  isHidden,
  enableDragAndDrop = false,
  dndType = null,
  id = null,
}) => {
  const globalDndContext = useContext(GlobalDndContext)
  const { themeStyleSelected } = useContext(DesignActionbarContext);
  
  return (
    <>
      {
        !isHidden && hasShowAndHide && (!hideWhenEmpty || objectsToAdd.length > 0) &&
        <ShowHideGroup
          id={id}
          label={`${label} (${objectsToAdd.length})`}
          defaultOpen={defaultOpen}
          useGrid={useGrid}
          hideWhenEmpty={hideWhenEmpty}
        >
          {
            objectsToAdd?.map((objectToAdd, i) => {
              const button = (
                useFormButton
                  ? <FormButton
                    key={i}
                    onClick={() => handleOnClick(objectToAdd.toAdd(themeStyleSelected?.config))}
                    text={objectToAdd.text}
                    icon={objectToAdd.icon}
                    useNewLine={true}
                    theme="white"
                  />
                  : <SidebarButton
                    key={i}
                    text={objectToAdd.text}
                    onClick={objectToAdd.onClick || (() => handleOnClick(objectToAdd.toAdd(themeStyleSelected?.config)))}
                    color={objectToAdd.color}
                    addSeparator={objectToAdd.addSeparator}
                    icon={objectToAdd.icon}
                  />
              )

              if (enableDragAndDrop && dndType) {
                const dndSidebarElement = globalDndContext.getElement(dndType, objectToAdd.text)
                return (
                  <DndSortable
                    type={dndType}
                    id={`${dndType}-${dndSidebarElement?.dndId}`}
                    name={objectToAdd?.text}
                    key={`${dndType}-${dndSidebarElement?.dndId}-${i}`}
                  >
                    {button}
                  </DndSortable>
                )
              }
              else {
                return (
                  <>
                    {button}
                  </>
                )
              }
            })
          }
        </ShowHideGroup>
      }

      {
        // hasShowAndHide is never set to false, and is defaulted to true. What is the point of the code below?
        !isHidden && !hasShowAndHide && objectsToAdd?.map((objectToAdd, i) => {
          return (
            useFormButton ?
              <FormButton
                key={i}
                onClick={() => handleOnClick(objectToAdd.toAdd())}
                text={objectToAdd.text}
                icon={objectToAdd.icon}
                useNewLine={true}
                theme="white"
              />
              :
              <IconButton icon={objectToAdd.icon} label={objectToAdd.text} onClick={() => handleOnClick(objectToAdd.toAdd())} />
          )
        })
      }
    </>
  );
}