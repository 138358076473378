import React from "react";
import { TextAreaInput } from "../../../components/Form/TextAreaInput";

export default function DigitalSigningLanguage({ matchingTerm, onTermChange, language, selectedElement, isDisabled }) {
    return (
        <>
            <div>
                <div className="my-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Define Signatories dialog</h3>
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signNowMessage}
                        label="Sign Now Message"
                        onChange={(value) => onTermChange('signNowBtn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signNowDescription}
                        label="Sign Now Message"
                        onChange={(value) => onTermChange('signNowBtn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signNowBtn}
                        label="Sign Now button"
                        onChange={(value) => onTermChange('signNowBtn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.askOtherBtn}
                        label="Ask Somebody else button"
                        onChange={(value) => onTermChange('askOtherBtn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signNowCancelBtn}
                        label="Sign Now Cancel button"
                        onChange={(value) => onTermChange('askOtherBtn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
            </div>
            <div>
                <div className="my-2 mt-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Define Signatories dialog</h3>
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.message}
                        label="Message"
                        onChange={(value) => onTermChange('message', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.description}
                        label="Description"
                        onChange={(value) => onTermChange('description', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.error}
                        label="Error"
                        onChange={(value) => onTermChange('error', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.firstColumn}
                        label="First column"
                        onChange={(value) => onTermChange('firstColumn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.secondColumn}
                        label="Second column"
                        onChange={(value) => onTermChange('secondColumn', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.add}
                        label="Add button"
                        onChange={(value) => onTermChange('add', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.cancel}
                        label="Cancel button"
                        onChange={(value) => onTermChange('cancel', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.submit}
                        label="Submit button"
                        onChange={(value) => onTermChange('submit', value, null, matchingTerm, language.id, 'digitalSigningTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
            </div>
        </>
    )
}