import React from 'react'
import { Badge } from '../../../components/Badge'
import { dataLoadStates } from '../../../utils/constants'

export default function DataLoadStatusBadge({status}) {
    return (<>
        {
            status === dataLoadStates.imported && (<Badge text="Imported" type="info"/>)
        }
        {
            status === dataLoadStates.dialogsGenerated && (<Badge text="Generated" type="success" />)
        }
        {
            status >= dataLoadStates.active && (<Badge text="Issued" type="success" />)
        }
    </>)
}
