import React from "react";
import ColorPicker from '../../../../components/ColorPicker';
import { Base64Upload } from '../../../../components/Properties';
import { XIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import { FormikTextInput } from '../../../../components/Form/Formik/FormikTextInput';
import { FormikSelectInput } from '../../../../components/Form/Formik/FormikSelect';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { errorSelector } from "recoil";
import { runYupValidationSchemaSync, yupToErrors } from "../../../../utils/yup";

export const SetupStep = ({ quickDesignDefinition: { backGroundColor, pageType, language }, onComplete, onAutoSave, step }) => {

    return (
        <div>
            <Formik
                initialValues={{
                    backGroundColor,
                    pageType,
                    language
                }}
                // validationSchema={step.stepValidationSchema}
                validate={(values) => {
                    // only way to auto save with formik.
                    const errors = runYupValidationSchemaSync(step.stepValidationSchema, values);
                    if (Object.keys(errors).length === 0) {
                        // autosave when no errors
                        onAutoSave(values)
                    }
                    return errors;
                }}
                onSubmit={async (values, actions) => {
                    onComplete(values);
                }}
            >
                {
                    props => (
                        <Form>
                            <div>
                                <div className="flex justify-between mb-4">
                                    <div>
                                        <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">Setup</h1>
                                        <p className="mb-2 text-sm text-gray-500">
                                            Start with the basics
                                        </p>
                                    </div>
                                    <div className="hidden sm:block">
                                        <FormikSubmitButton
                                            id="next"
                                            text="Next"
                                            className="bg-indigo-600 hover:bg-indigo-400 w-full sm:w-auto"
                                            formikProps={props}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-6 sm:space-y-5'>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Language
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="sm:flex flex-col">
                                            <div className="sm:w-48">
                                                <FormikSelectInput
                                                    onChange={e => { }}
                                                    name="language"
                                                    formikProps={props}
                                                    options={[
                                                        {
                                                            value: "English",
                                                            name: "English",
                                                        },
                                                        {
                                                            value: "Svenska",
                                                            name: "Svenska",
                                                        },
                                                        {
                                                            value: "Norsk",
                                                            name: "Norsk",
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-red-500">
                                            {props.errors['language']}
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Background Color
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <ColorPicker name={"backGroundColor"} onChange={props.handleChange} alignRight={false} initColor={props.values.backGroundColor} />
                                        <div className="text-red-500">
                                            {props.errors['backGroundColor']}
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Page type
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="sm:flex flex-col">
                                            <div className="sm:w-48">
                                                <FormikSelectInput
                                                    onChange={e => { }}
                                                    name="pageType"
                                                    formikProps={props}
                                                    options={[
                                                        {
                                                            value: "General",
                                                            name: "General",
                                                        },
                                                        {
                                                            value: "Product",
                                                            name: "Product",
                                                        },
                                                        {
                                                            value: "Invoice",
                                                            name: "Invoice",
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-red-500">
                                            {props.errors['pageType']}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-span-12 sm:col-span-5 md:col-span-3 flex items-center sm:justify-end'>
                                    <label className='font-semibold'>Logo</label>
                                </div>
                                <div className="col-span-12 sm:col-span-7 md:col-span-9 flex items-center">
                                    <div className='inline-block flex space-x-2 items-center'
                                    >
                                        {
                                            props.values.logo && (
                                                <div className="relative">
                                                    <img
                                                        className={"w-40 border border-gray-300 rounded-md p-2 relative"}
                                                        alt={`${""}`}
                                                        src={`${props.values.logo}`}
                                                    />
                                                    <button
                                                        type="button"
                                                        title="Clear logo"
                                                        className="absolute -top-2 -right-2 hover:text-red-500 w-auto inline-flex justify-center rounded-full border border-gray-300 shadow-sm p-1 bg-white text-base font-medium text-gray-700 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                                                        onClick={() => {
                                                            props.setFieldValue("logo", '');
                                                        }}
                                                    >
                                                        <XIcon className='h-3 w-3' />
                                                    </button>
                                                </div>
                                            )
                                        }
                                        <div>
                                            <Base64Upload onChange={props.handleChange} name={"logo"} value={props.values.logo} />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className='col-span-12 sm:col-span-5 md:col-span-3 flex items-center sm:justify-end'>
                                    <label className='font-semibold'>Company Name</label>
                                </div>
                                <div className="col-span-12 sm:col-span-7 md:col-span-9 flex items-center">
                                    <FormikTextInput
                                        name="companyName"
                                        type={"text"}
                                    // className="w-full sm:w-1/2 md:w-1/14 block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    />
                                </div>
                                <div className='col-span-12 sm:col-span-5 md:col-span-3 flex items-center sm:justify-end'>
                                    <label className='font-semibold'>Address</label>
                                </div>
                                <div className="col-span-12 sm:col-span-7 md:col-span-9 flex items-center">
                                    <FormikTextInput
                                        name="address"
                                        formikProps={props}
                                        type={"text"}
                                    // className="w-full sm:w-1/2 md:w-1/14 block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    />
                                </div> */}
                                <div className="sm:hidden">
                                    <FormikSubmitButton
                                        id="next"
                                        text="Next"
                                        className="bg-indigo-600 hover:bg-indigo-400 w-full sm:w-auto"
                                        formikProps={props}
                                    />
                                </div>
                            </div>
                        </Form>

                    )
                }
            </Formik>
        </div>
    )
}