import { useState } from "react";
import * as _ from 'lodash'
import * as Icon from "@heroicons/react/outline"

export const useTableSort = (data, initialSortProperty, initialSortDirection) => {
  const [sortProperty, setSortProperty] = useState(initialSortProperty);
  const [sortDirection, setSortDirection] = useState(initialSortDirection || "asc");

  return {
    tableData: sortProperty
      ? _.orderBy(data, [sortProperty], [sortDirection])
      : data,
    orderBy: (propertyName) => {
      if (propertyName === sortProperty) {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
        setSortDirection(newSortDirection)
      }
      else {
        setSortProperty(propertyName)
        setSortDirection("asc")
      }
    },
    getSortIcon: (propertyName) => {
      if (propertyName !== sortProperty) {
        return null;
      }
      return sortDirection === 'asc'
        ? <Icon.ArrowSmUpIcon className="h-5 w-5 inline" />
        : <Icon.ArrowSmDownIcon className="h-5 w-5 inline" />
    }
  }
}