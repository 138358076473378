import { ErrorMessage } from "formik"
import toast from "react-hot-toast"

export const FormikFileInput = ({
  name,
  className,
  formikProps,
  accept = null,
  maxFileSizeInMb = null
}) => {

  const onChange = (e) => {
    const file = e.target.files[0]
    if (maxFileSizeInMb > 0 && file?.size > (1048576 * maxFileSizeInMb)) {
      toast.error("File is too large")
    }
    else {
      formikProps.setFieldValue(name, file)
    }
  }

  const onReset = (e) => {
    formikProps.setFieldValue(name, null)
  }

  return (
    <>
      <input
        id={name}
        name={name}
        type="file"
        className={className}
        accept={accept}
        onBlur={formikProps?.handleBlur}
        onChange={onChange}
      />
      {
        (formikProps.values[name]?.name || formikProps.values[name]?.fileName) &&
        <div className="cursor-pointer w-25 inline-flex items-center ml-2 items-center px-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
          onClick={onReset}
        >
          <span className='cursor-pointer text-sm'>Delete file</span>
        </div>
      }
      <ErrorMessage name={name} component="div" className="text-red-500" />
    </>
  )
}