import { useAlertModal } from "@metaforcelabs/metaforce-core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import ColorPicker from "../../../../components/ColorPicker";
import { FieldAuth, FileInput } from "./uiComponents";
import { useToastAction } from "../../../../hooks/useToastAction";
import { activateAuthenticationTemplate, getAuthenticationTemplate, updateAuthenticationTemplate } from "../../../../api/authenticationTemplates";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import { FormikCheckbox } from "../../../../components/Form/Formik/FormikCheckbox";
import { FormikTextInput } from "../../../../components/Form/Formik/FormikTextInput";
import { authenticationTemplateStatus } from "../../../../utils/constants";
import { useModalHelper } from "../../../../hooks/useModalHelper";
import Confirm from "../../../../components/Confirm"

export default function EditAuthTemplate() {
  const { templateId } = useParams();
  const [template, setTemplate] = useState();
  const navigate = useNavigate();
  const { alertWarn } = useAlertModal();
  const loadAction = useToastAction();
  const saveAction = useToastAction(true);
  const activateAction = useToastAction();
  const activateModalHelper = useModalHelper()

  const loadData = async () => {
    loadAction.execute(async () => {
      const template = await getAuthenticationTemplate(templateId);

      setTemplate(template);
    }, "Failed to load template")
  }

  const createFormValue = () => {
    return {
      name: template?.name || "",
      isDefault: template?.isDefault || false,
      backgroundColor: template?.backgroundColor || "#ffffff",
      fontColor: template?.fontColor || "#000000",
      backgroundImage: template?.backgroundImage || null,
      logo: template?.logo || null,
      about: template?.about || "",
      headline: template?.headline || "",
      footer: template?.footer || "",
    };
  }

  const handleSaveTemplate = (values) => {
    saveAction.execute(async () => {
      const result = await updateAuthenticationTemplate(templateId, values);
      setTemplate(result)
      if (result.status === authenticationTemplateStatus.disabled) {
        activateModalHelper.open()
      }
    }, "Failed to save", "Template saved")
  }

  const onActivate = () => {
    activateAction.execute(async () => {
      await activateAuthenticationTemplate(templateId)
    }, "Error activating", "Template activated")
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <>
      {
        template &&
        <div>
          <div className="mt-10 pb-5 border-b border-gray-200 flex justify-center items-between flex-col">
            <h1 className="text-3xl font-bold">
              Authentication Template Registration
            </h1>
            <h4 className="mt-2 max-w-4xl text-sm text-gray-500">
              Customize your authentication screens
            </h4>
          </div>
          <div className="my-2 sm:my-4 md:my-6">
            <div className="bg-white shadow rounded-lg mt-4 p-8">
              <Formik
                initialValues={createFormValue()}
                enableReinitialize={true}
                validationSchema={() => {
                  return yup.object().shape({
                    name: yup.string().required("Required"),
                    isDefault: yup.bool(),
                    backgroundColor: yup.string(),
                  });
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  handleSaveTemplate(values)
                  setSubmitting(false);
                }}
              >
                {props => (
                  <Form>
                    <div className="space-y-6 sm:space-y-5">
                      <FieldAuth label={"Name"}>
                        <FormikTextInput
                          name={"name"}
                          formikProps={props}
                        />
                      </FieldAuth>

                      <FieldAuth label={"Default"}>
                        <FormikCheckbox name={"isDefault"} formikProps={props} />
                      </FieldAuth>

                      <FieldAuth
                        label={"Background"}
                        error={props.errors?.backgroundColor}
                      >
                        <ColorPicker
                          initColor={props.values?.backgroundColor}
                          name="backgroundColor"
                          onChange={props.handleChange}
                        />
                      </FieldAuth>

                      <FieldAuth
                        label={"Font"}
                        formikProps={props.errors?.fontColor}
                      >
                        <ColorPicker
                          initColor={props.values?.fontColor}
                          name="fontColor"
                          onChange={props.handleChange}
                        />
                      </FieldAuth>

                      <FieldAuth label={"Background Image"}>
                        <FileInput
                          name={"backgroundImage"}
                          formikProps={props}
                        />
                      </FieldAuth>

                      <FieldAuth label={"Logo"}>
                        <FileInput
                          name={"logo"}
                          formikProps={props}
                        />
                      </FieldAuth>

                      <FieldAuth label={"Headline"}>
                        <FormikTextInput
                          name={"headline"}
                          formikProps={props}
                        />
                      </FieldAuth>

                      <FieldAuth label={"About"}>
                        <FormikTextInput
                          name={"about"}
                          formikProps={props}
                          rows={5}
                        />
                      </FieldAuth>

                      <FieldAuth label={"Footer"}>
                        <FormikTextInput
                          name={"footer"}
                          formikProps={props}
                          rows={5}
                        />
                      </FieldAuth>

                      <div className="flex space-x-4 sm:justify-end sm:border-t sm:border-gray-200">
                        <button
                          className="w-1/2 sm:w-auto mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:col-start-1 sm:text-sm"
                          type="button"
                          onClick={(e) => {
                            if (_.isEqual(props.values, createFormValue(template))) {
                              navigate("/admin/templates/auth");
                            } else {
                              alertWarn(
                                "Unsaved changes, leave anyways?",
                                () => {
                                  navigate(
                                    "/admin/templates/auth"
                                  );
                                },
                                true,
                                "Cancel",
                                () => { },
                                "Leave"
                              );
                            }
                          }}
                        >
                          Back
                        </button>
                        <FormikSubmitButton
                          text={"Save"}
                          className="w-1/2 sm:min-w-40 sm:w-auto mt-3 inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                          disabled={_.isEqual(props.values, createFormValue(template))}
                          formikProps={props}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      }
      <Confirm
        modalHelper={activateModalHelper}
        title="Would you like to activate the template?"
        onConfirm={onActivate}
      />
    </>
  );
}
