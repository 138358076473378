import { Fragment, useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Configuration() {

  return (
    <>
      <h1>This is the configurations</h1>
    </>
  )
}
