import Enumerable from 'linq';
import { dependenciesType, elementTypes } from './constants';
import { setProperty } from './property';
import { getElementKey } from './features';
import { v4 as uuidv4 } from 'uuid';

const elementTypeKeys = Object.keys(elementTypes);

export const getHours = (value) => {
  if (!value) {
    return null
  }
  const chunks = value.split(':')
  if (chunks && chunks.length > 0) {
    const hours = parseInt(chunks[0])
    return hours != null && !isNaN(hours) ? hours : null
  }
  return null
}

export const getMinutes = (value) => {
  if (!value) {
    return null
  }
  const chunks = value.split(':')
  if (chunks && chunks.length === 2) {
    const minutes = parseInt(chunks[1])
    return minutes != null && !isNaN(minutes) ? minutes : null
  }
  return null
}

export const timeHasValueSet = (time) => {
  return getMinutes(time) != null && getHours(time) != null
}

export const getAllCheckboxesOptions = (containers) => {
  var elements = Enumerable.from(containers)
    .selectMany(c => c.rows)
    .selectMany(r => r.columns)
    .selectMany(c => c.elements)
    .toArray();

  let checkboxOptions = [{ name: 'None', value: 'none' }]
  const options = elements.filter((element) => {
    return element.type === elementTypes.checkbox
  }).map((element) => {
    return { name: element.property, value: getElementKey(element) }
  });

  checkboxOptions.push(...options)
  return checkboxOptions;
}

export const onChangeInlineEdit = (e, elementContext, dialogDefinition, clonedData, setClonedData) => {
  const { name, value } = e.target;
  const type = "text"
  const customEventObject = {
    target: {
      name,
      value
    }
  };

  elementContext.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)
  setProperty(clonedData, name, value, type)
  setClonedData((data) => ({
    ...data,
  }));
}

/**
 * @param {Event} event
 * @param {} openProperties - elementDesign.onClick 
 */
export const OpenElementProperties = (event, openProperties) => {
  const showPanel = true;
  openProperties(event, showPanel)
}

export const getAllCheckboxesAndRadioButtonOptions = (containers) => {
  const elements = Enumerable.from(containers)
    .selectMany(c => c.rows)
    .selectMany(r => r.columns)
    .selectMany(c => c.elements)
    .toArray();

  const array = [{ name: 'None', value: 'none' }];

  const options = elements
    .filter(element => element.type === elementTypes.checkbox || element.type === elementTypes.radioButtonGroup)
    .map(element => ({
      name: element.property,
      value: getElementKey(element)
    }));

  array.push(...options);
  return array;
};

export const isVisibleConnectedElementWithCheckedValue = (type, value) => {
  // Check for type == null so it also takes int account undefined
  if (type == null || type === dependenciesType.none) {
    return true;
  }

  const element = value === 'none' || value === null ? null : document.getElementById(value);
  return element !== null ? element.checked : false;
}

export const buildPropertyForConnectedObject = (element, connectedObjectCount = 0) => {
  const elementTypeName = elementTypeKeys[element.type];

  let elementLengthWithValidation = element.id.substring(0, 4);

  // if(connectedObjectCount > 0)
  //   elementLengthWithValidation += "-" + connectedObjectCount+1;

  element.property = `${elementTypeName}-${elementLengthWithValidation}`;
}

export const generateRowsFromTableValues = (rowAsString, totalColumns) => {
  const rows = [];
  const rowsCount = rowAsString?.length / totalColumns;

  for (let i = 0; i < rowsCount; i++) {
    const columns = [];

    for (let j = 0; j < totalColumns; j++) {
      const rowIndex = i * totalColumns;
      const rowData = rowAsString?.slice(rowIndex, rowIndex + totalColumns);

      columns.push({
        id: uuidv4(),
        value: rowData[j],
      })
    }
    rows.push(columns);
  }

  return rows;
}

export const findFieldsWithNoValue = (elements, data) => {

  const requiredFieldsWithNoValues = elements
    .filter(r => r.type !== elementTypes.fileUpload && r.type !== elementTypes.timePicker && r.type !== elementTypes.checkbox && r.type !== elementTypes.matrix)
    .filter(r => !data.find(d => d.property === r.property && d.value))
    .filter(r => isVisibleConnectedElementWithCheckedValue(r?.connectedElement?.type, r?.connectedElement?.value))

  const requiredFileUploadsWithNoValues = elements
    .filter(r => r.type === elementTypes.fileUpload)
    .filter(r => !data.find(d => d.property === r.property && d.files?.length > 0))

  const requiredTimePickersWithNoValues = elements
    .filter(r => r.type === elementTypes.timePicker)
    .filter(r => !data.find(d => d.property === r.property && timeHasValueSet(d.value)))

  const requiredCheckboxWithFalseValue = elements
    .filter(r => r.type === elementTypes.checkbox)
    .filter(r => !data.find(d => d.property === r.property && timeHasValueSet(d.value)))
    .filter(r => !data.find(d => d.property === r.property && d.value === 'true'))

  const requiredMatrixesWithMissingValues = elements
    .filter(r => r.type === elementTypes.matrix)
    .filter(r => !data.find(d => d.property === r.property && r.matrix?.questions?.length === d.matrix?.selections?.length))

  return {
    requiredFieldsWithNoValues,
    requiredFileUploadsWithNoValues,
    requiredTimePickersWithNoValues,
    requiredCheckboxWithFalseValue,
    requiredMatrixesWithMissingValues,
  }
}
/**
 * Converts the objects that contain all the various types of required fields into an array
 * @param {*} fieldsWithNoValue - input from the findFieldsWithNoValue
 */
export const requiredFieldsObjectsToArray = (fieldsWithNoValue) => {
  return [
    ...fieldsWithNoValue.requiredFieldsWithNoValues,
    ...fieldsWithNoValue.requiredFileUploadsWithNoValues,
    ...fieldsWithNoValue.requiredTimePickersWithNoValues,
    ...fieldsWithNoValue.requiredCheckboxWithFalseValue,
    ...fieldsWithNoValue.requiredMatrixesWithMissingValues
  ]
}
