import { createDefinitionProcessPhoneNumber, deleteDefinitionProcessPhoneNumber, getDefinitionProcessPhoneNumbers, updateDefinitionProcessPhoneNumber } from "../../../api/definitionProcesses";
import { Button } from "../../../components/Form/Button";
import { useToastAction } from "../../../hooks/useToastAction";
import { useEffect, useState } from "react"
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useDebounceCallback } from "@react-hook/debounce";
import 'react-phone-number-input/style.css'
import { PhoneInputConfig } from "../../../utils/config";

export const PhoneNumbers = ({ definitionProcessId }) => {
  const loadAction = useToastAction()
  const savePhoneNumberAction = useToastAction(true)
  const deletePhoneNumberAction = useToastAction()
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [errors, setErrors] = useState([])

  useEffect(() => {
    loadAction.execute(async () => {
      const phoneNumberList = await getDefinitionProcessPhoneNumbers(definitionProcessId)
      setPhoneNumbers(phoneNumberList)
    })
  }, [])

  const onAddClick = () => {
    setPhoneNumbers([{
      id: null,
      phoneNumber: '',
    }, ...phoneNumbers])
  }

  const onPhoneNumberChange = (value, index) => {
    const record = phoneNumbers[index]
    record.phoneNumber = value
    if (value && isPossiblePhoneNumber(value)) {
      setErrors([...errors.filter(e => e !== record)])
      const successMessage = record.id
      ? "Updated phone number"
      : "Added phone number"
      savePhoneNumberAction.execute(async () => {
        if (record.id) {
          await updateDefinitionProcessPhoneNumber(definitionProcessId, record.id, value)
        }
        else {
          const createResult = await createDefinitionProcessPhoneNumber(definitionProcessId, value)
          phoneNumbers[index].id = createResult.id
        }
        setPhoneNumbers([...phoneNumbers])
      }, 'Failed to save phone number', successMessage)
    }
    else {
      if (!errors.includes(record)) {
        setErrors([record, ...errors])
      }
    }
  }

  const onPhoneNumberChangeDebounced = useDebounceCallback(onPhoneNumberChange, 400)

  const onDeleteClick = (index) => {
    const record = phoneNumbers[index]
    if (record.id) {
      deletePhoneNumberAction.execute(async () => {
        await deleteDefinitionProcessPhoneNumber(definitionProcessId, record.id)
        setPhoneNumbers([...phoneNumbers.filter(p => p.id !== record.id)])
      }, 'Failed to delete phone number')
    }
    else {
      setPhoneNumbers([...phoneNumbers.filter(p => p !== record)])
    }
  }

  return (
    <div>
      <div>
        <Button
          text={"Add"}
          onClick={onAddClick}
        />
      </div>
      <div className="mt-4">
        {
          !loadAction.isExecuting && phoneNumbers?.length > 0 && phoneNumbers?.map((p, index) => {
            const hasError = errors.find(e => e === p)
            return (
              <div
                key={`phone-input-${index}`}
                className="my-4 w-6/12 flex"
              >
                <PhoneInput
                  defaultCountry={PhoneInputConfig.defaulPhoneInputCountry}
                  international
                  placeholder="Enter phone number"
                  className={hasError ? 'text-red-500' : ''}
                  value={p.phoneNumber}
                  onChange={(value) => onPhoneNumberChangeDebounced(value, index)}
                  countryCallingCodeEditable={false}
                />
                <Button
                  text={"Delete"}
                  theme="text"
                  onClick={() => onDeleteClick(index)}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
} 