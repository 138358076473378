import { useContext, useState } from "react";
import { FormContext } from "../../contexts";
import { ChevronDoubleUpIcon } from '@heroicons/react/outline'

export const StepsList = () => {
    const formContext = useContext(FormContext)
    const steps = formContext?.dialogValues?.processSteps
    const signingNeeded = formContext?.dialogValues?.signingNeeded
    const [open, setOpen] = useState(true)
    const currentStep = steps.find(step => step.status === 2)
    const processTranslations = formContext.translateProcess(formContext?.processValues)

    return (
        <div className="border-2 border-blue-400 px-5 py-2 mx-10 flex flex-col ">
            <div className="flex justify-between select-none">
                <span className="font-bold text-lg">{processTranslations?.header}</span>
                <ChevronDoubleUpIcon onClick={() => setOpen(!open)} className="w-5 cursor-pointer" />
            </div>
            <div className={`self-center text-sm flex flex-col transition-all duration-200 ease-in-out ${open ? "opacity-100 max-h-screen mt-2" : "opacity-0 max-h-0 overflow-hidden"}`}>
                <span className="mt-1 mb-2">{processTranslations?.description}</span>
                {steps && steps.map(step => {
                    return (<span key={step.stepId} className="pl-10 mt-1">{processTranslations?.step} {step.stepOrder}: {step.status === 2 && <span className="font-bold">[{processTranslations?.current}]</span>} {step?.email}</span>)
                })}

                {signingNeeded &&
                    <>
                        <span className="mt-4">{processTranslations?.signingDescription}</span>
                        <span className="mt-2">{processTranslations?.signatory} {currentStep?.email}</span>
                    </>
                }
            </div>
        </div>
    )
} 