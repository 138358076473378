import { destroy, get, post, put } from './api'

const controllerName = "NotificationTemplates"

export const getEmailNotificationTemplates = async () => {
    return await get(`${controllerName}/Email`);
}

export const getActiveEmailNotificationTemplates = async () => {
    return await get(`${controllerName}/Email/Active`);
}

export const getSmsNotificationTemplates = async () => {
    return await get(`${controllerName}/Sms`);
}

export const getActiveSmsNotificationTemplates = async () => {
    return await get(`${controllerName}/Sms/Active`);
}

export const getEmailNotificationTemplate = async (templateId) => {
    return await get(`${controllerName}/Email/${templateId}`);
}
export const getSmsNotificationTemplate = async (templateId) => {
    return await get(`${controllerName}/Sms/${templateId} `);
}

export const updateEmailNotificationTemplate = async (templateId, template) => {
    return await put(`${controllerName}/Email/${templateId}`, template);
}

export const resetEmailNotificationTemplate = async (templateId, template) => {
    return await put(`${controllerName}/Email/${templateId}/reset`, template);
}

export const updateAuthTemplate = async (templateId, template) => {
    return await put(`${controllerName}/auth/${templateId}`, template);
}

export const updateSmsNotificationTemplate = async (templateId, template) => {
    return await put(`${controllerName}/Sms/${templateId}`, template);
}

export const resetSmsNotificationTemplate = async (templateId, template) => {
    return await put(`${controllerName}/Sms/${templateId}/reset`, template);
}

export const createEmailNotificationTemplate = async (name) => {
    return await post(`${controllerName}/Email`, { name });
}

export const createAuthTemplate = async (name) => {
    return await post(`${controllerName}/auth`, { name });
}

export const createSmsNotificationTemplate = async (name) => {
    return await post(`${controllerName}/Sms`, { name });
}

export const activateEmailNotificationTemplate = async (templateId) => {
    return await post(`${controllerName}/Email/Activate`, { templateId })
}

export const activateSmsNotificationTemplate = async (templateId) => {
    return await post(`${controllerName}/Sms/Activate`, { templateId })
}

export const disableEmailNotificationTemplate = async (templateId) => {
    return await post(`${controllerName}/Email/Deactivate`, { templateId })
}
export const disableSmsNotificationTemplate = async (templateId) => {
    return await post(`${controllerName}/Sms/Deactivate`, { templateId })
}

export const destroyEmailNotificationTemplate = async (templateId) => {
    return await destroy(`${controllerName}/Email/${templateId}`);
}

export const destroySmsNotificationTemplate = async (templateId) => {
    return await destroy(`${controllerName}/Sms/${templateId}`);
}

export const getNotificationEventTypes = async () => {
    return await get(`${controllerName}/NotificationEventTypes`);
}