export const stringToLocaleDateTimeString = (val) => {
  if (!val) {
    return;
  }

  return new Date(val).toLocaleString();
}

export const isLeapYear = (year) => {
  let date = new Date(year, 1, 29);
  let day = date.getDate();

  return day === 29;
}

/**
 * Return if Date is valid
 * @param {string} dateString // format allowed ddmmyyyy
 */
export const isValidDate = (dateString) => {
  const dateRegex = /^(\d{2})(\d{2})(\d{4})$/;

  const match = dateString.match(dateRegex);
  if (!match) {
    return false;
  }

  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const year = parseInt(match[3], 10);
  
  const currentYear = new Date().getFullYear();

  if (year > currentYear) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  const daysInMonth = [
    31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
  ];

  if (day < 1 || day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
}

export const formatWithMonthName = (dateString) => {
  const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
}