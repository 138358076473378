import { Badge } from "@metaforcelabs/metaforce-core";

export default function Changelog() {
    const items = [
        {
            date: "2021-08-01",
            changes : [
                {
                    type: "New",
                    description: "Added a new feature that allow new functions.",
                },
                {
                    type: "Improvement",
                    description: "Optimized search algorithm for faster results",
                },
                { 
                    type: "Fix", description: "Fixed a bug that caused app crashes" 
                },
            ]
        }
    ];

    return (
        <div className={"h-screen overflow-y-scroll"}>
            <div className={`container max-w-screen-md mx-auto p-10 flex flex-col`}>
                <div className="pb-5">
                    <h1 className="text-2xl leading-6 font-medium text-gray-900">
                        Changelog
                    </h1>
                </div>
                {items?.map((item, index) => (
                <>
                    <div className="border-b border-gray-200 mb-5 pb-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-8">
                            {item.date}
                        </h3>
                    </div>
                    <div className="flex flex-col gap-y-4">
                        {item?.changes?.map((change, changeIndex) => (
                            <li key={changeIndex} className="mb-4 w-full flex">
                                <Badge
                                    type={
                                        change.type === "New"
                                            ? "success"
                                            : change.type === "Improvement"
                                            ? "info"
                                            : "error"
                                    }
                                    text={change.type}
                                />
                                <p className="ml-2">{change.description}</p>
                            </li>
                        ))}
                    </div>
                </>
                ))}
            </div>
        </div>
    );
}