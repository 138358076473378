import { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { useToastAction } from "../../../hooks/useToastAction";
import { Button } from "../../../components/Form/Button";
import Modal from "../../../components/Modal";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import MenuContextList from "../../../components/MenuContextList/index";
import { isWebhookActive } from "../../../utils/webhooks";
import { useModalHelper } from "../../../hooks/useModalHelper";
import { Badge } from '../../../components/Badge';
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import {
  getSystemIntegrationsByDialogDefinitionId,
  addSystemIntegration,
  activateSystemIntegration,
  deactivateSystemIntegration,
  deleteSystemIntegration,
  updateSystemIntegration
} from "../../../api/systemIntegration";
import { Label } from "../../../components/Form/Label";
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'
import { systemIntegrations } from "../../../utils/constants";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";


export const SystemIntegrations = ({ dialogKey }) => {
  const [systemIntegrationsItems, setSystemIntegrationsItems] = useState([]);
  const [systemIntegration, setSystemIntegration] = useState(null);

  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const activateDefinitionAction = useToastAction();
  const deactivateDefinitionAction = useToastAction();
  const deleteAction = useToastAction()

  const modalHelper = useModalHelper()

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    // ensure existing integration is always cleared out when modal closes
    if (!modalHelper.isOpen) {
      setSystemIntegration(null)
    }
  }, [modalHelper.isOpen])

  async function load() {
    loadAction.execute(async () => {
      const result = await getSystemIntegrationsByDialogDefinitionId(dialogKey);
      setSystemIntegrationsItems(result);
    }, "Failed to load system integrations");
  }

  const systemIntegrationNameFromType = (type) => {
    switch (type) {
      case systemIntegrations.salesforce:
        return "Salesforce";
      default:
        return "Unknown";
    }
  };

  const onSave = async (type, validObjects, existingRecord = null) => {
    saveAction.execute(async () => {
      if (existingRecord) {
        await updateSystemIntegration(existingRecord.id, { type: type, validObjects })
      }
      else {
        await addSystemIntegration(dialogKey, { type: +type, validObjects });
      }
      modalHelper.close()
      setSystemIntegration(null)
      load();
    }, "Failed to add system integration", "System integration added");
  };

  const onActivate = async (webhookId) => {
    activateDefinitionAction.execute(async () => {
      await activateSystemIntegration(webhookId);
      load();
    }, "Failed to activate", "Activated");
  };

  const onDeactivate = async (webhookId) => {
    deactivateDefinitionAction.execute(async () => {
      await deactivateSystemIntegration(webhookId);
      load();
    }, "Failed to Deactivate", "Deactivated");
  };

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteSystemIntegration(id);
      load();
    }, "Failed to delete webhook", "Webhook deleted");
  };

  const onView = (element) => {
    setSystemIntegration(element)
    modalHelper.open()
  }

  return (
    <>
      <section aria-labelledby="webhooks-heading">
        <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
          <div className="flex justify-between items-center px-4 sm:px-6">
            <h2
              id="webhooks-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              System Integrations
            </h2>
            <Button
              text="New"
              onClick={modalHelper.open}
            />
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-t border-gray-200">
                  <table className="min-w-full mb-1 divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >

                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {systemIntegrationsItems?.map((element, index) => (
                        <tr key={element.id ? element.id : index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {isWebhookActive(element)
                              ? <Badge type="success" text="Active" />
                              : <Badge type="warn" text="Deactive" />
                            }
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                            style={{ minWidth: "250px" }}
                          >
                            {systemIntegrationNameFromType(element.type)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {stringToLocaleDateTimeString(element.createdDate)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <MenuContextList
                              element={
                                element
                              }
                              deleteType={
                                "system integration"
                              }
                              actions={[
                                {
                                  name: "View",
                                  onClick: () => onView(element),
                                },
                                isWebhookActive(element)
                                  ? {
                                    name: "Deactivate",
                                    disabled: deactivateDefinitionAction.isExecuting,
                                    onClick: () => onDeactivate(element.id),
                                  }
                                  : {
                                    name: "Activate",
                                    disabled: activateDefinitionAction.isExecuting,
                                    onClick: () => onActivate(element.id),
                                  },
                                {
                                  name: "Delete",
                                  onClick: () => onDelete(element.id),
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalForm
        modalHelper={modalHelper}
        onSave={onSave}
        record={systemIntegration}
        existingIntegrationTypes={systemIntegrationsItems?.map(x => x.type)}
      />
    </>
  );


}

const ModalForm = ({ modalHelper, onSave, record, existingIntegrationTypes = [] }) => {
  const allSystemIntegrationTypes = [
    { value: -1, name: "Please pick system" },
    { value: systemIntegrations.salesforce, name: "Salesforce" },
  ]

  const integrationTypeOptions = () => {
    return allSystemIntegrationTypes.filter(x => x.value === record?.type || !existingIntegrationTypes?.includes(x.value));
  }

  const cancel = () => {
    modalHelper.close();
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title="Add system integration"
    >
      <Formik
        initialValues={{
          type: record?.type || '-1',
          objects: record?.validObjects?.map(r => ({ name: r })) || [],
        }}
        validationSchema={() => {
          return yup.object({
            type: yup.number().positive("System is required"),
            objects: yup.array()
              .of(
                yup.object().shape(
                  {
                    name: yup.string().required("Required")
                  }
                )
              )
          })
        }}
        onSubmit={async (values, actions) => {
          await onSave(values.type, values.objects?.map(o => o.name), record)
          actions.setSubmitting(false)
        }}
      >
        {
          props => (
            <Form>
              {
                integrationTypeOptions().length > 1 ?
                  (
                    <>
                      <FormikSelectInput
                        label="System"
                        onChange={e => props.setFieldValue('objects', [])}
                        name="type"
                        formikProps={props}
                        options={integrationTypeOptions()}
                      />
                      {
                        props.values.type !== "-1" &&
                        <FieldArray
                          name="objects"
                          render={arrayHelpers => (
                            <div className="mt-5">
                              <Label label={"Valid for objects"} />
                              {
                                props.values.objects.length === 0 &&
                                <div className="font-semibold opacity-60 text-xs mb-1">
                                  If you don't add any, the smartform will be available for all objects in Salesforce
                                </div>
                              }
                              {
                                props.values.objects?.map((object, index) => {
                                  return (
                                    <div className="flex" key={index}>
                                      <div className="flex-1">
                                        <FormikTextInput
                                          formikProps={props}
                                          name={`objects[${index}].name`}
                                          placeholder={"<Enter object name in Salesforce>"}
                                        />
                                      </div>
                                      <div className="cursor-pointer ml-2 mt-3">
                                        <TrashIcon className="w-5" onClick={() => arrayHelpers.remove(index)} />
                                      </div>
                                    </div>
                                  );
                                })}
                              <PlusCircleIcon className="w-5 cursor-pointer mt-2" onClick={() => arrayHelpers.push({ name: '' })} />
                            </div>
                          )}
                        />

                      }
                      <div className="mt-5 flex justify-between">
                        <FormikSubmitButton
                          formikProps={props}
                          text="Save"
                        />
                        <Button
                          onClick={cancel}
                          text="Close"
                          theme="white"
                        />
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <p className="text-gray-500">No more system integrations to add</p>
                      <div className="mt-6 flex justify-between">
                        <Button
                          onClick={cancel}
                          text="Close"
                          theme="white"
                        />
                      </div>
                    </>

                  )
              }
            </Form>
          )
        }
      </Formik>
    </Modal>
  );
}

