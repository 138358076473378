import { Badge } from "@metaforcelabs/metaforce-core";

export const DialogValuesStatusBadge = ({
  record,
  stepName = "In progress"
}) => {

  const renderBadge = () => {
    if (record.isAborted)
      return <Badge type="warn" text="Aborted" />
    else if (record.isDeletedFromRetencionPolicy)
      return <Badge type="error" text="Deleted" />
    else if (!record.isComplete)
      return <Badge type="default" text={stepName} />
    else if (record.isSigned)
      return <Badge type="success" text="Complete and signed" />
    else if (record.signingNeeded && record.isComplete)
      return <Badge type="info" text="Awaiting Signing" />
    else
      return <Badge type="success" text="Complete" />
  }

  return (
    <>
      {renderBadge()}
    </>
  );
}