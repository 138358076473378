import { post, get, put, destroy } from './api'

export const addSystemIntegration = async (dialogDefinitionId, data) => {
  return await post('systemintegrations', { dialogDefinitionId, ...data });
}

export const updateSystemIntegration = async (systemintegrationsId, systemIntegration) => {
  return await put(`systemintegrations/${systemintegrationsId}`, systemIntegration)
}

export const getSystemIntegrationsByDialogDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/systemintegrations`);
}

export const deleteSystemIntegration = async (systemintegrationsId) => {
  return await destroy(`systemintegrations/${systemintegrationsId}`)
}

export const activateSystemIntegration = async (systemintegrationsId) => {
  return await put(`systemintegrations/${systemintegrationsId}/activate`)
}

export const deactivateSystemIntegration = async (systemintegrationsId) => {
  return await put(`systemintegrations/${systemintegrationsId}/deactivate`)
}