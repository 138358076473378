import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'
import { useTimeoutFn } from 'react-use';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

export default function DataLoadNotificationPlaceholderModalContent({ title, setOpen, placeHolders }) {

    const AnimateCopyButton = ({ placeHolder }) => {
        const [isShowing, setIsShowing] = useState(true)
        const [animating, setAnimating] = useState(false);
        let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 100)
        let [, , resetIsAnimating] = useTimeoutFn(() => setAnimating(false), 2000)
        const copyToClipBoard = useCopyToClipboard();

        return (
            <div className="flex flex-col items-center">
                <button
                    className="flex items-center px-3 text-sm font-medium transition transform rounded-full hover:scale-105 focus:outline-none disabled:cursor-default"
                    onClick={e => {
                        copyToClipBoard.onCopy(placeHolder)
                        setIsShowing(false);
                        setAnimating(true);
                        resetIsShowing();
                        resetIsAnimating();
                        setTimeout(() => {
                            setOpen(false);
                        },100)
                    }}
                >
                    <div className="h-4 w-4 sm:h-6 md:w-6">
                        <Transition
                            show={isShowing}
                            enter="transform transition duration-200"
                            enterFrom="opacity-0 -rotate-120 scale-50"
                            enterTo="opacity-100 rotate-0 scale-100"
                            leave="transform duration-0 transition ease-in-out"
                            leaveFrom="opacity-100 rotate-0 scale-100 "
                            leaveTo="opacity-0 scale-95 "
                        >
                            {!animating && (<ClipboardCopyIcon className="h-5 w-5 sm:h-6 md:w-6" />)}
                            {animating && (<CheckCircleIcon className="h-5 w-5 sm:h-6 md:w-6 text-green-400"></CheckCircleIcon>)}
                        </Transition>
                    </div>
                </button>
            </div>
        )
    }

    const PlaceHolderRow = ({ element }) => {
        const [copied, setCopied] = useState(false);
        const toggleCopied = () => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 1500);
        }


        return (
            <tr className="odd:bg-white even:bg-gray-50">
                <td className="px-1 sm:px-3 py-2 text-xs sm:text-xs text-gray-500 text-left">
                    {element.placeHolder}
                </td>
                <td className="px-1 sm:px-3 py-2 text-xs font-medium text-gray-900 text-center">
                    {element.description}
                </td>
                <td className="px-2 sm:px-3 py-2 text-xs sm:text-xs text-gray-500 text-left hover:text-brand-pink-hover">
                    <span title="Copy to clipboard">
                        <AnimateCopyButton placeHolder={element.placeHolder} />
                    </span>
                </td>
            </tr>)
    }

    return (
        <div>
            <div>
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </Dialog.Title>
                    <div>
                        <p className="mt-2" >Placeholders can be used to dynamically insert text from the dataloaded values</p>
                        <div className="mt-4">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50" key="head">
                                        <tr key={"placeholderheader"}>
                                            <th key={"placeholderheader-0"} scope="col" className="px-1 sm:px-3 py-2 sm:py-3 text-left text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                PlaceHolder
                                            </th>
                                            <th key={"placeholderheader-1"} scope="col" className="px-1 sm:px-3 py-2 sm:py-3 text-left text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Description
                                            </th>
                                            <th scope="col" className="px-1 sm:px-3 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody key={"body"}>
                                        {
                                            placeHolders && (
                                                placeHolders.map((element, i) => {
                                                    return (
                                                        <PlaceHolderRow key={'placeholderrow-' + i} element={element}></PlaceHolderRow>
                                                    )
                                                })
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className="mt-2 text-gray-400 text-xs md:text-sm text-left" >If [dialogUrl] is omitted it will be placed at the end of the message</p>
                    </div>
                </div>
            </div>
            <div className="mt-4 flex justify-center">
                <button
                    type="button"
                    className={"w-1/2  py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400 hover:bg-red-300 focus:outline-none"}
                    onClick={() => { setOpen(false) }}
                >
                    Ok
                </button>
            </div>
        </div>
    )
}

