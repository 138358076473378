import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useDebounceCallback } from '@react-hook/debounce';
import { setDialogDefinition } from "../../../../slices/dialogDefinitionSlice"
import { useDefinitionManager } from "../useDefinitionManager";
import _ from 'lodash';
import { designContexts, elementTypes } from "../../../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import { useNewIdGenerator } from "../useNewIdGenerator";

export const useDndHook = (handleSave, columnActions, elementActions, containerActions, rowActions, changeLayoutType) => {
  const [activeDndId, setActiveDndId] = useState(null)
  const [activeReactBeautifulDndId, setActiveReactBeautifulDndId] = useState(null)
  const [activeDndType, setActiveDndType] = useState(null)
  const [dialogDefinitionDndClone, setDialogDefinitionDndClone] = useState(null)
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const definitionManager = useDefinitionManager()
  const [sidebarColumnId, setSidebarColumnId] = useState()
  const [sidebarRowId, setSidebarRowId] = useState()
  const [sidebarContainerId, setSidebarContainerId] = useState()
  const [sidebarElements, setSidebarElements] = useState({})
  const [activeFromSidebar, setActiveFromSidebar] = useState(false)
  const [newSectionRecord, setNewSectionRecord] = useState(null)
  const [logicDeletedElementId, setLogicDeletedElementId] = useState();
  const idGenerator = useNewIdGenerator()
  const dispatch = useDispatch()

  useEffect(() => {
    regenerateSidebarColumnId()
    regenerateSidebarContainerId()
    regenerateSidebarRowId()
  }, [])

  const regenerateSidebarColumnId = () => {
    setSidebarColumnId(uuidv4())
  }

  const regenerateSidebarRowId = () => {
    setSidebarRowId(uuidv4())
  }

  const regenerateSidebarContainerId = () => {
    setSidebarContainerId(uuidv4())
  }
  /**
   * Returns the type of an object from it's draggableId   
   * @param {string} draggableId - assumed format of "<type>-<objectId>"   
   */
  const getDraggableTypeFromId = (draggableId) => {
    return draggableId
      ? draggableId.split("-")[0]
      : null
  }

  /**
   * 
   * @param {string} draggableId - assmed format of "<type>-<objectId>"
   */
  const objectIdFromDraggableId = (draggableId) => {
    return draggableId.substring(draggableId.indexOf("-") + 1)
  }

  const hiddenOrShowElement = (elementId, activeType, hidden) => {
    let result;
    if (activeType === designContexts.column) {
      result = columnActions.hiddenOrShow(dialogDefinition, elementId, hidden, true)
    }
    else if (activeType === designContexts.element) {
      result = elementActions.hiddenOrShow(dialogDefinition, elementId, hidden, true)
    }
    else if (activeType === designContexts.container) {
      result = containerActions.hiddenOrShow(dialogDefinition, elementId, hidden, true)
    }
    else if (activeType === designContexts.row) {
      result = rowActions.hiddenOrShow(dialogDefinition, elementId, hidden, true)
    }

    if (result) {
      dispatch(setDialogDefinition({
        dialogDefinition: result,
        skipHistory: true
      }))
    }
  }

  const removeElementByActiveType = (elementId, activeType) => {
    let result;

    if (activeType === designContexts.column) {
      result = columnActions.remove(dialogDefinition, elementId)
    }
    else if (activeType === designContexts.element) {
      result = elementActions.remove(dialogDefinition, elementId)
    }
    else if (activeType === designContexts.container) {
      result = containerActions.remove(dialogDefinition, elementId)
    }
    else if (activeType === designContexts.row) {
      result = rowActions.remove(dialogDefinition, elementId)
    }

    if (result) {
      dispatch(setDialogDefinition({
        dialogDefinition: result,
        skipHistory: true
      }))
    }
  }

  /**
  * @param {DragOverEvent} event 
  */
  const handleDragOver = (event) => {
    const { active, over } = event

    if (!over) {
      return null
    }

    const { id: draggableId } = active;
    const { id: draggableOverId } = over;
    const id = objectIdFromDraggableId(draggableId)
    const overId = objectIdFromDraggableId(draggableOverId)
    const activeType = getDraggableTypeFromId(draggableId)
    const overType = getDraggableTypeFromId(draggableOverId)

    if (overType !== activeType) {
      return
    }

    const fromNewSection = !!newSectionRecord
    // Find the containers
    let activeContainerId = null;
    let overContainerId = null;
    if (activeType) {
      activeContainerId = fromNewSection
        ? `sortable-context-new-section`
        : active.data.current.sortable?.containerId.split("sortable-context-")[1]
      overContainerId = over.data.current.sortable?.containerId.split("sortable-context-")[1]
    }

    const fromSidebar = activeContainerId === "sidebar"
    const toSidebar = overContainerId === "sidebar"
    
    if(!fromSidebar && toSidebar) {
      setLogicDeletedElementId(id);
      hiddenOrShowElement(id, activeType, true);
      return;
    } else if(!fromSidebar && !toSidebar && id === logicDeletedElementId) {
      hiddenOrShowElement(id, activeType, false);
      setLogicDeletedElementId()
    }

    const toNewSection = draggableOverId === `${activeType}-new-section-item`
    if (!activeContainerId
      || !overContainerId
      || activeContainerId === overContainerId
    ) {
      return;
    }

    let activeItems = null
    let overItems = null
    if (activeType === designContexts.column) {
      const row = definitionManager.findRow(dialogDefinition, activeContainerId)
      const overRow = definitionManager.findRow(dialogDefinition, overContainerId)
      activeItems = (fromSidebar || fromNewSection) ? [] : row.columns
      overItems = toNewSection || toSidebar ? [] : overRow.columns
    }
    else if (activeType === designContexts.element) {
      const column = definitionManager.findColumn(dialogDefinition, activeContainerId)
      const overColumn = definitionManager.findColumn(dialogDefinition, overContainerId)
      activeItems = (fromSidebar || fromNewSection) ? [] : column.elements
      overItems = toNewSection || toSidebar ? [] : overColumn.elements
    }
    else if (activeType === designContexts.container) {
      activeItems = fromSidebar ? [] : dialogDefinition.containers
      overItems = dialogDefinition.containers
    }
    else if (activeType === designContexts.row) {
      const container = definitionManager.findContainer(dialogDefinition, activeContainerId)
      const overContainer = definitionManager.findContainer(dialogDefinition, overContainerId)
      activeItems = (fromSidebar || fromNewSection) ? [] : container.rows
      overItems = toNewSection || toSidebar ? [] : overContainer.rows
    }
    else {
      return;
    }

    const activeIndex = activeItems.findIndex(a => a.id === id)
    const overIndex = overItems.findIndex(a => a.id === overId)


    const isBelowOverItem =
      over &&
      active.rect.current.translated &&
      active.rect.current.translated.top >
      over.rect.top + over.rect.height;

    const modifier = isBelowOverItem ? 1 : 0;
    const newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    let result
    if (activeType === designContexts.column) {
      if (toNewSection) {
        if (fromNewSection) {
          return;
        }
        else if (fromSidebar) {
          const column = columnActions.createColumn()
          column.id = id
          regenerateSidebarColumnId()
          setNewSectionRecord(column)
        }
        else {
          const column = _.cloneDeep(definitionManager.findColumn(dialogDefinition, id))
          result = columnActions.remove(dialogDefinition, id, true)
          setNewSectionRecord(column)
        }
      }
      else if (fromSidebar) {
        regenerateSidebarColumnId()
        result = columnActions.addFromSidebar(dialogDefinition, id, overContainerId, newIndex, true)
      }
      else if (fromNewSection) {
        result = columnActions.addColumnFromSidebar(dialogDefinition, _.cloneDeep(newSectionRecord), overContainerId, newIndex, true)
        setNewSectionRecord(null);
      }
      else {
        result = columnActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, newIndex, true)
      }
    }
    else if (activeType === designContexts.row) {
      if (toNewSection) {
        if (fromNewSection) {
          return;
        }
        else if (fromSidebar) {
          const row = rowActions.createRow()
          row.id = id
          regenerateSidebarRowId()
          setNewSectionRecord(row)
        }
        else {
          const row = _.cloneDeep(definitionManager.findRow(dialogDefinition, id))
          result = rowActions.remove(dialogDefinition, id, true)
          setNewSectionRecord(row)
        }
      }
      else if (fromSidebar) {
        regenerateSidebarRowId()
        result = rowActions.addFromSidebar(dialogDefinition, overContainerId, newIndex, false, true, id)
      }
      else if (fromNewSection) {
        result = rowActions.addRowFromSidebar(dialogDefinition, _.cloneDeep(newSectionRecord), overContainerId, newIndex, true)
        setNewSectionRecord(null)
      }
      else {
        result = rowActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, newIndex, true)
      }
    }
    else if (activeType === designContexts.element) {
      if (toNewSection) {
        if (fromNewSection) {
          return;
        }
        else if (fromSidebar) {
          const element = sidebarElements["element"].find(e => e.dndId === id).toAdd()
          element.id = id;
          regenerateElementDndId(designContexts.element, id)
          setNewSectionRecord(element)
          elementActions.setElementContext(element)
        }
        else {
          const element = definitionManager.findElement(dialogDefinition, id)
          result = elementActions.remove(dialogDefinition, id, true)
          setNewSectionRecord(element)
        }
      }
      else if (fromSidebar) {
        const element = sidebarElements["element"].find(e => e.dndId === id).toAdd()
        element.id = id;
        regenerateElementDndId(designContexts.element, id)
        buildPropertyWithValidation(dialogDefinition, element)
        result = elementActions.addFromSidebar(dialogDefinition, element, overContainerId, newIndex, true)
        elementActions.setElementContext(element)
      }
      else if (fromNewSection) {
        const element = _.cloneDeep(newSectionRecord);
        buildPropertyWithValidation(dialogDefinition, element)
        result = elementActions.addFromSidebar(dialogDefinition, element, overContainerId, newIndex, true)
        setNewSectionRecord(null);
      }
      else {
        result = elementActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, newIndex, true)
      }
    }
    else if (activeType === designContexts.container && fromSidebar) {
      if (id === sidebarContainerId) { // adding the default template from the "Section" button
        regenerateSidebarContainerId()
        result = containerActions.addFromSidebar(dialogDefinition, newIndex, id, true)
      }
      else {
        const toAdd = sidebarElements["container"].find(e => e.dndId === id).toAdd()
        if (toAdd.container) { // if it's an object template
          const templateContainer = _.cloneDeep(toAdd.container);
          templateContainer.dialogObjectId = toAdd.id
          templateContainer.lockToDialogObject = true
          idGenerator.newIdsOnContainer(templateContainer);
          templateContainer.id = id
          regenerateElementDndId(designContexts.container, id)
          result = containerActions.addObjectFromSidebar(dialogDefinition, newIndex, templateContainer, true)
        }
        else {
          const newContainer = _.cloneDeep(toAdd)
          newContainer.id = id;
          regenerateElementDndId(designContexts.container, id)
          result = containerActions.addObjectFromSidebar(dialogDefinition, newIndex, newContainer, true)
        }
      }
    }
    if (result) {
      dispatch(setDialogDefinition({
        dialogDefinition: result,
        skipHistory: true
      }))
    }
  }
  const debouncedHandleDragOver = useDebounceCallback(handleDragOver, 250)

  const buildPropertyWithValidation = (payload, element) => {
    const elementsOfType = definitionManager.elementsOfTypeFromDialogDefinition(payload, element.type);
    const elementIndexOfProperty = elementsOfType.findIndex(e => !element.property ? !e.property : e.property === element.property);
    const elementTypeName = findElementTypeKeyByValue(element.type);
    let elementLengthWithValidation = element.id.substring(0, 4);

    if (elementIndexOfProperty !== -1) {
      elementLengthWithValidation = elementIndexOfProperty + 1;

    } else if (!element.property) {
      elementLengthWithValidation = elementsOfType.length + 1;
    }

    element.property = `${elementTypeName}-${elementLengthWithValidation}`;
  }

  const findElementTypeKeyByValue = (value) => {
    for (const key in elementTypes) {
      if (elementTypes[key] === value) {
        return key;
      }
    }
    return undefined;
  }

  /**
   * 
   * @param {DragEndEvent} event 
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;
    const { id: draggableId } = active;
    const activeType = getDraggableTypeFromId(draggableId)
    const toNewSection = !!newSectionRecord

    if(activeType === "row"){
      activeStyleActions(draggableId, "container", true)
    } else if( activeType === "column"){
      activeStyleActions(draggableId, "row", true)
    }

    if (!over) {
      handleDragCancelled(event)
      return
    }

    const id = objectIdFromDraggableId(draggableId)

    const { id: draggableOverId } = over;
    const overId = objectIdFromDraggableId(draggableOverId)
    const overType = getDraggableTypeFromId(draggableOverId)

    if (overType !== activeType) {
      resetState()
      return
    }

    // Find the containers
    let activeContainerId = null;
    let overContainerId = null;
    if (activeType === designContexts.column
      || activeType === designContexts.element
      || activeType === designContexts.container
      || activeType === designContexts.row) {
      activeContainerId = toNewSection
        ? 'new-section'
        : active.data.current.sortable.containerId.split("sortable-context-")[1]
      overContainerId = over ? over.data.current.sortable.containerId.split("sortable-context-")[1] : 'new-section'
    }

    const fromSidebar = activeContainerId === "sidebar"
    const toSidebar = overContainerId === "sidebar"
    
    // if someone dragged something already added on the designer to the sidebar.
    // save the current dialogDefinition and then reset & return
    if (toSidebar && !fromSidebar) {
      removeElementByActiveType(id, activeType);
      // handleSave(dialogDefinition)
      resetState()
      return;
    }

    if (
      (!activeContainerId
      || !overContainerId
      || activeContainerId !== overContainerId
      || toSidebar)
      && overContainerId !== "new-section"
      ) {
      resetState()
      return;
    }
    
    let activeItems = null
    let overItems = null
    if (activeType === designContexts.column) {
      const row = definitionManager.findRow(dialogDefinition, activeContainerId)
      const overRow = definitionManager.findRow(dialogDefinition, overContainerId)
      activeItems = toNewSection ? [] : row.columns
      overItems = toNewSection ? [] : overRow.columns
    }
    else if (activeType === designContexts.element) {
      const column = definitionManager.findColumn(dialogDefinition, activeContainerId)
      const overColumn = definitionManager.findColumn(dialogDefinition, overContainerId)
      activeItems = toNewSection ? [] : column.elements
      overItems = toNewSection ? [] : overColumn.elements
    }
    else if (activeType === designContexts.container) {
      activeItems = dialogDefinition.containers
      overItems = dialogDefinition.containers
    }
    else if (activeType === designContexts.row) {
      const container = definitionManager.findContainer(dialogDefinition, activeContainerId)
      const overContainer = definitionManager.findContainer(dialogDefinition, overContainerId)
      activeItems = toNewSection ? [] : container.rows
      overItems = toNewSection ? [] : overContainer.rows
    }

    const activeIndex = activeItems.findIndex(a => a.id === id)
    const overIndex = overItems.findIndex(a => a.id === overId)

    let result
    if (activeType === designContexts.column) {
      if (toNewSection) {
        // newSectionRecord will be a column, and it will have exactly one element in it.
        const element = newSectionRecord.elements[0]
        const lastContainer = definitionManager.findLastContainer(dialogDefinition)
        if (lastContainer) {
          result = containerActions.addBelow(dialogDefinition, lastContainer.id, _.cloneDeep(element), true)
        }
        else {
          result = containerActions.addEmptyWithElement(dialogDefinition, _.cloneDeep(element), true)
        }
      }
      else {
        result = columnActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, overIndex, true)
      }
      changeLayoutType(activeType)
    }
    else if (activeType === designContexts.element) {
      if (toNewSection) {
        const lastContainer = definitionManager.findLastContainer(dialogDefinition)
        const newElement =  _.cloneDeep(newSectionRecord)
        buildPropertyWithValidation(dialogDefinition, newElement);
        if (lastContainer) {
          result = containerActions.addBelow(dialogDefinition, lastContainer.id, newElement, true)
        }
        else {
          result = containerActions.addEmptyWithElement(dialogDefinition, newElement, true)
        }
      }
      else {
        result = elementActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, overIndex, true)
      }
      activeType !== designContexts.element && changeLayoutType(activeType)
    }
    else if (activeType === designContexts.container) {
      result = containerActions.dragAndDrop(dialogDefinition, activeIndex, overIndex, true)
    }
    if (activeType === designContexts.row) {
      if (toNewSection) {
        // newSectionRecord will be a row, and it will have exactly one column, which will have exactly one element
        const element = newSectionRecord.columns[0].elements[0]
        const lastContainer = definitionManager.findLastContainer(dialogDefinition)
        if (lastContainer) {
          result = containerActions.addBelow(dialogDefinition, lastContainer.id, _.cloneDeep(element), true)
        }
        else {
          result = containerActions.addEmptyWithElement(dialogDefinition, _.cloneDeep(element), true)
        }
      }
      else {
        result = rowActions.dragAndDrop(dialogDefinition, activeContainerId, overContainerId, activeIndex, overIndex, true)
      }
      changeLayoutType(activeType)
    }

    // Update in the reducer right away and skip history for better experience. 
    // Prevents dropped item from temporarily being rendered back at where it was before the drop, and then 
    // abruptly getting moved to where it was dropped.
    dispatch(setDialogDefinition({
      dialogDefinition: result,
      skipHistory: true,
    }))
    handleSave(result, {
      reducerActionPayload: {
        // Have been skipping setting history while dragging. Use the state at the point of starting the drag
        // to manually set the previous state in the reducer so undo works as expected
        previous: _.cloneDeep(dialogDefinitionDndClone)
      }
    })

    resetState()
  }

  const resetState = () => {
    setActiveDndId(null)
    setDialogDefinitionDndClone(null)
    setActiveDndType(null)
    setNewSectionRecord(null)

    setActiveReactBeautifulDndId(null)
  }

  /**
     * Handles dnd-kit drag started event for dialog definition design
     * @param {DragStartEvent} event 
     * @param {*} dialogDefinition 
     */
  const handleDragStarted = (event) => {
    const { active } = event
    const handleDragStartedType = getDraggableTypeFromId(active.id)
    setActiveDndId(active.id)
    const fromSidebar = active.data.current.sortable.containerId.split("sortable-context-")[1] === "sidebar"
    setActiveFromSidebar(fromSidebar)
    setDialogDefinitionDndClone(_.cloneDeep(dialogDefinition))
    setActiveDndType(handleDragStartedType)

    const containerId = active.data.current.sortable.containerId.split("sortable-context-")[1];
    if(handleDragStartedType === "row"){
      activeStyleActions(containerId, "container")
    } else if( handleDragStartedType === "column"){
      activeStyleActions(containerId, "row")
    }
  }

  const activeStyleActions = (id, type, removeStyle = false) => {
    const style = ["border-2", "border-red-600", "border-dotted", "z-40", "activeBorderDottedDnd"]
    if(removeStyle){
      const element  = document.getElementsByClassName("activeBorderDottedDnd")[0]
      element?.classList.remove(...style)
    }else{
      const element = document.getElementById(`active-${type}-${id}`)
      element?.classList.add(...style);
    }
  }

  const handleDragCancelled = (event) => {
    const { active } = event;
    const { id: draggableId } = active;
    const activeType = getDraggableTypeFromId(draggableId)

    if(activeType === "row") {
      activeStyleActions(draggableId, "container", true)
    } else if( activeType === "column") {
      activeStyleActions(draggableId, "row", true)
    }

    dispatch(setDialogDefinition({
      dialogDefinition: dialogDefinitionDndClone,
      skipHistory: true,
    }))
    resetState()
  }


  const hasActiveDrag = () => {
    return !!activeDndId || !!activeReactBeautifulDndId
  }

  /**
   * 
   * @param {DragStart} initial 
   * @param {ResponderProvided} provided 
   */
  const onReactBeautifulDndDragStart = (initial, provided) => {
    const { draggableId } = initial
    const indexCharacter = draggableId?.indexOf("-")
    const type = draggableId?.substring(0, indexCharacter);
    setActiveReactBeautifulDndId(draggableId)
    setActiveDndType(type)
  }

  const onReactBeautifulDndDragEnd = ({ source, destination, draggableId, ...props }) => {
    const fromSidebar = source?.droppableId.includes("sidebar");
    const toSidebar = destination?.droppableId.includes("sidebar")
    const indexCharacter = draggableId?.indexOf("-")
    const type = draggableId?.substring(0, indexCharacter);
    const elementDraggableId = objectIdFromDraggableId(draggableId)

    if (!destination || draggableId === null) {
      resetState();
      return;
    }

    if (fromSidebar && toSidebar) {
      resetState();
      return;
    }

    if (source.index === destination.index && source.droppableId === destination.droppableId) {
      resetState();
      return;
    }

    switch (type) {
      case designContexts.container:
        break;
      case designContexts.row:
        const sourceParent = source?.droppableId;
        const destinationParent = destination?.droppableId

        if (fromSidebar) {
          rowActions.addFromSidebar(dialogDefinition, destinationParent, destination.index, true)
        } else {
          rowActions.dragAndDrop(dialogDefinition, sourceParent, destinationParent, source.index, destination.index, fromSidebar)
          definitionManager.openRowActionBar({ id: elementDraggableId })
        }
        break;
      case designContexts.column:
        break;
      case designContexts.element:
        break;
      default:
        break;
    }

    changeLayoutType(type)

    resetState();
  }

  const setSidebarElementsByType = (sidebarElementsByType) => {
    let obj = {}
    sidebarElementsByType.forEach(e => {
      obj[e.type] = e.objects.map(o => ({ ...o, dndId: uuidv4() }))
    })
    setSidebarElements({ ...obj })
  }

  const getElement = (objectType, text) => {
    return sidebarElements[objectType].find(e => e.text === text)
  }

  const getElements = (objectsType) => {
    return sidebarElements[objectsType]
  }

  const regenerateElementDndId = (objectType, dndId) => {
    const cloned = _.cloneDeep(sidebarElements)
    const element = cloned[objectType].find(o => o.dndId === dndId)
    element.dndId = uuidv4()
    setSidebarElements(cloned)
  }

  const onDragHandleClick = (draggableId) => {
    const draggableType = getDraggableTypeFromId(draggableId)
    const objectId = objectIdFromDraggableId(draggableId)

    switch (draggableType) {
      case designContexts.row:
        changeLayoutType(designContexts.row)
        definitionManager.openRowActionBar({ id: objectId })
        break;
      case designContexts.column:
        definitionManager.openColumnActionBar({ id: objectId })
        break;
      case designContexts.element:
        definitionManager.openElementActionBar({ id: objectId })
        break;
      default:
        break;
    }
  }

  const getContainers = () => {
    return dialogDefinition?.containers
  }

  return {
    handleDragStarted,
    handleDragCancelled,
    handleDragOver: debouncedHandleDragOver,
    handleDragEnd,
    activeDndType,
    activeDndId,
    hasActiveDrag,
    onReactBeautifulDndDragStart,
    onReactBeautifulDndDragEnd,
    sidebarColumnId,
    sidebarRowId,
    sidebarContainerId,
    setSidebarElementsByType,
    getElement,
    getElements,
    regenerateElementDndId,
    onDragHandleClick,
    getContainers,
    activeFromSidebar,
    newSectionRecord,
  }
}