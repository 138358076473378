import { conditionConectorTypes } from "../../../utils/constants"

const ActionButton = ({ label, onClick }) => (
    <div className="bg-brand-background px-5 py-1 my-2 cursor-pointer text-xs font-semibold text-gray-500" onClick={onClick}>
        {label}
    </div>
)

export const AddConditionButtons = ({ isFirstCondition, onAddFirstConditionClick, onNewConditionClick }) => {
    return (
        <div className="flex gap-2">
            {
                isFirstCondition ? <ActionButton label="Add" onClick={() => onAddFirstConditionClick()} />
                    : <>
                        <ActionButton label="+ And" onClick={() => onNewConditionClick(conditionConectorTypes.and)} />
                        <ActionButton label="+ Or" onClick={() => onNewConditionClick(conditionConectorTypes.or)} />
                    </>}
        </div>
    )
}