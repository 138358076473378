import { useState } from "react"
import { createDialogObject } from "../../../api/dialogObjects";
import { Button } from "../../../components/Form/Button";
import Modal from '../../../components/Modal';
import { ContainerNew } from "../../../data";
import { useToastAction } from "../../../hooks/useToastAction";

export const NewObject = ({
    isOpen,
    onClose,
    onCreateSuccess,
}) => {
    const [name, setName] = useState();
    const createDialogObjectAction = useToastAction()

    const onCreate = () => {
        createDialogObjectAction.execute(async () => {
            const resp = await createDialogObject(ContainerNew(), name)
            setName()
            onClose()
            onCreateSuccess(resp)
        }, "Failed to create", "Object created")
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title='New object'
            size="small"
        >
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                <input type="text" className='flex-1 block w-full sm:text-sm pr-10 py-2 text-base border-gray-300 hover:border-gray-300 focus:border-gray-300 rounded-md shadow-sm' name="name"
                    onChange={(e) => setName(e.target.value)} 
                />
                <Button
                    text="Create"
                    className="mt-2"
                    disabled={!name?.length}
                    onClick={onCreate}
                />
            </div>
        </Modal>
    );
}