export const onKeyPressWithMinMaxVerification = (e, min, max) => {
  const keyPressed = parseInt(e.nativeEvent.key, 10);
  const keyPressedValidation = (predicate) => min !== 0 ? predicate :  (keyPressed !== 0 && predicate)

  keyPressedValidation(isNaN(keyPressed)) && e.preventDefault();
  
  const currentValue = e.currentTarget.valueAsNumber || "";
  const selection = window.getSelection().toString();
  const getDiff = e.target.value.split(selection).join('')
  const value = parseInt(`${selection.length ? getDiff : currentValue}${keyPressed}`, 10)

  keyPressedValidation(!value) && e.preventDefault();

  value < min && e.preventDefault();
  value > max && e.preventDefault();
}

export const onKeyPressEnter = (event, id) => {
  if (event.key === "Enter") {
    const button = document.getElementById(id);
    button.click();
  }
}

export const onKeyPressAvoidSymbols = (event) => {
  if(!/[a-zA-Z0-9\s]/.test(event.key)) {
    event.preventDefault()
  }
}