import { useState } from 'react'
import toast from 'react-hot-toast';
import { MegaByte } from '../../utils/constants';

/**
 * @param {*} Base64Upload 
 */

export const Base64Upload = ({ onChange, label, name, value, maxFileSizeInMb, defaultOnDelete = null, acceptedExtensions }) => {
  const [base64Url, setBase64Url] = useState('');
  const [file, setFile] = useState('');

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const isBase64Value = () => {
    const valueType = typeof (value);
    return value && valueType === 'string' && value?.startsWith('data:image/')
  }

  const hasBase64Value = value && isBase64Value();

  const handleFileInputChange = e => {
    let newFile = e.target.files[0];
    if (maxFileSizeInMb && newFile?.size && newFile.size > (maxFileSizeInMb * MegaByte)) {
      toast.error(`File is too large. Cannot exceed ${maxFileSizeInMb}MB`)
    }
    else {
      getBase64(newFile)
        .then(result => {
          newFile["base64"] = result;
          setBase64Url(result);
          setFile(newFile);
          onChange({ target: { name: name, value: result, label: newFile.name } })
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const resetFile = () => {
    onChange({ target: { name: name, value: defaultOnDelete, label: label } })
  }

  return (
    <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      {hasBase64Value &&
        <div className={`cursor-pointer w-25 inline-flex items-center mr-2 mt-3 items-center px-3 py-3 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
          onClick={resetFile}
        >
          <span className='cursor-pointer text-sm'>Delete file</span>
        </div>
      }
      <label className={`cursor-pointer w-25 inline-flex items-center mr-2 mt-3 items-center px-3 py-3 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}>
        <span className='cursor-pointer text-sm'>{hasBase64Value ? 'Edit file' : 'Add file'}</span>
        <input type="file" className='hidden' accept={acceptedExtensions} name="file" onChange={handleFileInputChange} />
      </label>
    </div>
  );
}