/**
 * @param {*} Subgroup 
 */
export const Subgroup = ({ title, children, first = false}) => {

  return (
    <>
      <div className={`${first ? 'mt-4' : 'mt-8'} w-full`}>
        <div className="mx-6 border-b border-gray-200 pb-2">
          <h4 className="relative inline-block font-medium text-sm text-gray-600">{title}</h4>
        </div>
        <div className="px-2">
          {children}
        </div>
      </div>
    </>
  );
}