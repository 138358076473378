import { XIcon } from '@heroicons/react/outline';
import React from 'react'
import { useClassNames } from '../../../../../hooks/useClassNames';

export const TextValue = ({ element, onChange }) => {
    const { classNames } = useClassNames();

    const handlePropertyChange = (target) => {
        // console.log("headerline", {value:target.value,name:target.name});
        onChange(target, element, "");
    }



    return (
        <div className={classNames("")}>
            {/* <div>HeaderLine</div> */}
            <div className='mb-1'><label className='font-semibold'>Element:</label><input name={"property"} type="text" className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md' value={element.property} onChange={e => handlePropertyChange(e.target)} /> </div>
            <label className='mb-1 font-semibold'>value:</label>
            <div>
                <input name={"value"} type="text" className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md' value={element.value} onChange={e => handlePropertyChange(e.target)} />
            </div>
        </div>
    )
}

export default TextValue;