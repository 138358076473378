import { useClassNames } from "../../../../hooks/useClassNames";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as AllObjectTemplates from '../../../../templates/objects';
import { useEffect, useRef, useState } from "react";
import React from "react";
import { ArrowDownIcon, ArrowUpIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import useClickOutside from "../../../../hooks/useClickOutside";
import { useQuickDesignObjectLibrary } from "../../../../hooks/useQuickDesignObjectLibrary";
import { runYupValidationSchemaSync } from "../../../../utils/yup";

export const ObjectsSelectStep = ({ quickDesignDefinition, onComplete, onAutoSave, step }) => {
    const { classNames } = useClassNames();
    const { allLibraryObjects, createNewObjectInstance } = useQuickDesignObjectLibrary();
    const [selectedObjects, setSelectedObjects] = useState(quickDesignDefinition.selectedObjects);
    const [errorMessage, setErrorMessage] = useState("");

    const [activeDragItem, setActiveDragItem] = useState(null);
    const [activeDragDestination, setActiveDragDestination] = useState(null);
    const [activeSourceItem, setActiveSourceItem] = useState(null);
    const [activeSelectedItem, setActiveSelectedItem] = useState(null)
    const allObjectsListRef = useRef(null);
    const selectedObjectsListRef = useRef(null);

    useClickOutside(allObjectsListRef, () => {
        setActiveSourceItem(null)
    });

    useClickOutside(selectedObjectsListRef, () => {
        setActiveSelectedItem(null);
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDragEnd = ({ destination, source, ...result }) => {
        setActiveDragItem(null);
        setActiveDragDestination(null);
        let items = [...selectedObjects];
        // selected dropped on delete
        if (source?.droppableId === "selected" && destination?.droppableId === "delete") {
            items.splice(source.index, 1)
            items = [...items]
            setSelectedObjects(
                items
            );
            return;
        }

        // const item = allObjects.find(x => x.id === result.draggableId);
        //source dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === "source" && destination.droppableId === "selected") {
            const item = createNewObjectInstance(allLibraryObjects[source.index].objectId);
            item.name = allLibraryObjects[source.index].name;
            items.splice(destination.index, 0, item)
            items = [...items];
            // setSelectedObjects([...selectedObjects]);
        } else if (source.droppableId === "selected" && destination.droppableId === "selected") {
            items = reorder(
                items,
                source.index,
                destination.index
            );
        }
        updateSelectedObjects(items);

    }
    const updateSelectedObjects = (updatedList) => {
        setSelectedObjects(
            updatedList
        );
        if (updatedList.length > 0) {
            setErrorMessage("")
        } else {
            setErrorMessage("At lease one object required")
        }
    }

    const handleDragStart = (source) => {
        setActiveDragItem(source);
    }

    const handleDragUpdate = ({ destination }) => {
        setActiveDragDestination(destination)
    }

    const validateStep = (selectedObjects, onValidCallback) => {
        const errors = runYupValidationSchemaSync(step.stepValidationSchema, { selectedObjects });
        if (Object.keys(errors).length === 0) {
            onValidCallback(selectedObjects)
        } else {
            setErrorMessage(errors["selectedObjects"])
        }
    }

    const handleNextClick = () => {
        validateStep(selectedObjects,onComplete);

    }

    const autoSave = () => {
        validateStep(selectedObjects,onAutoSave);
    }

    const handleResetClick = () => {
        setSelectedObjects(quickDesignDefinition.selectedObjects);
    }

    const selectedItemDragged = (onActiveCssClass) => {
        if (!activeDragItem) {
            return "";
        }

        return activeDragItem?.source?.droppableId === "selected" ? onActiveCssClass : "";
    }

    const selectedOverDelete = () => {
        return activeDragItem?.source?.droppableId === "selected" && activeDragDestination?.droppableId === "delete";
    }

    const handleAddSourceItem = () => {
        if (activeSourceItem) {
            const addObj = createNewObjectInstance(activeSourceItem.objectId);
            addObj.name = { ...activeSourceItem }.name;
            const selected = [...selectedObjects, addObj];
            setSelectedObjects(prev => selected);
        }
    }

    const handleRemoveSelected = () => {
        if (activeSelectedItem) {
            let clones = _.cloneDeep(selectedObjects);
            const activeIdx = clones.findIndex(x => x.id === activeSelectedItem?.id);
            let items = [...clones.filter(x => x.id !== activeSelectedItem?.id)];

            if (items.length === 0) {
                setActiveSelectedItem(null)
            } else {
                const newSelectedIdx = activeIdx === items.length ? items.length - 1 : activeIdx;
                setActiveSelectedItem(prev => items[newSelectedIdx])
            }

            setSelectedObjects(prev => items);
        }
    }

    const handleMoveUp = () => {
        if (activeSelectedItem) {
            let clones = _.cloneDeep(selectedObjects);
            const activeIdx = clones.findIndex(x => x.id === activeSelectedItem?.id);
            if (activeIdx === 0 || activeIdx === -1) {
                return;
            }
            const items = reorder(
                clones,
                activeIdx,
                activeIdx - 1
            );
            updateSelectedObjects(items);
        }
    }

    const handleMoveDown = () => {
        if (activeSelectedItem) {
            let clones = _.cloneDeep(selectedObjects);
            const activeIdx = clones.findIndex(x => x.id === activeSelectedItem?.id);
            if (activeSelectedItem.index === selectedObjects.length - 1) {
                return;
            }
            const items = reorder(
                clones,
                activeIdx,
                activeIdx + 1
            );
            updateSelectedObjects(items);
        }
    }

    useEffect(() => {
        autoSave();
    }, [selectedObjects])

    return (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
            <div>
                <div className="mb-4 flex justify-between border-b pb-4 mb-2">
                    <div>
                        <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">Objects</h1>
                        <p className="mb-2 max-w-4xl text-sm text-gray-500">
                            Drag and drop or select to choose and order objects
                        </p>
                    </div>
                    <div className='hidden sm:inline-block space-x-4'>
                        <button
                            type="button"
                            id={"next-btn"}
                            onClick={e => {
                                handleResetClick();
                            }}
                            className={`w-1/2 sm:w-auto inline-flex items-center w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm`}
                        >
                            <span>Reset</span>
                        </button>
                        <button
                            type="button"
                            id={"next-btn"}
                            onClick={e => {
                                handleNextClick();
                            }}
                            className={`w-1/2 sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                        >
                            <span>Next</span>
                        </button>
                    </div>
                </div>

                <div
                    className={classNames('grid grid-cols-12 pb-4 space-x-1 sm:space-x-4 rounded-lg')}>
                    <div className="col-span-5">
                        <Droppable droppableId="source">
                            {(provided, snapshot) => (<div className='space-y-2'>
                                <div className=''>
                                    <label className='font-semibold'>All Objects</label>
                                </div>
                                <div className="relative" ref={allObjectsListRef}>
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="border border-gray-200 rounded-md h-80 overflow-y-auto shadow"
                                    >
                                        {allLibraryObjects.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, { isDragging }) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        onClick={e => {
                                                            setActiveSourceItem(item)
                                                        }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            className={classNames("px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md",
                                                                isDragging ? "rounded-md border shadow-sm" : "border-b",
                                                                activeSourceItem?.id === item.id ? "rounded-md border border-indigo-500" : "")}
                                                        >
                                                            <span>{index + 1}.</span> <span className='font-semibold'>{item.name}</span>
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                    {
                                        activeSourceItem && !activeDragItem &&
                                        (
                                            <SourceListActionMenu onItemAdd={handleAddSourceItem} />
                                        )
                                    }
                                </div>
                            </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="col-span-2 flex">
                        <Droppable droppableId="delete">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={classNames("flex-1 flex mt-14 mb-6 border-2 border-transparent border-dotted rounded items-center justify-center", selectedItemDragged("border-red-200"), snapshot.isDraggingOver && activeDragItem?.source?.droppableId === "selected" ? "border-red-400" : "")}>
                                    <TrashIcon className={classNames("h-10 w-10 text-transparent", selectedItemDragged("text-red-200"))}></TrashIcon>
                                    <span className="hidden">{provided.placeholder}</span>
                                </div>
                            )}

                        </Droppable>
                    </div>
                    <div className="col-span-5">
                        <Droppable droppableId="selected">
                            {(provided, snapshot) => (
                                <div className='space-y-2'>
                                    <div className=''>
                                        <label className='font-semibold'>Selected Objects</label>
                                    </div>
                                    <div className="relative" ref={selectedObjectsListRef}>
                                        <ul
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="border border-gray-200 rounded-md h-80 overflow-y-auto overflow-x-hidden shadow w-full"
                                        >
                                            {selectedObjects.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, { isDragging }) => (
                                                        <li
                                                            ref={provided.innerRef}
                                                            onClick={e => setActiveSelectedItem(item)}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div
                                                                className={classNames("px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md",
                                                                    isDragging ? classNames("rounded-md border shadow-sm", selectedOverDelete() ? "inline-block px-2 py-.5 space-x-2 truncate w-16 sm:w-20" : "") : "border-b",
                                                                    activeSelectedItem?.id === item.id ? "rounded-md border border-indigo-500" : "")}
                                                            >
                                                                <span>{index + 1}.</span> <span className='font-semibold'>{item.name}</span>
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                        {
                                            activeSelectedItem && !activeDragItem &&
                                            (
                                                <SelectedListActionMenu onRemove={() => handleRemoveSelected()} onMoveUp={() => handleMoveUp()} onMoveDown={() => handleMoveDown()} />
                                            )
                                        }
                                    </div>

                                </div>
                            )}
                        </Droppable>
                    </div>

                </div>
                <div className='py-1 text-red-500'>
                    {errorMessage}
                </div>
                <div className='flex space-x-4 sm:hidden'>
                    <button
                        type="button"
                        id={"next-btn"}
                        onClick={e => {
                            handleResetClick();
                        }}
                        className={`w-1/2 sm:w-auto flex items-center w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm`}
                    >
                        <span>Reset</span>
                    </button>
                    <button
                        type="button"
                        id={"next-btn"}
                        onClick={e => {
                            handleNextClick();
                        }}
                        className={`w-1/2 sm:w-auto flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                    >
                        <span>Next</span>
                    </button>
                </div>
            </div>
        </DragDropContext >
    )
}

const SelectedListActionMenu = ({ onRemove, onMoveUp, onMoveDown }) => {
    return (
        <div className="absolute top-20 -left-20 grid grid-cols-2 space-y-2">
            <div className="row-span-2 flex items-center justify-center mt-2">
                <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onRemove()} title="Add Object">
                    <TrashIcon className="h-5 w-5 text-white" />
                </div>
            </div>
            <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onMoveUp()} title="Add Object">
                <ArrowUpIcon className="h-5 w-5 text-white" />
            </div>
            <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onMoveDown()} title="Add Object">
                <ArrowDownIcon

                    className="h-5 w-5 text-white" />
            </div>
        </div>
    )
}

const SourceListActionMenu = ({ onItemAdd }) => {
    return (
        <div className={`absolute top-24 -right-12 grid grid-cols-1 grid-rows-2`}>
            <div className="row-span-2 flex items-center justify-center mt-2">
                <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onItemAdd()} title="Add Object">
                    <PlusIcon className="h-5 w-5 text-white" />
                </div>
            </div>
        </div>
    )
}