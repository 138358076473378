export const useTableSearch = (sourceData) => {

    return {
        search: (searchBy,setOutput , ...searchFields)=> {
            if(!sourceData.length){
                return;
            }
            if (searchBy && searchBy !== '') {
                setOutput([...sourceData.filter(
                    m => searchFields.some(
                        f => m[searchFields].toLowerCase().includes(searchBy.toLowerCase())
                    )
                )]);
            }
            else {
                setOutput([...sourceData]);
            }
        },
    }
}