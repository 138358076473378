import { useState } from "react"
import { useParams } from "react-router-dom";
import { getDialogObject, updateDialogObject } from "../../../api/dialogObjects"
import { EmptyContainer } from "../../../data";
import { DialogDesign } from "../DialogDesign/DialogDesign";

export const EditObject = () => {
  const { objectId } = useParams()

  const [dialogObject, setDialogObject] = useState(null)

  const onLoad = async () => {
    const result = await getDialogObject(objectId)
    const dialogDefinition = {
      type: 1,
      status: result.status,
      containers: [result.container]
    }
    setDialogObject(result)
    return dialogDefinition
  }

  const onSave = async (dialogDefinition, lastUpdatedAt) => {
    if (dialogDefinition?.containers && dialogObject) {
      const dialogDefinitionContainer = dialogDefinition.containers[0] || EmptyContainer();
      const payload = lastUpdatedAt
        ? { ...dialogObject, container: dialogDefinitionContainer, updatedDate: lastUpdatedAt }
        : { ...dialogObject, container: dialogDefinitionContainer }

      const updateResult = await updateDialogObject(objectId, payload);

      return updateResult
    }
  }

  return (
    <DialogDesign
      onLoad={onLoad}
      onSave={onSave}
      disableAddObjects={true}
      disableAddContainer={true}
      disableSettings={true}
      disableLayoutSettings={true}
      disablePublish={true}
      disableActive={true}
      disablePreview={true}
      disableExport={true}
      disableDuplicateContainer={true}
      isObjectTemplate={true}
    />
  )
}