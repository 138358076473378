import { post, get, BaseUrl } from './api'

export const getEnvironments = async (props) => {
  return await get('api/InteractSoapClient/all',  BaseUrl.Centerpoint,  { ignore404: true });
}

export const getTemplateEnvironments = async (props) => {
  return await get('TemplateEnvironment/all',  BaseUrl.InteractAdmin,  { ignore404: true });
}

export const getTemplates = async (environment, metaFile) => {
  return await get(`${environment}/onlinedocuments/${metaFile}`,  BaseUrl.WebEditor,  { ignore404: true });
}