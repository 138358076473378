export const Label = ({ label, value }) => {
  return (
    <div className={`px-4 sm:px-6 pt-3 flex flex-col justify-between`}>
      <span className="flex-grow flex flex-col justify-center" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <div className="mt-1 relative rounded-md">
        <label>{value}</label>
      </div>
    </div>
  )
}