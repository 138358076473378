import * as _ from "lodash"

/**
 * Similar behavior as using Formik's ErrorMessage component except with ability to
 * show error without component being touched
 */
export const FormikError = ({ name, formikProps, requireTouched = true }) => {
  const hasError = formikProps?.errors && _.get(formikProps.errors, name);
  const touched = formikProps?.touched && _.get(formikProps.touched, name);

  if (!hasError || (!touched && requireTouched)) {
    return null
  }

  return (
    <div className="text-red-500">{_.get(formikProps.errors, name)}</div>
  )
}