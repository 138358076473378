import './styles.scss'
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import { TextInput } from '../../components/Form/TextInput';
import { smartObjectPropertyTypes } from '../../utils/constants';
import { Checkbox } from '../../components/Form/Checkbox';
import { useElementDesign } from '../useElementDesign';

export default function SmartObjectModal({ show, setShow, save, buttonStyle, smartObject, rowData, data, columns, ...props }) {
  const [currentData, setCurrentData] = useState(rowData);
  const elementDesign = useElementDesign(data.id, data);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentData({ ...currentData, [name]: value });
  };

  const handleSave = () => {
    save(currentData)
    setShow(false)
    setCurrentData({})
  }

  useEffect(() => {
    // if rowdata is empty, then we are creating a new row
    // with default values where the keys are the smart object property names
    // and values are empty string or first dropdown option
    if (show) {
      if (Object.keys(rowData).length === 0) {
        const defaultValues = {}
        smartObject?.smartObjectProperties?.forEach((property, index) => {
          if (property.type === smartObjectPropertyTypes.dropdown) {
            defaultValues[property.name] = property.dropdownOptions[0]
          } else if (property.type === smartObjectPropertyTypes.checkbox) {
            defaultValues[property.name] = false;
          } else {
            defaultValues[property.name] = ''
          }
        })
        setCurrentData(defaultValues)
      } else {
        setCurrentData(rowData)
      }
    }
  }, [rowData, smartObject?.smartObjectProperties, show])

  const verifyColumnIsHidden = (property) => {
    const findColumn = columns.find(column => column.name === property.name)
    
    return findColumn ? findColumn.hide : false
  }

  const isString = (type) => type === smartObjectPropertyTypes.string;
  const isDate = (type) => type === smartObjectPropertyTypes.date;
  const isDropdown = (type) => type === smartObjectPropertyTypes.dropdown;
  const isCheckbox = (type) => type === smartObjectPropertyTypes.checkbox;

  return (
    <Modal
      isOpen={show}
      onClose={() => {
        setShow(false)
      }}
      size="medium"
    >
      {
        smartObject?.smartObjectProperties?.filter(p => !verifyColumnIsHidden(p)).map((property, index) => {
          const type = property.type
          const label = elementDesign.translateColumnsSmartTable(property.id, property.label)

          return (
            <div key={property.id} className="mt-4">
              {
                (isString(type) || isDate(type)) &&
                <TextInput
                  type={isDate(type) ? 'date' : 'text'}
                  name={`name`}
                  value={currentData[property.name]}
                  onChange={value => {
                    handleChange({ target: { name: property.name, value } })
                  }}
                  label={label}
                  required
                />
              }

              {
                isDropdown(type) &&
                <>
                  <label htmlFor={property.name} className={"block text-sm font-medium text-gray-700"}>
                    {label}
                    <span className="text-red-400"> *</span>
                  </label>
                  <select
                    id="type"
                    name={property.name}
                    value={currentData[property.name]}
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
                  >
                    {
                      property.dropdownOptions?.map((option, index) => {
                        return (
                          <option key={index} value={option}>{elementDesign.translateOptionForSmartTable(property.id, index, option)}</option>
                        )
                      })
                    }
                  </select>
                </>
              }

              {
                isCheckbox(property.type) &&
                <Checkbox
                  id="type"
                  name={property.name}
                  checked={currentData[property.name]}
                  label={label}
                  onChange={value => {
                    handleChange({ target: { name: property.name, value } })
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
                  required
                />
              }

            </div>
          )
        }
        )}
      <div className="mt-5 sm:mt-6">
        <button
          id="submitButton"
          // disabled={newSmartObjectName.length === 0}
          type="button"
          style={buttonStyle}
          className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:text-sm"
          onClick={(event) => {
            event.preventDefault();
            handleSave();
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={(event) => {
            event.preventDefault();
            setShow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}