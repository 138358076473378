import { useState } from "react";

/**
 * @param {*} Select 
 */
export const Select = ({ onChange, label, name, options, value, defaultSelected = '', labelTop = false, disabled }) => {
const [localValue, setLocalValue] = useState(value || defaultSelected);

  const handleOnChange = (e, type) => {
    setLocalValue(e.target.value)
    onChange(e, type)
  }

  return (
    <div className={`px-4 sm:px-6 pt-3 flex flex-col justify-between`}>
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </span>
      <select
        name={name}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md"
        onChange={(e) => handleOnChange(e)}
        value={value}
        disabled={disabled}
      >
        {
          options?.map((option, index) => {
            return (<option key={`${option.name}${option.value}`} value={option.value}>{option.name}</option>)
          })
        }
      </select>
    </div>
  );
}