import { post, get, destroy, put } from './api'

export const createThemeStyle = async (props) => {
  return await post('themestyle', props);
}

export const updateThemeStyle = async (id, props) => {
  return await put(`themestyle/${id}`, props);
}

export const getAllThemeStyle = async () => {
  return await get('themestyle');
}

export const deleteThemeStyle = async (id) => {
  return await destroy(`themestyle/${id}`);
}