import { setDialogValuesHeader } from "../../api/public/dialogValues"
import { processTypes } from "../../utils/constants"
import { useNavigate } from 'react-router';

export const useTokenAuthentication = () => {
  const navigate = useNavigate()

  const getJwtForDialogValuesPhoneNumberLookup = (dialogValuesId) => {
    return sessionStorage.getItem(`DialogValues:${dialogValuesId}:PhoneList`)
  }

  const getJwtForDialogValues = (dialogValuesId) => {
    return sessionStorage.getItem(`DialogValues:${dialogValuesId}`)
  }

  const getJwtForDialogValuesStep = (dialogValuesId, stepId) => {
    return sessionStorage.getItem(`DialogValues:${dialogValuesId}:Step:${stepId}`)
  }

  return {
    requiresAuthentication: (dialogValues, dialogDefinition) => {
      if (dialogValues.process === processTypes.normal && !dialogDefinition?.contactListId) {
        return false
      }
      const jwt = getJwtForDialogValues(dialogValues.id)
      if (jwt) {
        setDialogValuesHeader(jwt);
        return false
      }
      return true
    },
    stepRequiresAuthentication: (dialogValues, step) => {
      if (step.securityOptions?.length === 0) {
        return false;
      }
      const jwt = getJwtForDialogValuesStep(dialogValues.id, step.id)
      if (jwt) {
        setDialogValuesHeader(jwt);
        return false
      }
      return true
    },
    completeAuthentication: (dialogValuesId, jwt) => {
      sessionStorage.setItem(`DialogValues:${dialogValuesId}`, jwt);

      // Clear out PhoneList session item if it exists
      sessionStorage.removeItem(`DialogValues:${dialogValuesId}:PhoneList`)
    },
    completeAuthenticationForStep: (dialogValuesId, stepId, jwt) => {
      sessionStorage.setItem(`DialogValues:${dialogValuesId}:Step:${stepId}`, jwt);
    },
    completeAuthenticationPhoneNumberLookup: (dialogValuesId, jwt) => {
      sessionStorage.setItem(`DialogValues:${dialogValuesId}:PhoneList`, jwt);
    },
    setHeaderForPhoneListAuth: (dialogValuesId) => {
      setDialogValuesHeader(getJwtForDialogValuesPhoneNumberLookup(dialogValuesId))
    },
    setHeaderForStepAuth: (dialogValuesId, stepId) => {
      setDialogValuesHeader(getJwtForDialogValuesStep(dialogValuesId, stepId))
    },
    setHeaderForDialogValuesAuth: (dialogValuesId) => {
      setDialogValuesHeader(getJwtForDialogValues(dialogValuesId))
    },
    getJwtForDialogValues,
    getJwtForDialogValuesStep,
    getJwtForDialogValuesPhoneNumberLookup,
    handleUnauthorized: () => {
      navigate(`${window.location.pathname}/authenticate`, { replace: true })
    },
    handlePhoneListProcessUnauthorized: (dialogDefinitionId, dialogValuesId) => {
      navigate(`/dialog/${dialogDefinitionId}/form/${dialogValuesId}/authenticate/contactlist`, { replace: true })
    }
  }
}