import React from "react"
import { dialogType } from "../../../../../utils/constants"
import { PropertyOfStandardWTable } from "./standardWTable"
import { OutsideStyles } from "./outsideStyles"

export const PropertyOfType = ({ dialogDefinition, handleChange }) => {

  return (
    <>
      <OutsideStyles data={dialogDefinition.outsideStyles} handleChange={handleChange} />

      {dialogDefinition.type === dialogType.standardWTable && <PropertyOfStandardWTable data={dialogDefinition.standardWTableStyles} handleChange={handleChange} />}
    </>
  )
}