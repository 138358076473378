export const useFileReader = () => {

  const getFileContent = file => {
    return new Promise(resolve => {
      let content = "";
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result;
        resolve(content);
      };

      reader.readAsText(file);
    });
  };

  const handleFileInputChange = async e => {
    let newFile = e.target.files[0];

    const result = await getFileContent(newFile)

    newFile["base64"] = result;
    return {
      fileName: newFile.name,
      content: result
    }
  };

  return {
    handleFileInputChange
  }
}