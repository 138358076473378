import { forwardRef } from "react"
import { Checkbox } from "./checkbox"
import { CustomErrorMessage } from "./customErrorMessage"

export const RequiredWithCustomMessage = forwardRef((props, ref) => {
    let { data, onChange, dependency = null } = props
    return (
        <>
            <Checkbox
                onChange={onChange}
                label={"Required field"}
                name={"requiredField"}
                value={data?.requiredField}
                ref={ref}
                dependency={dependency}
            />
            {data?.requiredField && <CustomErrorMessage onChange={onChange} byRequired value={data?.customErrorMessage?.byRequired} />}
        </>
    )
})