import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SetupStep } from './setupStep';
import { QuickFormStepBar } from './quickFormStepBar';
import { ObjectsSelectStep } from './objectsSelectStep';
import ModifyObjectDataStep from './modifyObjectDataStep';
import { PreviewQuickDesignStep } from './previewQuickDesignStep';
import { useToastAction } from '../../../../hooks/useToastAction';
import { createQuickDesignForm, getQuickDesignDefinition, updateQuickDesignForm } from '../../../../api/quickDesign';
import { object, string, array } from 'yup';
import { useDebounceCallback } from '@react-hook/debounce';
import { QuickDesignContext } from '../../../../contexts';

const quickDesignStepStatus = {
    current: "current",
    upcoming: "upcoming",
    completed: "complete"
};

export default function QuickDesign({ dialogDefinition, onLoad }) {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const [quickDesignDefinition, setQuickDesignDefinition] = useState();
    const [isNewForm, setIsNewForm] = useState(true);
    // {
    //     language: 'English',
    //     bgColor: 'ffff',
    //     pageType: 'General',
    //     selectedObjects: [],
    //     removedElementIds: []
    // }
    const [saveState, setSaveState] = useState("");
    const [activeStep, setActiveStep] = useState('01');
    const [steps, setSteps] = useState([
        {
            id: '01', name: 'Setup', status: quickDesignStepStatus.current, stepValidationSchema: object({
                // language: string().required(),
                backGroundColor: string().required("Background color required"),
                // pageType: string().required()
            })
        },
        {
            id: '02', name: 'Objects', status: quickDesignStepStatus.upcoming, stepValidationSchema: object({
                selectedObjects: array().required().min(1, "At least 1 selected object required")
            })
        },
        {
            id: '03', name: 'Data', status: quickDesignStepStatus.upcoming, stepValidationSchema: object({
                removedElementIds: array().required()
            })
        },
        { id: '04', name: 'Preview', status: quickDesignStepStatus.upcoming },
    ]);
    const loadAction = useToastAction();
    const saveAction = useToastAction();

    const loadData = () => {
        const isNew = !dialogDefinition.quickFormId;
        setIsNewForm(isNew);
        loadAction.execute(async () => {
            const quickForm = isNew ?
                await createQuickDesignForm(dialogDefinition.id) :
                await getQuickDesignDefinition(dialogDefinition.quickFormId);
            
            if(isNew){
                onLoad();
            }
            setQuickDesignDefinition(quickForm);
        })
    }

    const saveQuickForm = () => {
        saveAction.execute(async () => {
            const result = await updateQuickDesignForm(quickDesignDefinition);
            setQuickDesignDefinition(prev => {
                return { ...prev, updatedDate: result.updatedDate };
            })
        })
    }

    const goToNext = () => {
        const idx = steps.findIndex(x => x.id === activeStep);
        const nextStep = steps[idx + 1];
        if (nextStep) {
            setSteps(prev => {
                prev[idx].status = quickDesignStepStatus.completed;
                prev[idx].dirty = true;
                prev[idx + 1].status = quickDesignStepStatus.current;
                return [...prev];
            })
            navigate(`#${nextStep.id}`)
        }
    }
    const debounceSave = useDebounceCallback(saveQuickForm, 500);
    const handleAutoSaveSetup = (values) => {
        setQuickDesignDefinition(prev => {
            return { ...prev, ...values }
        });
        setSaveState(generateSaveId());
    }

    const handleSetupComplete = (values) => {
        handleAutoSaveSetup(values);
        goToNext();

    }

    const handleAutoSaveObjectsSelect = (selectedObjects) => {
        setQuickDesignDefinition(prev => {
            return { ...prev, selectedObjects }
        });
        setSaveState(generateSaveId());
    }

    const handleObjectsStepComplete = (selectedObjects) => {
        handleAutoSaveObjectsSelect(selectedObjects);
        goToNext();
    }

    const handleModifyObjectDataComplete = (modifiedObjects) => {
        setQuickDesignDefinition(prev => {
            return { ...prev, selectedObjects: modifiedObjects };
        })
        setSaveState(generateSaveId());
        goToNext();
    }

    const generateSaveId = () => {
        return new Date().getTime().toString();
    };

    const handleStepClick = (step, idx) => {
        const status = getStepStatus(step, idx);
        if (status === quickDesignStepStatus.current || status === quickDesignStepStatus.completed)
            navigate(`#${step.id}`)
    }

    useEffect(() => {
        setActiveStep(hash?.replace('#', '') || '01');
    }, [hash]);

    useEffect(() => {
        if (!quickDesignDefinition) {
            return;
        }
        debounceSave();

    }, [saveState])

    useEffect(() => {
        if (quickDesignDefinition) {
            // validateSteps();
        }
    }, [quickDesignDefinition])
    useEffect(() => {
        loadData();
    }, [])

    const getStepStatus = (step, idx) => {
        if (step.id === activeStep) {
            return "current";
        }
        if (!isNewForm || step.dirty) {
            // if (!step.stepValidationSchema) {
            //     return "complete";
            // }
            const prevSteps = steps.filter((x, i) => i < idx);
            const prevStepsValid = prevSteps.length === 0 || prevSteps.every(prevStep => {
                return !prevStep.stepValidationSchema || prevStep.stepValidationSchema?.isValidSync(quickDesignDefinition);
            })
            const valid = step.stepValidationSchema?.isValidSync(quickDesignDefinition) && prevStepsValid;
            return valid ? "complete" : "upcoming";
        } else {
            return idx === 0 ? "current" : "upcoming";
        }
    }

    return loadAction.isExecuting || !quickDesignDefinition ? (<></>) : (
        <>
            <QuickDesignContext.Provider
                value={{
                    quickDesignDefinition,
                    isNewForm,
                    getStepStatus,
                    onRemoveElementToggle: (elementId) => {
                        const idx = quickDesignDefinition.removedElementIds.findIndex(x => x === elementId);
                        let updatedList = [...quickDesignDefinition.removedElementIds];
                        if (idx === -1) {
                            updatedList = [elementId, ...updatedList];
                        } else {
                            updatedList.splice(idx, 1);
                        }
                        setQuickDesignDefinition(prev => {
                            return { ...prev, removedElementIds: updatedList };
                        })
                    }
                }}>
                <div>
                    <div className="pb-4">
                        <div className="pb-5 border-b border-gray-200 mt-10 mb-6">
                            <h1 className="text-2xl leading-6 font-medium  text-gray-900">QuickDesign</h1>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                Quick and easy SmartFrom design
                            </p>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <QuickFormStepBar steps={steps} onStepClick={handleStepClick} />
                    </div>
                    <div className="bg-white shadow rounded-lg mt-4 p-8">
                        {
                            activeStep === '01' ? (
                                <SetupStep onComplete={handleSetupComplete} onAutoSave={handleAutoSaveSetup} quickDesignDefinition={quickDesignDefinition} step={steps.find(x => x.id === '01')} />
                            ) : activeStep === '02' ? (
                                <ObjectsSelectStep onComplete={handleObjectsStepComplete} onAutoSave={handleAutoSaveObjectsSelect} quickDesignDefinition={quickDesignDefinition} step={steps.find(x => x.id === '02')} />
                            ) : activeStep === '03' ? (
                                <ModifyObjectDataStep onComplete={handleModifyObjectDataComplete} save={debounceSave} quickDesignDefinition={quickDesignDefinition} step={steps.find(x => x.id === '03')} />
                            ) : activeStep === '04' ? (
                                <PreviewQuickDesignStep quickDesignDefinition={quickDesignDefinition} step={steps.find(x => x.id === '01')} dialogDefinition={dialogDefinition} onLoad={onLoad} />
                            ) : (<></>)
                        }
                    </div>
                </div>
            </QuickDesignContext.Provider>
        </>
    )
}

