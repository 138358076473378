import Enumerable from 'linq';
import { dialogDefinitionStatuses, processTypes } from "./constants"

export const isActive = (dialogDefinition) => {
  return dialogDefinition.status !== dialogDefinitionStatuses.inactive;
}

export const isContainerLocked = (containerDefinition) => {
  return !!containerDefinition.dialogObjectId && containerDefinition.lockToDialogObject;
}

export const isContainerHidden = (data, inputValues ) => {
  let previousCondition = true;
  let isFirst = true;
    
  let orderedLogicControls = data.showLogicControl ? [...data.showLogicControl] : []; 
  orderedLogicControls.sort((a, b) => a.conector - b.conector);

  orderedLogicControls?.forEach((logicControl) => {
    const condition = logicControl.condition;
    let elementValue;
    elementValue = inputValues ? inputValues[condition.property] : { value: "" }
    
    if(elementValue?.group) {
      const values = Object.values(inputValues)
      const groupValue = values.find(x => x.group === elementValue.group && x.value);
      elementValue = groupValue;
    }
    
    if(logicControl.conector === 0)
      previousCondition = elementValue?.value === condition.match;  
    else if(logicControl.conector === 1)
      previousCondition = previousCondition && elementValue?.value === condition.match;  
    else
      previousCondition = previousCondition || elementValue?.value === condition.match;  
      

    isFirst = false;
  })

  return data?.showLogicControl?.length > 0 && !previousCondition;
}

export const getAllElements = (dialogDefinition) => {
  return Enumerable.from(dialogDefinition?.containers)
    .selectMany(c => c.rows)
    .selectMany(r => r.columns)
    .selectMany(c => c.elements)
    .toArray();
}

export const getAllContainers = (dialogDefinition) => {
  return Enumerable.from(dialogDefinition?.containers)
    .toArray();
}

export const isMultiStep = (dialogDefinition) => {
    return dialogDefinition.process === processTypes.multiStep;
}

export const ensureUniquePropertyNamesForAllElements = (allElements) => {
  const properyNameCollisions = []
  allElements.forEach(element => {
    ensureUniquePropertyNames(allElements, element, element.property, 1, properyNameCollisions)
  });
  return properyNameCollisions
}

export const ensureUniquePropertyNamesForAllContainers = (allContainers) => {
  const properyNameCollisions = []
  allContainers.forEach(container => {
    ensureUniqueName(allContainers, container, container.name, 1, properyNameCollisions)
  });
  return properyNameCollisions
}

export const createRetentionPolicyOptions = (retentionPolicies, currentPolicy) => {
  const filtered = retentionPolicies.filter(x => !x.isDisabled || x.id === currentPolicy);
  return filtered.map(x => ({ name: (x.isDisabled ? x.name + ' (Disabled)' : x.name), value: x.id, disabled: x.isDisabled }))
}

const ensureUniquePropertyNames = (allElements, currentElement, originalPropertyName, extraNumber, properyNameCollisions) => {
  const matchingProperties = allElements.filter(e => e.property === currentElement.property && e !== currentElement)
  if (matchingProperties.length > 0) {
    properyNameCollisions.push(currentElement.property)
    if (extraNumber === 1) {
      matchingProperties[0].property = `${currentElement.property}-${1}`
    }
    else {
      const numAsString = `${extraNumber}`
      const currentStringLength = currentElement.property.length
      const originalStringLength = originalPropertyName?.length
      const lengthDifference = currentStringLength - originalStringLength

      const substring = matchingProperties[0].property.substring(0, currentStringLength - lengthDifference)
      matchingProperties[0].property = `${substring}-${numAsString}`
    }
    ensureUniquePropertyNames(allElements, matchingProperties[0], originalPropertyName, extraNumber + 1, properyNameCollisions)
  }
}

const ensureUniqueName = (allContainers, currentContainer, originalName, extraNumber, properyNameCollisions) => {
  const matchingProperties = allContainers.filter(e => e.name === currentContainer.name && e !== currentContainer)
  if (matchingProperties.length > 0) {
    properyNameCollisions.push(currentContainer.name)
    if (extraNumber === 1) {
      matchingProperties[0].name = `${currentContainer.name}-${1}`
    }
    else {
      const numAsString = `${extraNumber}`
      const currentStringLength = currentContainer.name.length
      const originalStringLength = originalName?.length
      const lengthDifference = currentStringLength - originalStringLength

      const substring = matchingProperties[0].name.substring(0, currentStringLength - lengthDifference)
      matchingProperties[0].name = `${substring}-${numAsString}`
    }
    ensureUniqueName(allContainers, matchingProperties[0], originalName, extraNumber + 1, properyNameCollisions)
  }
}