import { featureFlags } from "./features";

/**
 * Maps dialogValues.values from API model to key value pair 
 */
export const valuesModelToKeyValuePair = (dialogValues) => {
  let keyValues = {}
  dialogValues.values.forEach(dv => {
    keyValues[featureFlags.mapFromPropertyName ? dv.property : dv.elementId] = dv;
  })
  return keyValues;
}

/**
 * Maps key value pair of values to API model of dialogValues.values 
 */
export const valuesKeyValuePairToModel = (keyValuePair, includeTableValues = false) => {
  const values = Object.keys(keyValuePair).filter(k => !k.files && !k.tableValues).map(key => {
    if (includeTableValues) {
      return (
        {
          elementId: featureFlags.mapFromPropertyName ? keyValuePair[key].elementId : key,
          value: keyValuePair[key].value,
          group: keyValuePair[key].group,
          files: keyValuePair[key].files,
          tableValues: keyValuePair[key].tableValues,
          smartTableValues: keyValuePair[key].smartTableValues,
          property: featureFlags.mapFromPropertyName ? key : keyValuePair[key].property,
          getKey: () => featureFlags.mapFromPropertyName ? keyValuePair[key].property : keyValuePair[key].elementId,
          clonedFromElementProperty: keyValuePair[key].clonedFromElementProperty,
          dynamicRowIndex: keyValuePair[key].dynamicRowIndex,
          dynamicRowId: keyValuePair[key].dynamicRowId,
          signatureName: keyValuePair[key].signatureName,
          matrix: keyValuePair[key].matrix,
        }
      )
    }
    else {
      return (
        {
          elementId: featureFlags.mapFromPropertyName ? keyValuePair[key].elementId : key,
          value: keyValuePair[key].value,
          group: keyValuePair[key].group,
          files: keyValuePair[key].files,
          smartTableValues: keyValuePair[key].smartTableValues,
          property: featureFlags.mapFromPropertyName ? key : keyValuePair[key].property,
          getKey: () => featureFlags.mapFromPropertyName ? keyValuePair[key].property : keyValuePair[key].elementId,
          clonedFromElementProperty: keyValuePair[key].clonedFromElementProperty,
          dynamicRowIndex: keyValuePair[key].dynamicRowIndex,
          dynamicRowId: keyValuePair[key].dynamicRowId,
          signatureName: keyValuePair[key].signatureName,
          matrix: keyValuePair[key].matrix,
        }
      )
    }
  })
  return values;
}