import { BaseUrl, destroy, get, post, put } from './api'

/**
 * @typedef {Object} GetParticipatingPartiesFilters
 * @prop {boolean} activeInSteps
 */

/**
 * @param {string} dialogDefinitionId
 * @param {GetParticipatingPartiesFilters} filters
 */
export const getParticipatingParties = async (dialogDefinitionId, filters = null) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/participatingParties`, BaseUrl.Api, {
    axiosParams: {
      params: filters
    }
  });
}

export const createParticipatingParty = async (dialogDefinitionId, props) => {
  return await post(`dialogDefinitions/${dialogDefinitionId}/participatingParties`, props);
}

export const updateParticipatingParty = async (dialogDefinitionId, id, props) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/participatingParties/${id}`, props);
}

export const deleteParticipatingParty = async (dialogDefinitionId, id) => {
  return await destroy(`dialogDefinitions/${dialogDefinitionId}/participatingParties/${id}`);
}
