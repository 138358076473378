import DigitalSigningLanguage from "./digitalSigningLanguage";
import { getElementKey } from "../../../utils/features";
import { TextAreaInput } from "../../../components/Form/TextAreaInput";
import RichTextWrapper from "../../../components/RichText";
import {
  hasSmartObject,
  hasContainer,
  hasDescription,
  hasDigitalSigning,
  hasDisplayLabel,
  hasDynamicListValues,
  hasLeadingText,
  hasMainLabel,
  hasOptionsWithValues,
  hasPlaceholder,
  hasRequiredErrorCustomMessage,
  hasRichText,
  hasRows,
  hasTrailingText,
  hasValidationErrorCustomMessage,
  hasValueLabel,
  isMatrix,
  hasProcess,
} from "../../../utils/definitionLanguages";
import _, { get } from "lodash";
import { elementTypes } from "../../../utils/constants";
import { getSmartObject } from "../../../api/smartObject";
import { useEffect, useState } from "react";

export default function ElementPropertiesForm({ selectedElement, matchingTerm, onTermChange, language, dialogDefinition }) {
  const [smartTableColumns, setSmartTableColumns] = useState([]);

  useEffect(() => {
    if (selectedElement?.smartTable?.smartObjectId) {
      const fetchData = async () => {
        const columns = await getSmartObject(selectedElement.smartTable.smartObjectId);
        setSmartTableColumns(columns?.smartObjectProperties);
      };

      fetchData();
    }
  }, [selectedElement]);

  const renderTextAreaInput = (label, valueKey, arrayName = "languageTerms") => {
    const nestedValue = get(matchingTerm, valueKey, '');
    return (
      <div className="mt-2">
        <TextAreaInput
          value={nestedValue}
          label={label}
          onChange={(value) => onTermChange(valueKey, value, getElementKey(selectedElement), matchingTerm, language.id, arrayName)}
          disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
          oneLine
        />
      </div>)
  };

  const renderOptionWithValues = () => {
    const isOrderTable = selectedElement.type === elementTypes.orderTable;

    return selectedElement.optionWithValues.map((ov, index) => {
      const matchingOption = matchingTerm.options.find((o) => o.optionId === ov.id);
      return (
        <div key={`${ov.id}`} className="mt-6 ml-6">
          <div key={`${ov.id}`} className="mt-6 ml-6">
            <div className="mt-2">
              <TextAreaInput
                label={`Display: ${ov.display}`}
                value={matchingOption.display}
                onChange={(value) => {
                  onTermChange(`options[${index}].display`, value, getElementKey(selectedElement), matchingTerm, language.id);
                  onTermChange(`options[${index}].optionId`, ov.id, getElementKey(selectedElement), matchingTerm, language.id);
                }}
                disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                oneLine
              />
            </div>
            {
              !isOrderTable &&
              <div className="mt-2">
                <TextAreaInput
                  label={`Description: ${ov.description}`}
                  value={matchingOption.description}
                  onChange={(value) => {
                    onTermChange(`options[${index}].description`, value, getElementKey(selectedElement), matchingTerm, language.id);
                    onTermChange(`options[${index}].optionId`, ov.id, getElementKey(selectedElement), matchingTerm, language.id);
                  }}
                  disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  oneLine
                />
              </div>
            }
          </div>
        </div>
      );
    });
  };

  const renderDynamicListValues = () => {
    return (
      <>
        {renderTextAreaInput("Title", "dynamicListValues.title")}
        {
          selectedElement.dynamicListValues.listValues.map((listValue, index) => {
            const matchingOption = matchingTerm?.dynamicListValues?.listValues?.find((o) => o.position === listValue.position);
            return (
              <div className="mt-2">
                <TextAreaInput
                  value={matchingOption?.value}
                  label={`Row ${index + 1}:`}
                  disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  onChange={(value) => onTermChange(`dynamicListValues.listValues[${index}]`, { value, position: listValue.position }, getElementKey(selectedElement), matchingTerm, language.id)}
                  oneLine
                />
              </div>
            );
          }
          )}
      </>
    );
  };

  const renderRichText = () => {
    return (
      <div className="mt-2">
        <label className="block text-sm font-medium text-gray-700">
          Rich text content
        </label>
        <RichTextWrapper
          key={`richText-${selectedElement.id}`}
          data={matchingTerm}
          value={matchingTerm.richTextValues.contentInHtml}
          id={language.id}
          handleOnChangeContent={(value) => onTermChange("richTextValues.contentInHtml", value, getElementKey(selectedElement), matchingTerm, language.id)}
          disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
          componentTitle="Content"
        />
      </div>
    );
  };

  const renderRows = () => {

    const handleInputChange = (value, index) => {
      const updatedMatchingTerm = _.cloneDeep(matchingTerm)
      updatedMatchingTerm.rows[index] = value;
      onTermChange(`orderTableValue.rows[${index}]`, value, getElementKey(selectedElement), updatedMatchingTerm, language.id)
    };

    return (
      <>
        {
          selectedElement?.orderTableValue?.rows.map((row, index) => {
            const matchingOption = matchingTerm?.rows[index];
            return (
              <div key={index} className="mt-2">
                <TextAreaInput
                  value={matchingOption}
                  label="Row"
                  disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  onChange={(value) => handleInputChange(value, index)}
                  oneLine
                />
              </div>
            )
          })
        }
      </>
    )
  }

  const renderSmartTableColumns = () => {

    const handleNameChange = (value, index) => {
      const updatedMatchingTerm = _.cloneDeep(matchingTerm)
      updatedMatchingTerm.smartObjectColumns[index].name = value;
      onTermChange(`smartObjectColumns[${index}].name`, value, getElementKey(selectedElement), updatedMatchingTerm, language.id)
    };

    const handleDropdownChange = (value, columnIndex, dropdownIndex) => {
      const updatedMatchingTerm = _.cloneDeep(matchingTerm)
      updatedMatchingTerm.smartObjectColumns[columnIndex].dropdownOptions[dropdownIndex] = value;
      onTermChange(`smartObjectColumns[${columnIndex}].dropdownOptions[${dropdownIndex}]`, value, getElementKey(selectedElement), updatedMatchingTerm, language.id)
    };

    return (
      <>
        {
          smartTableColumns.map((column, columnIndex) => {
            const matchingOption = matchingTerm?.smartObjectColumns[columnIndex];
            return (
              <div key={column.id} className="mt-2">
                <TextAreaInput
                  value={matchingOption?.name}
                  label={`Column ${columnIndex + 1}:`}
                  onChange={(value) => handleNameChange(value, columnIndex)}
                  disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  oneLine
                />
                {
                  column?.dropdownOptions?.length > 0 &&
                  column.dropdownOptions.map((dropdown, dropdownIndex) => {
                    const matchDropdown = matchingOption.dropdownOptions[dropdownIndex]
                    return (
                      <div key={dropdownIndex} className="ml-6 mt-2">
                        <TextAreaInput
                          value={matchDropdown}
                          label={`Dropdown option ${dropdownIndex + 1}:`}
                          onChange={(value) => handleDropdownChange(value, columnIndex, dropdownIndex)}
                          disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                          oneLine
                        />
                      </div>
                    )
                  })
                }
              </div>
            );
          })
        }
      </>
    )
  }

  const renderProcess = () => {
    const isDisabled = !dialogDefinition.processHasAutomaticTranslationOff || language.default;

    return (
        <>
            <div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.header}
                        label="Header"
                        onChange={(value) => onTermChange('header', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.description}
                        label="Description"
                        onChange={(value) => onTermChange('description', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.step}
                        label="Step"
                        onChange={(value) => onTermChange('step', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.current}
                        label="Current"
                        onChange={(value) => onTermChange('current', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signingDescription}
                        label="Signing description"
                        onChange={(value) => onTermChange('signingDescription', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
                <div className="mt-2">
                    <TextAreaInput
                        value={matchingTerm?.signatory}
                        label="Signatory"
                        onChange={(value) => onTermChange('signatory', value, null, matchingTerm, language.id, 'processTerm')}
                        disabled={isDisabled}
                        oneLine
                    />
                </div>
            </div>
        </>
    )
  }

  const renderMatrix = () => {
    const options = selectedElement.matrix?.options || []
    const questions = selectedElement.matrix?.questions || []

    return (
      <>
        <div className="ml-6">
          {
            options.map((o, optionIndex) => {
              const matchingOption = matchingTerm.matrixOptions?.find(mo => mo.id === o.id)
              return (
                <div key={`option-${o.id}`} className="mt-2">
                  <TextAreaInput
                    label={`Option: ${o.label}`}
                    value={matchingOption?.label || ''}
                    onChange={(value) => {
                      onTermChange(`matrixOptions[${optionIndex}].id`, o.id, getElementKey(selectedElement), matchingTerm, language.id)
                      onTermChange(`matrixOptions[${optionIndex}].label`, value, getElementKey(selectedElement), matchingTerm, language.id)
                    }}
                    disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  />
                </div>
              )
            })
          }
        </div>
        <div className="ml-6">
          {
            questions.map((q, questionIndex) => {
              const matchingQuestion = matchingTerm.matrixQuestions?.find(mq => mq.id === q.id)
              return (
                <div key={`question-${q.id}`} className="mt-2">
                  <TextAreaInput
                    label={`Question: ${q.label}`}
                    value={matchingQuestion?.label || ''}
                    onChange={(value) => {
                      onTermChange(`matrixQuestions[${questionIndex}].id`, q.id, getElementKey(selectedElement), matchingTerm, language.id)
                      onTermChange(`matrixQuestions[${questionIndex}].label`, value, getElementKey(selectedElement), matchingTerm, language.id)
                    }}
                    disabled={!selectedElement?.hasAutomaticTranslationOff || language.default}
                  />
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  return (
    <div className="mb-4 ml-4 w-full">
      {hasDigitalSigning(selectedElement) && (
        <DigitalSigningLanguage
          matchingTerm={matchingTerm}
          onTermChange={onTermChange}
          language={language}
          selectedElement={selectedElement}
          isDisabled={!dialogDefinition?.digitalSigningHasAutomaticTranslationOff || language.default}
        />
      )}
      {hasProcess(selectedElement) && renderProcess()}
      {hasContainer(selectedElement) && renderTextAreaInput("Name", "name", "containerLanguageTerms")}
      {!selectedElement.isContainer && !selectedElement.isDigitalSigning && !selectedElement.isProcess && (
        <>
          {hasMainLabel(selectedElement) && renderTextAreaInput("Label", "term")}
          {hasValueLabel(selectedElement) && renderTextAreaInput("Label", "valueLabel")}
          {renderTextAreaInput("Help", "helpText")}
          {hasDescription(selectedElement) && renderTextAreaInput("Description", "description")}
          {hasDisplayLabel(selectedElement) && renderTextAreaInput("Button", "displayLabel")}
          {hasPlaceholder(selectedElement) && renderTextAreaInput("Placeholder", "placeholder")}
          {hasLeadingText(selectedElement) && renderTextAreaInput("Leading", "leadingText")}
          {hasTrailingText(selectedElement) && renderTextAreaInput("Trailing", "trailingText")}
          {hasRequiredErrorCustomMessage(selectedElement) && renderTextAreaInput("Custom required error message", "customErrorMessage.byRequired")}
          {hasValidationErrorCustomMessage(selectedElement) && renderTextAreaInput("Custom validation error message", "customErrorMessage.byValidation")}
          {hasOptionsWithValues(selectedElement) && renderOptionWithValues()}
          {hasDynamicListValues(selectedElement) && renderDynamicListValues()}
          {hasRichText(selectedElement) && renderRichText()}
          {hasRows(selectedElement) && renderRows()}
          {hasSmartObject(selectedElement) && renderSmartTableColumns()}
          {isMatrix(selectedElement) && renderMatrix()}
        </>
      )
      }
    </div>
  );
};