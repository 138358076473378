import { useState } from "react"

export const useModalHelper = () => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    close: () => {
      setIsOpen(false)
    },
    open: () => {
      setIsOpen(true)
    },
    isOpen,
  }
}