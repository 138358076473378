import { useEffect, useState } from "react";
import { getDialogValuesWebhookPayload } from "../../../api/dialogValues";
import { triggerWebhook } from "../../../api/webhook";
import { Button } from "../../../components/Form/Button";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prettifyXml } from "../../../utils/string";

export const WebhookPayload = ({ type = "json", dialogValuesId, onClose, webhook = null }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [webhookPayloadSample, setWebhookPayloadSample] = useState(null);
  const [webhookResponse, setWebhookResponse] = useState(null);

  useEffect(() => {
    if (dialogValuesId) {
      loadWebhookPayload()
    }
  }, [dialogValuesId]);

  const loadWebhookPayload = async () => {
    setIsLoading(true);
    const result = await getDialogValuesWebhookPayload(dialogValuesId, type);

    if (type === "interact")
      setWebhookPayloadSample(result)
    else
      setWebhookPayloadSample(JSON.stringify(result, null, '\t'));

    setIsLoading(false);
  }

  const trigger = async () => {
    setIsLoading(true);
    const result = await triggerWebhook(webhook.id, dialogValuesId);
    if (result.content) {
      try {
        result.content = JSON.parse(result.content);
      }
      catch (e) {
        // swallow error. content was not JSON
      }      
    }
    setWebhookResponse(result);
    setIsLoading(false);
  }

  const handleClose = () => {
    setIsLoading(false);
    setWebhookPayloadSample(null);
    setWebhookResponse(null);
    onClose();
  }

  return (
    <Modal
      isOpen={dialogValuesId ? true : false}
      onClose={handleClose}
      size="large"
    >
      {
        isLoading && <Loader />
      }
      {
        !isLoading &&
        <>
          {
            webhookResponse &&
            <div>
              <label className="block text-sm font-medium text-gray-700">Response</label>
              <textarea
                rows="15"
                style={{ width: "100%" }}
                value={JSON.stringify(webhookResponse, null, '\t')}
                readOnly={true}
              />
              <Button
                text="Clear"
                onClick={() => setWebhookResponse(null)}
              />
            </div>
          }
          {
            !webhookResponse &&
            <>
              <div>
                {
                  webhookPayloadSample &&
                  <div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Header</label>
                      <p>Authorization: {webhook.authorizationSchemaOverride || 'Bearer'} {webhook.apiKey}</p>
                    </div>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">Sample payload</label>
                      <textarea
                        rows="15"
                        style={{ width: "100%" }}
                        value={webhookPayloadSample}
                        readOnly={true}
                      />
                    </div>
                  </div>
                }
              </div>
              {
                webhook?.id &&
                <div className="mt-3 flex items-center">
                  <Button
                    text="Trigger webhook"
                    onClick={trigger}
                  />
                  <FontAwesomeIcon className="ml-2" icon={faCircleInfo} size="lg" title="Sends the payload to most recently created webhook" />
                </div>
              }
            </>
          }
        </>
      }
      <div className="mt-10">
        <button
          type="button"
          className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => handleClose()}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}