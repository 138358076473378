import { faPaperclipVertical } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikFileInput } from "../../../../components/Form/Formik/FormikFileInput";
import { FormikError } from "../../../../components/Form/Formik/FormikError";

export function FieldAuth({ gridColsNumber = 3, error, label, children, containerClassName = '' }) {
  return (
    <div
      className={`sm:grid sm:grid-cols-${gridColsNumber} sm:gap-4 sm:items-start`}
    >
      {label && (
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {label}
        </label>
      )}
      <div className={`mt-1 sm:mt-0 sm:col-span-2 ${containerClassName}`}>
        {children}
        {error && <div className="text-red-500">{error}</div>}
      </div>
    </div>
  );
}

export function FileInput({ name, formikProps }) {
  return (
    <>
      <div className="flex">
        <FontAwesomeIcon icon={faPaperclipVertical} size="lg" />
        <label className="block mx-8 w-64 overflow-ellipsis overflow-hidden whitespace-nowrap">{formikProps.values[name]?.name || formikProps.values[name]?.fileName || '<None>'}</label>
        <label
          htmlFor={name}
          className="block w-8 h-7 text-center rounded-lg border border-gray-300 cursor-pointer"
        >
          ...
        </label>
        <FormikFileInput
          name={name}
          className="hidden"
          accept={"image/*"}
          formikProps={formikProps}
        />
      </div>
      <FormikError name={name} formikProps={formikProps} requireTouched={false} />
    </>
  );
}