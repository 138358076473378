import React from 'react'
import { useClassNames } from '../../../hooks/useClassNames'

export default function ImportStatusBar({ status }) {
    const { classNames } = useClassNames();

    const getBgColor = () => {
        switch (status.status) {
            case "error": return "bg-red-400 text-white";
            case "success": return "bg-green-200";
            case "info" : 
            default: return "bg-blue-200";
        }
    }

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                Status
            </label>
            <div className={classNames("mt-2 pl-5 py-2 rounded-lg", getBgColor())}>
                <div className="bg-transparent center">{status.statusText}</div>
            </div>
        </div>
    )
}
