import React from 'react'
import { useClassNames } from '../../../../../hooks/useClassNames';

export const Button = ({ element, onChange, className = "" }) => {
    const { classNames } = useClassNames();

    const handlePropertyChange = (target) => {
        // console.log("headerline", {value:target.value,name:target.name});
        onChange(target, element, "");
    }

    return (
        <div className={classNames("", className)}>
            {/* <div>HeaderLine</div> */}
            <div className='mb-1'><label className='font-semibold'>Element:</label> {element.property}</div>
            <label className='mb-1 font-semibold'>Button Text:</label>
            <div>
                <input name={"value"} type="text" className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md' value={element.value} onChange={e => handlePropertyChange(e.target)} />
            </div>
        </div>

    )
}

export default Button;