import { array } from "yup";

/**
 * Lookup an item in the array, and replace it with another item
 * @param {[]} array 
 * @param {([]) => boolean} predicate 
 * @param {any} newItem 
 */
export const replaceItem = (array, predicate, newItem) => {
  const index = array.findIndex(predicate);
  if (index > -1) {
    array.splice(index, 1, newItem)
  }
}

export const moveItem = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

/**
 * Filter an array of objects by search query, against specified properties to search
 * @param {any[]} array 
 * @param {string} query 
 * @param {((any)=>string)[]} propertiesExpressions 
 */
export const searchArray = (array, query, propertiesExpressions) => {
  if (!query) {
    return array;
  }

  return array?.filter(a => {
    for (const expression of propertiesExpressions) {
      if (expression(a)?.includes(query)) {
        return true;
      }
    }
    return false;
  })
}
export const distinctByKey = (array, key) => {
  const distinctArray = [];
  for (const item of array) {
    if (!distinctArray.some(a => a[key] === item[key])) {
      distinctArray.push(item);
    }
  }
  return distinctArray;
}

array.prototype.distinctByKey = function (key) {
  return distinctByKey(this, key);
}

export const objectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => value)
}