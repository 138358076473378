import { useEffect, useState } from "react"
import { useToastAction } from "../../../../hooks/useToastAction"
import { createContactList, deleteContactList, getAllContactLists, patchContactList } from "../../../../api/contactLists";
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/Table";
import { useTableSort } from "../../../../hooks/useTableSort";
import { Badge } from "../../../../components/Badge";
import { contactListStatuses } from "../../../../utils/constants";
import { stringToLocaleDateTimeString } from "../../../../utils/date";
import { useModalHelper } from "@metaforcelabs/metaforce-core";
import { CreateContactList } from "./createContactList";
import MenuContextList from "../../../../components/MenuContextList";
import { NavLink, useNavigate } from "react-router-dom";
import { PencilAltIcon, TrashIcon, BanIcon, CheckIcon } from '@heroicons/react/solid';
import { replaceItem } from "../../../../utils/array";
import { SearchIcon } from '@heroicons/react/outline'

export const ContactList = () => {
  const navigate = useNavigate();
  const [contactLists, setContactLists] = useState([]);
  const [filteredContactLists, setFilteredContactLists] = useState([]);
  const [searchBy, setSearchBy] = useState(null)
  const tableSort = useTableSort(filteredContactLists, "updatedDate", "desc")
  const loadAction = useToastAction();
  const createAction = useToastAction(true);
  const deleteAction = useToastAction(true);
  const patchAction = useToastAction(true);
  const createRecordModalHelper = useModalHelper()

  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    if (!contactLists) {
      return
    }

    let tableRecords = [...contactLists]
    if (searchBy && searchBy !== '') {
      tableRecords = [...contactLists.filter(cl => cl.name.toLowerCase().includes(searchBy.toLowerCase()))]
    }

    setFilteredContactLists(tableRecords)
  }, [searchBy, contactLists])

  const load = () => {
    loadAction.execute(async () => {
      const results = await getAllContactLists()
      setContactLists(results)
    })
  }

  const handleSave = (values) => {
    createAction.execute(async () => {
      const result = await createContactList(values)
      navigate(result.id)
      createRecordModalHelper.close()
    }, "Failed to create contact list", "Created contact list")
  }

  const handleDelete = (id) => {
    deleteAction.execute(async () => {
      await deleteContactList(id);
      setContactLists([...contactLists.filter(c => c.id !== id)])
    }, "Failed to delete contact list", "Deleted contact list");
  }

  const handleDeactivate = (id) => {
    patchAction.execute(async () => {
      const result = await patchContactList(id, {
        status: contactListStatuses.disabled
      })
      replaceItem(contactLists, c => c.id === id, result)
      setContactLists([...contactLists])
    }, "Failed to deactivate contact list", "Deactivated contact list")
  }

  const handleActivate = (id) => {
    patchAction.execute(async () => {
      const result = await patchContactList(id, {
        status: contactListStatuses.active
      })
      replaceItem(contactLists, c => c.id === id, result)
      setContactLists([...contactLists])
    }, "Failed to activate contact list", "Activated contact list")
  }

  return (
    <>
      <div>
        <div className="mt-6 mb-7 pb-5 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold">Contact lists</h1>
            <p>
              These lists are used with smartforms to limit people that can be request a security number for smartform Access. <br />
              The contact lists are added under Process for a Smartform.
            </p>
          </div>
          <div className='flex justify-end mt-5 pb-5'>
            <div className="w-72">
              <label htmlFor="desktop-search-candidate" className="sr-only">
                Search
              </label>
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="desktop-search-candidate"
                    id="desktop-search-candidate"
                    className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                    placeholder="Search..."
                    value={searchBy}
                    onChange={(evt) => setSearchBy(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                onClick={createRecordModalHelper.open}
              >
                New list
              </button>
            </div>
          </div>
        </div>
      </div >
      <Table>
        <TableHead>
          <TableHeader tableSort={tableSort} sortBy={"name"}>
            Name
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"status"}>
            Status
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"createdDate"}>
            Created
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"updatedDate"}>
            Updated
          </TableHeader>
          <TableHeader />
        </TableHead>
        <TableBody>
          {
            tableSort?.tableData?.map(element => {
              const isActive = element.status === contactListStatuses.active
              return (
                <TableRow id={element.id} key={`contact-list-${element.id}`}>
                  <TableColumn>
                    <NavLink
                      to={element.id}
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {element.name}
                    </NavLink>
                  </TableColumn>
                  <TableColumn>
                    <Badge
                      type={isActive ? "success" : "error"}
                      text={isActive ? "Active" : "Inactive"}
                    />
                  </TableColumn>
                  <TableColumn>
                    {stringToLocaleDateTimeString(element.createdDate)}
                  </TableColumn>
                  <TableColumn>
                    {stringToLocaleDateTimeString(element.updatedDate)}
                  </TableColumn>
                  <td className="pt-6 pb-10 whitespace-nowrap text-right text-sm font-medium absolute">
                    <MenuContextList
                      element={element}
                      deleteType={"Contact list"}
                      actions={[
                        isActive
                          ? {
                            name: "Deactivate",
                            disabled: patchAction.isExecuting,
                            onClick: () => handleDeactivate(element.id),
                            icon: BanIcon,
                            lineAfter: true,
                          }
                          : {
                            name: "Activate",
                            disabled: patchAction.isExecuting,
                            onClick: () => handleActivate(element.id),
                            icon: CheckIcon,
                            lineAfter: true,
                          },
                        {
                          name: "Edit",
                          disabled: false,
                          href: element.id,
                          icon: PencilAltIcon,
                        },
                        {
                          name: "Delete",
                          onClick: () => handleDelete(element.id),
                          disabled: deleteAction.isExecuting,
                          icon: TrashIcon,
                        }
                      ]}
                    />
                  </td>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <CreateContactList
        modalHelper={createRecordModalHelper}
        onSave={handleSave}
        isSaving={createAction.isExecuting}
      />
    </>
  )
}