import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { FormikTextInput } from '../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../components/Form/Formik/FormikSubmit';
import { XIcon } from '@heroicons/react/outline';
import { updateDialogValuesPowerOfAttorney } from '../../api/public/dialogValues';
import { useToastAction } from '../../hooks/useToastAction';
import { SignatureForPowerOfAttorney } from '../../components/Signature/signatureForPowerOfAttorney';

export default function PowerOfAttorney({ open, setOpen, valuesKey, data, stepId }) {
  let anyRef = useRef(null)
  const submitAction = useToastAction();
  const hasData = !!data
  const [signature, setSignature] = useState(data?.signature || null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0" onClose={setOpen} initialFocus={anyRef}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div ref={anyRef} className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:align-middle w-full min-h-screen h-full">
                <div className="fixed h-full w-0 lg:w-72 left-0 top-0 p-0 lg:p-2 pt-10 lg:pt-10 bg-gray-300 bg-opacity-50 z-10 overflow-hidden">
                  <h3 className="sm:pl-5 text-2xl leading-6 font-medium text-gray-900 mb-5">Power of Attorney</h3>
                  <p className="pl-5 text-sm font-medium text-gray-500 w-full">
                    All fields has to be filled out to give somebody else the attorney
                    to fill out and sign your form for you. You will still get the receipt
                    when the form is done.
                  </p>
                </div>
                <section className='fixed overflow-auto h-full top-0 left-0 lg:left-60 bg-white'>
                  <div className="absolute top-0 right-0">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="inline-flex m-3 text-gray-400"
                    >
                      <span className="sr-only">Dismiss</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: data?.firstName || '',
                      lastName: data?.lastName || '',
                      email: data?.email || '',
                      phone: data?.phone || '',
                      subject: data?.subject || '',
                      message: data?.message || '',
                    }}
                    enableReinitialize={true}
                    validationSchema={() => {
                      return yup.object().shape({
                        firstName: yup.string().required("Required"),
                        lastName: yup.string().required("Required"),
                        email: yup.string().email().required("Required"),
                        phone: yup.string().nullable().notRequired(),
                        subject: yup.string().required("Required"),
                        message: yup.string().max(500).required("Required"),
                      });
                    }}

                    onSubmit={async (values, actions) => {
                      submitAction.execute(async () => {
                        if (signature === null) return;

                        values.signature = signature
                        try {
                          await updateDialogValuesPowerOfAttorney(valuesKey, values, stepId);
                        }
                        finally {
                          actions.setSubmitting(false)
                          setOpen(false);
                          window.location.reload();
                        }
                      }, "Failed to save", "Power of Attorney information has been submited!");
                    }}
                  >
                    {
                      props => (
                        <Form className='grid grid-cols-4 mt-10 ml-20 gap-4 mx-10'>

                          <h3 className="col-span-4 text-4xl leading-6 font-medium text-gray-900 mb-5">Power of Attorney</h3>

                          <div className="col-span-4 sm:col-span-2">
                            <FormikTextInput
                              label="First name"
                              name="firstName"
                              required={true}
                              formikProps={props}
                              readOnly={hasData}
                            />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <FormikTextInput
                              label="Last name"
                              name="lastName"
                              required={true}
                              readOnly={hasData}
                              formikProps={props}
                            />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <FormikTextInput
                              label="Email"
                              name="email"
                              required={true}
                              readOnly={hasData}
                              formikProps={props}
                            />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <FormikTextInput
                              label="Phone"
                              name="phone"
                              required={false}
                              readOnly={hasData}
                              formikProps={props}
                            />
                          </div>

                          <div className="col-span-4">
                            <FormikTextInput
                              label="Subject"
                              name="subject"
                              required={true}
                              readOnly={hasData}
                              formikProps={props}
                            />
                          </div>

                          <div className="col-span-4">
                            <FormikTextInput
                              label="Message"
                              name="message"
                              required={true}
                              formikProps={props}
                              rows={6}
                              readOnly={hasData}
                              placeholder="Max. 500 characters"
                            />
                          </div>

                          <div className="col-span-4">
                            <SignatureForPowerOfAttorney
                              id={"powerOfAttorney"}
                              label={'Signature'}
                              value={data?.signature}
                              readOnly={hasData}
                              required={true}
                              onUpdate={setSignature}
                              heightStyle="154px"
                            />
                          </div>

                          <div className={`sm:col-start-4 sm:col-span-1 col-span-3 flex justify-end mr-10 sm:mb-0 mb-5 ${hasData ? 'hidden' : ''}`}>
                            <FormikSubmitButton
                              className="p-10 bg-green-400"
                              formikProps={props}
                              text="Submit"
                              disabled={hasData || submitAction.isExecuting || signature === null}
                            />
                          </div>

                        </Form>
                      )
                    }
                  </Formik>
                </section>

              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
