import { post, get, destroy } from './api'

export const createJavasciptIntegration = async (dialogDefinitionId, name) => {
  return await post('javascriptIntegrations', { dialogDefinitionId, name });
}

export const getJavascriptIntegrations = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/javascriptIntegrations`);
}

export const deleteJavascriptIntegration = async (id) => {
  return await destroy(`javascriptIntegrations/${id}`)
}
