import { useEffect, useState } from "react";
import {
    getAllDialogTemplates,
    deleteDialogTemplate,
    activateDialogTemplate,
    deactivateDialogTemplate,
    importDialogTemplate,
} from "../../../../api/dialogTemplates";
import {
    Table,
    TableActions,
    TableBody,
    TableColumn,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/Table";
import { useToastAction } from "../../../../hooks/useToastAction";
import { stringToLocaleDateTimeString } from "../../../../utils/date";
import { useTableSort } from "../../../../hooks/useTableSort";
import { isDialogObjectActive } from "../../../../utils/dialogObjects";
import { Badge } from "../../../../components/Badge";
import MenuContextList from "../../../../components/MenuContextList/index";
import { NavLink } from "react-router-dom";
import { SmartformTemplateForm } from "./smartformTemplateForm";
import { replaceItem } from "../../../../utils/array";
import { ImportFileModal } from "../../../../components/ImportFileModal";
import { useDialogDefinitionSchemaValidation } from "../../../../hooks/useDialogDefinitionSchemaValidation";

export const SmartformTemplateList = () => {
    const [dialogTemplates, setDialogTemplates] = useState([]);
    const [showSmartformTemplateForm, setShowSmartformTemplateForm] =
        useState(false);
    const [smartformToRename, setSmartformToRename] = useState(false);
    const [showSmartformImport, setShowSmartformImport] = useState(false);
    const tableSort = useTableSort(dialogTemplates);
    const loadDialogTemplatesAction = useToastAction();
    const deleteDialogtemplateAction = useToastAction();
    const activateObjectAction = useToastAction();
    const deactivateObjectAction = useToastAction();
    const schemaValidator = useDialogDefinitionSchemaValidation(true);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        loadDialogTemplatesAction.execute(async () => {
            const records = await getAllDialogTemplates();
            setDialogTemplates(records);
        }, "Failed to load objects");
    };

    const onDelete = async (id) => {
        deleteDialogtemplateAction.execute(
            async () => {
                await deleteDialogTemplate(id);
                await load();
            },
            "Failed to delete dialog object",
            "Deleted record"
        );
    };

    const onRename = (customObject) => {
        setSmartformToRename(customObject);
        setShowSmartformTemplateForm(true);
    };

    const locationHRef = (templateId) =>
        `/admin/templates/smartforms/${templateId}`;

    const onActionSuccess = (customObject) => {
        load();
        showSmartformTemplateForm === "new" &&
            (window.location.href = locationHRef(customObject.id));
    };

    const onCloseFormModal = () => {
        setShowSmartformTemplateForm(false);
        setTimeout(() => setSmartformToRename(""), 200);
    };

    const onActivate = (id) => {
        activateObjectAction.execute(
            async () => {
                const result = await activateDialogTemplate(id);
                replaceItem(dialogTemplates, (d) => d.id === result.id, result);
                setDialogTemplates(dialogTemplates);
            },
            "Failed to activate",
            "Activated"
        );
    };

    const onDeactivate = (id) => {
        activateObjectAction.execute(
            async () => {
                const result = await deactivateDialogTemplate(id);
                replaceItem(dialogTemplates, (d) => d.id === result.id, result);
                setDialogTemplates(dialogTemplates);
            },
            "Failed to deactivate",
            "Deactivated"
        );
    };

    return (
        <>
            <div className="mt-6 mb-7 border-b border-gray-200 flex justify-between items-center">
                <h1 className="text-3xl font-bold">SmartForm templates</h1>
                <div className='flex justify-end mt-5 pb-5'>
                    <TableActions
                        buttons={[
                            {
                                text: "Import SmartForm",
                                onClick: () => setShowSmartformImport(true),
                            },
                        ]}
                    />
                    <div className="mt-5 pb-5">
                        <button
                            type="button"
                            className="mt-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                            onClick={() => setShowSmartformTemplateForm(true)}
                        >
                            New Template
                        </button>
                    </div>
                </div>
            </div>
            <Table>
                <TableHead>
                    <TableHeader tableSort={tableSort} sortBy="name">
                        Name
                    </TableHeader>
                    <TableHeader tableSort={tableSort} sortBy="status">
                        Status
                    </TableHeader>
                    <TableHeader tableSort={tableSort} sortBy="createdDate">
                        Created
                    </TableHeader>
                    <TableHeader tableSort={tableSort} sortBy="updatedDate">
                        Updated
                    </TableHeader>
                    <TableHeader />
                </TableHead>
                <TableBody>
                    {tableSort?.tableData?.map((element) => {
                        return (
                            <TableRow id={element.id} key={element.id}>
                                <td
                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                                    style={{ minWidth: "150px" }}
                                >
                                    <span className="overflow-hidden left-0 right-0 whitespace-nowrap overflow-ellipsis">
                                        <NavLink
                                            to={locationHRef(element.id)}
                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                            {element.name}
                                        </NavLink>
                                    </span>
                                </td>
                                <TableColumn>
                                    <Badge type={isDialogObjectActive(element) ? "success" : "error"} text={isDialogObjectActive(element) ? "Active" : "Inactive"} />
                                </TableColumn>
                                <TableColumn>
                                    {stringToLocaleDateTimeString(
                                        element.createdDate
                                    )}
                                </TableColumn>
                                <TableColumn>
                                    {stringToLocaleDateTimeString(
                                        element.updatedDate
                                    )}
                                </TableColumn>
                                <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                    <MenuContextList
                                        element={element}
                                        deleteType={"object template"}
                                        actions={[
                                            {
                                                name: "Edit",
                                                disabled: false,
                                                href: locationHRef(element.id),
                                            },
                                            {
                                                name: "Rename",
                                                disabled:
                                                    deleteDialogtemplateAction.isExecuting,
                                                onClick: () =>
                                                    onRename(element),
                                            },
                                            isDialogObjectActive(element)
                                                ? {
                                                    name: "Deactivate",
                                                    disabled:
                                                        deactivateObjectAction.isExecuting,
                                                    onClick: () =>
                                                        onDeactivate(
                                                            element.id
                                                        ),
                                                }
                                                : {
                                                    name: "Activate",
                                                    disabled:
                                                        activateObjectAction.isExecuting,
                                                    onClick: () =>
                                                        onActivate(
                                                            element.id
                                                        ),
                                                },
                                            {
                                                name: "Delete",
                                                disabled:
                                                    deleteDialogtemplateAction.isExecuting,
                                                onClick: () =>
                                                    onDelete(element.id),
                                            },
                                        ]}
                                    />
                                </td>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <SmartformTemplateForm
                isOpen={showSmartformTemplateForm}
                onClose={() => onCloseFormModal()}
                onSuccess={onActionSuccess}
                smartformToRename={smartformToRename}
            />
            <ImportFileModal
                importAction={importDialogTemplate}
                title={"Smartform"}
                isOpen={showSmartformImport}
                onClose={() => setShowSmartformImport(false)}
                onImportSuccess={load}
                schemaValidator={schemaValidator}
                formatAccepted=".json"
            />
        </>
    );
};
