import { useState, useEffect } from 'react';
import { setProperty } from '../../utils/property';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { alignmentOptions } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Switch"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />
        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Toggle
          onChange={handleOnChange}
          label={'Default state'}
          name="defaultCheckedState"
          onText="On"
          offText="Off"
          enabled={data?.defaultCheckedState}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />

        {/* <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data?.backgroundColor}
        /> */}
      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={alignmentOptions}
        />

        <Property.FontStyleAndWeight
          onChange={handleOnChange}
          name="text"
          property={data?.text}
        />

      </Property.Group>
    </div>
  )
}
