import { useEffect, useState } from "react";
import { createDefinitionLanguage, deleteDefinitionLanguage, setDefinitionLanguageAsDefault, deactivateDefinitionLanguageAsDefault } from "../../../api/definitionLanguage";
import { Button } from "../../../components/Form/Button";
import MenuContextList from "../../../components/MenuContextList";
import Modal from "../../../components/Modal";
import { useToastAction } from "../../../hooks/useToastAction";
import Select from 'react-select';
import { countryList } from '../../../utils/countryList';
import { Badge } from "@metaforcelabs/metaforce-core";

export default function Languages({
  onDefinitionLanguagesCreated,
  dialogKey,
  definitionLanguages,
  setDefinitionLanguages,
  dialogDefinition,
  onAutomaticTranslationChanged,
  automaticTranslationInProgress,
}) {
  const createDefinitionLanguageAction = useToastAction()
  const deleteDefinitionLanguageAction = useToastAction()
  const setDefinitionLanguageAsDefaultAction = useToastAction()
  const deactivateDefinitionLanguageAsDefaultAction = useToastAction()
  const [newLanguage, setNewLanguage] = useState('')
  const [showNewLanguageModal, setShowNewLanguageModal] = useState(false)
  const [allLanguages, setAllLanguages] = useState([])
  const hasDefaultLanguage = definitionLanguages?.find(el => el.default === true);

  useEffect(() => filterLanguages(definitionLanguages), [definitionLanguages])

  const filterLanguages = (array) => {
    const myArrayFiltered = countryList.filter((el) => {
      return !array.find((f) => {
        return f.code === el.code;
      });
    });

    let languageOptions = [];

    myArrayFiltered.forEach(element => {
      languageOptions.push({ value: element.code, label: `${element.name} (${element.nativeName})` })
    });

    setAllLanguages(languageOptions);
  }

  const onAddLanguageClick = async (evt) => {
    evt.preventDefault();
    createDefinitionLanguageAction.execute(async () => {
      const newDefinitionLanguage = await createDefinitionLanguage(dialogKey, newLanguage);
      setNewLanguage({});
      filterLanguages([...definitionLanguages, newDefinitionLanguage]);
      onDefinitionLanguagesCreated(newDefinitionLanguage);
    }, "Failed to add new supported language", "New language added")
  }

  const onDeleteDefinitionLanguageClick = async (selectedDefinitionLanguageId) => {
    deleteDefinitionLanguageAction.execute(async () => {
      await deleteDefinitionLanguage(selectedDefinitionLanguageId, dialogKey);

      const remainingDefinitionLanguages = definitionLanguages.filter(d => d.id !== selectedDefinitionLanguageId)

      setDefinitionLanguages([...remainingDefinitionLanguages])
      filterLanguages(remainingDefinitionLanguages);
    }, "Failed to delete language", "Language deleted")
  }

  const onSetLanguageDefinitionAsDefault = async (selectedDefinitionLanguageId) => {
    setDefinitionLanguageAsDefaultAction.execute(async () => {
      await setDefinitionLanguageAsDefault(selectedDefinitionLanguageId, dialogKey);

      const activeLanguage = definitionLanguages.find(el => el.default === true && el.id !== selectedDefinitionLanguageId);
      if (activeLanguage) {
        await deactivateDefinitionLanguageAsDefault(activeLanguage.id, dialogKey);
      }

      const remainingDefinitionLanguages = definitionLanguages.map((el) => {
        if (el.id === selectedDefinitionLanguageId) {
          return { ...el, default: true };
        } else if (activeLanguage && el.id === activeLanguage.id) {
          return { ...el, default: false };
        }

        return el;
      });

      setDefinitionLanguages(remainingDefinitionLanguages);
    }, "Failed to Activate the language as default", "Language is default")
  }

  const onDeactivateDefinitionLanguageAsDefault = async (selectedDefinitionLanguageId) => {
    deactivateDefinitionLanguageAsDefaultAction.execute(async () => {
      await deactivateDefinitionLanguageAsDefault(selectedDefinitionLanguageId, dialogKey);

      let remainingDefinitionLanguages = definitionLanguages.map((el) => {
        if (el.id === selectedDefinitionLanguageId) {
          return { ...el, default: false };
        }

        return el;
      })

      setDefinitionLanguages(remainingDefinitionLanguages);
    }, "Failed to remove the language as default", "Language was removed as default")
  }

  return (
    <>
      <section aria-labelledby="languages-heading" >
        <div id="languages" className="bg-white pt-6 shadow sm:rounded-md">
          <div className="flex justify-between items-center px-4 sm:px-6">
            <div>
              <h2
                id="languages-heading"
                className="text-lg leading-6 pb-1 font-medium text-gray-900"
              >
                Languages
              </h2>
              <div>
                <input
                  type="checkbox"
                  checked={dialogDefinition?.hasAutomaticTranslation ?? false}
                  name="hasAutomaticTranslation"
                  onChange={onAutomaticTranslationChanged}
                  disabled={!hasDefaultLanguage || automaticTranslationInProgress}
                />
                <label className="ml-2 text-sm leading-6 font-medium text-gray-900">Automatic translation</label>
              </div>
            </div>
            <div>
              <Button text="New"
                disabled={automaticTranslationInProgress}
                onClick={(event) => {
                  event.preventDefault();
                  setShowNewLanguageModal(true);
                }}
              />
            </div>
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-t border-gray-200">
                  <table className="min-w-full mb-1 divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Language Code
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {definitionLanguages?.map(
                        (element, index) => (
                          <tr key={index}>
                            <td className={`flex gap-2 px-6 py-4 whitespace-nowrap text-sm font-medium ${element.default ? 'text-gray-900' : 'text-gray-900'} relative`}>
                              {element.language}
                              {
                                element?.default &&
                                <Badge text="Default" />
                              }
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${element.default ? 'text-gray-900' : 'text-gray-900'} `}>
                              {element.code}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {
                                <MenuContextList
                                  element={
                                    element
                                  }
                                  deleteType={
                                    "language"
                                  }
                                  actions={[
                                    {
                                      name: "Set as default",
                                      disabled: automaticTranslationInProgress,
                                      hidden: element.default,
                                      onClick:
                                        async (
                                          event
                                        ) => {
                                          await onSetLanguageDefinitionAsDefault(
                                            element.id
                                          );
                                        },
                                    },
                                    {
                                      name: "Delete",
                                      disabled: automaticTranslationInProgress,
                                      onClick:
                                        async (
                                          event
                                        ) => {
                                          await onDeleteDefinitionLanguageClick(
                                            element.id
                                          );
                                        },
                                    },
                                  ]}
                                />
                              }
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={showNewLanguageModal}
        onClose={() => {
          setShowNewLanguageModal(false);
        }}
        size="medium"
        showOverflow
      >
        <div className="mt-4">
          <div>
            <div
              className="flex flex-col"
            >
              <div>
                <Select
                  name="language"
                  options={allLanguages}
                  onChange={(event) => {
                    setNewLanguage({ language: event.label, code: event.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button type="button"
            className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
            onClick={(event) => {
              event.preventDefault()
              onAddLanguageClick(event);
              setShowNewLanguageModal(false);
            }}
          >
            Add
          </button>
          <button
            type="button"
            className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={(event) => {
              event.preventDefault();
              setShowNewLanguageModal(false)
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  )
}