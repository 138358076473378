import { useState, useRef, useEffect } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';


export default function Properties({ initData, actions, process }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const [signaturePopUpOpen, setSignaturePopUpOpen] = useState(false)
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges, 'value')
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)
  const readOnlyCheckbox = useRef();
  const requiredFieldCheckbox = useRef();

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Signature"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'value'}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Name'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.RequiredWithCustomMessage
          onChange={handleOnChange}
          data={data}
          ref={requiredFieldCheckbox}
          dependency={readOnlyCheckbox}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          ref={readOnlyCheckbox}
          dependency={requiredFieldCheckbox}
          value={data?.readOnly}
        />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'labelStyle.color'}
          initColor={data?.labelStyle?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data?.labelStyle?.size}
        />

      </Property.Group>

      <Property.Group title="Element Style">
        {/* <Property.Color
          onChange={handleOnChange}
          label={'Background color'}
          name={'backgroundColor'}
          initColor={data?.backgroundColor}
        /> */}

        <Property.Text
          onChange={handleOnChange}
          label={'Height'}
          name={'height'}
          type={'number'}
          value={data?.height}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

        {/* <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        /> */}

      </Property.Group>

    </div>
  )
}
