import { defaultPlaceholderRgx, getPlaceholdersInText } from "@metaforcelabs/metaforce-core";
import { templateLanguages } from "../../../utils/constants";

export const validateRequiredTextField = (textField) => {
    return textField ? null : "Required";
}
export const validateRequiredTextFieldWithPlaceholders = (textField, placeholders = []) => {
    let error = validateRequiredTextField(textField);

    if (error) return error;
    const requiredPlaceholders = placeholders.filter(x => x.required);
    const placeholdersInSubject = getPlaceholdersInText(textField, defaultPlaceholderRgx);
    const missingRequired = requiredPlaceholders.filter(p => !placeholdersInSubject.includes(p.placeholder))
    error = missingRequired.length === 0 ? null : "Missing Required Placeholder";
    return error;
}

export const validateSubjects = (subjects) => {
    const invalidSubjectLanguages = subjects.filter(x => {
        return validateRequiredTextField(x.subject);
    })
    return invalidSubjectLanguages.some(x => x) ? `Invalid or missing subject for language(s): ${invalidSubjectLanguages.map(l => templateLanguages.find(x => x.value === l.language).name).join(", ")}` : null;
}
export const validateBody = (body) => {

    return body ? null : "Required";
}
export const validateBodies = (bodies, placeholders) => {
    const invalidBodyLanguages = bodies.filter(x => {
        return validateRequiredTextFieldWithPlaceholders(x.body, placeholders);
    })
    return invalidBodyLanguages.some(x => x) ? `Invalid or message message for language(s): ${invalidBodyLanguages.map(l => templateLanguages.find(x => x.value === l.language).name).join(", ")}` : null;
}

export const validateMessages = (messages, placeholders) => {
    const invalidBodyLanguages = messages.filter(x => {
        return validateRequiredTextFieldWithPlaceholders(x.message, placeholders);
    })
    return invalidBodyLanguages.some(x => x) ? `Invalid or missing message for language(s): ${invalidBodyLanguages.map(l => templateLanguages.find(x => x.value === l.language).name).join(", ")}` : null;
}