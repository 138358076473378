import { ChevronRightIcon } from "@heroicons/react/solid";
import {
    ChatIcon,
    SpeakerphoneIcon,
    TerminalIcon,
} from "@heroicons/react/outline";
import { Link } from 'react-router-dom';
import { config } from "../../../utils/config";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function WelcomePage({showDialog}) {

    const items = [
        {
            name: "Create form",
            description: "Start creating a Smartform",
            iconColor: "bg-pink-500",
            icon: SpeakerphoneIcon,
            onClick: () => showDialog(true),
        },
        {
            name: "Watch intructions video",
            description: "Learn how Smartforms works by watching some how-to videos",
            href: "/admin/videos",
            iconColor: "bg-purple-500",
            icon: TerminalIcon,
        },
        {
            name: "Get help",
            description: "Contact us",
            mailTo: "mailto:support@metaforce.se",
            iconColor: "bg-yellow-500",
            icon: ChatIcon,
        },
    ];

    return (
        <>
            <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 justify-items-center items-center gap-20 w-full h-full">
                <section className="w-full h-full">
                    <h1 className="text-3xl font-bold">
                        Welcome to SmartForms
                    </h1>
                    <h3 className="mt-4 mb-4 text-gray-600">
                        A Smartform is an HTML form or document containing texts, input fields, images, videos, and much more. Smartforms replaces the need for paper-based forms and documents.
                    </h3>
                    <div className="max-w-lg">
                        <h2 className="text-lg font-medium text-gray-900">
                            Create your first form
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                            Start with the following steps
                        </p>
                        <ul
                            className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200"
                        >
                            {items.map((item, itemIdx) => (
                                <li key={itemIdx}>
                                    <div className="relative group py-4 flex items-start space-x-3">
                                        <div className="flex-shrink-0">
                                            <span
                                                className={classNames(
                                                    item.iconColor,
                                                    "inline-flex items-center justify-center h-10 w-10 rounded-lg"
                                                )}
                                            >
                                                <item.icon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <div className="text-sm font-medium text-gray-900">
                                                {
                                                    item.onClick &&
                                                    <Link to="#" onClick={item?.onClick}>
                                                        <span className="absolute inset-0"
                                                            aria-hidden="true"
                                                        />
                                                            {item.name}
                                                    </Link> 
                                                }
                                                {
                                                    item.href &&
                                                    <Link to={item.href}>
                                                        <span className="absolute inset-0"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </Link>
                                                }
                                                {
                                                    item.mailTo &&
                                                    <a href={item.mailTo}>
                                                        <span className="absolute inset-0"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </a>
                                                }
                                                
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 self-center">
                                            <ChevronRightIcon
                                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-6 flex">
                            <a 
                                href={config.docsUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            >
                                Or read more documentation
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </section>

                <section className="sm:h-full h-96 w-full flex justify-center align-center">
                    <div className="w-4/5 h-4/5	sm:w-10/12 sm:h-full bg-gray-200">

                    </div>
                </section>
            </div>
        </>
    );
}
