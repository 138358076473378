import { get, post, destroy, patch } from './api'


export const getAllContactLists = async () => {
  return await get(`contactLists`);
}

export const getContactList = async (id) => {
  return await get(`contactLists/${id}`)
}

export const getAllActiveContactLists = async () => {
  return await get(`contactLists/active`);
}

export const createContactList = async (record) => {
  return await post(`contactLists`, record);
}

export const deleteContactList = async (id) => {
  return await destroy(`contactLists/${id}`);
}

export const patchContactList = async (id, props) => {
  return await patch(`contactLists/${id}`, props)
}