import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/solid';
import { useClassNames } from '../../hooks/useClassNames';

export default function RowOptions({ data, onChange, open, setOpen, title = "Edit items" }) {
  const [initOptions, setInitOptions] = useState([])
  const [options, setOptions] = useState([])
  useEffect(() => {
    const clonedData = _.cloneDeep(data)
    setInitOptions(clonedData?.orderTableValue?.rows || [])
    setOptions(clonedData?.orderTableValue?.rows || [])
  }, [data])
  
  const [validationMessage, setValidationMessage] = useState();
  const classNames = useClassNames();

  const handleOnChange = (e) => {
    const { name, value } = e.target
    const newOptions = options.map((option, index) => {
        if (index.toString() === name) {
          return value;
        }
        return option;
    });
  
    setOptions(newOptions);

  }

  const handleSave = () => {
    onChange({
      target: {
        name: 'orderTableValue.rows',
        value: options
      }
    })

    setOpen(false)
  }

  const handleCancel = () => {
    let newOptions = [...(initOptions || [])]
    setOptions(newOptions)
    setValidationMessage('')
    setOpen(false)
  }

  const handleAdd = (index = -1) => {
    let newOptions = [...options]

    newOptions.splice(index + 1, 0, "")

    setOptions(newOptions);
  }

  const handleRemove = (id) => {
    setOptions(options.filter(m => m.id !== id))
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames.classNames(
                'sm:max-w-2xl inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6'
              )}
            >
              <div className='flex justify-between items-center'>

                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
                  {title}
                </Dialog.Title>

              </div>
              <div className="">
                {
                  validationMessage &&
                  <div className="rounded-md bg-red-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{validationMessage}</h3>
                      </div>
                    </div>
                  </div>
                }
                {
                  options?.length > 0 &&
                  <div className="grid grid-cols-11 gap-3 mb-1">
                    <div className="col-span-5">
                      Display
                    </div>
                    <div className="col-span-1"></div>
                  </div>
                }
                {
                  options?.map((option, index) => (
                    <div key={index} >
                      <div
                        className={classNames.classNames(
                          'mb-3 grid grid-cols-11 gap-3')}
                      >
                        <input
                          name={index}
                          type={"text"}
                          value={option}
                          placeholder="Display..."
                          className={`col-span-5 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                          onChange={(e) => handleOnChange(e)}
                        />
                        <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                          <PlusCircleIcon className="w-5" onClick={() => handleAdd(index)} />
                          <TrashIcon className="w-5" onClick={() => handleRemove(option.id)} />
                        </div>
                      </div>
                    </div>

                  ))
                }

                {
                  options?.length < 1 &&
                  <div className="">
                    No options are created.
                    <button
                      type="button"
                      onClick={handleAdd}
                      className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                    >
                      Add option
                    </button>
                  </div>
                }

              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}