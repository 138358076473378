import { GlobalDndContext } from '../../../../contexts';

export const GlobalDndContextWrapper = ({ children, dndHook }) => {
  const isTypeEnabled = (type) => {
    return !dndHook.activeDndType || dndHook.activeDndType === type
  }

  return (
    <GlobalDndContext.Provider
      value={{
        isTypeEnabled,
        hasActiveDrag: () => {
          return dndHook.hasActiveDrag()
        },
        activeDndId: dndHook.activeDndId,
        activeDndType: dndHook.activeDndType,
        sidebarColumnId: dndHook.sidebarColumnId,
        sidebarRowId: dndHook.sidebarRowId,
        sidebarContainerId: dndHook.sidebarContainerId,
        setSidebarElements: dndHook.setSidebarElementsByType,
        getElement: dndHook.getElement,
        getElements: dndHook.getElements,
        getContainers: dndHook.getContainers,
        regenerateElementDndId: dndHook.regenerateElementDndId,
        onDragHandleClick: dndHook.onDragHandleClick,
        activeFromSidebar: dndHook.activeFromSidebar,
        newSectionRecord: dndHook.newSectionRecord
      }}
    >
      {children}
    </GlobalDndContext.Provider>
  )
}