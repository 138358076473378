import { SearchIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

export default function List({ data, hasMissingTerms, setSelectedElement, selectedElementId }) {
  const [searchBy, setSearchBy] = useState(null);
  const [dataToShow, setDataToShow] = useState(data);

  useEffect(() => {
    let items = data;
    if (searchBy && searchBy !== "") {
      items = Object.entries(data).reduce((acc, [key, value]) => {
        const filteredValue = value.filter(
          (item) =>
            item.name
              ?.toLowerCase()
              .includes(searchBy.toLowerCase()) ||
            item.property
              ?.toLowerCase()
              .includes(searchBy.toLowerCase())
        );
        if (filteredValue.length > 0) {
          acc[key] = filteredValue;
        }
        return acc;
      }, {});
    }

    setDataToShow(items);
  }, [data, searchBy]);

  const handleSearch = (e) => {
    setSearchBy(e.target.value);
  };

  const MissingTerm = () => (<span className="ml-2 text-red-500 break-normal">(Missing)</span>);

  return (
    <div className="flex flex-col w-full sm:w-1/4 h-96 overflow-auto">
      <div className="mb-5 w-72">
        <div className="flex rounded-md shadow-sm">
          <div className="relative flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <nav className=" overflow-y-auto" aria-label="Directory">
        {Object.keys(dataToShow).map((letter) => (
          <div key={letter} className="relative">
            <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
              <h3>{letter}</h3>
            </div>
            <ul className="relative z-0 divide-y divide-gray-200">
              {dataToShow[letter].map((item) => (
                <li key={item.id} className="bg-white">
                  <div className={`relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50 cursor-pointer ${item.id === selectedElementId ? 'bg-gray-50' : ''} `}>
                    <div className="min-w-0 flex-1">
                      <div
                        className="focus:outline-none"
                        onClick={() =>
                          setSelectedElement(item)
                        }
                      >
                        {/* Extend touch target to entire panel */}
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        />
                        <p className="text-sm font-medium text-gray-900">
                          {item.isContainer ||
                            item.isDigitalSigning ||
                            item.isProcess
                            ? `${item.name}`
                            : `${item.property}`}
                          {hasMissingTerms(item) && (
                            <MissingTerm />
                          )}
                        </p>
                        <p className="truncate text-sm text-gray-500"></p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  );
}