import { useEffect, useState } from "react"
import { getAllMigrations, getMigrationByMigrationName, startMigration } from "../../../api/migrations"
import { Button } from "../../../components/Form/Button"
import Modal from "../../../components/Modal"
import { useToastAction } from "../../../hooks/useToastAction"
import { dataMigrationStatuses } from "../../../utils/constants"
import { stringToLocaleDateTimeString } from "../../../utils/date"

export const Migrations = () => {
  const [migrations, setMigrations] = useState([])
  const [migration, setMigration] = useState([])
  const [migrationKey, setMigrationKey] = useState()
  const [migrationName, setMigrationName] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const loadAllAction = useToastAction()
  const loadSingleAction = useToastAction()
  const startMigrationAction = useToastAction()

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    loadAllAction.execute(async () => {
      const results = await getAllMigrations()
      setMigrations(results);
    })
  }

  const onMigrationClick = async (migrationInfo) => {
    loadSingleAction.execute(async () => {
      const result = await getMigrationByMigrationName(migrationInfo.key);
      setMigration(result);
      setMigrationKey(migrationInfo.key)
      setMigrationName(migrationInfo.name)
      setIsModalOpen(true)
    })
  }

  const onRefreshMigrationClick = async () => {
    loadSingleAction.execute(async () => {
      const result = await getMigrationByMigrationName(migrationKey);
      setMigration(result);
    })
  }

  const onStartMigrationClick = async () => {
    startMigrationAction.execute(async () => {
      await startMigration(migrationKey)
      await onRefreshMigrationClick()
    })
  }

  const migrationStatusToName = (dataMigrationStatus) => {
    switch (dataMigrationStatus) {
      case dataMigrationStatuses.queued:
        return "Queued"
      case dataMigrationStatuses.running:
        return "Running"
      case dataMigrationStatuses.complete:
        return "Complete";
      case dataMigrationStatuses.failed:
        return "Failed";
      default:
        return "";
    }
  }

  const onModalClose = () => {
    setIsModalOpen(false)
    setMigrationKey(null)
    setMigrationName(null)
    setMigration(null)
  }

  return (
    <div>
      {
        migrations?.map(m => {
          return (
            <Button theme="text" text={m.name} onClick={() => onMigrationClick(m)} />
          )
        })
      }
      <Modal
        isOpen={isModalOpen}
        size={"large"}
        title={migrationName}
        onClose={onModalClose}
      >
        <>
          {
            migration &&
            <div>
              {
                (migration.dataMigrationStatus === dataMigrationStatuses.running
                  || migration.dataMigrationStatus === dataMigrationStatuses.queued)
                &&
                <div>
                  <Button
                    text={"Refresh"}
                    theme="white"
                    onClick={onRefreshMigrationClick}
                    disabled={loadSingleAction.isExecuting}
                  />
                </div>
              }
              <div>Status: {migrationStatusToName(migration.dataMigrationStatus)}</div>
              <div>Started at: {stringToLocaleDateTimeString(migration.startedAt)}</div>
              {
                migration.completedAt &&
                <>
                  <div>Completed at: {stringToLocaleDateTimeString(migration.completedAt)}</div>
                  <Button
                    onClick={onStartMigrationClick}
                    text="Restart"
                    disabled={startMigrationAction.isExecuting}
                  />
                </>
              }
              <div style={{ maxHeight: "800px", overflowY: 'auto' }}>
                {migration.log?.split("\r\n").map(c => {
                  return (
                    <p>{c}</p>
                  )
                })}
              </div>
            </div>
          }
          {
            !migration &&
            <div>
              <Button
                onClick={onStartMigrationClick}
                text="Start"
                disabled={startMigrationAction.isExecuting}
              />
            </div>
          }
        </>
      </Modal>
    </div>
  )
}