import { useState, useEffect } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'
      >
        <Property.Label
          label="Type"
          value="Dynamic List"
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

      </Property.Group>

      <Property.Group title="Element Style">
        <Property.Checkbox
          onChange={handleOnChange}
          label={"Rounded corners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

      </Property.Group>


      <Property.Group title="Label Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"labelStyle.color"}
          initColor={data?.labelStyle?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"labelStyle.size"}
          type={'number'}
          value={data?.labelStyle?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"labelStyle.bold"}
          value={data?.labelStyle?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"labelStyle.italic"}
          value={data?.labelStyle?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"labelStyle.underline"}
          value={data?.labelStyle?.underline}
        />
      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.FontStyleAndWeight 
          onChange={handleOnChange}
          name="text"
          property={data?.text}
        />

      </Property.Group>
    </div>
  )
}
