import { useContext, useEffect, useMemo, useState } from 'react'
import { FormContext } from '../../contexts'
import { v4 as uuidv4 } from 'uuid';
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import _ from 'lodash';
import { useElementStyle } from '../useElementStyle';

export default function OrderTable({ data }) {
  const { dialogValues } = useContext(FormContext) || {};
  const elementDesign = useElementDesign(data.id, data);
  const [orderValue, setOrderValue] = useState({});

  const totalColumns = data.optionWithValues?.length || 0

  const { textStyle, textClassName } = useElementStyle();

  const tableHeaderClassName = textClassName(data?.labelStyle)
  const { textAlign: textAlignHeader, width: widthHeader, ...tableHeaderStyle} = textStyle(data?.labelStyle)

  const tableHeaderClass = (currentItem) => {
    var className = `py-3.5 px-3 text-right ${tableHeaderClassName} table-cell`;

    if(currentItem === 0) {
      className = `py-3.5 pl-4 pr-3 text-left ${tableHeaderClassName} sm:pl-6 md:pl-0`;
    }
    else if(currentItem === totalColumns - 1) {
      className = `py-3.5 pl-3 pr-4 text-right ${tableHeaderClassName} sm:pr-6 md:pr-0`;
    }
    
    return className;
  }

  const tableBodyClassName = textClassName(data?.text)
  const tableFooterClassName = textClassName(data.orderTable.footerTextStyle);
  const tableFooterTextStyle = textStyle(data.orderTable.footerTextStyle);
  
  const { textAlign: textAlignBody, width: widthBody, ...tableBodyStyle} = textStyle(data?.text)

  const tableBodyClass = (currentItem) => {
    var className = `py-4 px-3 text-right ${tableBodyClassName} table-cell`;

    if(currentItem === 0) {
      className = `py-4 pl-4 pr-3 ${tableBodyClassName} sm:pl-3`;
    }
    else if(currentItem === totalColumns - 1) {
      className = `py-4 pl-3 pr-4 text-right ${tableBodyClassName} sm:pr-3`;
    }
    
    return className;
  }

  const generateRowsFromTableValues = (rowAsString) => {
    const rows = [];
    const rowsCount = rowAsString?.length / totalColumns;

    for(let i = 0; i < rowsCount; i++) {
      const columns = [];

      for(let j = 0; j < totalColumns; j++) {
        const rowIndex = i * totalColumns;
        const rowData = rowAsString?.slice(rowIndex, rowIndex + totalColumns);

        columns.push({
          id: uuidv4(),
          value: rowData[j],
        })
      }
      rows.push(columns);
    }

    return rows;
  }

  const generateOrderValue = () => {
    const orderTableValue = dialogValues?.orderTableValue || data?.orderTableValue;
  
    if (orderTableValue) {
      const orderTable = _.cloneDeep(orderTableValue);
      orderTable.rows = generateRowsFromTableValues(orderTable.rows);
      setOrderValue(orderTable);
    }
  }

  useEffect(() => {  
    generateOrderValue();
  }, [data]);

  const borderStyle = () => {  
    let style = {};
    if(data?.orderTable?.borders ) {
      style.borderBottom = `1px solid ${data?.orderTable?.borderColor || '#000'}`
    }

    return style;
  }

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div onBlur={elementDesign.handleBlur}>
      {
          data?.orderTable?.stripedRows &&
          <style type="text/css">
              {
                  data?.orderTable?.stripesEvenColor &&
                  `tbody.table-${data?.id} tr:nth-child(even) { background-color: ${data?.orderTable?.stripesEvenColor} }`
              }
              {
                  data?.orderTable?.stripesOddColor &&
                  `tbody.table-${data?.id} tr:nth-child(odd) { background-color: ${data?.orderTable?.stripesOddColor} }`
              }
          </style>
      }
      <table className="min-w-full">
          <thead>
            <tr style={borderStyle()}>
              {
                data.optionWithValues?.map((option, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={tableHeaderClass(index, data.optionWithValues?.length || 0)}
                    style={tableHeaderStyle}
                  >
                    {elementDesign.translateOption(option.id, option.display, "display")}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody className={`table-${data?.id} px-3`} style={borderStyle()}>
            {
              orderValue?.rows?.map((row, rowIndex) => (
                <tr key={rowIndex} className="">
                  {
                    row?.map((column, columnIndex) => (
                      <td 
                        key={column?.id} 
                        className={tableBodyClass(columnIndex)} 
                        style={tableBodyStyle}
                      >
                        {elementDesign.translateRow(rowIndex, columnIndex, column.value)}
                      </td>  
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
          <tfoot style={{...tableFooterTextStyle}}>
            {
              data?.orderTable?.discount ?
              <tr>
                <th
                  scope="row"
                  colSpan={totalColumns - 2}>
                </th>
                <th
                  scope="row"
                  // colSpan={totalColumns - 1}
                  className={"hidden pl-6 pr-3 pt-4 text-right font-normal  sm:table-cell " + tableFooterClassName}
                >
                  Discount 1
                </th>
                <th scope="row" className={"pl-4 pr-3 pt-4 text-left  font-normal  sm:hidden " + tableFooterClassName}>
                  Discount 2
                </th>
                <td className={"pl-3 pr-4 pt-6 text-right  sm:pr-3" + tableFooterClassName}>
                  {orderValue?.discount}
                </td>
              </tr> : null
            }
            {
              data?.orderTable?.vat &&
              <tr>
                <th
                  scope="row"
                  colSpan={totalColumns - 2}>
                </th>
                <th
                  scope="row"
                  // colSpan={totalColumns - 1}
                  className={"hidden pl-6 pr-3 pt-4 text-right font-normal  sm:table-cell " + tableFooterClassName}
                >
                  Vat 1
                </th>
                <th scope="row" className={"pl-4 pr-3 pt-4 text-left font-normal  sm:hidden " + tableFooterClassName}>
                  Vat 2
                </th>
                <td className={"pl-3 pr-4 pt-4 text-right sm:pr-3" + tableFooterClassName}>
                  {orderValue?.vat}
                </td>
              </tr>
            }
            {
              data?.orderTable?.sum &&
              <tr>
                <th
                  scope="row"
                  colSpan={totalColumns - 2}>
                </th>
                <th
                  scope="row"
                  // colSpan={totalColumns - 1}
                  style={borderStyle()}
                  className={"hidden pl-6 pr-3 pt-4 text-right font-semibold sm:table-cell"+ tableFooterClassName}
                >
                  Sum 1
                </th>
                <th scope="row" className={"pl-4 pr-3 pt-4 text-left font-semibold sm:hidden"+ tableFooterClassName} style={borderStyle()}>
                  Sum 2
                </th>
                <td className={"pl-3 pr-4 pt-4 text-right font-semibold sm:pr-3"+ tableFooterClassName} style={borderStyle()}>
                  {orderValue?.sum}
                </td>
              </tr>
            }
          </tfoot>
        </table>
      </div>
    </InlineActionbarElementWrapper>
  )
}
