import { useEffect, useMemo, useState } from "react";
import { getTagsForDialogDefinition, createTag, deleteTag } from "../../../api/tag";
import { useToastAction } from "../../../hooks/useToastAction";
import CreatableSelect from 'react-select/creatable';
import toast from "react-hot-toast";

export const Tags = ({
  dialogDefinitionId,
  onBlur
}) => {
  const [tags, setTags] = useState([])
  const [text, setText] = useState('');
  const [tagsLoading, setTagsLoading] = useState(false)
  const createTagAction = useToastAction()
  const loadAction = useToastAction()
  const hasDuplicatedTag = useMemo(() => tags?.some(tag => tag.label === text), [tags, text])

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    setTagsLoading(true)
    loadAction.execute(async (e) => {
      const tags = await getTagsForDialogDefinition(dialogDefinitionId)
        .then((r) => {
          const formatTags = r.map(tag => { return { value: tag.id, label: tag.text } })
          setTags(formatTags);
        }).finally(
          setTagsLoading(false)
        );
    }, 'Failed to load tags')
  }

  const onTextChange = (value) => {
    setText(value)
  }

  const handleSave = async () => {
    if (!text) return;
    if (hasDuplicatedTag) return;
      createTagAction.execute(async () => {
        const result = await createTag(dialogDefinitionId, text)
        setTags([...tags, {value: result.id, label: result.text}])
        setText('')
      }, 'Failed to create tag')
  }

  const onKeyPress = async (event) => {

    switch (event.key) {
      case 'Enter':
        if(hasDuplicatedTag) {
          toast.error("Tags can't be duplicated")
        } else {
          handleSave();
          setText('')
        }
        break;

      default:
        break;
    }

  }

  const onDelete = async (values) => {
    let difference = tags.filter(x => !values.includes(x)); // calculates diff
    if(!difference.length) return;

    await deleteTag(difference[0].value)
    setTags(values)
  }

  const components = {
    DropdownIndicator: null,
  };

  const handleBlur = () => {
    if(!hasDuplicatedTag){
      handleSave()
    }
  }

  return (
        <CreatableSelect
          className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
          components={components}
          inputValue={text}
          menuIsOpen={false}
          value={tags}
          isLoading={tagsLoading}
          onInputChange={onTextChange}
          onKeyDown={onKeyPress}
          onChange={onDelete}
          isMulti
          isClearable={false}
          placeholder=""
          onBlur={handleBlur}
        />
  );
}