import { useState } from 'react';
import * as AllObjectTemplates from '../templates/objects';

export const useQuickDesignObjectLibrary = () => {
    const [allObjects, setAllObjects] = useState(Object.keys(AllObjectTemplates).map(k => {
        const itemFunc = AllObjectTemplates[k];
        const template = itemFunc();
        return { ...template, name: k, objectId: k, };
    }));

    return {
        allLibraryObjects: allObjects,
        createNewObjectInstance: (objectId) => {
            const objectGeneratorFunc = AllObjectTemplates[objectId];
            return objectGeneratorFunc ? objectGeneratorFunc() : null;
        }
    }
}