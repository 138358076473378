import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom';
import { generateDataLoadDialogs, getDataLoad, getDataLoadReceipt } from '../../../api/dataLoad';
import { getDialogDefinition } from '../../../api/dialogDefinition';
import { useFileSaver } from '../../../hooks/useFileSaver';
import { useToastAction } from '../../../hooks/useToastAction';
import { dataLoadStates } from '../../../utils/constants';
import ImportStatusBar from './importStatusBar';

export default function ManageDataLoad() {
    const { dialogKey, dataLoadId } = useParams();
    const [dialog, setDialog] = useState(null);
    const [dataLoad, setDataLoad] = useState({})
    const [importStatus, setImportStatus] = useState({ statusText: "Ready to create dialogs", status: "info" });
    const fileSaver = useFileSaver();
    const loadAction = useToastAction();
    const generateAction = useToastAction();
    const downloadReceiptAction = useToastAction();

    const evaluateImportStatus = (status) => {
        if (status === dataLoadStates.imported) {
            setImportStatus({ statusText: "Ready to generate dialogs", status: "info" });
        } else if (status === dataLoadStates.dialogsGenerated) {
            setImportStatus({ statusText: "Generated", status: "success" });
        } else if (status === dataLoadStates.notfied) {
            setImportStatus({ statusText: "Issued", status: "success" });
        }
    }

    useEffect(() => {
        loadAction.execute(async () => {
            const dialog = await getDialogDefinition(dialogKey);
            const dl = await getDataLoad(dialogKey, dataLoadId);
            evaluateImportStatus(dl.status);
            setDialog(dialog);
            setDataLoad(dl);
        }, "Failed to load dialog");
    }, []);

    const handleGenerateDialogs = () => {
        generateAction.execute(async () => {
            const updatedDataLoad = await generateDataLoadDialogs(dialogKey, dataLoadId);
            evaluateImportStatus(updatedDataLoad.status);
            setDataLoad(updatedDataLoad);
        }, "Generation failed");
    }

    const handleDownloadReceipt = () => {
        downloadReceiptAction.execute(async () => {
            const receipt = await getDataLoadReceipt(dialogKey, dataLoadId);
            fileSaver.saveAs(receipt.fileName, receipt.fileContent, "text/plain");
        }, "Failed to download receipt")
    }

    const GenerateDataLoadScreen = () => {
        return (<>
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Data Load Name
                </label>
                <input
                    type={"text"}
                    name="name"
                    value={dataLoad?.dataLoadName}
                    readOnly
                    disabled
                    className={`focus:ring-none focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md ${[].find(e => e === "name") ? 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500' : ''}`}

                />
            </div>
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Import details
                </label>
                <div className="text-sm text-gray-500 grid grid-cols-3 gap-y-1 gap-x-1">
                    <div className="font-medium">Dialogs to generate:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.numberOfDialogs}</div>
                    <div className="font-medium">Dialogs with email recipient:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.dialogsIncludingEmail}</div>
                    {dataLoad?.dataLoadDetails.validEmails != null && (<><div className="font-medium">Dialogs with valid email recipient:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.validEmails}</div></>)}
                </div>
            </div>
            <div className="mt-4 pt-4 flex border-t border-gray-200">
                <Link
                    to={`/admin/dataload/${dialogKey}`}
                    className="w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"

                >
                    Exit
                </Link>
                <button
                    type="button"
                    className="ml-5 justify-center flex items-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"

                    onClick={() => handleGenerateDialogs()}
                >
                    {
                        generateAction.isExecuting && (
                            <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        )
                    }
                    Generate
                </button>
            </div>
        </>)
    }

    const ReceiptScreen = () => {

        return (
            <>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Data Load Name
                    </label>
                    <input
                        type={"text"}
                        name="name"
                        value={dataLoad?.dataLoadName}
                        readOnly
                        disabled
                        className={`focus:ring-none focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md ${[].find(e => e === "name") ? 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500' : ''}`}

                    />
                </div>
                <div className="mt-4">
                    <div className="flex justify-between items-center">
                        <label className="block text-sm font-medium text-gray-700">
                            Receipt file
                        </label>
                        <button className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                            disabled={downloadReceiptAction.isExecuting}
                            onClick={() => {
                                handleDownloadReceipt();
                            }}>
                            {
                                downloadReceiptAction.isExecuting && (
                                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                )
                            }
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Import details
                    </label>
                    <div className="text-sm text-gray-500 grid grid-cols-3 gap-y-1 gap-x-1">
                        <div className="font-medium">Dialogs generated:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.numberOfDialogs - dataLoad?.dataLoadDetails.failedDialogs}</div>
                        <div className="font-medium">Dialogs with email:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.dialogsIncludingEmail}</div>
                        {dataLoad?.dataLoadDetails.validEmails != null && (<><div className="font-medium">Dialogs with valid email recipient:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.validEmails}</div></>)}
                        <div className="font-medium">Dialogs that failed:</div><div className="col-span-2">{dataLoad?.dataLoadDetails.failedDialogs}</div>
                    </div>
                </div>
                <div className="mt-4 pt-4 flex border-t border-gray-200">
                    <Link
                        to={`/admin/dataload/${dialogKey}`}
                        className="w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"

                    >
                        Exit
                    </Link>
                    <Link
                        to={`./notify`}

                        className="ml-5 justify-center flex items-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                    >
                        Send Emails
                    </Link>
                </div>
            </>
        )
    }

    return (
        <div className="max-w-4xl">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Generate dialogs for: {dialog?.name}</h3>
            </div>

            <div className="mt-4">
                <ImportStatusBar status={importStatus} />
            </div>

            {
                dataLoad?.status === dataLoadStates.imported && (
                    <GenerateDataLoadScreen />
                )
            }
            {
                dataLoad?.status >= dataLoadStates.dialogsGenerated && (
                    <ReceiptScreen />
                )
            }

        </div>
    )
}
