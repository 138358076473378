import { useEffect, useState } from "react"
import { useDebounce } from '@react-hook/debounce'

export const useSearchDebounce = (wait = 500, value = '') => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebounce('', wait);

  useEffect(() => {
    setDebouncedSearchTerm(searchTerm)
  }, [searchTerm])

  return {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
  }
}