import React, { useEffect, useState } from "react";
import * as Structures from "../../../structures"
import { useToastAction } from '../../../hooks/useToastAction';
import { getDialogDefinitionVersion } from "../../../api/dialogDefinition";


export const VersionPreview = ({ versionId }) => {
  const loadAction = useToastAction();
  const [dialogDefinition, setDialogDefinition] = useState();
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    load()
  }, []);

  const load = async () => {
    await loadAction.execute(async () => {
      const dialogDefinition = await getDialogDefinitionVersion(versionId);
      setDialogDefinition(dialogDefinition.record);
      setLoadingData(false)
    }, "Failed to load");
  }
  
  return (
    <div className="border-2 rounded-md" style={{pointerEvents: 'none'}}>
      {
        dialogDefinition && <Structures.Dialog data={dialogDefinition} isPreviewMode={true} />
      }
    </div>
  )
}

