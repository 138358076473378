import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { FormContext, DialogModes } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing'
import { useDebounceCallback } from '@react-hook/debounce'
import { getInformation } from '../../api/apiDefinition'
import { getDialogValueKey } from '../../utils/features'
import _ from 'lodash'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({ data, ...props }) {
  const [selected, setSelected] = useState(-1)
  const [query, setQuery] = useState('')
  const formContext = React.useContext(FormContext);
  const { isEditMode, isPdfMode, isPreviewMode } = React.useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data);
  const initialDisplayValue = elementDesign.translateTerm(data?.placeholder ? data?.placeholder : 'Select from dropdown', "placeholder")
  const optionsWithValueClone = _.cloneDeep(data.optionWithValues);
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'
  const [buttonWidth, setButtonWidth] = useState(data?.width ?? 0);
  const buttonRef = useRef(null);
  
  useEffect(() => {
    if (buttonRef?.current) {
      setButtonWidth(buttonRef?.current?.offsetWidth);
    }
  }, []);

  const classPrefix = "dropdown";
  let currentValue;
  let currentDisplayValue;
  let onChange;
  if (formContext) {
    currentValue = formContext?.inputValues[getDialogValueKey(data)]?.value || initialDisplayValue;
    onChange = (value) => {
      if (data.apiRetrieveId)
        getInformationFromApi(value, data.apiRetrieveId);

      formContext.updateValue(getDialogValueKey(data), value)
    }
  }
  else {
    currentValue = selected
    onChange = (value) => {
      setSelected(value);
    }
  }

  const match = data.optionWithValues?.find(d => d.value === currentValue)
  if (match) {
    currentDisplayValue = elementDesign.translateOption(match.id, match.display, "display")
  }
  else {
    currentDisplayValue = initialDisplayValue
  }

  const getCurrentValue = (value) => {
    const getMatch = data.optionWithValues?.find(d => d.value === value)
    return elementDesign.translateOption(getMatch?.id, getMatch?.display, "display")
  }

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, currentValue === initialDisplayValue ? null : currentValue) // treat initialDisplayValue as null
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const marginStyle = data?.margin ? data.margin : 'inherit'
  const wrapperStyle = {
    margin: marginStyle
  }

  const { borderAndWidthStyle, textStyle, textClassName } = useElementStyle(data);
  const { borderColor, borderWidth, width: inputWidth } = borderAndWidthStyle();
  const { justifyContent, width: textWidth, ...textStyleProps } = textStyle(data?.text)
  const labelStyle = textStyle(data?.labelStyle)

  const inputWrapperStyle = {
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    width: inputWidth,
    background: backgroundColorStyle,
    ...textStyleProps
  }

  const { color } = textStyleProps;
  const selectorStyle = {
    color: color
  }

  const isLabelHidden = data?.hideLabel ?? false;

  const getInformationFromApi = useDebounceCallback((value, apiRetrieveId) => {
    const execute = async () => {
      var response = await getInformation(apiRetrieveId, [{ property: data.property, value: value }])

      response.forEach(element => {
        if (!element?.preventDataInsert)
          formContext?.updateByPropertyNameValue(element.property, element.value)
      });
    }

    execute();
  },
    500
  );

  const labelTop = {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: "1.5rem",
    placeItems: "center"
  }

  const hideElement = elementDesign.hideConnectedElement(data?.connectedElement?.value, data?.connectedElement?.type)

  if (data?.optionWithValueAlphabeticalOrder) {
    optionsWithValueClone?.sort((a, b) => a?.display?.localeCompare(b?.display))
  }

  const filteredOptions =
    query === ''
      ? optionsWithValueClone
      : optionsWithValueClone.filter((opt) => {
        return opt.display.toLowerCase().includes(query.toLowerCase())
      })

  const handleChangeDropdownValue = (value) => {
    onChange(value)
    setTimeout(() => {
      setQuery("")
    }, 100)
  }

  return (
    <div style={{ width: '-webkit-fill-available' }}>
      <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
        <div
          className={`${hideElement && !isEditMode ? 'hidden' : ''}`}
          style={!data?.labelTop ? labelTop : undefined}
        >
          <div className={`${isLabelHidden ? 'hidden' : ''}`}>
            <InlineEditing
              initData={data}
              style={labelStyle}
              classes={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.labelStyle)}`}
              name='label'
              id={`label-${data.id}`}
            >
              <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.labelStyle)}`}>
                {elementDesign.translateTerm(data.label)}
                {elementDesign.requiredFieldIndicator(data.requiredField)}
                {elementDesign.translateHelpText(data)}
              </label>
            </InlineEditing>
          </div>
          <div
            className="mt-2 sm:mt-0 w-full"
            style={wrapperStyle}
            onBlur={elementDesign.handleBlur}
          >
            {
              isPdfMode ?
                <label className='w-full' style={textStyleProps}>{currentDisplayValue}</label>
                :
                <Combobox value={currentValue} onChange={handleChangeDropdownValue} disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type)}>
                  {({ open }) => (
                    <>
                      <div className="">
                        <Combobox.Button
                          ref={buttonRef}
                          className={`bg-white relative ${inputErrorClasses} ${roundedCornerClass} ${textClassName(data?.text)} shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm`}
                          style={inputWrapperStyle}
                        >
                          {(isPreviewMode || formContext) ? <Combobox.Input
                            className="w-full bg-transparent border-0 outline-none border-none focus:ring-0 focus:ring-transparent p-0 m-0"
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(optValue) => getCurrentValue(optValue)}
                            placeholder={initialDisplayValue}
                          /> : <span className="block truncate">
                            {currentDisplayValue}
                          </span>}

                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon style={selectorStyle} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Combobox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Combobox.Options
                            static
                            style={{ width: buttonWidth + 'px' }}
                            className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-40"
                          >
                            {!isEditMode && filteredOptions?.map((option, index) => (
                              <Combobox.Option
                                key={option.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9 ui-active:bg-indigo-600 hover:bg-indigo-600',
                                  )
                                }
                                value={option.value}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {elementDesign.translateOption(option.id, option.display, "display")}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'hover:text-white' : 'text-indigo-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Combobox>
            }
          </div>
        </div>
      </InlineActionbarElementWrapper>
    </div>
  )
}