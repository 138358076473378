import { Form, Formik } from "formik"
import * as yup from "yup";
import Modal from "../../../../components/Modal"
import { FormikTextInput } from "../../../../components/Form/Formik/FormikTextInput"
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit"
import { Button } from "../../../../components/Form/Button"

export const CreateContactList = ({ onSave, modalHelper, isSaving }) => {
  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
    >
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={() => {
          return yup.object({
            name: yup.string().required("Required"),
          });
        }}
        onSubmit={async (values, actions) => {
          await onSave(values)
          actions.setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <div>
              <FormikTextInput
                required
                label="Name"
                name="name"
                formikProps={props}
              />
            </div>
            <div className="mt-5 sm:mt-6 flex justify-between">
              <FormikSubmitButton
                formikProps={props}
                text="Save"
                disabled={isSaving}
              />
              <Button
                onClick={modalHelper.close}
                text="Close"
                theme="white"
                disabled={isSaving}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}