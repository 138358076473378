import { get, post, put } from './api'

/**
 * @typedef {Object} CreateDialogDefinitionReminder
 * @prop {number} daysUntilReminder
 * @prop {boolean} enabled
 * @prop {number} order
 * @prop {number[]} dialogDefinitionReminderMethods
 */

/**
 * @typedef {Object} UpdateDialogDefinitionReminder
 * @prop {number} daysUntilReminder
 * @prop {boolean} enabled 
 * @prop {number[]} dialogDefinitionReminderMethods
 */

export const getDialogDefinitionRemindersByDialogDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogDefinitionReminders`);
}

/**
 * @param {string} dialogDefinitionId 
 * @param {CreateDialogDefinitionReminder} dialogDefinitionReminder  
 */
export const createDialogDefinitionReminder = async (dialogDefinitionId, dialogDefinitionReminder) => {
  return await post(`dialogDefinitions/${dialogDefinitionId}/dialogDefinitionReminders`, dialogDefinitionReminder);
}

/**
 * @param {string} id 
 * @param {string} dialogDefinitionId 
 * @param {UpdateDialogDefinitionReminder} dialogDefinitionReminder 
 * @returns 
 */
export const updateDialogDefinitionReminder = async (id, dialogDefinitionId, dialogDefinitionReminder) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/dialogDefinitionReminders/${id}`, dialogDefinitionReminder);
}