import React from "react";
import Modal from "../../components/Modal";
import { LoaderThreeDots } from "../../components/Loader";

const PreparingSignDocumentModal = ({ modalHelper }) => {

    const handleClose = () => {
        modalHelper.close();
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={handleClose}
            size="large"
            title={'Preparing document...'}
        >
            <div>
                <LoaderThreeDots />
            </div>
        </Modal >
    );
}
export default PreparingSignDocumentModal;