import { useDefinitionManager } from "./useDefinitionManager"
import { useNewIdGenerator } from "./useNewIdGenerator";
import * as Data from "../../../data";
import _ from 'lodash';
import { setProperty } from '../../../utils/property'
import { v4 as uuidv4 } from 'uuid';

export const useColumnActions = (onSuccess, setColumnContext, themeStyle) => {
  const definitionManager = useDefinitionManager();
  const idGenerator = useNewIdGenerator();

  const findColumn = (dialogDefinition, columnId) => {
    let column;
    dialogDefinition.containers?.forEach(container => {
      container?.rows.forEach(row => {
        const match = row?.columns?.find(e => e.id === columnId);
        if (match) {
          column = match;
        }
      })
    });
    return column
  }
  return {
    addLeft: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newColumn = _.cloneDeep(Data.ColumnNew(themeStyle))
      idGenerator.newIdsOnColumn(newColumn);

      let row = definitionManager.findRowFromColumn(payload, columnId)
      definitionManager.addItemAbove(row.columns, columnId, newColumn)

      onSuccess(payload);
      setColumnContext(newColumn)
    },
    addRight: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newColumn = _.cloneDeep(Data.ColumnNew(themeStyle))
      idGenerator.newIdsOnColumn(newColumn);

      let row = definitionManager.findRowFromColumn(payload, columnId)
      definitionManager.addItemBelow(row.columns, columnId, newColumn)

      onSuccess(payload);
      setColumnContext(newColumn)
    },
    remove: (dialogDefinition, columnId, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      const container = definitionManager.findContainerFromColumn(payload, columnId);
      let row = definitionManager.findRowFromColumn(payload, columnId)
      let column = definitionManager.findColumn(dialogDefinition, columnId);
      const elementLength = column.elements.length;
      const columnLength = row.columns.length;
      const rowLength = container.rows.length;

      if (rowLength === 1 && columnLength === 1 && elementLength === 1) {
        //If only one line, column, and element exists in this container, delete the container
        definitionManager.removeItem(payload.containers, container.id)
      } else if (columnLength === 1 && elementLength === 1) {
        //If there is only one item in the column, delete the row
        definitionManager.removeItem(container.rows, row.id)
      } else if (columnLength !== 1 && elementLength === 1) {
        //If there are more than one column in the row and there is only one element in the column, delete the column.
        definitionManager.removeItem(row.columns, columnId)
      } else {
        definitionManager.removeItem(row.columns, columnId)
      }

      definitionManager.updateLogicControls(payload);

      if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    hiddenOrShow: (dialogDefinition, columnId, hidden, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
       definitionManager.hiddenOrShowColumn(payload, columnId, hidden);

      if (skipSave) {
        return payload
      }
      onSuccess(payload, { dismissActions: true });
    },
    duplicate: (dialogDefinition, columnId, column) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newColumn = _.cloneDeep(column);
      idGenerator.newIdsOnColumn(newColumn);

      let row = definitionManager.findRowFromColumn(payload, columnId)
      definitionManager.addItemBelow(row.columns, columnId, newColumn)

      onSuccess(payload);
    },
    moveLeft: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let row = definitionManager.findRowFromColumn(payload, columnId)
      let itemIndex = row.columns?.findIndex((item) => item.id === columnId);

      definitionManager.swapItems(itemIndex, itemIndex - 1, row.columns);

      onSuccess(payload);
    },
    moveRight: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let row = definitionManager.findRowFromColumn(payload, columnId)
      let itemIndex = row.columns?.findIndex((item) => item.id === columnId);

      definitionManager.swapItems(itemIndex, itemIndex + 1, row.columns);

      onSuccess(payload);
    },
    moveUp: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let row = definitionManager.findRowFromColumn(payload, columnId)
      let itemIndex = row.columns?.findIndex((item) => item.id === columnId);

      definitionManager.swapItems(itemIndex, itemIndex - 1, row.columns);

      onSuccess(payload);
    },
    moveDown: (dialogDefinition, columnId) => {
      const payload = _.cloneDeep(dialogDefinition)
      let row = definitionManager.findRowFromColumn(payload, columnId)
      let itemIndex = row.columns?.findIndex((item) => item.id === columnId);

      definitionManager.swapItems(itemIndex, itemIndex + 1, row.columns);

      onSuccess(payload);
    },
    handleChange: (dialogDefinition, evt, columnId, type) => {
      const payload = _.cloneDeep(dialogDefinition)
      const { name, value } = evt.target;

      const column = findColumn(payload, columnId);
      if (column) {
        setProperty(column, name, value, type)
        onSuccess(payload);
      }
    },
    addElement: (dialogDefinition, columnId, element) => {
      const payload = _.cloneDeep(dialogDefinition)
      const column = findColumn(dialogDefinition, columnId);
      if (column) {
        const elementClone = _.cloneDeep(element);
        elementClone.id = uuidv4();
        column.elements.push(elementClone);
        onSuccess(payload)
      }
    },
    dragAndDrop: (dialogDefinition, sourceParentId, destinationParentId, indexFrom, indexTo, skipSave) => {
      const payload = _.cloneDeep(dialogDefinition)
      const sourceContainer = definitionManager.findRow(payload, sourceParentId)

      if (sourceParentId === destinationParentId) {
        definitionManager.reorder(sourceContainer.columns, indexFrom, indexTo)
      } else {
        const destinationContainer = definitionManager.findRow(payload, destinationParentId)
        definitionManager.reorderBetweenTwo(sourceContainer.columns, destinationContainer.columns, indexFrom, indexTo)
      }

      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    addFromSidebar: (dialogDefinition, columnId, rowId, rowIndexTo, skipSave) => {
      const payload = _.cloneDeep(dialogDefinition)
      let newColumn = _.cloneDeep(Data.ColumnNew(themeStyle))
      idGenerator.newIdsOnColumnElements(newColumn);
      newColumn.id = columnId

      const row = definitionManager.findRow(payload, rowId)
      definitionManager.addItemByIndex(row.columns, newColumn, rowIndexTo)

      setColumnContext(newColumn)
      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    addColumnFromSidebar: (dialogDefinition, column, rowId, rowIndexTo, skipSave = false) => {
      const payload = _.cloneDeep(dialogDefinition)
      const row = definitionManager.findRow(payload, rowId)
      definitionManager.addItemByIndex(row.columns, column, rowIndexTo)

      setColumnContext(column)
      if (skipSave) {
        return payload
      }
      onSuccess(payload);
    },
    createColumn: () => {
      return _.cloneDeep(Data.ColumnNew(themeStyle))
    }
  }
}