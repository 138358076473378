import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import {
  RecoilRoot,
} from 'recoil';
import { AuthenticationProvider, oidcLog, WebStorageStateStore, useReactOidc } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { config, oidcConfig } from './utils/config';
import AdminLayout from "./layouts/AdminLayout"
import Oidc from "./screens/Oidc";
import DialogDevelopment from "./screens/DialogDevelopment";
import EditForm from "./screens/EditFormValues";
import { AdminReadOnlyForm } from "./screens/EditFormValues/admin";
import Dashboard from "./screens/Admin/Dashboard"
import DialogSmartForm from "./screens/Admin/DialogSmartForm"
import Configuration from "./screens/Admin/Configuration"
import DialogConfiguration from "./screens/Admin/DialogConfiguration"
import DialogVersions from "./screens/Admin/DialogVersions"
import DialogLanguage from "./screens/Admin/DialogLanguage"
import DialogDesign from "./screens/Admin/DialogDesign"
import DialogRecords from "./screens/Admin/DialogRecords"
import DialogPreview from "./screens/Admin/DialogPreview"
import { Toaster } from './toaster';
import { OidcSecure } from '@axa-fr/react-oidc-context'
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader, setHeader } from './api/api'
import { MissingLicense } from './screens/Errors/MissingLicense';
import DataLoad from './screens/Admin/DataLoad';
import { getUserProfile } from './api/userProfile';
import { FolderContext, OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useToastAction } from './hooks/useToastAction';
import { useFeature } from './hooks/useFeature';
import { useLicense } from './hooks/useLicense';
import { featureFlags } from './utils/features';
import DialogDataLoads from './screens/Admin/DataLoad/dialogDataLoads';
import ImportDataLoad from './screens/Admin/DataLoad/importDataLoad';
import ManageDataLoad from './screens/Admin/DataLoad/manageDataLoad';
import DataExport from './screens/Admin/DataExport';
import { Objects } from './screens/Admin/Templates/objects';
import { Dialogs } from './screens/Admin/Templates/dialogs';
import DataLoadIssueNotification from './screens/Admin/DataLoad/dataLoadIssueNotification';
import Videos from './screens/Admin/Videos/index';
import MultiStepForms from './screens/Admin/MultiStepForms/index'
import Process from './screens/Admin/Process/index';
import ProcessDetails from './screens/Admin/ProcessDetails/index';
import SignForm from './screens/SignForm';
import { EditObject } from './screens/Admin/Templates/editObject';
import { AuthenticateForm } from './screens/EditFormValues/authenticateForm';
import { DialogProcess } from './screens/Admin/DialogProcess';
import { Embedded } from './screens/Embedded';
import DialogIntegration from './screens/Admin/DialogIntegration'
import * as Constants from './utils/constants';
import EmailTemplateList from './screens/Admin/Templates/Email/emailTemplateList';
import EmailTemplateForm from './screens/Admin/Templates/Email/emailTemplateForm';
import Api from './screens/Admin/Api';
import { AlertModal } from '@metaforcelabs/metaforce-core';
import SmsTemplateList from './screens/Admin/Templates/Sms/smsTemplateList';
import SmsTemplateForm from './screens/Admin/Templates/Sms/smsTemplateForm';
import DialogNotification from './screens/Admin/DialogNotification';
import EditAuthTemplate from './screens/Admin/Templates/Authentication/editAuthTemplate';
import AuthTemplateList from './screens/Admin/Templates/Authentication/authTemplateList';
import DialogSecurity from './screens/Admin/DialogSecurity';
import ErrorLayout from './layouts/ErrorLayout';
import { SmartformTemplateList } from './screens/Admin/Templates/SmartForm/smartformTemplateList';
import { ContactList } from './screens/Admin/Templates/ContactList/index';
import { EditContactList } from './screens/Admin/Templates/ContactList/editContactList';
import { SmartformTemplateEdit } from './screens/Admin/Templates/SmartForm/smartformTemplateEdit';
import DocumentationAPI from './screens/DocumentationAPI';
import { getUserAccessGroups } from './api/accessGroups';
import { Migrations } from './screens/Admin/Migrations';
import ThemeStyle from './screens/Admin/Templates/ThemeStyle';
import Changelog from './screens/Changelog';
import SmartObjectList from './screens/Admin/Templates/SmartObject/smartObjectList';
import SmartObjectForm from './screens/Admin/Templates/SmartObject/smartObjectForm';
import { AuthenticateSmsForm } from './screens/EditFormValues/authenticateSmsForm';
import Resources from './screens/Admin/Templates/Resources';
import { systemFolderIds } from './utils/folders';

function App() {
  const publicRoutes = [
    "/embedded",
    "/unauthorized",
    "/missing-license",
    "/dialog",
    "/sign",
    '/documentation-api',
    '/archive',
    '/not-found',
    '/changelog'
  ]

  useEffect(() => {
    document.title = config.appTitle;
    const faviconEl = document.getElementById("favicon");
    faviconEl.href = config.favicon;
  }, [])


  const isPublicRoute = publicRoutes.some(path => window.location.pathname.startsWith(path));

  return (
    <div id="app" className="app h-screen">
      <RecoilRoot>
        <AlertModal>
          <Router>
            {
              isPublicRoute ?
                (
                  <Routes>
                    <Route exact={true} path="/embedded/:token" element={<Embedded />} />

                    <Route exact={true} path="/unauthorized" element={<ErrorLayout><Unauthorized /></ErrorLayout>} />
                    <Route exact={true} path="/missing-license" element={<ErrorLayout><MissingLicense /></ErrorLayout>} />
                    <Route exact={true} path="/not-found" element={<ErrorLayout><NotFound /></ErrorLayout>} />

                    <Route exact={true} path="/documentation-api" element={<DocumentationAPI />} />

                    <Route exact={true} path="/dialog/:dialogKey/preview" element={<DialogPreview />} />
                    <Route exact={true} path="/dialog/:dialogKey/form/:valuesKey/authenticate/contactlist" element={<AuthenticateSmsForm />} />
                    <Route exact={true} path="/dialog/:dialogKey/form/:valuesKey" element={<EditForm />} />
                    <Route exact={true} path="/dialog/:dialogKey/form/:valuesKey/authenticate" element={<AuthenticateForm />} />
                    <Route exact={true} path="/dialog/:dialogKey/form/:valuesKey/steps/:currentStepKey" element={<EditForm />} />
                    <Route exact={true} path="/dialog/:dialogKey/form/:valuesKey/steps/:currentStepKey/authenticate" element={<AuthenticateForm />} />
                    <Route exact={true} path="/archive/:companyId/:archiveId" element={<EditForm loadFromArchive={true} />} />

                    <Route exact={true} path="/sign/:signatureToken" element={<SignForm />} />

                    <Route exact={true} path="/changelog" element={<Changelog />} />
                  </Routes>
                )
                :
                (
                  <AuthenticationProvider
                    configuration={oidcConfig}
                    loggerLevel={oidcLog.ERROR}
                    isEnabled={true}
                    UserStore={WebStorageStateStore}
                    callbackComponentOverride={Oidc}
                    notAuthenticated={() => { return (<h1>Not Authenticated.</h1>) }}
                    notAuthorized={() => { return (<h1>Not Authorized.</h1>) }}
                    authenticating={Oidc}
                  >
                    <OidcSecure>
                      <SetAuthToken>
                        <SetOidcRoutesContext>
                          <Routes>
                            {/* Demo / test routes */}
                            <Route exact={true} path="/development" component={DialogDevelopment} />
                            <Route exact={true} path="/amf" component={DialogDevelopment} />


                            {/* Admin routes */}
                            <Route exact={true} path="/admin/api" element={
                              <AdminLayoutRoute exact={true} path="/admin/api" nav="api" component={Api} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/smartforms" element={
                              <AdminLayoutRoute nav="dialog_smartForm" component={DialogSmartForm} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/dataload/:dialogKey/import/:dataLoadId/notify" element={
                              <AdminLayoutRoute nav="dataload" component={DataLoadIssueNotification} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dataload/:dialogKey/import/:dataLoadId" element={
                              <AdminLayoutRoute subMenuNav="data" nav="data_load" component={ManageDataLoad} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dataload/:dialogKey/import" element={
                              <AdminLayoutRoute subMenuNav="data" nav="data_load" component={ImportDataLoad} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dataload/:dialogKey" element={
                              <AdminLayoutRoute subMenuNav="data" nav="data_load" component={DialogDataLoads} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dataload" element={
                              <AdminLayoutRoute subMenuNav="data" nav="data_load" component={DataLoad} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dataexport" element={
                              <AdminLayoutRoute subMenuNav="data" nav="data_export" component={DataExport} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/configurations" element={
                              <AdminLayoutRoute nav="configurations" component={Configuration} featureEnabled={true} />
                            } />

                            <Route exact={true} path="/admin/templates/smartobject" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_smartobject" component={SmartObjectList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />


                            <Route exact={true} path="/admin/templates/smartobject/:smartObjectId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_smartobject" component={SmartObjectForm} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms}  />
                            } />

                            <Route exact={true} path="/admin/templates/objects" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="import_object" component={Objects} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/objects/:objectId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="edit_object" component={EditObject} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/dialog" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="import_dialog" component={Dialogs} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/smartforms" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_smartform" component={SmartformTemplateList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/smartforms/:templateId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_smartform" component={SmartformTemplateEdit} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/resources" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_resources" component={Resources} featureEnabled={featureFlags.templateResources} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/contact-lists" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_contactList" component={ContactList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/contact-lists/:contactListId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_contactList" component={EditContactList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/email" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_email" component={EmailTemplateList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/sms" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_sms" component={SmsTemplateList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/email/:templateId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_email" component={EmailTemplateForm} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/sms/:templateId" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_sms" component={SmsTemplateForm} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/templates/auth/:templateId" element={
                              <AdminLayoutRoute subMenuNav="auth" nav="templates_auth" component={EditAuthTemplate} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/auth" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_auth" component={AuthTemplateList} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/templates/themestyle" element={
                              <AdminLayoutRoute subMenuNav="templates" nav="templates_themestyle" component={ThemeStyle} featureEnabled={featureFlags.template} requiredFeatureCode={Constants.templateFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/multi-step-forms" element={
                              <AdminLayoutRoute nav="multi_step_forms" component={MultiStepForms} featureEnabled={featureFlags.formsToSign} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dashboard" element={
                              <AdminLayoutRoute nav="multi_step_forms" component={Process} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dashboard/:dialogKey" element={
                              <AdminLayoutRoute nav="multi_step_forms" component={ProcessDetails} featureEnabled={featureFlags.dataLoad} requiredFeatureCode={Constants.dataFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route path="/admin/dialog/:dialogKey/process" element={
                              <AdminLayoutRoute nav="dialog_process" subMenuNav="dialog" component={DialogProcess} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/configurations" element={
                              <AdminLayoutRoute nav="dialog_configurations" subMenuNav="dialog" component={DialogConfiguration} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/integration" element={
                              <AdminLayoutRoute nav="dialog_integration" subMenuNav="dialog" component={DialogIntegration} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route path="/admin/dialog/:dialogKey/design" element={
                              <AdminLayoutRoute subMenuNav="dialog" nav="dialog_design" component={DialogDesign} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/records" element={
                              <AdminLayoutRoute nav="dialog_records" subMenuNav="dialog" component={DialogRecords} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/versions" element={
                              <AdminLayoutRoute nav="dialog_versions" subMenuNav='dialog' component={DialogVersions} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/language" element={
                              <AdminLayoutRoute nav="dialog_language" subMenuNav='dialog' component={DialogLanguage} featureEnabled={featureFlags.language} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/notification" element={
                              <AdminLayoutRoute nav="dialog_notification" subMenuNav='dialog' component={DialogNotification} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/dialog/:dialogKey/security" element={
                              <AdminLayoutRoute nav="dialog_security" subMenuNav='dialog' component={DialogSecurity} featureEnabled={true} requiredFeatureCode={Constants.designFeatureCode} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin" element={
                              <AdminLayoutRoute nav="home" component={Dashboard}  licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/" element={
                              <AdminLayoutRoute nav="home" component={Dashboard} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />
                            <Route exact={true} path="/admin/videos" element={
                              <AdminLayoutRoute nav="home" component={Videos} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/dialog/:dialogKey/form/:valuesKey" element={
                              <AdminLayoutRoute component={AdminReadOnlyForm} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />

                            <Route exact={true} path="/admin/migrations" element={
                              <AdminLayoutRoute exact={true} path="/admin/migrations" nav="migrations" component={Migrations} featureEnabled={true} licenseId={Constants.licenses.smartForms} />
                            } />
                          </Routes>
                        </SetOidcRoutesContext>
                      </SetAuthToken>
                    </OidcSecure>
                  </AuthenticationProvider>
                )
            }
          </Router>
        </AlertModal>
        <Toaster />
      </RecoilRoot>
    </div>
  )
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  requiredFeatureCode,
  licenseId,
  ...rest
}) {
  const { hasLicenseRerouteCheck } = useLicense();
  const feature = useFeature();

  return (
    <ProtectedRoute featureEnabled={featureEnabled} hasFeature={feature.hasFeature(requiredFeatureCode)} hasLicense={hasLicenseRerouteCheck(licenseId)}>
      <AdminLayout nav={nav} subMenuNav={subMenuNav} {...rest} >
        <Component {...rest} />
      </AdminLayout>
    </ProtectedRoute>
  )
}

const ProtectedRoute = ({ featureEnabled, hasFeature, hasLicense, children }) => {
  return featureEnabled && hasFeature && hasLicense ? (
    <>
      {children}
    </>
  ) :
    (<Navigate to={'/'} />)
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState();
  const [hasTriedToGetCustomerData, setHasTriedToGetCustomerData] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({ index: 0, folderId: systemFolderIds.home });

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      setHasTriedToGetCustomerData(true);
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const userAccessGroupsPromise = getUserAccessGroups();
      const res = await Promise.all([userProfilePromise, customerPromise, userAccessGroupsPromise]);

      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
      setHeader("userAccessGroups", res[2])
    }, "Failed to load user data");
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return !loadAction.isExecuting && (
    <OidcRoutesContext.Provider
      value={{
        userProfile,
        customerInfo
      }}>
      <FolderContext.Provider
        value={{
          currentFolder,
          setCurrentFolder
        }}
      >
        {customerInfo && children}
        {(!customerInfo && !loadAction.isExecuting && hasTriedToGetCustomerData) ? window.location.replace(config.centerpointUiBaseUrl) : ''}
      </FolderContext.Provider>
    </OidcRoutesContext.Provider>
  )
}

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header 
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token)

  useEffect(() => {
    if (!events) return
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events])

  const addUserSignedOut = () => {
    const userManager = getUserManager()
    userManager.removeUser();
    window.location.reload(false);
  }

  return (
    <>
      {children}
    </>
  );
}

export default App;
