import { importDialogObject } from "../../../api/dialogObjects";
import { ImportFileModal } from "../../../components/ImportFileModal";
import { useDialogObjectSchemaValidation } from "../../../hooks/useDialogObjectSchemaValidation";

export const ImportObject = ({ isOpen, onClose, onImportSuccess }) => {
    const schemaValidator = useDialogObjectSchemaValidation();

    return (
        <ImportFileModal
            importAction={importDialogObject}
            title={"Object"}
            isOpen={isOpen}
            onClose={onClose}
            onImportSuccess={onImportSuccess}
            schemaValidator={schemaValidator}
            formatAccepted=".json"
        />
    );
};
