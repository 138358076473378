import { post, get, put, destroy } from './api'

export const getDefinitionLanguages = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages`);
}

export const createDefinitionLanguage = async (dialogDefinitionId, language) => {
  return await post(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages`, language);
}

export const getDefinitionLanguage = async (id, dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages/${id}`);
}

export const setTermsForLanguage = async (id, dialogDefinitionId, languageTerms, containerLanguageTerms, digitalSigningTerm, processTerm) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages/${id}/terms`, {
    languageTerms,
    containerLanguageTerms,
    digitalSigningTerm,
    processTerm
  })
}

export const setDefinitionLanguageAsDefault = async (id, dialogDefinitionId) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages/${id}/setAsDefault`)
}

export const deactivateDefinitionLanguageAsDefault = async (id, dialogDefinitionId) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages/${id}/deactivateAsDefault`)
}

export const deleteDefinitionLanguage = async (id, dialogDefinitionId) => {
  return await destroy(`dialogDefinitions/${dialogDefinitionId}/definitionLanguages/${id}`);
}

export const translateDefinitionLanguage = async (value, sourceLanguage, targetLanguage) => {
  return await post(`dialogDefinitions/translate`, {textToTranslate: value, sourceLanguage, targetLanguage})
}