import * as _ from "lodash"
import { useEffect, useState } from 'react';
import { SelectWithSearch } from '../../SelectWithSearch'

export const FormikDate = ({
  name,
  formikProps,
  label
}) => {
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [day, setDay] = useState();

  useEffect(() => {
    let date = new Date(year, month, day);

    if(!isNaN(date)) {
      formikProps.setFieldValue(name, date)
    }
  }, [year, month, day]);

  const getInterval = (start, end, orderDesc = false) => {
    let result = [];

    for (let i = start; i <= end; i++) {
      result.push(`${i}`);
    }

    if(orderDesc) {
      result = result.reverse();
    }

    return result;
  }

  return (
    <div>
      <div className="relative grid grid-cols-3 gap-3">
        <SelectWithSearch label={label} value={day} setValue={setDay} options={getInterval(1, 31)} />
        <SelectWithSearch label="Month" value={month} setValue={setMonth} options={getInterval(1, 12)} />
        <SelectWithSearch label="Year" value={year} setValue={setYear} options={getInterval(1920, new Date().getFullYear(), true)} />
      </div>
    </div>
  )
}