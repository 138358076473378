import { useEffect, useState } from "react"
import { getDialogDefinitionSchema } from "../api/dialogDefinition"

const validate = require('jsonschema').validate;

/**
 * Validate a dialog definition against the server side schema 
 */
export const useDialogDefinitionSchemaValidation = (additionalProperties) => {
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const definitionSchema = await getDialogDefinitionSchema();
    additionalProperties && (definitionSchema.additionalProperties = additionalProperties);
    setSchema(definitionSchema);
  }

  return {
    validate: (dialogDefinition) => {
      const result = validate(dialogDefinition, schema);
      console.log(result?.errors)
      return result.errors;
    }
  }
}