import './styles.scss';
import { useState, useRef, useCallback, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";

export default function PaddingPicker({ onChange, name, value, type }) {
    const spacings = value?.split(' ') || []
    const spacingIndex = spacings.findIndex(v => v.includes('px'))

    let spacingStyle = 'all'

    if (spacings.length > 1) {

        let paddingStyles = []

        spacings.forEach((padding, i) => {

            if (padding.includes('px')) {
                if (i === 0)
                    paddingStyles.push('top')
                if (i === 1)
                    paddingStyles.push('right')
                if (i === 2)
                    paddingStyles.push('bottom')
                if (i === 3)
                    paddingStyles.push('left')
            }
        })

        if (paddingStyles.length)
            spacingStyle = paddingStyles.join(' ')

        if (paddingStyles.length === 4)
            spacingStyle = 'all'
    }

    const [style, setStyle] = useState(spacingStyle)
    const [spacing, setPadding] = useState(spacings[spacingIndex]?.match(/\d+/)?.[0] || 0)
    const [isOpen, toggle] = useState(false);
    const popover = useRef();
    const close = useCallback(() => toggle(false), []);
    
    useEffect(() => setPadding(spacings[spacingIndex]?.match(/\d+/)?.[0] || 0), [value])

    useClickOutside(popover, close);

    const handleStyleChange = (pStyle) => {  //always use e.target.value as state doesn't seem to contain the current value
        let currentStyle = style
        
        if (pStyle === style)
            currentStyle = 'remove'
        else if (pStyle === 'all')
            currentStyle = 'all'
        else {
            if (currentStyle.includes('all'))
                currentStyle = currentStyle.replace('all', '')

            if (currentStyle.includes(pStyle))
                currentStyle = currentStyle.replace(pStyle, '')
            else
                currentStyle = currentStyle.concat(` ${pStyle}`)

            currentStyle = currentStyle.trim()

            if (currentStyle.split(' ').length === 4)
                currentStyle = 'all'
        }

        setStyle(currentStyle);
        handleOnChange({}, undefined, currentStyle)
    }

    const handlePaddingChange = (e) => {
        if(e.target.value > 100) {
            e.preventDefault();
            return;
        }

        setPadding(e.target.value);
        handleOnChange(e, undefined, undefined, e.target.value)
    }

    const handleOnChange = (e, type, pStyle, pSpacing)  => {
        let locSpacingSizes = [];
        let currentStyle = pStyle || style
        
        if (currentStyle === 'all')
            locSpacingSizes = [`${pSpacing || spacing}px`]
        else if (currentStyle === 'remove')
            locSpacingSizes = [0]
        else {
            locSpacingSizes[0] = currentStyle?.includes('top') ? `${pSpacing || spacing}px` : 0
            locSpacingSizes[1] = currentStyle?.includes('right') ? `${pSpacing || spacing}px` : 0
            locSpacingSizes[2] = currentStyle?.includes('bottom') ? `${pSpacing || spacing}px` : 0
            locSpacingSizes[3] = currentStyle?.includes('left') ? `${pSpacing || spacing}px` : 0
        }
        
        e.target = {
            name: name,
            value: locSpacingSizes.join(' ')
        };

        onChange(e, type)
    }
  
    return (
        <div className="picker">
            <button type="button" className="w-10 h-10 flex justify-center items-center rounded border border-gray-200 shadow-sm hover:bg-gray-50" onClick={() => toggle(true)}>
                { type === 'margin' && 
                    <div className="w-8 h-8 border-4 flex justify-center items-center">
                        <div className="w-6 h-6 border border-gray-400"/>
                    </div>
                }
                { type === 'padding' && 
                    <div className="w-8 h-8 border border-gray-400 flex justify-center items-center">
                        <div className="w-7 h-7 border-4"/>
                    </div>
                }
            </button>

            {isOpen && (
                <div className="-left-72 z-50 popover rounded-md border border-gray-300 text-gray-500 p-3 bg-white" ref={popover}>

                    <div className="grid grid-cols-5 w-72">
                        <button type="button" className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('left') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderLeftColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-l-4 cursor-pointer" onClick={() => { handleStyleChange('left') }} />
                        </button>
                        <button type="button" className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('right') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderRightColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-r-4 cursor-pointer" onClick={() => { handleStyleChange('right') }} />
                        </button>
                        <button type="button" className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('top') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderTopColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-t-4 cursor-pointer" onClick={() => { handleStyleChange('top') }} />
                        </button>
                        <button type="button" className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('bottom') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderBottomColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-b-4 cursor-pointer" onClick={() => { handleStyleChange('bottom') }} />
                        </button>
                        <button type="button" className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style === 'all' ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderColor: 'gray' }} className="w-6 h-6 border-4 border-gray-400 cursor-pointer" onClick={() => { handleStyleChange('all') }} />
                        </button>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="margin-size">Size:</label>

                        <input
                        id="margin-size"
                        onChange={handlePaddingChange}
                        className="ml-3 focus:ring-gray-400 focus:border-gray-400 text-gray-500 sm:text-sm shadow-sm w-20 border border-gray-300 rounded-md p-1 pl-3"
                        type="text"
                        value={spacing}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={close}
                        className="inline-flex mt-3 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Close
                    </button>
                </div>
                
            )}
        </div>
    );
}