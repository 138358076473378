import { XCircleIcon } from "@heroicons/react/solid";
import { Form, useFormikContext } from "formik";
import React from "react";
import { TextInput } from "../../components/Form/TextInput";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from 'uuid';
import { Select } from "../../components/Properties";

export default function PartiesToSignForm({ errorMessage, digitalSigningTranslations, digitalSigningProvider, availableSignAuthMethods, isSingleMode, handleClose, digitalSigningAllowDirectSigning, onSignDirect, lockSignatures }) {
    const { values, isSubmitting, errors, touched, handleBlur, setFieldValue, handleChange, handleSubmit, submitForm, isValid, ...props } = useFormikContext();
    const createFieldName = (name, index) => {
        return `partiesToSignForm.${index}.${name}`
    }

    const handleAdd = () => {
        setFieldValue('partiesToSignForm', [...values.partiesToSignForm, { key: uuidv4(), name: '', email: '' }])
    }

    const handleRemove = (index) => {
        values.partiesToSignForm.splice(index, 1)
        setFieldValue('partiesToSignForm', [...values.partiesToSignForm]);
    }

    const handleSubmitParties = (e, signNow = false) => {
        e.preventDefault();
        e.stopPropagation();
        setFieldValue("signNow", signNow);
        submitForm();
    };

    return (
        <Form>
            {
                // submitAttempted && 
                // props.dirty && Object.keys(errors).length > 0 &&
                false &&
                <div className="rounded-md bg-red-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                        </div>
                    </div>
                </div>
            }
            <div className="md:hidden -mr-4 pr-4 border-b border-b-gray-200 mb-4 max-h overflow-y-auto overflow-x-hidden" style={{ "max-height": "70vh" }}>
                {
                    values.partiesToSignForm?.map((party, index) => (
                        <MobileSignature key={party.key} index={index} party={party}
                            availableSignAuthMethods={availableSignAuthMethods}
                            digitalSigningTranslations={digitalSigningTranslations}
                            lockSignatures={lockSignatures}
                            createFieldName={createFieldName}
                            digitalSigningProvider={digitalSigningProvider}
                            handleRemove={handleRemove} />
                    ))
                }
            </div>

            <table className="min-w-full mb-1 hidden md:table divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {availableSignAuthMethods?.length > 0 && digitalSigningProvider === 'scrive' &&
                            (<th
                                scope="col"
                                className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                                Authorization
                            </th>)}
                        <th
                            scope="col"
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                            {digitalSigningTranslations?.firstColumn ?? 'Name'}
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                            {digitalSigningTranslations?.secondColumn ?? 'Email'}
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {
                        values.partiesToSignForm?.map((party, index) => (
                            <tr key={party.key} className="grid grid-cols-1 md:table-row ">
                                {availableSignAuthMethods?.length > 0 && digitalSigningProvider === 'scrive' &&
                                    (<td className="px-0 w-full sm:w-auto py-1 whitespace-nowrap text-sm font-medium text-gray-900 relative align-top">

                                        <div className="-mx-4 md:-ml-4 md: mr-0">
                                            <div className="md:hidden">
                                                Authorization
                                            </div>
                                            <Select
                                                name={createFieldName("signatureAuthorizationChoosenMethod", index)}
                                                value={party.signatureAuthorizationChoosenMethod}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    const name = event.target.name;
                                                    handleChange({ target: { name, value } });
                                                }}
                                                options={availableSignAuthMethods}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                    </td>)
                                }
                                <td className="px-0 sm:px-3 py-5 whitespace-nowrap text-sm font-medium text-gray-900 relative align-top">
                                    <TextInput
                                        name={createFieldName("name", index)}
                                        value={party.name}
                                        data-option-type={"text"}
                                        disabled={lockSignatures}
                                        hasError={errors?.partiesToSignForm && errors.partiesToSignForm[index]?.name}
                                        onChange={(value, name) => {
                                            handleChange({ target: { name, value } });
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className="text-red-500 font-normal fixed">{touched.partiesToSignForm && touched.partiesToSignForm[index]?.name && errors?.partiesToSignForm && errors.partiesToSignForm[index]?.name}</span>
                                </td>
                                <td className="px-0 w-full sm:w-auto sm:px-3 py-5 whitespace-nowrap text-sm font-medium text-gray-900 align-top">
                                    <TextInput
                                        name={createFieldName("email", index)}
                                        value={party.email}
                                        data-option-type={"email"}
                                        disabled={lockSignatures}
                                        hasError={errors?.partiesToSignForm && errors.partiesToSignForm[index]?.email}
                                        onChange={(value, name) => {
                                            handleChange({ target: { name, value } });
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className={'text-red-500 font-normal fixed'}>{touched.partiesToSignForm && touched.partiesToSignForm[index]?.email && errors?.partiesToSignForm && errors.partiesToSignForm[index]?.email}</span>

                                </td>
                                <td className="w-8 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                                    <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                        {/* {
                                            !isSingleMode && (<PlusCircleIcon className="w-5 cursor-pointer" onClick={() => handleAdd()} />)
                                        } */}

                                        {
                                            index > 0 && <TrashIcon className="w-5 cursor-pointer hover:text-red-500" onClick={() => handleRemove(index)} />
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>

            <div className="flex justify-end mr-0">
                {

                    !isSingleMode &&
                    (
                        <button
                            onClick={() => handleAdd()}
                            type="button"
                            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <span className="flex items-center gap-x-2"><PlusIcon className="w-4 h-4 cursor-pointer" />Add Signature</span>

                        </button>
                    )
                    // (<PlusCircleIcon className="w-5 cursor-pointer" onClick={() => handleAdd()} />)
                }
            </div>

            <div className="pt-5 mt-5 flex justify-end items-strech border-t border-gray-300">
                <button
                    onClick={handleClose}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    {digitalSigningTranslations?.cancel || 'Cancel'}
                </button>
                <button
                    onClick={(e) => handleSubmitParties(e)}
                    type="button"
                    disabled={!isValid || isSubmitting}
                    className="ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    {digitalSigningTranslations?.submit || 'Submit'}
                </button>

                {/* {
                    digitalSigningAllowDirectSigning && !isSingleMode && (
                        <button
                            // onClick={(e) => handleSubmitParties(e, true)}
                            onClick={(e) => handleSignDirect(e)}
                            type="button"
                            disabled={isSubmitting}
                            className="ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            {digitalSigningTranslations?.signNowBtn}
                        </button>
                    )

                } */}
                {
                    digitalSigningAllowDirectSigning && (
                        <button
                            // onClick={(e) => handleSubmitParties(e, true)}
                            onClick={(e) => handleSubmitParties(e, true)}
                            type="button"
                            disabled={!isValid || isSubmitting || values.partiesToSignForm.length > 1}
                            className="ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            {digitalSigningTranslations?.signNowBtn}
                        </button>
                    )
                }

            </div>
        </Form>
    )
}

const MobileSignature = ({ index, party, availableSignAuthMethods, lockSignatures, createFieldName, digitalSigningTranslations, digitalSigningProvider, handleRemove }) => {
    const { values, isSubmitting, errors, touched, handleBlur, setFieldValue, handleChange, handleSubmit, submitForm, isValid, ...props } = useFormikContext();
    return (
        <div>
            <div className="w-full space-y-2 pb-2 mb-2 border-t border-gray-200">
                {
                    availableSignAuthMethods?.length > 0 && digitalSigningProvider === 'scrive' && (<div className="-mx-4">
                        <Select
                            name={createFieldName("signatureAuthorizationChoosenMethod", index)}
                            value={party.signatureAuthorizationChoosenMethod}
                            label={'Authorization'}
                            onChange={(event) => {
                                const value = event.target.value;
                                const name = event.target.name;
                                handleChange({ target: { name, value } });
                            }}
                            options={availableSignAuthMethods}
                            onBlur={handleBlur}
                        />
                    </div>)
                }

                <div>
                    <TextInput
                        name={createFieldName("name", index)}
                        value={party.name}
                        data-option-type={"text"}
                        disabled={lockSignatures}
                        label={digitalSigningTranslations?.firstColumn ?? 'Name'}
                        hasError={errors?.partiesToSignForm && errors.partiesToSignForm[index]?.name}
                        onChange={(value, name) => {
                            handleChange({ target: { name, value } });
                        }}
                        onBlur={handleBlur}
                    />
                </div>
                <div>
                    <TextInput
                        name={createFieldName("email", index)}
                        value={party.email}
                        data-option-type={"email"}
                        label={digitalSigningTranslations?.secondColumn ?? 'Email'}
                        disabled={lockSignatures}
                        hasError={errors?.partiesToSignForm && errors.partiesToSignForm[index]?.email}
                        onChange={(value, name) => {
                            handleChange({ target: { name, value } });
                        }}
                        onBlur={handleBlur}
                    />
                    <span className={'text-red-500 font-normal fixed'}>{touched.partiesToSignForm && touched.partiesToSignForm[index]?.email && errors?.partiesToSignForm && errors.partiesToSignForm[index]?.email}</span>
                </div>
                {
                    index > 0 && (
                        <div className="px-4 flex justify-end">
                            <TrashIcon className="w-5 cursor-pointer hover:text-red-500" onClick={() => handleRemove(index)} />
                        </div>
                    )
                }


            </div>
        </div>
    )
}