import Select from "react-select";

export const MultiSelect = ({ onChange, label, name, options, value }) => {

  const values = options?.filter(option => value?.includes(option?.value));

  const handleOnChange = (selectedOptions) => {
    const event = {
        target: {
            name: name,
            value: selectedOptions?.map(option => option?.value)
        }
    }
    onChange(event)
  }

  return (
    <div className={`px-4 sm:px-6 pt-3 flex flex-col justify-between`}>
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <Select
        isMulti
        onChange={handleOnChange}
        name="multi-select"
        options={options}
        className="multi-select"
        classNamePrefix="select"
        value={values}
      />
    </div>
  );
}