import { conditionTypesByElementType } from "../../../utils/constants"

export const ConditionTypeByElementTypeSelector = ({ condition, onChange, elementType }) => {

    const conditionTypes = conditionTypesByElementType(
        elementType
    )

    const onConditionTypeChange = (e) => {
        onChange({ ...e, target: { ...e.target, name: "conditionType", value: parseInt(e.target.value, 10) } })
    }

    return (
        conditionTypes.length === 1 ?
            <div className="px-1 text-sm bg-brand-background">
                <div className="border-gray-400 bg-white border rounded-md m-1 px-1 py-px flex font-semibold text-xs">
                    {conditionTypes[0]}
                </div>
            </div> : <div className="px-1 text-sm bg-brand-background">
                <select
                    className="border-gray-400 bg-white border rounded-md m-1 px-1 py-px flex font-semibold text-xs"
                    name="conditionType"
                    value={condition.condition.conditionType} onChange={onConditionTypeChange}>
                    {conditionTypes.map((conditionType, index) => conditionType && (<option value={index}>{conditionType}</option>))}
                </select>
            </div>
    )
}